'use strict';

commusoftCommon.value('version', '0.1')
    .service('fileName', function () {
        this.getFilenameFromContentDisposition = function getFilenameFromContentDisposition(contentDisposition) {
            if (angular.isString(contentDisposition)) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);

                if (matches !== null) {
                    const filename = matches[1].replace(/['"]/g, ''); // Remove quotes if present
                    return decodeURIComponent(filename);
                }
            }
            return 'default_filename.csv'; // Fallback filename if none found
        }
    })
    .service('responseToBlob', function ($window, fileName) {
        this.download = function download(response) {
            const contentDisposition = response.headers('Content-Disposition');
            const filename = fileName.getFilenameFromContentDisposition(contentDisposition);

            // Create Blob and trigger file download
            const blob = new Blob([response.data], {type: response.headers('Content-Type')});
            const url = $window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            $window.URL.revokeObjectURL(url);
        }
    });
