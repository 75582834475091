'use strict';

function NewSuppliersCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix, $rootScope, formService,ProvinceService) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.shouldBeOpen = false;
    $scope.addressArray = [];
    $scope.error = false;
    $scope.errorMessage = '';
    $scope.saving = false;
    $scope.invoiceNumberToShow = '';
    $scope.creditNoteNumberToShow = '';

    $scope.docparserTemplate = 0;
    $scope.docparserTemplates = [];

    $scope.loadDocParsers = function() {
        $http.get(prefix + '/listDocParsers').then(function(resp) {
           $scope.docparserTemplates = angular.copy(resp.data.parsers);
           angular.forEach($scope.docparserTemplates, function(val, index) {
              if (val.id == $scope.docparserTemplate) {
                  $scope.docparserTemplateName = val.name;
              }
           });
        });
    };

    $scope.loadDocParsers();

    $scope.searchPostcode = function () {
        if ($scope.postcode != '') {
            $http.get(prefix + '/search_postcode?postcode=' + $scope.postcode).success(function (data) {
                $scope.addressArray = data.address;
                $scope.error = data.error;
                $scope.errorMessage = data.errorMessage;
                $scope.shouldBeOpen = true;
            })
        }
    }

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);
    ProvinceService.getProvinces().then(function(data){
        $scope.countyLists = data;
    });

    $scope.close = function () {
        $scope.shouldBeOpen = false;
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                $rootScope.flashMessage = data.msg;
                $state.transitionTo("loggedin.list_supplier.view.details", {'id': data.id});
            })
    }
}

function SupplierCtrl($scope, $state, $http, prefix, datasets, formService, $timeout, $interval, $window, $rootScope, toastBox, $translate, Upload) {
    $scope.data = datasets;
    $scope.supplierSubNav = false;
    $scope.$state = $state;
    $rootScope.successMessage = '';
    $rootScope.mode = '';

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if (toState.url === '/edit' || toState.url === '/new' || toState.url === '/new_phone_call') {
            $scope.handleFormBreadcrumbs(toState.name);
            $scope.hideSubNav = true;
        } else {
            $scope.hideSubNav = false;
        }

        // When view loads get the return state url
        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)$/);

        if (matches === null) {
            //return;
        }

        $scope.purchaseOrderNav = false;
        $scope.showMiniPODetailsNav = false;
        $scope.supplierInvoiceNav = false;
        $scope.supplierCreditNoteNav = false;
        $scope.returnState = matches ? matches[3] : false;
        $scope.hideSubNavSupplierName = false;
        $scope.supplierPayment = false;
        if ($scope.returnState === 'view.details') {
            $scope.returnState = 'Details';
        }
        if ($scope.returnState === 'view.details_edit') {
            $scope.returnState = 'Details';
            $scope.formBreadcrumbStr = 'Edit.Details';
            $scope.hideSubNav = true;
            $scope.hideSubNavSupplierName = true;
        }

        if ($scope.returnState === 'view.contacts') {
            $scope.returnState = $translate('.Contacts');
            $scope.hideSubNavSupplierName = true;
        }
        if ($scope.returnState === 'view.contacts_new') {
            $scope.returnState = $translate('.Contacts');
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'New.Contact';
            $scope.hideSubNavSupplierName = true;
        }
        if ($scope.returnState === 'view.contacts_edit') {
            $scope.returnState = $translate('.Contacts');
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Edit.Contact';
            $scope.hideSubNavSupplierName = true;
        }

        if ($scope.returnState === 'view.branches') {
            $scope.returnState = $translate('.Branches');
            $scope.hideSubNavSupplierName = true;
        }
        if ($scope.returnState === 'view.new_branch') {
            $scope.returnState = $translate('.Branches');
            $scope.handleSpecialCases(toState.name);
            $scope.hideSubNavSupplierName = true;
            $scope.formBreadcrumbStr = 'New.Branch';
        }
        if ($scope.returnState === 'view.edit_branch') {
            $scope.returnState = $translate('.Branches');
            $scope.handleSpecialCases(toState.name);
            $scope.hideSubNavSupplierName = true;
            $scope.formBreadcrumbStr = 'Edit.Branch';
        }
        if ($scope.returnState === 'view.files') {
            $scope.returnState = $translate('.Files');
            $scope.hideSubNavSupplierName = true;
        }
        if ($scope.returnState === 'view.new_file') {
            $scope.returnState = $translate('.Files');
            $scope.hideSubNav = true;
            $scope.handleSpecialCases(toState.name);
            $scope.hideSubNavSupplierName = true;
            $scope.formBreadcrumbStr = 'New.File';
        }
        if ($state.current.name == 'loggedin.list_supplier.view.notes.table' || $state.current.name == 'loggedin.list_supplier.view.notes.timeline') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNavSupplierName = true;
            if($state.current.name == 'loggedin.list_supplier.view.notes.table'){
                $scope.tableview = true;
                $scope.timelineview = false;
            } else {
                $scope.tableview = false;
                $scope.timelineview = true;
            }
        }
        if ($state.current.name === 'loggedin.list_supplier.view.notes_new') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Add.New.Note';
            $scope.hideSubNavSupplierName = true;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.notes_edit') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Edit.Note';
            $scope.hideSubNavSupplierName = true;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.new_phone_call') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Add.Phone.Call';
            $scope.hideSubNavSupplierName = true;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.new_sms_message') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Send.New.SMS';
            $scope.hideSubNavSupplierName = true;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.details') {
            $scope.hideSubNav = false;
            $scope.returnState = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId + ' / ' + $translate('.Details');
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
        }

        if ($state.current.name == 'loggedin.list_supplier.view.purchase_order.notes.table' || $state.current.name == 'loggedin.list_supplier.view.purchase_order.notes.timeline') {
            $scope.hideSubNav = false;
            $scope.purchaseOrderNav = true;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Communications');
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.new_purchase_order') {
            $scope.hideSubNav = false;
            $scope.returnState = $translate('New.Purchase.Order');
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.new_supplier_invoice') {
            $scope.hideSubNav = false;
            $scope.returnState = $translate('New.Invoice');
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.edit') {
            $scope.hideSubNav = false;
            $scope.returnState = $translate('.Edit');
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.edit') {
            $scope.hideSubNav = false;
            $scope.returnState = $translate('.Edit');
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.complete_purchase_order') {
            $scope.hideSubNav = false;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('Complete.Purchase.Order');
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.edit_purchase_order') {
            $scope.hideSubNav = false;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Edit');
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.notes.new') {
            $scope.hideSubNav = true;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Communications');
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = 'New.Note';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.notes.edit') {
            $scope.hideSubNav = true;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Communications');
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = 'Edit.Note';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.notes.newphonecall') {
            $scope.hideSubNav = true;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Communications');
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = 'New.Phonecall';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.purchaseorderfiles') {
            $scope.hideSubNav = false;
            $scope.purchaseOrderNav = true;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Files');
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.purchase_order.new_purchaseorder_files') {
            $scope.hideSubNav = true;
            $scope.purchaseOrderDetails = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnState = $translate('.Files');
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = 'New.File';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.details') {
            $scope.hideSubNav = false;
            $scope.returnState = $translate('.Details');
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name == 'loggedin.list_supplier.view.invoice.notes.table' || $state.current.name == 'loggedin.list_supplier.view.invoice.notes.timeline') {
            $scope.hideSubNav = false;
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = 'Supplier.Invoice.no';
            $scope.returnState = $translate('.Communications');
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.notes.new') {
            $scope.hideSubNav = true;
            $scope.invoiceDetails = 'Supplier.Invoice.no';
            $scope.returnState = $translate('.Communications');
            $scope.supplierInvoiceNav = true;
            $scope.formBreadcrumbStr = 'New.Note';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.notes.edit') {
            $scope.hideSubNav = true;
            $scope.invoiceDetails = 'Supplier.Invoice.no';
            $scope.returnState = $translate('.Communications');
            $scope.supplierInvoiceNav = true;
            $scope.formBreadcrumbStr = 'Edit.Note';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.notes.new_sms_message') {
            $scope.hideSubNav = true;
            $scope.invoiceDetails = 'Supplier.Invoice.no ';
            $scope.returnState = $translate('.Communications');
            $scope.supplierInvoiceNav = true;
            $scope.formBreadcrumbStr = 'Send.New.SMS';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.notes.newphonecall') {
            $scope.hideSubNav = true;
            $scope.invoiceDetails = 'Supplier.Invoice.no';
            $scope.returnState = $translate('.Communications');
            $scope.supplierInvoiceNav = true;
            $scope.formBreadcrumbStr = 'Add.Phone.Call';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.new_supplier_invoice_payment') {
            $scope.hideSubNav = false;
            $scope.returnState = 'New.Payment';
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.view_payment') {
            $scope.hideSubNav = false;
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.returnState = '';
            $scope.supplierPayment = true;
            $scope.supplierPaymentText = "Payment.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.view_payment.edit') {
            $scope.hideSubNav = false;
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.returnState = '.Edit';
            $scope.supplierPayment = true;
            $scope.supplierPaymentText = "Payment.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.new_credit_note' || $state.current.name === 'loggedin.list_supplier.view.new_credit_note_returns') {
            $scope.hideSubNav = false;
            $scope.returnState = 'New.Credit.Note';
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if($state.current.name === 'loggedin.list_supplier.view.new_supplier_invoice_credit_note'){
            $scope.hideSubNav = false;
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.returnState = 'New.Credit.Note';
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.details') {
            $scope.hideSubNav = false;
            $scope.returnState = '.Details';
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.new_supplier_credit_note_receive_credit') {
            $scope.hideSubNav = false;
            $scope.returnState = 'Receive.Credit';
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.edit_receive_credit') {
            $scope.hideSubNav = false;
            $scope.returnState = 'Allocation.no' + ' ' + $state.params.allocationId +' / Edit';
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.new_allocation') {
            $scope.hideSubNav = false;
            $scope.returnState = 'New.Allocation';
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.edit_allocation') {
            $scope.hideSubNav = false;
            $scope.returnState = 'Edit.Allocation';
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.new_cash_allocation') {
            $scope.hideSubNav = false;
            $scope.returnState = 'Cash.Allocation';
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.files') {
            $scope.hideSubNav = false;
            $scope.returnState = '.Files';
            $scope.supplierCreditNoteNav = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = $state.params.creditNoteId;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.new_creditnote_files') {
            $scope.hideSubNav = true;
            $scope.returnState = 'CreditNote.Files';
            $scope.creditNote = "Credit.Note.no";
            $scope.supplierCreditNoteNav = true;
            $scope.formBreadcrumbStr = 'New.File';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = $state.params.creditNoteId;
        }

		if ($scope.returnState === 'view.invoice.files') {
            $scope.hideSubNav = false;
            $scope.returnState = '.Files';
            $scope.supplierInvoiceNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }

        if ($state.current.name === 'loggedin.list_supplier.view.invoice.new_file') {
            $scope.hideSubNav = true;
            $scope.invoiceDetails = "Supplier.Invoice.no";
            $scope.returnState = 'Supplier.Files';
            $scope.supplierInvoiceNav = true;
            $scope.formBreadcrumbStr = 'New.File';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = 1;
        }
        if ($state.current.name == 'loggedin.list_supplier.view.credit_note.notes.table' || $state.current.name == 'loggedin.list_supplier.view.credit_note.notes.timeline') {
            $scope.hideSubNav = false;
            $scope.supplierCreditNoteNav = true;
            $scope.returnState = $translate('.Communications');
            $scope.formBreadcrumbStr = '';
            $scope.creditNote = "Credit.Note.no";
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = $state.params.creditNoteId;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.notes.new') {
            $scope.hideSubNav = true;
            $scope.returnState = $translate('.Communications');
            $scope.supplierCreditNoteNav = true;
            $scope.formBreadcrumbStr = 'New.Note';
            $scope.creditNote = "Credit.Note.no";
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = $state.params.creditNoteId;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.notes.edit') {
            $scope.hideSubNav = true;
            $scope.returnState = $translate('.Communications');
            $scope.supplierCreditNoteNav = true;
            $scope.formBreadcrumbStr = 'Edit.Note';
            $scope.hideSubNavSupplierName = true;
            $scope.creditNote = "Credit.Note.no";
            $scope.supplierSubNav = $state.params.creditNoteId;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.new_sms_message') {
            $scope.hideSubNav = true;
            $scope.returnState = $translate('.Communications');
            $scope.supplierCreditNoteNav = true;
            $scope.formBreadcrumbStr = $translate('Send.New.SMS');
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = $state.params.creditNoteId;
        }
        if ($state.current.name === 'loggedin.list_supplier.view.credit_note.notes.newphonecall') {
            $scope.hideSubNav = true;
            $scope.returnState = $translate('.Communications');
            $scope.supplierCreditNoteNav = true;
            $scope.formBreadcrumbStr = 'Add.Phone.Call';
            $scope.hideSubNavSupplierName = true;
            $scope.supplierSubNav = $state.params.creditNoteId;
        }
    });

    $scope.handleSpecialCases = function handleSpecialCases(state) {
        $scope.hideSubNav = true;
        var matches = state.match(/^(.*?)\.(.*?)\.(.*?)$/);
        $scope.formBreadcrumbStr = $scope.formatStr(matches[3]);
    }

    /* ========================================
     Replaces underscores with spaces and then capitalises each word
     ========================================*/
    $scope.formatStr = function formatStr(str) {
        var formatted_str = str.split('_');
        for (var i = 0; i < formatted_str.length; i++) {
            formatted_str[i] = formatted_str[i].charAt(0).toUpperCase() + formatted_str[i].slice(1);
        }
        return formatted_str.join(' ');
    }

    $scope.$on('supplier:supplierSubNav', function (event, data) {
        $scope.supplierSubNav = data.supplierSubNav;
        $scope.selectedTab = data.selectedTab;
    });

    $scope.$on('event:setSupplierInvoiceNumber', function (event, invoiceNumber) {
        $scope.invoiceNumberToShow = invoiceNumber;
    });

    $scope.$on('event:setSupplierCreditNoteNumber', function (event, creditNoteNumber) {
        $scope.creditNoteNumberToShow = creditNoteNumber;
    });
    $scope.$on('tabSupplier:selected', function (event, data) {
        if (data != undefined && data != '') {
            $scope.selectedSupplierName = data.companyname;
            $scope.outstandingValue = data.outstanding;
            $scope.overdueValue = data.overdue;
            $scope.telephoneNumber = data.telephonenumber;
            $scope.supplierAddress = data.supplierAddress;
            $scope.selectedId = data.id;
            $scope.contactsCount = data.contactsCount;
            $scope.branchesCount = data.branchesCount;
            $scope.notesCount = data.notesCount;
            $scope.filesCount = data.filesCount;
            $scope.notesCount = data.notesCount;
            $scope.hideSubNavSuppliers = true;
            $scope.showPoMiniNav = false;
            if(data.showPoMiniNav) {
                $scope.showPoMiniNav = true;
            }
            if(data.showMiniPODetailsNav) {
                $scope.showMiniPODetailsNav = true;
            }
        } else {
            $scope.contactsCount = 0;
            $scope.branchesCount = 0;
            $scope.filesCount = 0;
            $scope.notesCount = 0;
            $scope.formBreadcrumbStr = '';
            $scope.returnState = '';
            $scope.selectedId = '';
            $scope.selectedSupplierName = '';
            $scope.hideSubNavSuppliers = false;
        }
    });

    $scope.$on('tabSupplier:successMessage', function (event, data) {
        $rootScope.successMessage = data;
        toastBox.show($rootScope.successMessage, 4000);

    });

    $scope.getBack = function getBack() {
        if ($scope.returnState == 'Details') {
            $scope.supplierEditdetails($scope.selectedId);
        } else if ($scope.returnState == 'Contacts') {
            $scope.supplierContacts($scope.selectedId);
        } else if ($scope.returnState == 'Branches') {
            $scope.supplierNewBranches($scope.selectedId);
        } else if ($scope.returnState == 'Files') {
            $scope.supplierNewFiles($scope.selectedId);
        } else if ($scope.purchaseOrderNav && $scope.returnState == 'Communications') {
            if($state.params.mode == 'timeline'){
                $state.transitionTo("loggedin.list_supplier.view.purchase_order.notes.timeline", {'id': $state.params.id, 'purchaseOrderId': $state.params.purchaseOrderId});
            } else {
                $state.transitionTo("loggedin.list_supplier.view.purchase_order.notes.table", {'id': $state.params.id, 'purchaseOrderId': $state.params.purchaseOrderId});
            }
        } else if ($scope.supplierInvoiceNav && $scope.returnState == 'Communications') {
            if($state.params.mode == 'timeline'){
                $state.transitionTo("loggedin.list_supplier.view.invoice.notes.timeline", {'id': $state.params.id, 'invoiceId': $state.params.invoiceId});
            } else {
                $state.transitionTo("loggedin.list_supplier.view.invoice.notes.table", {'id': $state.params.id, 'invoiceId': $state.params.invoiceId});
            }
        } else if ($scope.returnState == 'Communications' && $state.params.mode == 'table') {
            $scope.supplierNotesList($scope.selectedId);
        } else if ($scope.returnState == 'Communications' && $state.params.mode == 'timeline') {
            $scope.supplierNotesTimeviewList($scope.selectedId);
        } else if ($scope.returnState == '.Files') {
            $scope.purchaseOrderNewFiles($state.params.id, $state.params.purchaseOrderId);
        } else if ($scope.returnState == 'CreditNote.Files') {
            $scope.creditNoteListFiles($state.params.id, $state.params.creditNoteId);
        } else if ($scope.returnState == 'Supplier.Files') {
            $scope.supplierInvoiceNewFiles($state.params.id, $state.params.invoiceId);
        }else if ($scope.supplierCreditNoteNav && $scope.returnState == 'Communications') {
            if ($state.params.mode == 'timeline') {
                $state.transitionTo("loggedin.list_supplier.view.credit_note.notes.timeline", {
                    'id': $state.params.id,
                    'creditNoteId': $state.params.creditNoteId
                });
            } else {
                $state.transitionTo("loggedin.list_supplier.view.credit_note.notes.table", {
                    'id': $state.params.id,
                    'creditNoteId': $state.params.creditNoteId
                });
            }
        }
    }

    $scope.supplierEditdetails = function (id) {
        $state.transitionTo("loggedin.list_supplier.view.details", {'id': id});
    }
    $scope.supplierNewBranches = function (id) {
        $state.transitionTo("loggedin.list_supplier.view.branches", {'id': id});
    }
    $scope.supplierContacts = function (id) {
        $state.transitionTo('loggedin.list_supplier.view.contacts', {'id': id});
    }
    $scope.supplierNewFiles = function (id) {
        $state.transitionTo('loggedin.list_supplier.view.files', {'id': id});
    }
    $scope.purchaseOrderNewFiles = function (id, purchaseOrderId) {
        $state.transitionTo('loggedin.list_supplier.view.purchase_order.purchaseorderfiles', {'id': id, 'purchaseOrderId': purchaseOrderId});
    }
    $scope.supplierNotesList = function (id) {
        $state.transitionTo("loggedin.list_supplier.view.notes.table", {'id': id});
    }
    $scope.supplierNotesTimeviewList = function (id) {
        $state.transitionTo("loggedin.list_supplier.view.notes.timeline", {'id': id});
    }
    $scope.supplierInvoiceDetails = function (id, invoiceId) {
        $state.transitionTo('loggedin.list_supplier.view.invoice.details', {'id': id, 'invoiceId': invoiceId});
    }
    $scope.supplierCreditNoteDetails = function (id, creditNoteId) {
        $state.transitionTo('loggedin.list_supplier.view.credit_note.details', {'id': id, 'creditNoteId': creditNoteId});
    }
    $scope.creditNoteListFiles = function (id, creditNoteId) {
        $state.transitionTo('loggedin.list_supplier.view.credit_note.files', {'id': id, 'creditNoteId': creditNoteId});
    }
	$scope.supplierInvoiceNewFiles = function (id, invoiceId) {
        $state.transitionTo('loggedin.list_supplier.view.invoice.files', {'id': id, 'invoiceId': invoiceId});
    }
}

function ListSuppliersCtrl($scope, $state, prefix, $http, suppliersresolver, defaultPagingValue, $rootScope, $timeout) {

    $scope.suppliers = suppliersresolver.suppliers;
    $scope.totalSuppliers = suppliersresolver.count;
    $scope.$emit('tabSupplier:selected', '');
    $scope.ascending = false;
    $scope.descending = true;
    $scope.ascendingaddress = false;
    $scope.descendingaddress = true;

    if ($rootScope.flashMessage) {
        $rootScope.successMessage = $rootScope.flashMessage;
        $timeout(function () {
            $("#saveMessage").fadeOut(4000);
            $("#saveMessage").show();
        }, 1000);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    if ($rootScope.selection != '') {
        $scope.selectedSupplier = $rootScope.selection.rowObject.id;
    }

    $scope.$on("SupplierPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Supplier List';
            $scope.excelRouteName = 'printSupplierDownload';
            var download = prefix + '/' + $scope.excelRouteName + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pageName = 'Supplier List';
            $scope.pdfRouteName = 'printSupplierPdf';
            var print = prefix + '/' + $scope.pdfRouteName + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        } else if (action == "nameAsc") {
            $scope.orderChange(0);
        } else if (action == "nameDesc") {
            $scope.orderChange(1);
        } else if (action == "addressAsc") {
            $scope.orderChange(2);
        } else if (action == "addressDesc") {
            $scope.orderChange(3);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.fetchSuppliers = function (pageNum) {
        var orders = $scope.name ? $scope.name : '';
        $http.get(prefix + '/listAllSuppliers?order=' + orders + '&page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.suppliers = data.suppliers;
            $scope.totalSuppliers = data.count;
        })

    }

    $scope.orderChange = function (ordering) {
        $scope.order = ordering;
        if ($scope.order == 0) {
            $scope.descending = true;
            $scope.ascending = false;
            $scope.name = 'nameAsc';
        } else if ($scope.order == 1) {
            $scope.descending = false;
            $scope.ascending = true;
            $scope.name = 'nameDesc';
        } else if ($scope.order == 2) {
            $scope.descendingaddress = true;
            $scope.ascendingaddress = false;
            $scope.name = 'addressAsc';
        } else if ($scope.order == 3) {
            $scope.descendingaddress = false;
            $scope.ascendingaddress = true;
            $scope.name = 'addressDesc';
        }
        $http.get(prefix + '/listAllSuppliers?order=' + $scope.name + '&page=' + $scope.currentPage + '&limit=' + $scope.limit).success(function (data) {
            $scope.suppliers = data.suppliers;
            $scope.totalSuppliers = data.count;
        })
    }

}
function SupplierSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate) {
    var data = getIdData.navBarDetails;
    $scope.selectedId = data.id;
    $scope.selectedTabValue = data.pinedStatus;
    $scope.selectedFavouriteValue = data.favouriteStatus;
    $scope.selectedPartReminderValue = data.partReminderStatus;
    $scope.stockModules = $rootScope.moduleDetails['Stock'];

    $scope.isDelete = data.isDelete;
    $scope.partsModuleAccess = getIdData.partsModuleAccess;
    $scope.shortlistedSupplier = getIdData.shortlistedSupplier;

    $scope.$on('tabSupplier:selected', function (event, data) {
        if (data != undefined && data != '') {
            $scope.selectedSupplierName = data.companyname;
            $scope.selectedId = data.id;
            $scope.contactsCount = data.contactsCount;
            $scope.branchesCount = data.branchesCount;
            $scope.notesCount = data.notesCount;
            $scope.filesCount = data.filesCount;
            $scope.notesCount = data.notesCount;
            $scope.hideSubNavSuppliers = true;
            $scope.isDelete = data.isDelete;
            $scope.selectedTabValue = data.pinedStatus;
            $scope.selectedFavouriteValue = data.favouriteStatus;
        }
    });
    $scope.$on('tabSupplier:selected-delete', function (event, data) {
        if (data != undefined && data != '') {
            $scope.isDelete = data.isDelete;
            $scope.selectedTabValue = data.pinedStatus;
            $scope.selectedFavouriteValue = data.favouriteStatus;
        }
    });

    $scope.triggerSupplierDelete = function () {

        if($scope.isDelete == false){
            warningModal.show($translate('You.cannot.delete.this.supplier.due.to.financial.transactions.against.the.account'), $translate('Supplier'));
        } else {
            $scope.supplierId = $scope.selectedId;
            $scope.supplierName = $scope.selectedSupplierName;
            $scope.supplierDeleteShouldBeOpen = true;
        }
    }

    $scope.deleteSupplier = function () {
        $scope.flashMessage = 'Supplier Deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.list_supplier');
    }

    $scope.pinSupplierToHome = function() {

        $http.get(prefix + '/pin_supplier/' + $scope.selectedId).
            success(function (data) {
                if(data.result == 'success'){
                    var flashMessage = $translate('Pined.the.Supplier.Successfully');
                    $scope.$emit('tabSupplier:successMessage', flashMessage);
                    $scope.selectedTabValue = 1;
                }
            });
    }

    $scope.unPinSupplierToHome = function() {

        $http.get(prefix + '/un_pin_supplier/' + $scope.selectedId).
            success(function (data) {
                if(data.result == 'success'){
                    var flashMessage = $translate('un.Pined.the.Supplier.Successfully');
                    $scope.$emit('tabSupplier:successMessage', flashMessage);
                    $scope.selectedTabValue = 0;
                }
            });
    }
    $scope.favouriteSupplier = function(status) {
        $http.get(prefix + '/favourite_supplier/' + $scope.selectedId+'/'+status).
        success(function (data) {
            if(data.result == 'success'){
                var flashMessage = (status == 1) ?$translate('set.the.favourite.supplier'):$translate('un.set.the.favourite.supplier');
                $scope.$emit('tabSupplier:successMessage', flashMessage);
                $scope.selectedFavouriteValue = status;
            }
        });
    }

    $scope.partReminderSupplier = function(status) {
        $http.get(prefix + '/set_deliver_part_reminder/' + $scope.selectedId+'/'+status).
        success(function (data) {
            if(data.result == 'success'){
                var flashMessage = (status == 1) ?$translate('Send.parts.reminder.mail.for.supplier'):$translate('Dont.send.parts.reminder.mail.for.supplier');
                $scope.$emit('tabSupplier:successMessage', flashMessage);
                $scope.selectedPartReminderValue = status;
            }
        });
    }

}

function SupplierViewCtrl($scope, $state, getIdData, $http, prefix, $rootScope, canLoad, $q, $timeout, Upload, defaultPagingValue, tableCollection, creditNoteData, warningModal, $translate, confirmationBoxHelper, dragNDropService) {
    $scope.selectedId = $state.params.id;
    $scope.supplierDetails = getIdData;
    $scope.supplierId = $scope.supplierDetails.id;
    $scope.docparserTemplate = $scope.supplierDetails.adminlookupdocparserid;
    $scope.wolseleyData = $scope.supplierDetails.wolseleyData;
    if( $scope.supplierDetails.wolseleyData ) {
        $rootScope.thirdpartyIntegrationName = $scope.supplierDetails.wolseleyData.thirdpartyName;
    }
    $scope.docparserTemplates = [];
    $scope.docparserTemplateName = false;
    $scope.uploadPercentage = '0%';
    $scope.filesToBeUploaded = [];
    $scope.uploadInProgress = false;
    $scope.uploadProgressStyle = { 'width': '0%' };
    $scope.progressBar = 0;
    $scope.uploadInterval = false;
    $scope.purchaseOrders = getIdData.purchaseOrders;
    $scope.purchaseOrderCount = getIdData.purchaseOrderCount;
    $scope.supplierInvoices = getIdData.supplierInvoices;
    $scope.supplierInvoiceCount = getIdData.supplierInvoiceCount;
    $scope.supplierCreditNotes = getIdData.supplierCreditNotes;
    $scope.supplierCreditNotesCount = getIdData.supplierCreditNoteCount;
    $scope.purchaseOrderPage = 1;
    $scope.returnsPage = 1;
    $scope.limit = defaultPagingValue;
    $scope.supplierInvoicePage = 1;
    $scope.supplierInvoiceLimit = defaultPagingValue;
    $scope.supplierCreditNotePage = 1;
    $scope.supplierCreditNoteLimit = defaultPagingValue;
    $scope.searchResult = false;
    $scope.selectedTab = 'Details';
    $scope.showUploadInBackground = false;
    $scope.purchaseOrdersVisible = false;
    $scope.returnsCount = getIdData.returns.supplier_returns.count;

    $scope.customUploadFileParams = {'supplier': {supplierid: $scope.supplierId}, 'file': []};

    dragNDropService.customErrors('docparser', {'pattern': $translate('Please.upload.a.PDF.file')});

    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.uploadFileTypeError = $translate('Please.upload.a.PDF.file');
    $rootScope.$broadcast('tabSupplier:selected-delete', getIdData.navBarDetails);
    $scope.supplierSubNav = false;
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);
    $scope.selectedTab = "Details";

    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.AIAccountSetup = getIdData.AIAccountSetup;
    tableCollection.setData('supplier_returns',  getIdData.returns.supplier_returns);

    $scope.setSupplierReturns = function(returnId){

        if(returnId.length === 0 && $scope.$smartTableInstance.getSelectedRows().length > 0) {
            angular.forEach($scope.$smartTableInstance.getSelectedRows(), function(value, key){
                returnId.push(value.referenceID)
            });
        }
        $scope.supplierReturns = [];
        var poStatus = [];
        var supplierReturns = getIdData.returns.supplier_returns.allReturnsRecords;
        angular.forEach(supplierReturns, function(value, key){
            if(inArray(value.referenceID, returnId)){
                $scope.supplierReturns.push(value);
                if(value.poStatus != 'Completed'){
                    poStatus.push(value.referenceID);
                }
            }
        });
        if(poStatus.length > 0){
            warningModal.clean('return_error');
            warningModal.show($translate('Please raise supplier invoice before raising credit note for reference ' + poStatus.join()), 'Error', 'return_error');
        }else{
            creditNoteData.setSelectedReturns($scope.supplierReturns);
            $state.transitionTo("loggedin.list_supplier.view.new_credit_note_returns",({ id: $state.params.id, returnId: returnId }));
        }
    }

    $scope.setCreditNoteNotRequired = function(returnId){

        if(returnId.length === 0 && $scope.$smartTableInstance.getSelectedRows().length > 0) {
            angular.forEach($scope.$smartTableInstance.getSelectedRows(), function(value, key){
                returnId.push(value.referenceID)
            });
        }

        $scope.returnId = returnId;
        $scope.shouldBeOpen = true;
    }

    $rootScope.$on('smart_table:active_row_id', function (event, message) {
        $scope.returnId = message;
    });

    $scope.continueDocParser = function() {
        $scope.showUploadInBackground = false;

	$rootScope.$broadcast('dragndrop:closeuploadprompt');
    };

    $scope.uploadAction = '/postDocParserDocuments';

    $scope.uploadCallback = function() {
	$scope.showUploadInBackground = true;
    }

    $scope.checkIfUploadPossible = function(files, singleFile, newFiles, duplicateFiles, invalidFiles, evt) {
	   if (Array.isArray(newFiles)) {
		 if (newFiles) {
		      $scope.customUploadFileParams['file'] = files;
		      $rootScope.$broadcast('ngFileUpload', {
			'files': files,
			'customUploadObject': $scope.customUploadFileParams,
			'singleFile': singleFile,
			'newFiles': newFiles,
			'duplicateFiles': duplicateFiles,
			'invalidFiles': invalidFiles,
			'originalEvent': evt
			});
		      return true;
		 }
	   }
    };


    $http.get(prefix + '/listDocParsers').then(function(resp) {
       $scope.docparserTemplates = angular.copy(resp.data.parsers);
       angular.forEach($scope.docparserTemplates, function(val, index) {
          if (val.id == $scope.docparserTemplate) {
              $scope.docparserTemplateName = val.name;
          }
       });
    });

    $scope.isDocparserEnabled = function(permission) {
        var featureId = features['Setupdocparserintegration'];
        return $rootScope.hasPermission(featureId, permission);
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    var canceler = null;

    $scope.fetchPurchaseOrders = function(pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/suppliers/'+ $scope.selectedId +'/listPurchaseOrder?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&status=' + $scope.status, {timeout: canceler.promise}).success(function (data) {
            $scope.purchaseOrders = data.purchaseOrders;
            angular.forEach($scope.purchaseOrders, function (key, value) {
                if($scope.purchaseOrders[value].ponumber) {
                    $scope.purchaseOrders[value].poid = $scope.purchaseOrders[value].ponumber;
                } else {
                    $scope.purchaseOrders[value].poid = $scope.purchaseOrders[value].id;
                }
            });
            $scope.purchaseOrderCount = data.purchaseOrderCount;
            if (searchText != '' || $scope.status !== '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }

  	    if ($('#purchaseorder').offset().top < 162) {
		$rootScope.$broadcast('smoothscroll', {target: '#purchaseorder', offset: 162});
	    }

            $scope.purchaseOrdersVisible = true;
        });
    }

    $scope.$watchCollection('[searchText, status]', function (newVal) {
        if (typeof(newVal) != 'undefined') {
            $scope.fetchPurchaseOrders($scope.purchaseOrderPage);
        }
    });

    $scope.invoicesSearchTimer = false;
    $scope.fetchingInvoices = false;
    $scope.invoicesSearch = false;
    $scope.invoicesQuery = "";

    $scope.beginSearchingInvoices = function() {
        $timeout.cancel($scope.invoicesSearchTimer);
        $scope.invoicesSearchTimer = $timeout(function() {
            $scope.fetchingInvoices = false;
            if ($scope.invoicesQuery.length > 0) {
                $scope.invoicesSearch = true;
                $scope.supplierInvoicePage = 1;
                $scope.fetchSupplierInvoice(1);
            }
            else {
                $scope.supplierInvoicePage = 1;
                $scope.fetchSupplierInvoice(1);
            }
        }, 500);
    };

    $scope.creditNotesSearchTimer = false;
    $scope.fetchingCreditNotes = false;
    $scope.creditNotesSearch = false;
    $scope.creditNotesQuery = "";

    $scope.beginSearchingCreditNotes = function() {
        $timeout.cancel($scope.creditNotesSearchTimer);
        $scope.creditNotesSearchTimer = $timeout(function() {
            $scope.fetchingcreditNotes = false;
            if ($scope.creditNotesQuery.length > 0) {
                $scope.creditNotesSearch = true;
                $scope.supplierCreditNotePage = 1;
                $scope.fetchSupplierCreditNote(1);
            }
            else {
                $scope.supplierCreditNotePage = 1;
                $scope.fetchSupplierCreditNote(1);
            }
        }, 500);
    };

    var supplierInvoiceCanceler = null;

    $scope.fetchSupplierInvoice = function(pageNum) {
        canLoad.setLoadValue(false);
        if (supplierInvoiceCanceler) {
            supplierInvoiceCanceler.resolve();
        }
        supplierInvoiceCanceler = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        if ($scope.invoicesQuery.length > 0) {
            $http.get(prefix + '/suppliers/'+ $scope.selectedId +'/invoices_list?page=' + pageNum + '&limit=' + $scope.supplierInvoiceLimit + '&invoiceSearchText=' + $scope.invoicesQuery, {timeout: supplierInvoiceCanceler.promise}).success(function (data) {
                $scope.supplierInvoices = data.supplierInvoices;
                $scope.supplierInvoiceCount = data.supplierInvoiceCount;

     	        if ($('#supplierinvoice').offset().top < 162) {
		    $rootScope.$broadcast('smoothscroll', {target: '#supplierinvoice', offset: 162});
	        }
            });
        }
        else {
            $http.get(prefix + '/suppliers/'+ $scope.selectedId +'/list_supplier_invoice?page=' + pageNum + '&limit=' + $scope.supplierInvoiceLimit, {timeout: supplierInvoiceCanceler.promise}).success(function (data) {
                $scope.supplierInvoices = data.supplierInvoices;
                $scope.supplierInvoiceCount = data.supplierInvoiceCount;

     	        if ($('#supplierinvoice').offset().top < 162) {
		    $rootScope.$broadcast('smoothscroll', {target: '#supplierinvoice', offset: 162});
	        }
            });
        }
    }

    var creditNoteCanceler = null;

    $scope.fetchSupplierCreditNote = function(pageNum) {
        canLoad.setLoadValue(false);
        if (creditNoteCanceler) {
            creditNoteCanceler.resolve();
        }
        creditNoteCanceler = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        if ($scope.creditNotesQuery.length > 0) {
            $http.get(prefix + '/suppliers/'+ $scope.selectedId +'/credit_notes_list?page=' + pageNum + '&limit=' + $scope.supplierCreditNoteLimit + '&creditSearchText=' + $scope.creditNotesQuery, {timeout: creditNoteCanceler.promise}).success(function (data) {
                $scope.supplierCreditNotes = data.supplierCreditNotes;
                $scope.supplierCreditNotesCount = data.supplierCreditNoteCount;

     	        if ($('#creditnote').offset().top < 162) {
		    $rootScope.$broadcast('smoothscroll', {target: '#creditnote', offset: 162});
	        }
            });
        }
        else {
            $http.get(prefix + '/suppliers/'+ $scope.selectedId +'/list_supplier_credit_note?page=' + pageNum + '&limit=' + $scope.supplierCreditNoteLimit, {timeout: creditNoteCanceler.promise}).success(function (data) {
                $scope.supplierCreditNotes = data.supplierCreditNotes;
                $scope.supplierCreditNotesCount = data.supplierCreditNoteCount;

     	        if ($('#creditnote').offset().top < 162) {
		    $rootScope.$broadcast('smoothscroll', {target: '#creditnote', offset: 162});
	        }
            });
        }
    }

    $scope.fetchReturns = function (page){
        $scope.$smartTableInstance.reload()
    }

    $scope.smartTableSettings = {
        metadata:$scope.supplierDetails.returns.supplier_returns.active_columns,
        rowSelect: true,
        columnEdit: true,
        params: {},
        dataUrl: 'suppliers/'+ $scope.selectedId +'/list_returns',
        category: 'supplier_returns',
        subcategory: 'supplier_returns',
        actions: [
            {
                title: $translate('Raise.credit.note'),
                permissions: ['Creditsnote.writeaccess'],
                onClick: function(data, buttonProp, smartTableInstance, index) {
                    return $scope.setSupplierReturns([data.referenceID]);
                }
            },
            {
                title: $translate('Credit.Note.Not.Required'),
                permissions: ['Creditsnote.writeaccess'],
                onClick: function(data, buttonProp, smartTableInstance, index) {
                    return $scope.setCreditNoteNotRequired([data.referenceID]);
                }
            }
        ]
    };

    $scope.setSmartTableInstance = function($event) {
        $scope.$smartTableInstance = $event;
    };
    
    //wolseley
    $scope.isValidWolseleyAccount = 1;
    $scope.isSupplierPoIntegration = false;
    if( $scope.wolseleyData && $scope.wolseleyData.count > 0 ) {
        if( $scope.wolseleyData.accountStatus == 0 ||  $scope.wolseleyData.accountStatus == 2 ) {
            if( $scope.wolseleyData.accountStatus == 2 ) {
                $scope.wolseleyMessage  = "Invalid "+$scope.wolseleyData.thirdpartyName+" account number provided, please enter correct details by going to Company Settings -> Integrations -> Manage supplier integrations";
            } else {
                $scope.wolseleyMessage  = $scope.wolseleyData.thirdpartyName+" account is pending for approval:  Your details have been sent to "+$scope.wolseleyData.thirdpartyName+" and are pending to be verified";
            }
                $scope.isValidWolseleyAccount = 0;
                $scope.isWolseleySetup = 0;
        } else {
                $scope.isWolseleySetup = 1;
        }
    }
}

function SupplierEditCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.supplierSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierDetails = getIdData;
    $scope.docparserTemplate = $scope.supplierDetails.adminlookupdocparserid;
    $scope.docparserTemplates = [];
    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);
    $scope.supplierName = $scope.supplierDetails.companyname;
    $scope.addressline1 = $scope.supplierDetails.addressline1;
    $scope.addressline2 = $scope.supplierDetails.addressline2;
    $scope.addressline3 = $scope.supplierDetails.addressline3;
    $scope.town = $scope.supplierDetails.town;
    $scope.county = $scope.supplierDetails.county;
    $scope.countyId = $scope.supplierDetails.countyId;
    $scope.postcode = $scope.supplierDetails.postcode;
    $scope.telephone_code = $scope.supplierDetails.countrycode;
    $scope.thirdPartyAccountingRef = $scope.supplierDetails.thirdPartyAccountingRef;
    $scope.nominalCode = $scope.supplierDetails.nominalCode;
    $scope.defaultNominalCode = $scope.supplierDetails.defaultNominalCodeId;
    $scope.emailaddress = $scope.supplierDetails.emailaddress;
    $scope.vatReg = $scope.supplierDetails.vatReg;

    $scope.countyLists = getIdData.countyRecords;

    $scope.loadDocParsers = function() {
        $http.get(prefix + '/listDocParsers').then(function(resp) {
           $scope.docparserTemplates = angular.copy(resp.data.parsers);
           angular.forEach($scope.docparserTemplates, function(val, index) {
              if (val.id == $scope.docparserTemplate) {
                  $scope.docparserTemplateName = val.name;
              }
           });
        });
    };
    /* this is a workaround for a bug in ui-select2 */
/*
    $scope.$watch('docparserTemplate', function(newval, oldval) {
        if (newval == "" || newval == undefined || newval == null || newval == false) {
            $scope.docparserTemplate = oldval;
        }
    });
*/

    if ($scope.supplierDetails.vatReg != 0) {
        $scope.vatReg = $scope.supplierDetails.vatReg;
    } else {
        $scope.vatReg = '';
    }
    if ($scope.supplierDetails.telephone != 0 && $scope.supplierDetails.telephone != "No Numbers Saved") {
        $scope.telephone = $scope.supplierDetails.telephone;
    } else {
        $scope.telephone = '';
    }
    $scope.creditTerms = $scope.supplierDetails.creditTerms;
    $scope.permissionAccess = $scope.supplierDetails.permissionAccess;
    $scope.creditors = $scope.supplierDetails.creditor;
    $scope.shouldBeOpen = false;
    $scope.addressArray = [];
    $scope.error = false;
    $scope.errorMessage = '';
    $scope.AIAccountSetup = getIdData.AIAccountSetup;
    $scope.searchPostcode = function () {
        if ($scope.postcode != '') {
            $http.get(prefix + '/search_postcode?postcode=' + $scope.postcode).success(function (data) {
                $scope.addressArray = data.address;
                $scope.error = data.error;
                $scope.errorMessage = data.errorMessage;
                $scope.shouldBeOpen = true;
            })
        }
    }

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);

    $scope.close = function () {
        $scope.shouldBeOpen = false;
    }

    $scope.editSupplier = function ($event, formStatus) {
        //onchange third party reference enabled customer save button
        $scope.enableSupplierAccountingReference = function () {
            $scope.supplierSaveButtonDisabled = false;
            $scope.validateReference          = false
        }

        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        var county = _.findWhere($scope.countyLists,{'id':parseInt(this.countyId)});

        $http.post(prefix + '/edit_supplier', "supplier[id]=" + $scope.selectedId 
                + "&supplier[name]=" + encodeURIComponent(this.supplierName) 
                + "&supplier[addressline1]=" + encodeURIComponent(this.addressline1)
                + "&supplier[addressline2]=" + encodeURIComponent(this.addressline2) 
                + "&supplier[addressline3]=" + encodeURIComponent(this.addressline3) 
                + "&supplier[telephonenumber]=" + encodeURIComponent(this.telephone) 
                + "&supplier[countrycode]=" + this.telephone_code
                + "&supplier[town]=" + encodeURIComponent(this.town) 
                + "&supplier[settingsstateprovinceid]=" + (this.countyId > 0 ? this.countyId : '')
            + "&supplier[county]=" + encodeURIComponent(county ? county.description : '')
                + "&supplier[postcode]=" + encodeURIComponent(this.postcode)
                + "&supplier[emailaddress]=" + encodeURIComponent(this.emailaddress)
                + "&supplier[vatreg]=" + encodeURIComponent(this.vatReg) 
                + "&supplier[creditterms]=" + encodeURIComponent(this.creditTerms) 
                + "&supplier[thirdPartyAccountingRef]=" + encodeURIComponent(this.thirdPartyAccountingRef) 
                + "&supplier[defaultNominalCode]=" + encodeURIComponent(this.defaultNominalCode)
                + "&supplier[adminlookupdocparserid]=" + encodeURIComponent(this.docparserTemplate)).
            success(function (data, status) {
                //show validation error if third party accounting reference is invalid
                if (data.error && data.error == 'Invalid third party accounting reference') {
                    $scope.validateReference          = true;
                    $scope.updating                   = false;
                    $scope.supplierSaveButtonDisabled = true;
                } else {
                    $scope.validateReference = false;

                    if (status == 200) {
                        $scope.editSupplierForm.$setPristine();
                        $rootScope.flashMessage = data;
                        $scope.supplierEditdetails($scope.selectedId);
                        $scope.updating = false;
                    }
                }
            });
    }

    $scope.select2Options = {
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.loadDocParsers();
}

function SupplierContactsCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.supplierSubNav = false;
    $scope.selectedTab = "Contacts";
    $scope.contactslist = getIdData.contacts;
    $scope.contactsid = $scope.contactslist.contactsid;
    $scope.contactsCount = $scope.contactslist.contactsCount;
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.supplierId = $scope.supplierDetails.id;
    $scope.selectedId = $state.params.id;
    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }


    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierContacts", function (event, action, data) {
        var myObject = eval('(' + data + ')');
        if (action == "delete") {
            $scope.triggerDelete(myObject.id, myObject.name);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.deleteContacts = function(pageNum) {
        $scope.fetchContacts(pageNum).then(function() {
            $scope.$emit('tabSupplier:successMessage', 'Contact Deleted');
        });
    }

    $scope.fetchContacts = function (pageNum) {
        return $http.get(prefix + '/suppliers/list_supplier/' + $scope.supplierId + '/contacts/view?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.contactslist = data.contacts;
            $scope.contactsCount = data.contacts.contactsCount;
            $scope.selectedId = data.navBarDetails.id;
            $scope.$emit('tabSupplier:selected', data.navBarDetails);
        })
    }
    $scope.triggerDelete = function (id, contacts) {
        $scope.selectedId = id;
        $scope.description = contacts;
        $scope.shouldBeOpen = true;
    }
    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
}

function SupplierNewContactsCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, countries) {
    countries.setNumberTypes(getIdData['numberTypes']);
    $scope.supplierSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierDetails = getIdData;
    $scope.$emit('tabSupplier:selected', getIdData);
    $scope.saving = false;
    $scope.show_date_picker = false;
    $scope.selectedDate = '';
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $scope.checkValidNumber = true;
    $scope.$on('GetErrorMessage', function (event, data) {
        $scope.checkValidNumber = data;
    });
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newContactForm.$setPristine();
                $rootScope.flashMessage = data.success;
                $scope.supplierContacts($scope.selectedId);
                $scope.saving = false;
            });
    }
    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });
}

function SupplierEditContactsCtrl($scope, $state, $http, prefix, getIdData, $rootScope, countries, CS_LANGUAGE) {
    countries.setNumberTypes(getIdData.numberTypes);
    $scope.supplierSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.contactsDetails = getIdData.contacts;
    $scope.contactsDetails = getIdData.contacts;
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.supplierid = $scope.supplierDetails.id;
    $scope.branchesDetail = getIdData.branchesDetails;
    $scope.branchAllow = getIdData.contacts.branchAllow;
    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.contactId = $scope.contactsDetails.id;
    $scope.contactName = $scope.contactsDetails.name;
    $scope.contactSurname = $scope.contactsDetails.surname;
    $scope.numbers_collection = [];
    $scope.countries = [];
    $scope.numbers_collection = $scope.contactsDetails.numbers_collection;
    $scope.countries = countries.getCountries();
    for (var n = 0; n < $scope.numbers_collection.length; n++) {
        for (var i = 0; i < $scope.countries.length; i++) {
            if (parseInt($scope.countries[i].code) === $scope.numbers_collection[n].code) {
                $scope.numbers_collection[n].image_name = $scope.countries[i].image_name;
            }
        }
    }
    $scope.$on("numbersCollection", function (event, data) {
        $scope.numbers_collection = data;
        delete $scope.numbers_collection.selected;
    });
    $scope.checkValidNumber = true;
    $scope.$on('GetErrorMessage', function (event, data) {
        $scope.checkValidNumber = data;
    });
    $scope.emailaddress = $scope.contactsDetails.emailaddress;
    $scope.twitterhandle = $scope.contactsDetails.twitterhandle;
    $scope.facebookurl = $scope.contactsDetails.facebookurl;
    $scope.linkedinurl = $scope.contactsDetails.linkedinurl;
    $scope.branchOfficesid = $scope.contactsDetails.branchOfficesid;
    $scope.position = $scope.contactsDetails.position;
    $scope.show_date_picker = false;
    if(CS_LANGUAGE == 'en-US'){
        var pickerFormat = 'MM/DD/YYYY';
    }else{
        var pickerFormat = 'DD/MM/YYYY';
    }
    if ($scope.contactsDetails.birthdaydate != null) {
        $scope.selectedDate = moment($scope.contactsDetails.birthdaydate.date);
    } else {
        $scope.selectedDate = '';
    }
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $http.get(prefix + '/get_supplier_contacts?id=' + $scope.supplierid).success(function (data) {
        $scope.contactlist = data.contactlist;
        $scope.suppliers = data.suppliers;
    })
    $scope.selectToMerge = function (contactIds) {
        $scope.contactIds = contactIds;
    }

    //Transferring a contact moves the contact from this supplier to another supplier

    $scope.triggerTransfer = function (id, contactSurname, mergeId, supplierid) {
        $scope.mergeandtransferfunction = '/transfer_contact';
        $scope.selectedContent = true;
        $scope.branchesDetail = '';
        $scope.supplierid = supplierid;
        $scope.selectedId = id;
        $scope.mergeId = mergeId;
        $scope.titleName = 'Transfer Contact';
        $scope.description = contactSurname;
        $scope.buttonValue = 'Confirm Transfer Contact';
        $scope.textToConfirm = 'TRANSFER';
        $scope.validationMessage = "'TRANSFER' to Transfer this Contact";
        if ($scope.mergeId != '') {
            $scope.shouldBeOpen = true;
        } else {
            $scope.shouldBeOpen = false;
        }
    }


    //Merging a contact merges them permanently (you cannot undo this) with another contact at the same supplier.

    $scope.triggerMerge = function (contactId, contactSurname, contactIds, supplierid, contactlist, branchesDetail) {
        $scope.mergeandtransferfunction = '/supplier_merge_contact';
        $scope.contactdetails = contactlist;
        $scope.branchesDetail = branchesDetail;
        $scope.selectedContent = false;
        $scope.supplierid = supplierid;
        $scope.selectedId = contactId;
        $scope.mergeId = contactIds;
        $scope.titleName = 'Merge contact';
        $scope.description = contactSurname;
        $scope.buttonValue = 'Confirm Merge Contact';
        $scope.textToConfirm = 'MERGE';
        $scope.validationMessage = "'MERGE' to Merge this Contact";
        if ($scope.mergeId != '' && $scope.mergeId != $scope.selectedId) {
            $scope.shouldBeOpen = true;
        } else {
            $scope.shouldBeOpen = false;
        }
    }

    $scope.editContact = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        if (this.selectedDate == '' ||  this.selectedDate == undefined) {
            var editDate = '';
        } else {
            var editDate = moment(this.selectedDate).format("YYYY-MM-DD");
        }
        $http.post(prefix + '/edit_supplier_contact', "contact[contactId]=" + $scope.contactId
                + "&contact[name]=" + encodeURIComponent(this.contactName)
                + "&contact[surname]=" + encodeURIComponent(this.contactSurname)
                + "&contact[emailaddress]=" + encodeURIComponent(this.emailaddress)
                + "&contact[contactstelephone]=" + angular.toJson($scope.numbers_collection)
                + "&contact[position]=" + encodeURIComponent(this.position)
                + "&contact[selectedDate]=" + editDate
                + "&contact[branchOfficesid]=" + this.branchOfficesid
                + "&contact[twitterhandle]=" + encodeURIComponent(this.twitterhandle)
                + "&contact[facebookurl]=" + encodeURIComponent(this.facebookurl)
                + "&contact[linkedinurl]=" + encodeURIComponent(this.linkedinurl)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.editContactForm.$setPristine();
                    $rootScope.flashMessage = data;
                    console.log($rootScope.flashMessage);
                    $scope.supplierContacts($scope.supplierid);
                    $scope.updating = false;
                }

            });
    }


    // $rootScope.$on("datepicker_selected", function (event, message) {
    //     $scope[message.modelName] = message.date;
    // });

    $scope.$on("contactTransfer", function (event, message) {
        $scope.contactId = message.contactId;
        $scope.mergeId = message.mergeId;
    });

    $scope.fetchSuppliercontent = function (contactId, mergeId, titleName, supplierid) {
        $scope.titleName = titleName;
        if ($scope.titleName == "Merge contact") {
            $rootScope.flashMessage = "Contact Merged";
            $state.transitionTo("loggedin.list_supplier.view.contacts_edit", {'id': supplierid, 'contactsid': $scope.mergeId});
        } else if ($scope.titleName == "Transfer Contact") {
            $rootScope.flashMessage = "Contact Transferred";
            $state.transitionTo("loggedin.list_supplier.view.contacts_edit", {'id': mergeId, 'contactsid': $scope.contactId});
        }
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_suppliers',
            data: function (term, page) {
                return { id: $scope.selectedId, 'q': term, 'excludeSuppliers': $scope.selectedId};
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }

    $scope.canShowTransferButton=function() {
        return ($scope.transferSupplierId == null || $scope.transferSupplierId.id == null ||
            $scope.transferSupplierId.id == '');
    }
}

function SupplierBranchListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, canLoad, $q, $rootScope, warningModal) {
    $scope.branches = getIdData.customerOffices;
    $scope.branchesCount = getIdData.count;
    $scope.wolseleyData = getIdData.wolseleyData;
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Branches";
    $scope.supplierSubNav = false;
    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierBranches", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("BranchesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Branches List';
            $scope.excelRouteName = 'downloadSupplierBranches';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Branches List';
            $scope.pdfRouteName = 'printSupplierBranches';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.fetchBranches($scope.currentPage);
        }
    });

    var canceler = null;
    $scope.fetchBranches = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/suppliers/list_supplier/' + $scope.selectedId + '/branches/view?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.branches = data.customerOffices;
            $scope.branchesCount = data.count;
            $scope.$emit('tabSupplier:selected', data.supplierDetails);

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.triggerDelete = function (id, selectedId, branchname) {
        $http.get(prefix + '/check_supplier_branch_delete?id=' + id).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.branchId = id;
                $scope.customerId = selectedId;
                $scope.branchName = branchname;
                $scope.shouldBeOpen = true;
            }
        });
    }
    
    $scope.isWolseleySetup = 0;
    if( $scope.wolseleyData && $scope.wolseleyData.count > 0 ) {
        if( $scope.wolseleyData.accountStatus == 1 ) {
            $scope.isWolseleySetup = 1;
        } else {
            $scope.isWolseleySetup = 0;
        }
    } else {
        $scope.isWolseleySetup = 0;
    }
    
}


function CreateSupplierNewBranchCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);
    $scope.selectedId = $state.params.id;
    $scope.supplierSubNav = true;
    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);
   
    if(  getIdData.wolseleyBranches ) {
        $scope.wolseleyBranches = getIdData.wolseleyBranches.details;
    }
   
    $scope.handleWolseleyBranchChange = function(branchcode) {
       if( $scope.wolseleyBranches && $scope.wolseleyBranches[branchcode]) {
        $scope.addressline1 =  $scope.wolseleyBranches[branchcode]['addressLine1'];
        $scope.addressline2 =  $scope.wolseleyBranches[branchcode]['addressLine2'];
        $scope.addressline3 =  $scope.wolseleyBranches[branchcode]['addressLine3'];
        $scope.branchname =  $scope.wolseleyBranches[branchcode]['branchName'];
        $scope.postcode =  $scope.wolseleyBranches[branchcode]['postCode'];
      }
    }
   
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newBranchForm.$setPristine();
                $scope.supplierId = data.suppliersid;
                $rootScope.flashMessage = "Branch Saved";
                $scope.supplierNewBranches($scope.supplierId);
                $scope.saving = false;
            })
    }
}

function EditSupplierBranchCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);
    BranchMergeAndDeleteCtrl.call(this, $scope);
    $scope.selectedId = $state.params.id;
    $scope.supplierSubNav = true;
    $scope.branches = getIdData.branches;
    $scope.branchDetails = getIdData.branchDetails;
    $scope.branchcode = getIdData.branchDetails.branchcode;
    $scope.addressline1 = getIdData.branchDetails.addressline1;
    $scope.addressline2 = getIdData.branchDetails.addressline2;
    $scope.addressline3 = getIdData.branchDetails.addressline3;
    $scope.telephone_code = getIdData.branchDetails.telephoneCountrycode;
    $scope.telephone = getIdData.branchDetails.telephone;
    $scope.mobile_code = getIdData.branchDetails.mobileCountrycode;
    $scope.mobile = getIdData.branchDetails.mobile;
    $scope.email = getIdData.branchDetails.email;
    $scope.town = getIdData.branchDetails.town;
    $scope.countyId = getIdData.branchDetails.countyId;
    $scope.countyLists = getIdData.countyRecords;
    $scope.wolseleyData = getIdData.wolseleyData;
    $scope.wolseleyBranches = getIdData.wolseleyBranches;
    $scope.wolseleyBranchDetails = getIdData.wolseleyBranchDetails;
    $scope.postcode = getIdData.branchDetails.postcode;
    $scope.showPostCodeLookUp = getIdData.showPostCodeLookUp;
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);

    $scope.backTo = function () {
        $state.transitionTo('loggedin.list_supplier.view.branches', {'id': $scope.selectedId});
    }

    PostCodeCtrl.call(this, $scope, $state, $http, prefix);
    $scope.editBranch = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.saving = true;
        $scope.current = this;
        var county = _.findWhere($scope.countyLists,{'id':parseInt(this.countyId)});
        $http.post(prefix + '/update_supplierbranch', "branch[selectedId]=" + this.selectedId
                + "&branch[branchId]=" + encodeURIComponent(this.branchDetails.branchId)
                + "&branch[branchname]=" + encodeURIComponent(this.branchDetails.branchname)
                + "&branch[branchcode]=" + encodeURIComponent(this.branchDetails.branchcode)
                + "&branch[addressline1]=" + encodeURIComponent(this.addressline1)
                + "&branch[addressline2]=" + encodeURIComponent(this.addressline2)
                + "&branch[addressline3]=" + encodeURIComponent(this.addressline3)
                + "&branch[town]=" + encodeURIComponent(this.town)
                + "&branch[email]=" + encodeURIComponent(this.email)
                + "&branch[telephonecountrycode]=" + encodeURIComponent(this.telephone_code)
                + "&branch[telephone]=" + encodeURIComponent(this.telephone)
                + "&branch[mobilecountrycode]=" + encodeURIComponent(this.mobile_code)
                + "&branch[mobile]=" + encodeURIComponent(this.mobile)
                + "&branch[county]=" + encodeURIComponent(county ? county.description : '')
                + "&branch[settingsstateprovineid]=" + (this.countyId > 0 ? this.countyId : '')
                + "&branch[postcode]=" + encodeURIComponent(this.postcode)).
            success(function (data, status) {
                if (status == 200) {
                    $rootScope.flashMessage = "Branch Updated";
                    $state.transitionTo('loggedin.list_supplier.view.branches', {'id': $scope.selectedId});
                    $scope.saving = false;
                }
            });
    }

    $scope.fetchBranch = function (selectedId, mergeId, titleName) {
        $scope.titleName = titleName;
        if ($scope.titleName == "Merge branch") {
            $rootScope.flashMessage = "Branch Merged";
            $state.transitionTo("loggedin.list_supplier.view.branches", { 'id': selectedId });
        } else if ($scope.titleName == "Disable Branch") {
            $rootScope.flashMessage = "Branch Disabled";
            $scope.supplierNewBranches(selectedId);
        }

    }
    
    $scope.handleWolseleyBranchChange = function(branchcode) {
        if( $scope.wolseleyBranchDetails && $scope.wolseleyBranchDetails[branchcode]) {
           $scope.addressline1 =  $scope.wolseleyBranchDetails[branchcode]['addressLine1'];
           $scope.addressline2 =  $scope.wolseleyBranchDetails[branchcode]['addressLine2'];
           $scope.addressline3 =  $scope.wolseleyBranchDetails[branchcode]['addressLine3'];
           $scope.branchDetails.branchname =  $scope.wolseleyBranchDetails[branchcode]['branchName'];
           $scope.postcode =  $scope.wolseleyBranchDetails[branchcode]['postCode'];
       }
    }
}

function GetSupplierFilesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, tableCollection) {
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.supplierSubNav = false;
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};
    tableCollection.setData('attached_files', getIdData.attached_files);

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("SuppliersAttachedFile", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "preview") {
            $scope.previewFile(myObject);
        } else if (action == "delete") {
            $scope.deleteFile(myObject.id, myObject.name);
        } else if (action == "download") {
            DownloadFunction.call(this, $scope, myObject.location, myObject.name);
        }

    });

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {

	 $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_supplier_files', { id: $scope.selectedId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.$emit('tabSupplier:selected', data.supplierDetails);
            tableCollection.setData('attached_files', data.attached_files);
            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }

    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });
/*
    $scope.$on("file:preview", function(evt, data) {
        console.log("In Supplier Attached Files");
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        var file = {
            'name': data['File name'],
            'location': data['location'],
            'mimeType': data['Type'],
            'createdondatetime': data['createdondatetime'],
            'type': data['Type'],
            'id': data['id']
        };
        $scope.previewFile(file);
    });

    $scope.previewFile = function (fileObj) {
        $scope.enablePreviewFiles = "SuppliersAttachedFile";
        $scope.imageFile = false;
        $scope.loadFromObject = false;
        $scope.file = fileObj;
        $scope.selectedRow = fileObj.id;

	    $scope.image360 = false;
	    $scope.videoFile = false;
	    $scope.viewableFile = false;
	    $scope.image360 = $scope.detect360Images(fileObj.location);
	    $scope.fileTypeDetectionFinished = false;

	    if (fileObj.type.toLowerCase() == 'jpg' || fileObj.type.toLowerCase() == 'jpeg') {
		    $scope.image360.then(function(isImage360) {
			    if (isImage360 == true) {
			      $scope.image360 = true;
			      $scope.viewableFile = true;

			      pannellum.viewer('panorama', {
				  'type': 'equirectangular',
				  'panorama': fileObj.location,
				  'autoLoad': true,
				  'autoRotate': -5,
				  'autoRotateStopDelay': 4000
			      });
			    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
			      $scope.imageFile = true;
			      $scope.viewableFile = true;
			    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
			      $scope.loadFromObject = true;
			    }

			    $scope.fileTypeDetectionFinished = true;

			    $scope.$apply();
		    });
	    }
	    else {
		    if (fileObj.type.toLowerCase() == 'mp4' || fileObj.type.toLowerCase() == 'webm' || fileObj.type.toLowerCase() == 'ogm') {
		      $scope.videoFile = true;
		      $scope.viewableFile = true;
		      $scope.fileTypeDetectionFinisheed = true;
		    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
		      $scope.imageFile = true;
		      $scope.viewableFile = true;
		    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
		      $scope.loadFromObject = true;
		    }

		    $scope.fileTypeDetectionFinished = true;
	    }
	    $scope.fileSelected = true;
    }
	  $scope.detect360Images = function(fileUrl) {
	    var element = {};
	    element['src'] = fileUrl;

	    var async = $q.defer();

	    EXIF.enableXmp();
	    EXIF.getData(element, function() {
		var make = EXIF.getTag(this, "Make");
		var model = EXIF.getTag(this, "Model");
		var panoramaFound = false;
		if (make) {
		  if (make.toLowerCase().indexOf('ricoh') > -1) {
		      panoramaFound = true;
		      async.resolve(true);
		  }
		}
		if (model && !panoramaFound) {
		  if (model.toLowerCase().indexOf('ricoh') > -1) {
		      panoramaFound = true;
		      async.resolve(true);
		  }
		}
		async.resolve(false);
	    });

	    return async.promise;
	  }
    $scope.closePreview = function () {
        $scope.enablePreviewFiles = '';
        $scope.selectedRow = "";
        $scope.file = "";
        $scope.fileSelected = false;
        $scope.recordArgs = '';
        $rootScope.selection = '';
        $rootScope.$broadcast('closeall');
    }
*/
}

function AddSupplierFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService) {
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink= NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    $scope.error = false;
    $scope.testDrag = true;
    $scope.supplierSubNav = true;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_supplier_file?mode=suppliers&selectedId=' + $scope.selectedId;
    $scope.uploadAction = $scope.action;

    $scope.uploadCallback = function() {
        $scope.supplierNewFiles($scope.selectedId);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File Uploaded";
            $scope.supplierNewFiles($scope.selectedId);
        }
    }
    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}


function SupplierNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope, defaultPagingValue, canLoad, $q, $sce, emailService) {

    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;

    $scope.supplierSubNav = false;
    $scope.returnNotes = true;
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.supplierId = $scope.supplierDetails.id;
    $scope.selectedId = $state.params.id;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notesDetails = getIdData.notesDetails;
        $scope.notesCount = getIdData.notesDetails.notesCount;
        $scope.notesCountMain = getIdData.notesDetails.notesCountMain;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $scope.supplierId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=3&id=' + $scope.supplierId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 3;        // For Supplier
        clone_obj.relatedObjectId = $scope.supplierId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.currentPagePhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.currentPagePhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.searchCommunications(1);
    });

    var searchCanceler = null;
    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (searchCanceler) {
            searchCanceler.resolve();
        }
        searchCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/list_supplier/' + $scope.supplierId + '/notes/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: searchCanceler.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.printLetters = getIdData.printLetters;
            $scope.printLettersCount = getIdData.printLettersCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.selectedId = data.navBarDetails.id;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/list_supplier/' + $scope.supplierId + '/notes/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;

            $scope.$parent.$parent['notesCount'] = data.notesDetails.notesCount; // The scope that shows the count is up because of ng-ifs

            //$scope.$emit('tabSupplier:selected', data.navBarDetails);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        });
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNumPhoneCalls) {
        canLoad.setLoadValue(false);
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/list_supplier/' + $scope.supplierId + '/notes/view/table?pagePhoneCalls=' + pageNumPhoneCalls + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceler = null;
    $scope.fetchSmsMessages = function (pageNumSmsMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceler) {
            smsCanceler.resolve();
        }
        smsCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/list_supplier/' + $scope.supplierId + '/notes/view/table?pageSmsMessages=' + pageNumSmsMessages + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: smsCanceler.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNumEmailMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/list_supplier/' + $scope.supplierId + '/notes/view/table?pageEmailMessages=' + pageNumEmailMessages + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix +'/suppliers/list_supplier/' + $scope.supplierId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierNotes", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });
    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.supplierId;
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadsuppliernotes';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.supplierId;
            $scope.pageName = 'Notes List';
            $scope.pdfRouteName = 'printsuppliernotes';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}
function SupplierCommunicationsCtrl($scope, $state, $http, prefix, getIdData, $rootScope, dateRange, emailService) {
    $scope.supplierSubNav = false;
    $scope.selectedTab = "Notes";
    $scope.selectedId = $state.params.id;
    $scope.supplierId = $scope.selectedId;
    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.contacts = getIdData.contacts;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.searchText = '';
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};

        $scope.$broadcast('filterValue', filters);
    });

    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $scope.supplierId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=3&id=' + $scope.supplierId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 3;        // For Supplier
        clone_obj.relatedObjectId = $scope.supplierId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.action = "";
    
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
      
      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }
    var baseRoute = prefix + '/suppliers/' + $state.params.id;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}

function SupplierNewNotesCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.supplierSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierDetails = getIdData;
    $scope.$emit('tabSupplier:selected', getIdData);
    $scope.saving = false;
    $scope.modeChange = $state.params.mode;
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                if (status == 200) {
                    $scope.newNoteForm.$setPristine();
                    $scope.selectedId = data.tablepkid;
                    $rootScope.flashMessage = data.success;
                    if ($scope.modeChange == 'timeline') {
                        $scope.supplierNotesTimeviewList($scope.selectedId);
                    } else if ($scope.modeChange == 'table') {
                        $scope.supplierNotesList($scope.selectedId);
                    }
                    $scope.saving = false;
                }

            });
    }
}

function SupplierEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.supplierSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.supplierid = $scope.supplierDetails.id;
    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': ''};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
                + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
            success(function (data, status) {
                if (status == 200) {
                    $scope.editNoteForm.$setPristine();
                    $rootScope.flashMessage = data;
                    if ($scope.modeChange == 'timeline') {
                        $scope.supplierNotesTimeviewList($scope.selectedId);
                    } else if ($scope.modeChange == 'table') {
                        $scope.supplierNotesList($scope.selectedId);
                    }
                    $scope.updating = false;
                }
            });
    }
}

function SupplierTimelineViewCtrl($scope, $state, $http, prefix, $timeout, $rootScope, canLoad, $q, $sce, emailService) {
    $scope.supplierSubNav = false;
    $scope.returnNotes = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierId = $scope.selectedId;

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

   // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierNotes", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $scope.supplierId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=3&id=' + $scope.supplierId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 3;        // For Supplier
        clone_obj.relatedObjectId = $scope.supplierId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    //$scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    var url = '/suppliers/list_supplier/' + $scope.selectedId + '/notes/view/timeline';
    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, null, $sce, $timeout, $rootScope, url, prefix);
}

function SupplierNewPhoneCallCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.supplierSubNav = true;
    $scope.modeChange = $state.params.mode;

    $scope.$emit('tabSupplier:selected', getIdData);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newPhoneCallForm.$setPristine();
                $scope.selectedId = data.tablepkid;
                $rootScope.flashMessage = data.success;
                if ($scope.modeChange == 'timeline') {
                    $scope.supplierNotesTimeviewList($scope.selectedId);
                } else if ($scope.modeChange == 'table') {
                    $scope.supplierNotesList($scope.selectedId);
                }
                $scope.saving = false;
            })
    }
}
function SupplierNewSmsMessageCtrl($scope, $state, datasets, $http, prefix, $rootScope, formService, getIdData, smsTemplates, sidePanel, $sce) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    smsTemplates.setTemplatesValue(getIdData.templateDetails);
    $scope.smsTemplates = getIdData.templateDetails;
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.supplierSubNav = true;
    $scope.modeChange = $state.params.mode;

    $scope.$emit('tabSupplier:selected', getIdData);
    $scope.supplierSubNavTab = {'supplierSubNav': $scope.supplierSubNav, 'selectedTab': $scope.selectedTab};
    $scope.$emit('supplier:supplierSubNav', $scope.supplierSubNavTab);

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });
    SMSMessageControllers.call(this, $scope);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newSmsMessageForm.$setPristine();
                $rootScope.flashMessage = data.success;
                if ($scope.modeChange == 'timeline') {
                    $scope.supplierNotesTimeviewList($scope.selectedId);
                } else if ($scope.modeChange == 'table') {
                    $scope.supplierNotesList($scope.selectedId);
                }
                $scope.saving = false;
            })
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function SupplierNewPurchaseOrderCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, warningModal, $translate, canLoad, $q, $compile, confirmationBoxHelper, toastBox, $timeout, CS_LANGUAGE) {
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.readAccess = getIdData.readAccess;
    $scope.supplierthirdpartyreference = $scope.supplierDetails['thirdpartyRef'];

    $scope.vatType = getIdData.vatType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.supplierDetails.showPoMiniNav = true;
    $scope.selectedSupplierId = $state.params.id;

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);

    //$scope.getPartListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?mode=parts&requireRowTemplate=yes';
    //$scope.getItemListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?mode=items&type=new_purchase_order_items';
    $scope.addUrl = prefix + '/suppliers/' + $state.params.id + '/purchase_order/addSpreadsheet';
    //$scope.new_purchase_order_parts = [];
    //$scope.new_purchase_order_items = [];

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    // Update the default date with all line items in spreadsheet
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
        if(message.name === 'alterDeliveryDate') {
            $scope.alterSave(message.date, 'deliveryDate');
        }
    });

    $scope.addPurchaseOrder = function (event) {
        // $scope.saving = true;
        var note = ($scope.note == undefined) ? '' : $scope.note;
        var date = moment($scope.purchaseOrderDate).format('YYYY-MM-DD');
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date + "&note=" + note + "&vatType=" + encodeURIComponent($scope.vatType)).
            success(function (data, status) {
                if (status == 200) {
                    // $scope.saving = false;
                    if(data.error) {
                        $scope.saving = false;
                        warningModal.clean('supplier_po_error');
                        warningModal.show($translate(data.message), 'Error', 'supplier_po_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                    } else if(data.id > 0) {
                        $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { id: $state.params.id, purchaseOrderId: data.id});
                    }
                }
            });
    }

    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'new_purchase_order');

    /*** Routing URL for to load the default data ***/
    $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?mode=parts&type=new_purchase_order_parts';
    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?mode=items&type=new_purchase_order_items';

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_new_purchase_order";
    $scope.itemHotId = "item_new_purchase_order";
    $scope.showDeliveryDetails = true;
    $scope.isErrorReturn = false;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_new_purchase_order"] = true;
    $scope["item_new_purchase_order"] = true;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'new_purchase_order_parts', warningModal, $timeout, $compile, CS_LANGUAGE);

}
function DefaultListCtrl($scope, $rootScope, $element, clickEvents)
{
    $scope.salesPriceError = false;
    $scope.defaultValueSave = function(value, dropDownType) {

        $scope.executeValue = true;
        //# Check the decimal value
        if(dropDownType == 'salesPrice') {
            var re = /^[0-9]{0,3}(\.[0-9]{1,2})?$/;
            if (!re.test(value)) {
                $scope.executeValue = false;
            }
        }
        if($scope.executeValue) {
            $scope.$emit('default_update_spreadsheet_value', {
                value : value,
                dropDownType : dropDownType
            });
        }
    }

    $scope.checkValidData = function(value) {
        $scope.salesPriceError = false;
        var re = /^[0-9]{0,3}(\.[0-9]{1,2})?$/;
        if (!re.test(value)) {
            $scope.salesPriceError = true;
        }
    }

    $scope.closeSidePanel = function() {
        $scope.salesPriceError = false;

        //# Reset the date when close side panel
        $scope.$broadcast('resetDate');
        $rootScope.$broadcast('sidepanel:close');
    }
}
function SupplierCompletePurchaseOrderCtrl($scope, $state, $http, prefix, $rootScope, excludedFileTypes, getIdData, $upload, warningModal, $translate, confirmationBoxHelper, toastBox, $timeout, $compile, CS_LANGUAGE) {
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.supplierDetails.poData = getIdData.poData;
    $scope.note = getIdData.poData.note;
    $scope.invoiceNumber = '';
    $scope.sendToAccounts = false;
    $scope.customerSupplierSubNavDetails = false;
    $scope.excludedeTypes = excludedFileTypes;
    $scope.action = "/upload_file_to_temp";
    $scope.fileLocation = '';
    $scope.uploadedFileName = '';
    $scope.checkExistUrl = '/suppliers/' + $state.params.id + '/check_exist/invoice?value=';
    $scope.readAccess = getIdData.readAccess;

    $scope.vatType = getIdData.vatType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.canAddNewRows = true;
    $scope.hasExistingData = false;
    if(getIdData.poData.job_number > 0) {
        $scope.canAddNewRows = false;
        var selectedType = 'customers';
    } else {
        $scope.hasExistingData = true;
        var selectedType = 'suppliers';
    }
    $scope.selectedTypes = selectedType;

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: false});
    $scope.$emit('event:changePOScope', {name: 'showPoDetailNav', value: true});

    var poId = $state.params.purchaseOrderId;
    $scope.getPartListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?mode=parts&purchaseId=' + poId;
    $scope.getItemListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?type=complete_purchase_order_items&mode=items&purchaseId=' + poId + '&selectedType='+ selectedType;
    $scope.addUrl = prefix + '/suppliers/' + $state.params.id + '/purchase_order/' + poId + '/complete_purchase_order_invoice';
    $scope.complete_purchase_order_parts = [];
    $scope.complete_purchase_order_items = [];

    /*$http.get($scope.getPartListUrl).success(function(resp) {
        $scope.hasExistingData = (resp.complete_purchase_order_parts.existing_data && resp.complete_purchase_order_parts.existing_data.length);
        if (Object.keys(resp.complete_purchase_order_parts.auto_complete_options.parts).length > 0) {
            $scope.show_parts_spreadsheet = true;
        } else {
            $scope.show_parts_spreadsheet = false;
        }
    });*/

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    // Update the default date with all line items in spreadsheet
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
    });

    $scope.addPurchaseOrder = function () {
        // $scope.saving = true;
        var date = moment($scope.poDate).format('YYYY-MM-DD');
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&sendToAccounts=" + $scope.sendToAccounts + "&total=" + $scope.total_cost + "&vat=" + $scope.total_VAT +
            "&grandTotal=" + $scope.grand_total + "&invoiceNumber=" + encodeURIComponent($scope.invoiceNumber) +
            "&fileLocation=" + encodeURI($scope.fileLocation) + "&fileName=" + encodeURI($scope.uploadedFileName) + "&vatType=" + encodeURIComponent($scope.vatType)).
            success(function (data, status) {
                if (status == 200) {
                    // $scope.saving = false;
                    if(data.error) {
                        $scope.saving = false;
                        warningModal.clean('supplier_po_error');
                        warningModal.show($translate(data.message), 'Error', 'supplier_po_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                    } else {
                        $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $state.params.id, 'purchaseOrderId': $state.params.purchaseOrderId });
                    }
                }
            });
    }
    HandleDeleteRowFromSpreadsheet.call(this, $scope);
    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'complete_purchase_order');

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.fileObject = resp.message;
            $scope.uploadedFileName = resp.message.name;
            $scope.fileLocation = resp.fileLocation;
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileObject});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_complete_purchase_order";
    $scope.itemHotId = "item_complete_purchase_order";
    $scope.showDeliveryDetails = false;

    $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type=complete_purchase_order_parts&mode=parts&purchaseId=' + poId;
    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type=complete_purchase_order_items&mode=items&purchaseId=' + poId + '&selectedType='+ $scope.selectedTypes;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_complete_purchase_order"] = true;
    $scope["item_complete_purchase_order"] = true;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'complete_purchase_order', warningModal, $timeout, $compile, CS_LANGUAGE);
}

function SupplierEditPurchaseOrderCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData, warningModal, $translate, confirmationBoxHelper, toastBox, $compile, CS_LANGUAGE) {
    $scope.isFromEstimatedCost = getIdData.isFromEstimatedCost;
    $scope.selectedSupplierId = $state.params.id;
    $scope.customerSupplierSubNavDetails = false;
    $scope.vatType = getIdData.poData.vatType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;


    handleEditPo.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);
    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);
    $scope.supplierthirdpartyreference = $scope.supplierDetails['thirdpartyRef'];

    /*** Routing URL for to load the default data ***/
    var poId = $state.params.purchaseOrderId;
    $scope.editUrl = prefix + '/suppliers/' + $state.params.id + '/purchase_order/' + poId + '/updateSpreadsheet';

    $scope.addPurchaseOrder = function () {
        // $scope.saving = true;
        var note = ($scope.note == undefined) ? '' : $scope.note;
        var date = moment($scope.purchaseOrderDate).format('YYYY-MM-DD');
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.editUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&note=" + encodeURIComponent(note) + "&total=" + $scope.total_cost + "&vat=" + $scope.total_VAT + "&rowsToDelete=" + rowsToDelete +
            "&grandTotal=" + $scope.grand_total + "&vatType=" + encodeURIComponent($scope.vatType)).
            success(function (data, status) {
                if (status == 200) {
                    // $scope.saving = false;
                    if(data.error) {
                        $scope.saving = false;
                        warningModal.clean('supplier_po_error');
                        warningModal.show($translate(data.message), 'Error', 'supplier_po_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                    } else {
                        $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $state.params.id, 'purchaseOrderId': $state.params.purchaseOrderId });    
                    }
                }
            });
    }
    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'edit_purchase_order')

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_edit_purchase_order";
    $scope.itemHotId = "item_edit_purchase_order";
    $scope.showDeliveryDetails = true;

    $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type=edit_purchase_order_parts&mode=parts&purchaseId=' + poId;
    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type=edit_purchase_order_items&mode=items&purchaseId=' + poId + '&selectedType='+ $scope.selectedTypes;

    // Update the default date with all line items in spreadsheet
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
        if(message.name === 'alterDeliveryDate') {
            $scope.alterSave(message.date, 'deliveryDate');
        }
    });

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_edit_purchase_order"] = true;
    $scope["item_edit_purchase_order"] = true;
    if($scope.isFromEstimatedCost == true){
        $scope.screenType =  'edit_purchase_order_parts_estimatedCost';
    }else{
        $scope.screenType =  'edit_purchase_order_parts';
    }

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, $scope.screenType, warningModal, $timeout, $compile, CS_LANGUAGE);
}

function NewSupplierInvoiceCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, $upload, excludedFileTypes, confirmationBoxHelper, warningModal, $translate, toastBox, $timeout, NgConfigKBUrlService, $compile, CS_LANGUAGE) {
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.readAccess = getIdData.readAccess;
    $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.vatType = $rootScope.taxType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.supplierDetails.showPoMiniNav = true;
    $scope.invoiceNumber = '';
    $scope.excludedeTypes = excludedFileTypes;
    $scope.action = "/upload_file_to_temp";
    $scope.fileSrc = '';
    $scope.fileLocation = '';
    $scope.uploadedFileName = '';
    $scope.checkExistUrl = '/suppliers/' + $state.params.id + '/check_exist/invoice?value=';

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.error = false;
            $scope.fileObject = resp.message;
            $scope.fileLocation = resp.fileLocation;
            $scope.uploadedFileName = resp.message.name;
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileObject});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);

    $scope.getPartListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?mode=parts&type=new_supplier_invoice_parts&requireRowTemplate=yes';
    $scope.getItemListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?mode=items&type=new_supplier_invoice_items&selectedType=suppliers';
    $scope.addUrl = prefix + '/suppliers/' + $state.params.id + '/invoice/insert';
    $scope.new_supplier_invoice_parts = [];
    $scope.new_supplier_invoice_items = [];

    /*$http.get($scope.getPartListUrl).success(function(resp) {
        if (Object.keys(resp.new_supplier_invoice_parts.auto_complete_options.parts).length) {
            $scope.show_parts_spreadsheet = true;
        } else {
            $scope.show_parts_spreadsheet = false;
        }
    });*/

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
        validateSpreadSheetRows.call(this, $scope, category, rows);
    });

    $scope.$on("validate:save-spreadsheet-data", function(event, is_valid) {
        if(is_valid.type == 'new_supplier_invoice_parts'){
            $scope.saveSpreadsheet_parts = is_valid.isValid;
        }else if (is_valid.type == 'new_supplier_invoice_items'){
            $scope.saveSpreadsheet_items = is_valid.isValid;
        }
    });

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
    });

    $scope.addSupplierInvoice = function() {
        $scope.saving = true;
        var dontSendToAccounts = ($scope.dontSendToAccounts == undefined) ? '' : $scope.dontSendToAccounts;
        var date = moment($scope.supplierInvoiceDate).format('YYYY-MM-DD');
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&invoiceNumber=" + encodeURIComponent($scope.invoiceNumber) + "&dontSendToAccounts=" + dontSendToAccounts + "&total=" + $scope.total_cost +
            "&tax=" + $scope.total_VAT + "&grandTotal=" + $scope.grand_total +
            "&fileLocation=" + encodeURI($scope.fileLocation) + "&fileName=" + encodeURI($scope.uploadedFileName) + "&vatType=" + encodeURIComponent($scope.vatType)).
            success(function (data, status) {
                if (status == 200) {
                    if(data.error && data.error == 'Exists') {
                        $scope.supplierInvoiceForm.invoiceNumber.$error.checkExists = true;
                    } else if(data.error) {
                        $scope.saving = false;
                        warningModal.clean('supplier_new_invoice_error');
                        warningModal.show($translate(data.message), 'Error', 'supplier_new_invoice_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);
                    }else {
                        $state.transitionTo("loggedin.list_supplier.view.details", { 'id': $state.params.id });
                    }
                    $scope.saving = false;
                }
            });
    }

    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'new_invoice');

    /*** Routing URL for to load the default data ***/
    $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?mode=parts&type=new_supplier_invoice_parts';
    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?mode=items&type=new_supplier_invoice_items&selectedType=suppliers';

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_new_supplier_invoice";
    $scope.itemHotId = "item_new_supplier_invoice";
    $scope.showDeliveryDetails = false;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_new_supplier_invoice"] = true;
    $scope["item_new_supplier_invoice"] = true;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'new_supplier_invoice', warningModal, $timeout, $compile, CS_LANGUAGE);
}

function validateSpreadSheetRows($scope, category, rows){
    if(category == 'new_supplier_invoice_items' || category == 'edit_supplier_invoice_items'){
        if(rows.length) {
            $scope.saveSpreadsheet_items = false;
            // Used to check required fields in spreadsheet.
            for(var y=0; y < rows.length; y++) {
                if(rows[y].row[0].associated_rows[0][0].value) {
                    if((rows[y].row[0].associated_rows[0][1].value > 0) && (rows[y].row[0].associated_rows[0][2].value || rows[y].row[0].associated_rows[0][2].value == 0) && (rows[y].row[0].associated_rows[0][3].value || rows[y].row[0].associated_rows[0][3].value == 0) && rows[y].row[0].associated_rows[0][4].value && rows[y].row[0].associated_rows[0][5].value) {
                        $scope.saveSpreadsheet_items = true;
                    } else {
                        $scope.saveSpreadsheet_items = false;
                        break;
                    }
                }
            }
        }
    }else if(category == 'new_supplier_invoice_parts' || category =='edit_supplier_invoice_parts'){
        if(rows.length) {
            $scope.saveSpreadsheet_parts = false;
            // Used to check required fields in spreadsheet.
            for(var y=0; y < rows.length; y++) {
                if(rows[y].row[0].associated_rows[0][0].value) {
                    if((rows[y].row[0].associated_rows[0][1].value > 0) && (rows[y].row[0].associated_rows[0][2].value || rows[y].row[0].associated_rows[0][2].value == 0) && rows[y].row[0].associated_rows[0][3].value && rows[y].row[0].associated_rows[0][4].value) {
                        $scope.saveSpreadsheet_parts = true;
                    } else {
                        $scope.saveSpreadsheet_parts = false;
                        break;
                    }
                }
            }
        }
    }else if(category == 'new_job_purchase_order_parts_prepopulated'){
        if(rows.length){
            $scope.saveSpreadsheet_parts = false;
            if(category == 'new_job_purchase_order_parts_prepopulated'){
                $scope.saveSpreadsheet_items = false;
            }
            // Used to check required fields in spreadsheet
            for(var y=0; y< rows.length; y++){
                if(rows[y].row[0].associated_rows[0][0].value){
                    if((rows[y].row[0].associated_rows[0][1].value > 0) && (rows[y].row[0].associated_rows[0][2].value || rows[y].row[0].associated_rows[0][2].value == 0) && rows[y].row[0].associated_rows[0][3].value && rows[y].row[0].associated_rows[0][4].value && rows[y].row[0].associated_rows[0][5].value){
                        $scope.saveSpreadsheet_parts = true;
                    } else{
                        $scope.saveSpreadsheet_parts = false;
                        break;
                    }
                }
            }
        }
    }
}

function handleSpreadsheetGrandTotal($scope, $rootScope, mode) {
    $scope.total_cost = 0;
    $scope.total_VAT = 0;
    $scope.grand_total = 0;

    $rootScope.$on('update:supplier:' + mode + ':spreadsheetGrandTotals', function() {
        $scope.total_cost = 0;
        $scope.total_VAT = 0;
        $scope.grand_total = 0;

        var grand_totals_str = localStorage.getItem('supplier:' + mode + ':spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = grand_totals.length > 1;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.total_cost += grand_totals[i][key]["Total cost (exc vat)"];
            $scope.total_VAT += grand_totals[i][key]["Total vat"];
        }

        $scope.total_cost = parseFloat($scope.total_cost.toFixed(2));
        $scope.total_VAT = parseFloat($scope.total_VAT.toFixed(2));
        $scope.grand_total = $scope.total_VAT + $scope.total_cost;
    });
}

function SupplierPurchaseOrderCtrl($scope, getIdData, $rootScope, warningModal, $state, $http, prefix, $translate) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.poDetails = getIdData.poDetails;
    $scope.wolseleyData = getIdData.wolseleyData;

    $scope.markArrivedStatus = getIdData.poDetails.purchase_order_has_items_that_are_arrived;
    $scope.poStatus = getIdData.poDetails.status;
    $scope.showMiniNav = true;
    $scope.showPoDetailNav = false;
    $scope.showSupplierNav = false;
    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);

    $scope.isSupplierPoIntegration = false;
    var featureName = 'SupplierPoIntegration', featureAccess = 'readaccess', featureId = features[featureName];
    $scope.isSupplierPoIntegration = $rootScope.hasPermission(featureId, featureAccess);

    $scope.$on("updatePoStatus", function (event, message) {
        $scope.markArrivedStatus = message.purchase_order_has_items_that_are_arrived;
        $scope.poStatus = message.status;
    });
    $scope.$on("event:changePODetails", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope.poDetails[name] = value;
    });

    $scope.$on("event:changePOScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });

    $scope.deleteParam = [
        {
            "reject": {
                "url": "/purchase_order_reject",
                "validationMessage": "REJECT to purchase order reject",
                "title": $translate("Reject.Purchase.Order"),
                "content": $translate("To.confirm.you.wish.to.reject.this.purchase.order.please.type.'REJECT'.in.the.box.below.and.click.reject"),
                "deleteText": "Reject",
                "deleteArray": "purchaseOrder[id]=",
                "deleteTextConfirm": "REJECT"
            },
            "unreject": {
                "url": "/purchase_order_unreject",
                "validationMessage": "UN-REJECT to purchase order unreject",
                "title": $translate("Un-reject.Purchase.Order"),
                "content": $translate("To.confirm.you.wish.to.unreject.this.purchase.order.please.type.'UN-REJECT'.in.the.box.below.and.click.unreject"),
                "deleteText": "Un-reject",
                "deleteArray": "purchaseOrder[id]=",
                "deleteTextConfirm": "UN-REJECT"
            },
            "delete": {
                "url": "/purchase_order_delete",
                "validationMessage": "DELETE to purchase order delete",
                "title": $translate("Delete.purchase.order"),
                "content": $translate("To.confirm.you.wish.to.delete.this.purchase.order.please.type.'DELETE'.in.the.box.below.and.click.delete"),
                "deleteText": "Delete",
                "deleteArray": "purchaseOrder[id]=",
                "deleteTextConfirm": "DELETE"
            }
        }
    ];

    $scope.triggerDelete = function (cat, id) {
        if(cat == 'delete' || cat == 'reject'){
            var url = prefix + '/is_purchase_order_delete_reject?id=' + id + '&category=' + cat
        }else{
            var url = prefix + '/is_purchase_order_un_reject?id=' + id + '&category=' + cat
        }
        if( $scope.isSupplierPoIntegration ) {
            url += "&thirdpartyName="+$scope.wolseleyData.thirdpartyName;
        }
        $http.get(url).success(function (data) {
            warningModal.clean(data.id);    // Close any existing modal definitions
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.methodType = cat;
                $scope.currentPage = $scope.$parent.pageNum;
                $scope.selectedId = id;
                $scope.shouldBeOpen = true;
                $scope.deleteText = $scope.deleteParam[0][cat].deleteText;
                $scope.deleteTextConfirm = $scope.deleteParam[0][cat].deleteTextConfirm;
                $scope.deleteUrl = $scope.deleteParam[0][cat].url;
                $scope.title = $scope.deleteParam[0][cat].title;
                $scope.validationMessage = $scope.deleteParam[0][cat].validationMessage;
                $scope.content = $scope.deleteParam[0][cat].content;
                $scope.deleteArrayText = $scope.deleteParam[0][cat].deleteArray;
            }
        });
    }
    
    $scope.fetchPurchaseOrderRecords = function fetchPurchaseOrderRecords(page){
        if($scope.methodType == 'delete'){
            $state.transitionTo("loggedin.list_supplier.view.details", { 'id': $state.params.id });
        }else{
            $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $state.params.id , 'purchaseOrderId' : $scope.selectedId }, {reload:true});
        }
    }
    
    //wolseley
    $scope.isWolseleySetup = 0;
    if( $scope.wolseleyData && $scope.wolseleyData.count > 0 ) {
        if( $scope.wolseleyData.accountStatus == 1 ) {
            $scope.isWolseleySetup = 1;
        } else {
            $scope.isWolseleySetup = 0;
        }
    } else {
        $scope.isWolseleySetup = 0;
    }
    
    /*if( $scope.isSupplierPoIntegration ) {
        $http.get(prefix + '/getWolseleyAccountData?supplierId='+$state.params.id+'&page=1&limit=1').success(function(data) {
                
            $scope.wolseleyData = data.data;
            //(data.count);
            if( data.count > 0 ) {
                if( data.accountStatus == 1 ) {
                    $scope.isWolseleySetup = 1;
                } else {
                    $scope.isWolseleySetup = 0;
                }
            } else {
                $scope.isWolseleySetup = 0;
            }
        });
    }*/
}

function SupplierPurchaseOrderDetailsCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, emailService, $compile, $timeout,warningModal) {

    $scope.wolseleyDetails = getIdData.wolseleyDetails;
    $rootScope.thirdpartyName = getIdData.wolseleyDetails.thirdpartyName;
    
    if(getIdData.deleteOrNull== 'Deleted') {
        var message = getIdData.deleteOrNull;
        warningModal.show('This Purcahse Order has been  '+message, 'Purchase Order','Deleted' );
        $state.transitionTo('loggedin.list_supplier.view.details', {'id': $state.params.id}, {reload: true});
        return false;
    }
    $scope.printAndPostModuleAccess = getIdData.printAndPostModuleAccess;
    $scope.supplierId = $state.params.id;

    $scope.vatType = getIdData.poData.vatType;
    handleViewPo.call(this, $scope, getIdData, prefix, $http, $rootScope, formService, $state, emailService);

    $scope.purchaseOrderParts = _.where($scope.purchaseOrders, {type: 'Part'});
    $scope.purchaseOrderItems = _.where($scope.purchaseOrders, {type: 'Item'});

    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.$emit('updatePoStatus', {'purchase_order_has_items_that_are_arrived': $scope.markArrivedStatus, 'status': $scope.poStatus});

    $scope.$on("informationbar:purchase_order_complete", function (event, message) {
        $state.transitionTo("loggedin.list_supplier.view.invoice.details", { 'id': $state.params.id , 'invoiceId' : $scope.poData.invoiceId });
    });
    
    $scope.showPOpdf = function showPOpdf(){
        var side_panel_tpl = $compile('<span id="view_pdf" ' +
            'sidepanel template="print_and_post" ' +
            'supports_print="true"'+
            'supports_print_and_post="'+ $scope.printAndPostModuleAccess +'"' +
            'print_and_post_message="Your purchase order is on its way to the supplier"' +
            'title="Print and post purchase order"'+
            'pdf_url="'+ prefix + '/suppliers/ '+ $scope.supplierId + '/purchase_orders/' +  $state.params.purchaseOrderId + '/printPurchaseOrder"'+
            'printpost_params="tableType=6&tablePKID='+ $state.params.purchaseOrderId +'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_pdf').scope().$$childTail.initialise();
        }, 600);
    }
    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_pdf')) {
            angular.element('#view_pdf').remove();
        };
    });
    $scope.panelData = {};
    $scope.panelData.poDetails = getIdData;

    $scope.sending = true;

    $scope.openPOSidePanel  = function openPOSidePanel() {
        if (angular.element('#wolseley_po')) {
            angular.element('#wolseley_po').remove();
        };
        var side_panel_tpl1 = $compile('<span id="wolseley_po" ' +
            'sidepanel template="send_po_wolseley" ' +
            'title="Send Po"'+ ' maindata="panelData" ></span>')($scope);
        
        angular.element('body').append(side_panel_tpl1);
        $timeout(function() {
            angular.element('#wolseley_po').scope().$$childTail.initialise();
        }, 600);
    }
    $scope.triggerSendPO = function() {
         $scope.shouldBeOpenWolseley = true;
    }
    $scope.sendToWolseley = function () {
        $http.post(prefix + '/sendPOWolseley', "po[id]=" + $state.params.purchaseOrderId + "&po[supplierId]=" +$scope.supplierId+'&po[thirdparty]='+$rootScope.thirdpartyName).
        success(function (data, status) {
            
            if (status == 200) {
                
                $scope.$emit('tabSupplier:successMessage', "Successfully sent to "+$rootScope.thirdpartyName);
                if( $state.params.jobId ) {
                    $state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order",  { 'id': $state.params.id, 'type': $state.params.type, 'jobId': $state.params.jobId, 'purchaseOrderId': $state.params.purchaseOrderId }, {reload:true})
                } else {
                    $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $scope.supplierId , 'purchaseOrderId' : $state.params.purchaseOrderId  }, {reload:true});
                    
                }
                //$rootScope.sending = false;
                //$rootScope.poStatus = data.status;
            }
        });
    }
    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#wolseley_po')) {
            angular.element('#wolseley_po').remove();
        };
    });

}

function SupplierPurchaseOrderNotesCtrl($scope, $state, $http, prefix, $rootScope, dateRange, getIdData, emailService) {
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.searchText = '';
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.contacts = getIdData.contacts;
    $scope.contactId = '';

    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: true});

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};

        $scope.$broadcast('filterValue', filters);
    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=6&id=' + $state.params.purchaseOrderId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 6;        // For PO
        clone_obj.relatedObjectId = $state.params.purchaseOrderId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    
    $scope.action = "";
    
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
      
      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }
    var baseRoute = prefix + '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}

function handlePrintAndDownloadOptions($scope, baseRoute) {
    var actionFilter = '';
    if( $scope.action ) {
        actionFilter = '&action=' + $scope.action;
    }
    $scope.filterStr = 'searchText=' + $scope.searchText + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate + actionFilter;
    $scope.pdfRoute = baseRoute + '/printNotesCommPdf?' + $scope.filterStr;
    $scope.excelRoute = baseRoute + '/downloadNotesCommExcel?' + $scope.filterStr;
    $scope.contactId = '';
    $scope.$watchCollection('[startdate,endDate,searchText,contactId,action]', function (newVal, oldVal) {
        
        if (newVal != undefined) {
            actionFilter = '&action=' + $scope.action;
            $scope.filterStr = 'searchText=' + $scope.searchText + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate + '&contactId=' +$scope.contactId + actionFilter;
            $scope.pdfRoute = baseRoute + '/printNotesCommPdf?' + $scope.filterStr;
            $scope.excelRoute = baseRoute + '/downloadNotesCommExcel?' + $scope.filterStr;
        }
    });
}

function handleNotesAndCommunicationFilter($scope){
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};
        }else{
            var filters = {'searchText': $scope.searchText, contactId: $scope.contactId};
        }
        $scope.$broadcast('event:communicationNotesFilter', filters);
    });

    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }

    $scope.$on('searchDropDown', function (event, message) {
        if(typeof message == 'object') {
            $scope.contactId = message.id;
            var filter = {'searchText': '', 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};
            $scope.$broadcast('event:communicationNotesFilter', filter);
        } else if (message != undefined && message.charAt(0) != '@') {
            $scope.contactId = '';
            $scope.searchText = message;
            var filter = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: false};
            $scope.$broadcast('event:communicationNotesFilter', filter);
        }
    });
}

function SupplierPurchaseOrderNotesTableCtrl($scope, $state, $http, prefix, $rootScope, getIdData, defaultPagingValue, canLoad, $q, $sce, emailService) {
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;

    $scope.notesDetails = getIdData.notesDetails;
    $scope.printAndPostModule = getIdData.printAndPostModule;
    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notes = $scope.notesDetails.notesDetails;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.notesImportantCount = $scope.notesDetails.notesCount;
        $scope.notesCount = $scope.notesDetails.notesCountMain;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: true});

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("PurchaseOrderNotesAndCommunication", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix +  '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix +  '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId +'/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.pageNumPhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.pageNumPhoneCalls = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        if($scope.contactId > 0) {
            $scope.notes = [];
            $scope.notesCount = 0;
            return;
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesImportantMain = data.notesCount;
            $scope.notesCount = data.notesCountMain;
            var message = {name: 'notesCount', value: $scope.notesImportantMain};
            $scope.$emit('event:changePODetails', message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/listPhoneCalls?pagePhoneCalls=' + pageNum + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/listEmail?pageEmailMessages=' + pageNum + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.fetchNotes(1);
        $scope.fetchPhoneCalls(1);
        $scope.fetchEmailMessages(1);
        $scope.fetchPrintLetters(1);
    });

    $scope.triggerDelete = function(noteId) {
        $scope.selectedId = noteId;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=6&id=' + $state.params.purchaseOrderId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 6;        // For PO
        clone_obj.relatedObjectId = $state.params.purchaseOrderId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function SupplierPurchaseOrderNotesTimelineCtrl($scope, $state, $http, prefix, $rootScope, getIdData, $timeout, canLoad, $q, $sce) {
    $scope.supplierSubNav = false;
    $scope.returnNotes = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierId = $scope.selectedId;

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

   // This steps used only for keyboard shotcuts screen.
    $scope.$on("PurchaseOrderNotesAndCommunication", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=19&id=' + $state.params.invoiceId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 19;        // For Supplier Invoice
        clone_obj.relatedObjectId = $state.params.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    var url = '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/notes/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
/*
    $scope.setTime = false;
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.results = getIdData.records;
        $scope.attachedFilesDetails = getIdData.attachedFilesDetails;
    }
    $scope.shouldBeOpen = false;

    $timeout(function () {
        $scope.setTime = true;
    }, 1000);

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: true});

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("PurchaseOrderNotesAndCommunication", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix +  '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix +  '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId +'/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    var canceler = null;
    $scope.fetchRecords = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/purchase_orders/' + $state.params.purchaseOrderId + '/notes/timeline?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent($scope.searchText) + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.results = data.records;
            $scope.notesImportantMain = data.navBarDetails.notesCount;
            var message = {name: 'notesCount', value: $scope.notesImportantMain};
            $scope.$emit('event:changePODetails', message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }


    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.fetchRecords(1);
    });

    $scope.triggerDelete = function(noteId) {
        $scope.selectedId = noteId;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.fetchRecords(1);

    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
*/
}

function SupplierPurchaseOrderNotesNewCtrl($scope, $state, prefix, $http, $rootScope, datasets) {
    $scope.data = datasets;
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showPoDetailNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: false});
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                $rootScope.flashMessage = data.success;
                $state.transitionTo("loggedin.list_supplier.view.purchase_order.notes." + $state.params.mode, { 'id': $state.params.id, purchaseOrderId : $state.params.purchaseOrderId });
            })
    }
}

function SupplierPurchaseOrderEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.purchaseOrderId;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;

    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showPoDetailNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: false});
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
        + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
            success(function (data, status) {
                if (status == 200) {
                    $scope.editNoteForm.$setPristine();
                    $rootScope.flashMessage = data;
                    $state.transitionTo("loggedin.list_supplier.view.purchase_order.notes." + $state.params.mode, { 'id': $state.params.id, purchaseOrderId : $state.params.purchaseOrderId });
                    $scope.updating = false;
                }
            });
    }
}

function GetPurchaseOrderFilesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, tableCollection) {
    $scope.selectedId = $state.params.id;
    $scope.purchaseOrderId = $state.params.purchaseOrderId;
    $scope.filesCount = getIdData.attached_files.count;
    $scope.selectedTab = 'Files';
    tableCollection.setData('attached_files', getIdData.attached_files);

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: true});
    $scope.$emit('event:changePODetails', {name: 'filesCount', value: $scope.filesCount});

    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("PurchaseOrderAttachedFiles", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "preview") {
            $scope.previewFile(myObject);
        } else if (action == "delete") {
            $scope.deleteFile(myObject.id, myObject.name);
        } else if (action == "download") {
            DownloadFunction.call(this, $scope, myObject.location, myObject.name);
        }

    });

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {

	 $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_purchaseorder_files', { id: $scope.selectedId, purchaseOrderId: $scope.purchaseOrderId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            tableCollection.setData('attached_files', data.attached_files);

            $scope.filesCount = data.attached_files.count;
            var message = {name: 'filesCount', value: $scope.filesCount};
            $scope.$emit('event:changePODetails', message);
            $scope.$emit('tabSupplier:selected', data.supplierDetails);

            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });

    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }
/*
    $scope.$on("file:preview", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        var file = {
            'name': data['File name'],
            'location': data['location'],
            'mimeType': data['Type'],
            'createdondatetime': data['createdondatetime'],
            'type': data['Type'],
            'id': data['id']
        };
        $scope.previewFile(file);
    });

    $scope.previewFile = function (fileObj) {
        $scope.enablePreviewFiles = "PurchaseOrderAttachedFiles";
        $scope.imageFile = false;
        $scope.loadFromObject = false;
        $scope.file = fileObj;
        $scope.selectedRow = fileObj.id;
        if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
            $scope.imageFile = true;
        } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf') {
            $scope.loadFromObject = true;
        }
        $scope.fileSelected = true;
    }
    $scope.closePreview = function () {
        $scope.enablePreviewFiles = '';
        $scope.selectedRow = "";
        $scope.file = "";
        $scope.fileSelected = false;
        $scope.recordArgs = '';
        $rootScope.selection = '';
        $rootScope.$broadcast('closeall');
    }
*/
}

function AddPurchaseOrderFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService) {
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink=NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    $scope.purchaseOrderId = $state.params.purchaseOrderId;
    $scope.error = false;
    $scope.testDrag = true;
    $scope.supplierSubNav = true;
    $scope.selectedTab = 'Files';

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: false});
    $scope.$emit('event:changePOScope', {name: 'showPoDetailNav', value: true});

    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_purchaseorder_file?mode=purchaseorder&purchaseOrderId=' + $scope.purchaseOrderId;
    $scope.uploadAction = $scope.action;

    $scope.uploadCallback = function() {
        $scope.purchaseOrderNewFiles($scope.selectedId, $scope.purchaseOrderId);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File.Uploaded";
            $scope.purchaseOrderNewFiles($scope.selectedId, $scope.purchaseOrderId);
        }
    }
    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}

function SupplierInvoiceCtrl($scope, getIdData, $rootScope, warningModal, $http, prefix, $state) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.invoiceDetails = getIdData.invoiceDetails.invoiceDetails;
    $scope.filesCount = getIdData.filesCount;
    $scope.showMiniNav = true;
    $scope.showInvoiceDetailNav = false;
    $scope.showSupplierNav = false;
    $scope.showInvoiceTabs = true;
    $scope.id = $state.params.id;
    $scope.invoiceId = $state.params.invoiceId;

    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:setSupplierInvoiceNumber', getIdData.invoiceDetails.invoiceDetails.invoicenumber);

    $scope.$on("event:changeInvoiceDetails", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope.invoiceDetails[name] = value;
    });

    $scope.$on("event:changeInvoiceScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });

    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }

    $scope.deleteSupplierInvoice = function() {

        $http.get(prefix + '/is_supplier_invoice_delete?invoiceId=' + $state.params.invoiceId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.invoiceId = $state.params.invoiceId;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.redirectToSupplier = function () {
        $state.transitionTo("loggedin.list_supplier.view.details", {'id': $state.params.id});
    }
}

function SupplierInvoiceDetailsCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData,warningModal) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.invoiceDetails = getIdData.invoiceDetails.invoiceDetails;
    $scope.invoiceItems = getIdData.invoiceItems;
    $scope.invoicePayments = getIdData.invoicePayments;
    $scope.poData = getIdData.purchaseOrders;

    $scope.taxBreakdownByRates = getIdData.taxBreakdownByComponents.taxRateOfBreakdownByRates;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: true});
    $scope.prefix = prefix;
    $scope.audits = getIdData.invoiceDetails.invoiceDetails.audits;
    $scope.aiAccountStatus = getIdData.aiAccountStatus;

    $scope.invoicePartsValue = _.where($scope.invoiceItems, {type: 'Part'});
    $scope.invoiceItemsValue = _.where($scope.invoiceItems, {type: 'Item'});
    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 19) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.audits = data;
            });
        }
    });

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    if ($rootScope.selection != '') {
        $scope.selectedSupplier = $rootScope.selection.rowObject.id;
    }

    $scope.handleSupplierInvoiceEdit = function(){
        $state.transitionTo("loggedin.list_supplier.view.invoice.edit", { 'id': $state.params.id, 'invoiceId': $state.params.invoiceId });
    }

    $scope.$on("informationbar:purchase_order_details", function (event, message) {
        $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $state.params.id , 'purchaseOrderId' : $scope.poData.id });
    });
}

function DatePickerWithDefaultDate($scope, $element, $rootScope, clickEvents, $filter) {
    $scope.selectedDate = new Date();
    DatePickerWidget.call(this, $scope, $element, $rootScope, clickEvents, $filter);
}

function HandleDeleteRowFromSpreadsheet($scope) {
    $scope.rowToDelete = [];
    $scope.$on("event:delete_spreadsheet_row", function (event, rowId) {
        $scope.rowToDelete.push(rowId);
    });
}

function EditSupplierInvoiceCtrl($scope, $state, $http, prefix, $rootScope, $upload, getIdData, excludedFileTypes, warningModal, confirmationBoxHelper, $translate, toastBox, $timeout, $compile, CS_LANGUAGE) {
    var currencySymbol = getIdData.currencySymbol;
    $scope.supplierDetails = getIdData.navBarDetails;
    var invoiceDetails = getIdData.invoiceDetails.invoiceDetails;
    $scope.invoiceNumber = invoiceDetails.invoicenumber;
    $scope.supplierInvoiceDate = invoiceDetails.invoicedate.date;
    $scope.dontSendToAccounts = invoiceDetails.dontsendtoaccounts;
    $scope.fileDetails = getIdData.fileDetails;
    $scope.invoicePaymentDetails = getIdData.invoicePaymentDetails;
    $scope.excludedeTypes = excludedFileTypes;
    $scope.action = "/suppliers/" + $state.params.id + "/invoice/" + $state.params.invoiceId + "/upload_file";
    $scope.checkExistUrl = '/suppliers/' + $state.params.id + '/check_exist/invoice?selectedId='+$state.params.invoiceId+'&value=';
    $scope.readAccess = getIdData.readAccess;

    $scope.vatType = getIdData.vatType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.canAddNewRows = true;
    $scope.hasExistingData = false;
    if(invoiceDetails.jobId > 0) {
        $scope.canAddNewRows = false;
        var selectedType = 'customers';
    } else {
        $scope.hasExistingData = true;
        var selectedType = 'suppliers';
    }
    $scope.selectedType = selectedType;
    $scope.selectedTypes = selectedType;

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: resp.message});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.invoiceDetails.invoiceDetails.invoicedate});
        $scope.selectedDate = getIdData.invoiceDetails.invoiceDetails.invoicedate.date;
    });

    var invoiceId = $state.params.invoiceId;
    $scope.getPartListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?type=edit_supplier_invoice_parts&mode=parts&invoiceId=' + invoiceId + '&requireRowTemplate=yes';
    $scope.getItemListUrl = prefix + '/suppliers/' + $state.params.id + '/get_parts_details?type=edit_supplier_invoice_items&mode=items&invoiceId=' + invoiceId + '&selectedType='+ selectedType + '&readAccess=' + $scope.readAccess;
    $scope.addUrl = prefix + '/suppliers/' + $state.params.id + '/invoice/' + invoiceId + '/update_with_spreadsheet';
    $scope.deleteUrl = prefix + '/suppliers/' + $state.params.id + '/invoice/' + invoiceId + '/delete';
    $scope.edit_supplier_invoice_parts = [];
    $scope.edit_supplier_invoice_items = [];
    $scope.fileBroadcasted = false;

    /*$http.get($scope.getPartListUrl).success(function(resp) {
        $scope.hasExistingData = (resp.edit_supplier_invoice_parts.existing_data && resp.edit_supplier_invoice_parts.existing_data.length);
        if (Object.keys(resp.edit_supplier_invoice_parts.auto_complete_options.parts).length) {
            $scope.show_parts_spreadsheet = true;
        } else {
            $scope.show_parts_spreadsheet = false;
        }
    });*/

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
        if(!$scope.fileBroadcasted) {
            if($scope.fileDetails) {
                $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileDetails});
            }
            $scope.fileBroadcasted = true;
        }
        validateSpreadSheetRows.call(this, $scope, category, rows);
    });

    $scope.$on("validate:save-spreadsheet-data", function(event, is_valid) {
        if(is_valid.type == 'edit_supplier_invoice_parts'){
            $scope.saveSpreadsheet_parts = is_valid.isValid;
        }else if (is_valid.type == 'edit_supplier_invoice_items'){
            $scope.saveSpreadsheet_items = is_valid.isValid;
        }
    })

    HandleDeleteRowFromSpreadsheet.call(this, $scope);

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
    });

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabSupplier:successMessage', data);
    });

    $scope.editSupplierInvoice = function () {
        if($scope.grand_total < $scope.invoicePaymentDetails.amountPaid) {
            warningModal.show('Cannot.save.supplier.invoice.reason.amount.less.than.allocated', 'Cannot.save.supplier.invoice', 'cannot-delete-modal', {total: currencySymbol + $scope.grand_total, amountPaid: currencySymbol + $scope.invoicePaymentDetails.amountPaid});
            return false;
        }
        $scope.saving = true;
        var dontSendToAccounts = ($scope.dontSendToAccounts == undefined) ? '' : $scope.dontSendToAccounts;
        var date = moment($scope.supplierInvoiceDate).format('YYYY-MM-DD');
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&dontSendToAccounts=" + dontSendToAccounts + "&total=" + $scope.total_cost + "&vat=" + $scope.total_VAT + "&rowsToDelete=" + rowsToDelete +
            "&grandTotal=" + $scope.grand_total + "&invoiceNumber=" + encodeURIComponent($scope.invoiceNumber) + "&vatType=" + encodeURIComponent($scope.vatType)).
            success(function (data, status) {
                if (status == 200) {
                    if(data.error && data.error == 'Exists') {
                        $scope.supplierInvoiceForm.invoiceNumber.$error.checkExists = true;
                    } else if(data.error && data.message == 'total_error') {
                        warningModal.show('Cannot.save.supplier.invoice.reason.amount.less.than.allocated', 'Cannot.save.supplier.invoice', 'cannot-delete-modal', {total: currencySymbol + data.values.total, amountPaid: currencySymbol + data.values.amountPaid});
                    } else if(data.error) {
                        $scope.saving = false;
                        warningModal.clean('supplier_invoice_error');
                        warningModal.show($translate(data.message), 'Error', 'supplier_invoice_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);
                    }else {
                        $state.transitionTo("loggedin.list_supplier.view.invoice.details", { 'id': $state.params.id, 'invoiceId': $state.params.invoiceId });
                    }
                    $scope.saving = false;
                }
            });
    }
    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'edit_invoice')

    /*** Routing URL for to load the default data ***/
    $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?mode=parts&type=edit_supplier_invoice_parts&invoiceId=' + invoiceId;
    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?mode=items&type=edit_supplier_invoice_items&invoiceId=' + invoiceId + '&selectedType='+selectedType;

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_edit_supplier_invoice";
    $scope.itemHotId = "item_edit_supplier_invoice";
    $scope.showDeliveryDetails = false;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_edit_supplier_invoice"] = true;
    $scope["item_edit_supplier_invoice"] = true;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'edit_supplier_invoice', warningModal, $timeout, $compile, CS_LANGUAGE);
}

function NewSupplierInvoicePaymentCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, $timeout) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.invoiceDetails = getIdData.invoiceDetails.invoiceDetails;
    $scope.amountallocated = getIdData.invoicePaymentDetails.remainderToPay;
    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});
    $scope.$emit('event:setSupplierInvoiceNumber', $scope.invoiceDetails.invoicenumber);

    $timeout(function () {
        $scope.amountallocated = getIdData.invoicePaymentDetails.remainderToPay;
    }, 50);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newInvoicePaymentForm.$setPristine();
                $rootScope.flashMessage = data.success;
                $scope.supplierInvoiceDetails(data.id, data.invoiceId);
                $scope.saving = false;
            });
    }
}

function SupplierInvoicePaymentDetailsCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, warningModal) {
    $scope.invoice_payment = getIdData.invoicePayments.payments;
    $scope.allocatedAmount = getIdData.allocatedAmount;
    $scope.paymentId = $state.params.invoicePaymentId;
    $scope.invoicePaymentsAudits = getIdData.invoicePaymentsAudits;
    $scope.shouldBeOpen = false;
    $scope.aiAccountStatus = ($scope.invoice_payment.sendtoaccounts)?$scope.invoice_payment.sendtoaccounts:'';
    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});

    $scope.handlePaymentDelete = function() {
        if($scope.aiAccountStatus == 2 || $scope.aiAccountStatus == 4) {
            warningModal.show('Delete.invoice.payment.warning.message', 'Delete.invoice.payment', 'warning_delete_invoice_payment');
        } else {
            $scope.paymentId = $state.params.invoicePaymentId;
            $scope.shouldBeOpen = true;
        }
    }

    $scope.handlePaymentEdit = function() {
        $state.transitionTo("loggedin.list_supplier.view.invoice.view_payment.edit", { 'id': $state.params.id, 'invoiceId': $state.params.invoiceId, 'invoicePaymentId' : $state.params.invoicePaymentId });
    }

    $scope.redirectToSupplier = function() {
        $state.transitionTo("loggedin.list_supplier.view.invoice.details", { 'id': $state.params.id, 'invoiceId': $state.params.invoiceId });
    }
}

function NewSupplierCreditNoteCtrl($scope, $state, $http, prefix, $rootScope, $upload, getIdData, excludedFileTypes, confirmationBoxHelper, warningModal, $translate, toastBox, $timeout, creditNoteData, $compile, CS_LANGUAGE) {
    $scope.supplierDetails = getIdData.navBarDetails;

    $scope.vatType = getIdData.vatType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.supplierDetails.showPoMiniNav = true;
    $scope.creditNoteNumber = '';
    $scope.selectedSupplierId = $state.params.id;
    if($state.current.name == 'loggedin.list_supplier.view.new_credit_note_returns'){
        $scope.selectedReturns = creditNoteData.getSelectedReturns();
        var screenType = 'new_supplier_return_credit_note'
    }else{
        $scope.selectedReturns = '';
        var screenType = 'new_supplier_credit_note';
    }
    $scope.jobId = getIdData.jobId;
    if($scope.jobId > 0){
        $scope.selectedTypes = 'customers';
    }else{
        $scope.selectedTypes = 'suppliers';
    }
    $scope.addUrl = prefix + '/suppliers/' + $state.params.id + '/credit_note/insert';
    $scope.checkExistUrl = '/suppliers/' + $state.params.id + '/check_exist/credit_note?value='
    $scope.new_supplier_credit_note_parts = [];
    $scope.new_supplier_credit_note_items = [];
    $scope.action = "/upload_file_to_temp";
    $scope.fileSrc = '';
    $scope.fileLocation = '';
    $scope.uploadedFileName = '';
    $scope.excludedeTypes = excludedFileTypes;
    $scope.fromSupplierInvoice = getIdData.fromSupplierInvoice;
    if($scope.fromSupplierInvoice == true){
        $scope.partType = 'new_supplier_invoice_credit_note_parts';
        $scope.itemType = 'new_supplier_invoice_credit_note_items';
        var invoiceDetails = '&invoiceId='+ $state.params.invoiceId;
    }else{
        $scope.partType = 'new_supplier_credit_note_parts';
        $scope.itemType = 'new_supplier_credit_note_items';
        var invoiceDetails = '';
    }

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.fileObject = resp.message;
            $scope.fileLocation = resp.fileLocation;
            $scope.uploadedFileName = resp.message.name;
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileObject});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
    });

    $scope.addSupplierCreditNote = function() {
        $scope.saving = true;
        var dontSendToAccounts = ($scope.dontSendToAccounts == undefined) ? '' : $scope.dontSendToAccounts;
        var date = moment($scope.purchaseOrderDate).format('YYYY-MM-DD');
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&creditNoteNumber=" + encodeURIComponent($scope.creditNoteNumber) + "&dontSendToAccounts=" + dontSendToAccounts +
            "&total=" + $scope.total_cost + "&tax=" + $scope.total_VAT + "&grandTotal=" + $scope.grand_total +
            "&fileLocation=" + encodeURI($scope.fileLocation) + "&returnId=" + $state.params.returnId + "&fileName=" + encodeURI($scope.uploadedFileName) + "&vatType=" + encodeURIComponent($scope.vatType) + invoiceDetails).
            success(function (data, status) {
                if (status == 200) {
                    if(data.error && data.error == 'Exists') {
                        $scope.supplierCreditNoteForm.creditNoteNumber.$error.checkExists = true;
                    } else if(data.error) {
                        warningModal.show($translate(data.message), 'Error', 'supplier_creditnote_new_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                    } else {
                        $state.transitionTo("loggedin.list_supplier.view.details", { 'id': $state.params.id });
                    }
                    $scope.saving = false;
                }
            });
    }

    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'new_credit_note');

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_new_supplier_credit_note";
    $scope.itemHotId = "item_new_supplier_credit_note";
    $scope.showDeliveryDetails = false;

    if($scope.selectedReturns.length > 0){
        $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type='+ $scope.partType +'&mode=parts&returnId=' + $state.params.returnId  + invoiceDetails;
    }else{
        if($scope.selectedTypes == 'suppliers') {
            creditNoteData.setSelectedReturns([]);
            $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type='+ $scope.partType +'&mode=parts&returnId=' + $state.params.returnId + invoiceDetails;
        }
    }

    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type='+ $scope.itemType +'&mode=items&returnId=' + $state.params.returnId + '&selectedType='+ $scope.selectedTypes + invoiceDetails;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_new_supplier_credit_note"] = true;
    $scope["item_new_supplier_credit_note"] = true;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, screenType, warningModal, $timeout, $compile, CS_LANGUAGE);
}

function SupplierCreditNoteCtrl($scope, $state, getIdData, warningModal, $http, prefix) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.creditNoteDetails = getIdData.creditNoteDetails;
    $scope.CreditNoteFlesCount = getIdData.navBarDetails.filesCount;
    $scope.showMiniNav = true;
    $scope.showInvoiceDetailNav = false;
    $scope.showSupplierNav = false;
    $scope.creditNoteId = $state.params.creditNoteId;
    $scope.shouldBeOpen = false;
    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:setSupplierCreditNoteNumber', getIdData.creditNoteDetails.creditnotenumber);

    $scope.$on("event:changeCreditNoteDetails", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope.creditNoteDetails[name] = value;
    });

    $scope.$on("event:changeCreditNoteScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });

    $scope.deleteCreditNote = function () {

        $http.get(prefix + '/is_credit_note_delete?creditNoteId=' + $state.params.creditNoteId).success(function (data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            } else {
                $scope.creditNoteId = $state.params.creditNoteId;
                $scope.shouldBeOpen = true;
            }
        });
    }

    $scope.redirectToSupplier = function () {
        $state.transitionTo("loggedin.list_supplier.view.details", {'id': $state.params.id});
    }
}

function SupplierCreditNoteDetailsCtrl($scope, $state, $http, prefix, $rootScope, getIdData, warningModal) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.supplierInvoicePayments = getIdData.supplierInvoicePayments;
    $scope.navBarDetails.showPoMiniNav = true;
    $scope.creditNoteDetails = getIdData.creditNoteDetails;
    $scope.creditNoteItems = getIdData.creditNoteItems;
    $scope.allocationHistory = getIdData.allocationHistory;
    $scope.prefix = prefix;
    $scope.audits = getIdData.creditNoteDetails.audits;
    $scope.aiAccountStatus = getIdData.aiAccountStatus;

    $scope.taxBreakdownType = $rootScope.taxBreakdownType;
    $scope.taxBreakdownByRates = getIdData.taxBreakdownByComponents;

    $scope.creditNoteParts = _.where($scope.creditNoteItems, {type: 'Part'});
    $scope.creditNoteItems = _.where($scope.creditNoteItems, {type: 'Item'});

    $scope.$emit('tabSupplier:selected', getIdData.navBarDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: true});
    var message = {name: 'CreditNoteFlesCount', value: getIdData.filesCount};
    $scope.$emit('event:changeCreditNoteScope', message);
    var message = {name: 'CreditNoteCount', value: getIdData.notesCount};
    $scope.$emit('event:changeCreditNoteScope', message);
    var baseRoute = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/print_download';
    $scope.pdfRoute = baseRoute + '/printPdf?screen=details';
    $scope.excelRoute = baseRoute + '/downloadExcel?screen=details';

    $scope.handleCreditNoteEdit = function() {
        $state.transitionTo("loggedin.list_supplier.view.credit_note.edit", { 'id': $state.params.id, 'creditNoteId': $state.params.creditNoteId });
    }
}

function EditSupplierCreditNoteCtrl($scope, $state, $http, prefix, $rootScope, $upload, getIdData, excludedFileTypes, warningModal, confirmationBoxHelper, $translate, toastBox, $timeout, $compile, CS_LANGUAGE) {
    var creditNoteId = $state.params.creditNoteId;
    var currencySymbol = getIdData.currencySymbol;
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.creditNoteDetails = getIdData.creditNoteDetails;
    $scope.navBarDetails.showPoMiniNav = true;
    $scope.creditNoteNumber = getIdData.creditNoteDetails.creditnotenumber;
    $scope.supplierCreditNoteDate = getIdData.creditNoteDetails.creditnotedate.date;
    $scope.dontSendToAccounts = getIdData.creditNoteDetails.sendtoaccounts;
    $scope.allocatedAmount = getIdData.allocatedAmount;
    $scope.fileDetails = getIdData.fileDetails;
    $scope.fileBroadcasted = false;
    $scope.jobId = getIdData.creditNoteDetails.jobId;

    $scope.vatType = getIdData.creditNoteDetails.vatType;
    $scope.isHideVat = false;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    if($scope.jobId > 0){
        $scope.selectedTypes = 'customers';
    }else{
        $scope.selectedTypes = 'suppliers';
    }

    $scope.action = "/suppliers/" + $state.params.id + "/credit_note/" + $state.params.creditNoteId + "/upload_file";
    $scope.excludedeTypes = excludedFileTypes;
    $scope.checkExistUrl = '/suppliers/' + $state.params.id + '/check_exist/credit_note?selectedId='+creditNoteId+'&value=';

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: resp.message});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.saveUrl = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + creditNoteId + '/update';
    $scope.deleteUrl = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + creditNoteId + '/delete';

    $scope.edit_supplier_credit_note_parts = [];
    $scope.edit_supplier_credit_note_items = [];

    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.creditNoteDetails.creditnotedate});
        $scope.selectedDate = getIdData.creditNoteDetails.creditnotedate.date;
    });

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
        if(!$scope.fileBroadcasted) {
            if($scope.fileDetails) {
                $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileDetails});
            }
            $scope.fileBroadcasted = true;
        }
    });
    HandleDeleteRowFromSpreadsheet.call(this, $scope);

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
    });

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabSupplier:successMessage', data);
    });

    $scope.addSupplierCreditNote = function() {
        if($scope.grand_total < $scope.allocatedAmount) {
            warningModal.show('Cannot.save.credit.note.reason.amount.less.than.allocated', 'Cannot.save.credit.note', 'cannot-delete-modal', {total: currencySymbol + $scope.grand_total, amountAllocated: currencySymbol + $scope.allocatedAmount});
            return false;
        }
        $scope.saving = true;
        var dontSendToAccounts = ($scope.dontSendToAccounts == undefined) ? '' : $scope.dontSendToAccounts;
        var date = moment($scope.supplierCreditNoteDate).format('YYYY-MM-DD');
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.saveUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&creditNoteNumber=" + encodeURIComponent($scope.creditNoteNumber) + "&dontSendToAccounts=" + dontSendToAccounts + "&total=" + $scope.total_cost +
            "&tax=" + $scope.total_VAT + "&grandTotal=" + $scope.grand_total + "&rowsToDelete=" + rowsToDelete + "&vatType=" + encodeURIComponent($scope.vatType)).
            success(function (data, status) {
                if (status == 200) {
                    if(data.error && data.error == 'Exists') {
                        $scope.supplierCreditNoteForm.creditNoteNumber.$error.checkExists = true;
                    } else if(data.error && data.message == 'total_error') {
                        warningModal.show('Cannot.save.credit.note.reason.amount.less.than.allocated', 'Cannot.save.credit.note', 'less_than_allocated_amount', {total: currencySymbol + data.values.total, amountAllocated: currencySymbol + data.values.allocatedAmount});
                    } /*else if(data.error && data.message == 'accounting_package') {
                        warningModal.show('Edit.credit.note.warning.message', 'Edit.credit.note', 'warning_edit_credit_note');
                        $state.transitionTo("loggedin.list_supplier.view.credit_note.details", { 'id': $state.params.id, creditNoteId: $state.params.creditNoteId });
                    } */
                    else if(data.error) {
                        warningModal.show($translate(data.message), 'Error', 'supplier_creditNote_edit_error');
                        $scope.isErrorReturn = true;
                        $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);
                    }
                    else {
                        $state.transitionTo("loggedin.list_supplier.view.credit_note.details", { 'id': $state.params.id, creditNoteId: $state.params.creditNoteId });
                    }
                    $scope.saving = false;
                }
            });
    }

    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'edit_credit_note');

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_edit_supplier_credit_note";
    $scope.itemHotId = "item_edit_supplier_credit_note";
    $scope.showDeliveryDetails = false;

    $scope.getPartSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type=edit_supplier_credit_note_parts&mode=parts&creditNoteId=' + creditNoteId;
    $scope.getItemSpreadsheetURL = prefix + '/suppliers/' + $state.params.id + '/get_purchase_order_spreadsheet?type=edit_supplier_credit_note_items&mode=items&creditNoteId=' + creditNoteId;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_edit_supplier_credit_note"] = true;
    $scope["item_edit_supplier_credit_note"] = true;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'edit_supplier_credit_note', warningModal, $timeout, $compile, CS_LANGUAGE);
}
function NewSupplierCreditNoteReceiveCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, $timeout) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.creditNoteDetails = getIdData.creditNoteDetails;

    $timeout(function () {
        $scope.amountallocated = parseFloat($scope.creditNoteDetails.remainingToAllocate).toFixed(2);
    }, 100);

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});

    $scope.closeDatePicker = function closeDatePicker(e) {
        var datepicker_input = $(e.target).parents('.datepicker-append');
        var inline_datepicker = $(e.target).parents('.page-datepicker');

        if (datepicker_input.length > 0 || inline_datepicker.length > 0) {
            e.preventDefault;
        } else {
            if ($(e.target).parents('div.row-fluid').length == 1 || $(e.target).parents('div.row-fluid').length == 2) {
                this.show_date_picker = false;
            } else {
                if (angular.isNumber(parseInt(e.target.textContent)) == true && e.target.textContent.length <= 2) {
                    this.show_date_picker = false;
                } else {
                    e.preventDefault;
                }
            }
        }
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newCreditNoteReceiveCreditForm.$setPristine();
                $rootScope.flashMessage = data.success;
                $scope.supplierCreditNoteDetails(data.id, data.creditNoteId);
                $scope.saving = false;
            });
    }
}

function EditSupplierCreditNoteReceiveCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData) {
    $scope.parseFloat = parseFloat;
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.creditNoteDetails = getIdData.creditNoteDetails;
    $scope.navBarDetails.showPoMiniNav = true;
    $scope.allocations = getIdData.allocationHistory.allocations;
    $scope.nominalCodeRecords = getIdData.nominalCodeRecords;
    $scope.paymentMethods =  getIdData.paymentMethods;
    $scope.method =  parseInt($scope.allocations.method);
    $scope.nominal =  parseInt($scope.allocations.nominalCodeId);
    $scope.amount =  $scope.allocations.amount;
    $scope.description =  $scope.allocations.description == null ? '' : $scope.allocations.description;
    $scope.reference =  $scope.allocations.reference == null ? '' : $scope.allocations.reference;
    $scope.sendToAccounts =  $scope.allocations.sendToAccounts;
    $scope.receiveCreditDate = moment($scope.allocations.date.date);
    $scope.remainingToAllocate = $scope.creditNoteDetails.remainingToAllocate;

    $scope.$watch('amount', function (newVal, oldVal) {
        if (newVal !== undefined && oldVal !== undefined) {
           var allocated = parseFloat($scope.creditNoteDetails.remainingToAllocate)+parseFloat($scope.allocations.amount);
           var remainingToAmount = parseFloat(allocated)-parseFloat(newVal);
            if (remainingToAmount < 0 || remainingToAmount < allocated) {
                $scope.remainingToAllocate = allocated;
            }
        }
        else if(oldVal === undefined || newVal === undefined) {
            $scope.remainingToAllocate = parseFloat($scope.creditNoteDetails.remainingToAllocate)+parseFloat($scope.allocations.amount);
        }
    });

    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabSupplier:successMessage', data);
    });

    $scope.editUrl = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/allocations/' + $state.params.allocationId + '/save';

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: $scope.allocations.date});
        $scope.selectedDate = $scope.allocations.date.date;
    });

    $scope.editCreditNoteReceiveCredit = function () {
        $scope.saving = true;
        var sendToAccounts = ($scope.sendToAccounts == undefined) ? '' : $scope.sendToAccounts;
        var date = moment(this.receiveCreditDate, "L").format('YYYY-MM-DD');
        $http.post($scope.editUrl, "receivecredit[paymentdate]=" + date
                + "&receivecredit[paymentmethod]=" + this.method
                + "&receivecredit[nominalcodeid]=" + this.nominal
                + "&receivecredit[amountallocated]=" + this.amount
                + "&receivecredit[description]=" + this.description
                + "&receivecredit[reference]=" + this.reference
                + "&receivecredit[sendtoaccounts]=" + this.sendToAccounts).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    $state.transitionTo("loggedin.list_supplier.view.credit_note.details", { 'id': $state.params.id, 'creditNoteId': $state.params.creditNoteId });
                }
            });
    }

}

function SupplierNewCreditNoteAllocationCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData, warningModal, confirmationBoxHelper, toastBox) {
    $scope.dontSendToAccounts = false;
    $scope.description = '';
    $scope.remainingCreditAmount = getIdData.remainingCreditAmount;

    var creditNoteId = $state.params.creditNoteId;

    HandleNewEditCreditAllocations.call(this, $scope, $state, $rootScope, getIdData, prefix, $http, creditNoteId, warningModal);

    $scope.show_invoice_spreadsheet = false;
    $scope.allocationHotId = "creditNoteAllocationSpreadSheet";
    $scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/new_supplier_credit_allocation';
    $scope.spreadSheetTitle = 'Invoices';
    supplierAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, toastBox, $timeout)
}

function SupplierEditCreditNoteAllocationCtrl($scope, $state, $http, prefix, $rootScope, getIdData, warningModal, confirmationBoxHelper, toastBox, $timeout) {
    $scope.dontSendToAccounts = getIdData.paymentDetails.dontsendtoaccounts;
    $scope.description = getIdData.paymentDetails.description;
    $scope.remainingCreditAmount = getIdData.creditNoteDetails.total;
    $scope.creditNoteNumber = getIdData.creditNoteDetails.creditnotenumber;

    var creditNoteId = $state.params.creditNoteId;

    $scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/new_supplier_credit_allocation?creditNoteId=' + creditNoteId;

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.paymentDetails.creditnoteallotteddate});
        $scope.selectedDate = getIdData.paymentDetails.creditnoteallotteddate.date;
    });
    HandleNewEditCreditAllocations.call(this, $scope, $state, $rootScope, getIdData, prefix, $http, creditNoteId, warningModal);
    HandleDeleteRowFromSpreadsheet.call(this, $scope);

    $scope.show_invoice_spreadsheet = false;
    $scope.allocationHotId = "creditNoteAllocationSpreadSheet";
    $scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/new_supplier_credit_allocation?creditNoteId=' + creditNoteId;
    $scope.spreadSheetTitle = 'Invoices';
    supplierAllocationSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, toastBox, $timeout);
}

function HandleNewEditCreditAllocations($scope, $state, $rootScope, getIdData, prefix, $http, creditNoteId, warningModal){
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.creditNoteDetails = getIdData.creditNoteDetails;
    $scope.receivedAmount = $scope.creditNoteDetails.receivedAmount;
    $scope.navBarDetails.showPoMiniNav = true;
    $scope.deleteUrl = prefix + '/suppliers/' + $state.params.id + '/credit_notes/' + creditNoteId + '/delete';
    $scope.saveUrl = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + creditNoteId + '/add_credit_allocation';

    $scope.new_supplier_credit_allocation = [];

    $scope.$emit('tabSupplier:selected', $scope.navBarDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabSupplier:successMessage', data);
    });

    $scope.addSupplierCreditNoteAllocation = function() {
        $scope.saving = true;
        var dontSendToAccounts = ($scope.dontSendToAccounts == undefined) ? '' : $scope.dontSendToAccounts;
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        var date = moment($scope.selectedDate).format('YYYY-MM-DD');
        $http.post($scope.saveUrl, "invoices=" + encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)) +
            "&description=" + encodeURIComponent($scope.description) + "&date=" + date +  "&rowsToDelete=" + rowsToDelete +
            "&dontSendToAccounts=" + dontSendToAccounts).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    if(data.error && data.error === true) {
                        warningModal.clean('credit_allocation_warning');
                        warningModal.show(data.errorMessage, 'Credit.allocation.save', 'credit_allocation_warning');
                    } else {
                        $state.transitionTo("loggedin.list_supplier.view.credit_note.details", { 'id': $state.params.id, creditNoteId: $state.params.creditNoteId });
                    }
                }
            });
    }

    $rootScope.$on('update:new_supplier_credit_allocation:spreadsheetGrandTotals', function() {
        $scope.amount_allocated = 0;
        $scope.invoice_amount = 0;
        $scope.remainder_to_pay = 0;

        var grand_totals_str = localStorage.getItem('new_supplier_credit_allocation:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = true;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.amount_allocated += parseFloat(grand_totals[i][key]["Amount to allocate"]);
            $scope.invoice_amount += parseFloat(grand_totals[i][key]["Invoice amount"]);
            $scope.remainder_to_pay += parseFloat(grand_totals[i][key]["Remainder to pay"]);
        }

        $scope.amount_allocated = parseFloat($scope.amount_allocated.toFixed(2));
        $scope.invoice_amount = parseFloat($scope.invoice_amount.toFixed(2));
        $scope.remainder_to_pay = parseFloat($scope.remainder_to_pay.toFixed(2));
    });
}

function SupplierCashAllocationCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, warningModal, confirmationBoxHelper, toastBox, $timeout) {
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.nominalCode = getIdData.defaultNominalAccount;
    $scope.supplierDetails.showPoMiniNav = true;
    $scope.paymentMethod = '';
    $scope.reference = '';
    $scope.dontSendToAccounts = false;

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);

    $scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/new_supplier_cash_allocation_invoices';
    $scope.saveUrl = prefix + '/suppliers/' + $state.params.id + '/save_supplier_cash_allocation';

    HandleSupplierCashAllocation.call(this, $scope);

    $scope.show_invoice_spreadsheet = false;
    $scope.allocationHotId = "cashAllocationSpreadSheet";
    $scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/new_supplier_cash_allocation_invoices';
    $scope.spreadSheetTitle = 'Outstanding invoices';
    supplierCashAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, toastBox, $timeout)

    $scope.addCashAllocation = function (event) {
        $scope.submitted = true;
        $scope.saving = true;
        var date = moment($scope.selectedDate).format('YYYY-MM-DD');
        $http.post($scope.saveUrl, "invoices=" + encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)) +
            "&reference=" + encodeURIComponent($scope.reference) +
            "&date=" + date + "&dontSendToAccounts=" +
            $scope.dontSendToAccounts + "&nominalCode=" +
            $scope.nominalCode + "&description=" +
            encodeURIComponent($scope.description) + "&paymentMethod=" +
            $scope.paymentMethod + "&reference=" +
            encodeURIComponent($scope.reference) + "&amountAllocated=" + $scope.amount_allocated).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    if(data.error && data.error === true) {
                        warningModal.clean('cash_allocation_warning');
                        warningModal.show(data.errorMessage, 'Cash.Allocation.Save', 'cash_allocation_warning');
                    } else {
                        $state.transitionTo("loggedin.list_supplier.view.details", { 'id': $state.params.id });
                    }
                }
            });
    }
}

function HandleSupplierCashAllocation($scope) {
    $scope.new_supplier_cash_allocation_invoices = [];

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.$on('update:supplier:new_cash_allocation:spreadsheetGrandTotals', function() {
        $scope.amount_allocated = 0;
        $scope.remaining_balance = 0;
        $scope.outstanding_payments = 0;

        var grand_totals_str = localStorage.getItem('supplier:new_cash_allocation:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = grand_totals.length > 1;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.amount_allocated += grand_totals[i][key]["Amount to allocate"];
            $scope.outstanding_payments += grand_totals[i][key]["Remainder to pay"];
        }

        $scope.amount_allocated = parseFloat($scope.amount_allocated.toFixed(2));
        $scope.outstanding_payments = parseFloat($scope.outstanding_payments.toFixed(2));
    });
}

function SupplierPaymentEditCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, warningModal, confirmationBoxHelper, toastBox, $timeout) {
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.nominalCode = (getIdData.supplierPayment.nominalCode!= null)?getIdData.supplierPayment.nominalCode:'';
    $scope.description = getIdData.supplierPayment.description;
    $scope.supplierDetails.showPoMiniNav = true;
    $scope.paymentMethod = getIdData.supplierPayment.paymentmethod;
    $scope.reference = (getIdData.supplierPayment.reference != null) ? getIdData.supplierPayment.reference : '';
    $scope.dontSendToAccounts = getIdData.supplierPayment.sendtoaccounts;

    $scope.$emit('tabSupplier:selected', $scope.supplierDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.supplierPayment.paymentdate});
        $scope.selectedDate = moment(getIdData.supplierPayment.paymentdate.date);
    });

    //$scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/new_supplier_cash_allocation_invoices?paymentId=' + $state.params.invoicePaymentId;
    $scope.saveUrl = prefix + '/suppliers/' + $state.params.id + '/save_supplier_cash_allocation?paymentId=' + $state.params.invoicePaymentId;
    $scope.deleteUrl = prefix + '/suppliers/' + $state.params.id + '/payment/' + $state.params.invoicePaymentId + '/delete';

    HandleSupplierCashAllocation.call(this, $scope);
    HandleDeleteRowFromSpreadsheet.call(this, $scope);

    $scope.show_invoice_spreadsheet = false;
    $scope.allocationHotId = "cashAllocationSpreadSheet";
    $scope.getListUrl = prefix + '/suppliers/' + $state.params.id + '/get_cash_allocation_data/edit_supplier_payment_allocation?paymentId=' + $state.params.invoicePaymentId;
    $scope.spreadSheetTitle = 'Outstanding invoices';
    supplierAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, toastBox, $timeout)

    $scope.addCashAllocation = function (event) {
        $scope.saving = true;
        var date = moment($scope.selectedDate).format('YYYY-MM-DD');
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.saveUrl, "invoices=" + encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)) +
            "&reference=" + encodeURIComponent($scope.reference) +
            "&rowsToDelete=" + rowsToDelete +
            "&description=" + encodeURIComponent($scope.description) +
            "&date=" + date + "&dontSendToAccounts=" + $scope.dontSendToAccounts +
            "&nominalCode=" + $scope.nominalCode + "&paymentMethod=" + $scope.paymentMethod +
            "&reference=" + encodeURIComponent($scope.reference) + "&amountAllocated=" + $scope.amount_allocated).
            success(function (data, status) {
                if (status == 200) {
                    $scope.saving = false;
                    if(data.error && data.error === true && data.message != 'accounting_package') {
                        warningModal.clean('cash_allocation_warning');
                        warningModal.show(data.errorMessage, 'Cash.Allocation.Save', 'cash_allocation_warning');
                    } else {
                        $state.transitionTo('loggedin.list_supplier.view.invoice.view_payment', { id : $state.params.id, invoiceId: $state.params.invoiceId, invoicePaymentId : $state.params.invoicePaymentId}, {reload:true});
                    }
                }
            });
    }
}

function SupplierCreditNoteFilesCtrl($scope, $state, $http, prefix, $rootScope, getIdData, tableCollection, defaultPagingValue) {
    $scope.supplierDetails = getIdData.supplierDetails;
    $scope.supplierDetails.showPoMiniNav = true;
    $scope.selectedId = $state.params.id;
    $scope.creditNoteId = $state.params.creditNoteId;
    tableCollection.setData('attached_files', getIdData.attached_files);

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: true});
    var message = {name: 'CreditNoteFlesCount', value: getIdData.attached_files.count};
    $scope.$emit('event:changeCreditNoteScope', message);
    $scope.selectedTab = 'Files';
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);
    $scope.$on("file:preview", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        var file = {
            'name': data['File name'],
            'location': data['location'],
            'mimeType': data['Type'],
            'createdondatetime': data['createdondatetime'],
            'type': data['Type'],
            'id': data['id']
        };
        $scope.previewFile(file);
    });
    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });
    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    $scope.previewFile = function (fileObj) {
        $scope.imageFile = false;
        $scope.loadFromObject = false;
        $scope.file = fileObj;
        $scope.selectedRow = fileObj.id;
        if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
            $scope.imageFile = true;
        } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf') {
            $scope.loadFromObject = true;
        }
        $scope.fileSelected = true;
    }
    $scope.closePreview = function () {
        $scope.selectedRow = "";
        $scope.file = "";
        $scope.fileSelected = false;
        $scope.recordArgs = '';
        $rootScope.selection = '';
        $rootScope.$broadcast('closeall');
    }
    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }
    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {
        $scope.currentPage = pageNum;

	 $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('supplier_credit_note_files', { id: $scope.selectedId, creditNoteId:$scope.creditNoteId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.$emit('tabSupplier:selected', data.supplierDetails);
            var message = {name: 'CreditNoteFlesCount', value: data.attached_files.count};
            $scope.$emit('event:changeCreditNoteScope',message);
            tableCollection.setData('attached_files', data.attached_files);
            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

}
function AddCreditNoteFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService) {
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    $scope.creditNoteId = $state.params.creditNoteId;
    $scope.error = false;
    $scope.testDrag = true;
    $scope.supplierSubNav = true;
    $scope.selectedTab = 'Files';

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});

    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_credit_note_file?mode=creditnote&creditNoteId=' + $scope.creditNoteId;
    $scope.uploadAction = $scope.action;

    $scope.uploadCallback = function() {
        $scope.creditNoteListFiles($scope.selectedId, $scope.creditNoteId);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File.Uploaded";
            $scope.creditNoteListFiles($scope.selectedId, $scope.creditNoteId);
        }
    }
    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}

function GetSupplierInvoiceFilesCtrl($scope, $state, $http, prefix, $rootScope, getIdData, tableCollection, defaultPagingValue) {
    $scope.selectedId = $state.params.id;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.selectedTab = 'Files';
    tableCollection.setData('attached_files', getIdData.attached_files);

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: true});
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: true});
    var message = {name: 'filesCount', value: getIdData.attached_files.count};
    $scope.$emit('event:changeInvoiceScope', message);

    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {
	 $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_supplier_invoice_files', { id: $scope.selectedId, invoiceId: $scope.invoiceId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.$emit('tabSupplier:selected', data.supplierDetails);
            var message = {name: 'filesCount', value: data.attached_files.count};
            $scope.$emit('event:changeInvoiceScope',message);
            tableCollection.setData('attached_files', data.attached_files);
            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }

    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });
}

function AddSupplierInvoiceFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService) {
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    $scope.invoiceId = $state.params.invoiceId;
    $scope.error = false;
    $scope.testDrag = true;
    $scope.selectedTab = 'Files';

    $scope.$emit('tabSupplier:selected', getIdData.supplierDetails);
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});

    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_supplier_invoice_file?mode=supplierinvoice&invoiceId=' + $scope.invoiceId;
    $scope.uploadAction = $scope.action;

    $scope.uploadCallback = function() {
        $scope.supplierInvoiceNewFiles($scope.selectedId, $scope.invoiceId);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File.Uploaded";
            $scope.supplierInvoiceNewFiles($scope.selectedId, $scope.invoiceId);
        }
    }
    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}
function SupplierCreditNotesCtrl($scope, $state, $http, prefix, $rootScope, dateRange, getIdData, emailService) {
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.searchText = '';
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.contacts = getIdData.contacts;
    $scope.contactId = '';
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: true});
    var message = {name: 'CreditNoteFlesCount', value: getIdData.navBarDetails.filesCount};
    $scope.$emit('event:changeCreditNoteScope', message);
    var message = {name: 'CreditNoteCount', value: getIdData.navBarDetails.notesCount};
    $scope.$emit('event:changeCreditNoteScope', message);

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};

        $scope.$broadcast('filterValue', filters);
    });

    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=23&id=' + $state.params.creditNoteId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 23;        // For Credit Note
        clone_obj.relatedObjectId = $state.params.creditNoteId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.action = "";
    
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
      
      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }
    
    var baseRoute = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}
function SupplierCreditNotesTableCtrl($scope, $state, $http, prefix, $rootScope, getIdData, defaultPagingValue, canLoad, $q, $sce,emailService) {
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;

    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;

    $scope.notesDetails = getIdData.notesDetails;
    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notes = $scope.notesDetails.notesDetails;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.notesImportantCount = $scope.notesDetails.notesCount;
        $scope.notesCount = $scope.notesDetails.notesCountMain;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

     var message = {name: 'CreditNoteCount', value: $scope.notesImportantCount};
     $scope.$emit('event:changeCreditNoteScope', message);
    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CreditNoteNotesandCommunication", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId  + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId  + '/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;

    if ($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.pageNumPhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.pageNumPhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        if ($scope.contactId > 0) {
            $scope.notes = [];
            $scope.notesCount = 0;
            return;
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesImportantMain = data.notesCount;
            $scope.notesCount = data.notesCountMain;
            var message = {name: 'notesCount', value: $scope.notesImportantMain};
            $scope.$emit('event:changePODetails', message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/listPhoneCalls?pagePhoneCalls=' + pageNum + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }
    var smsCanceler = null;
    $scope.fetchSmsMessages = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceler) {
            smsCanceler.resolve();
        }
        smsCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/listSms?pageSmsMessages=' + pageNum + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: smsCanceler.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/listEmail?pageEmailMessages=' + pageNum + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if (message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.fetchNotes(1);
        $scope.fetchPhoneCalls(1);
        $scope.fetchSmsMessages(1);
        $scope.fetchEmailMessages(1);
    });

    $scope.triggerDelete = function (noteId) {
        $scope.selectedId = noteId;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=23&id=' + $state.params.creditNoteId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 23;        // For Credit Note
        clone_obj.relatedObjectId = $state.params.creditNoteId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function SupplierCreditNotesTimelineCtrl($scope, $state, $http, prefix, $rootScope, getIdData, $timeout, canLoad, $q, $sce, emailService) {
    $scope.supplierSubNav = false;
    $scope.returnNotes = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierId = $scope.selectedId;

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

   // This steps used only for keyboard shotcuts screen.
    $scope.$on("CreditNoteNotesandCommunication", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=19&id=' + $state.params.invoiceId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 19;        // For Supplier Invoice
        clone_obj.relatedObjectId = $state.params.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    var url = '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/notes/view/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
/*
    $scope.setTime = false;
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;

    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.results = getIdData.records;
        $scope.attachedFilesDetails = getIdData.attachedFilesDetails;
    }
    $scope.shouldBeOpen = false;

    $timeout(function () {
        $scope.setTime = true;
    }, 1000);

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId  + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId  + '/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    if ($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CreditNoteNotesandCommunication", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });
    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=23&id=' + $state.params.creditNoteId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 23;        // For Credit Note
        clone_obj.relatedObjectId = $state.params.creditNoteId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchRecords(1);
    });

    var canceler = null;
    $scope.fetchRecords = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/notes/view/timeline?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent($scope.searchText) + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.results = data.records;
            $scope.notesImportantMain = data.navBarDetails.notesCount;
            var message = {name: 'CreditNoteCount', value: $scope.notesImportantMain};
            $scope.$emit("event:changeCreditNoteScope", message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if (message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.fetchRecords(1);
    });

    $scope.triggerDelete = function (noteId) {
        $scope.selectedId = noteId;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.fetchRecords(1);
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
*/
}
function SupplierCreditNotesNewCtrl($scope, $state, prefix, $http, $rootScope, datasets) {
    $scope.data = datasets;
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                $rootScope.flashMessage = data.success;
                $state.transitionTo("loggedin.list_supplier.view.credit_note.notes." + $state.params.mode, {
                    'id': $state.params.id,
                    creditNoteId: $state.params.creditNoteId
                });
            })
    }
}
function SupplierCreditNotesEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.creditNoteId;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;

    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
        + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
            success(function (data, status) {
                if (status == 200) {
                    $scope.editNoteForm.$setPristine();
                    $rootScope.flashMessage = data;
                    $state.transitionTo("loggedin.list_supplier.view.credit_note.notes." + $state.params.mode, {
                        'id': $state.params.id,
                        creditNoteId: $state.params.creditNoteId
                    });
                    $scope.updating = false;
                }
            });
    }
}

function SupplierInvoiceNotesCtrl($scope, $state, $http, prefix, $rootScope, dateRange, getIdData, emailService) {
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.searchText = '';
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.contacts = getIdData.contacts;
    $scope.contactId = '';
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.smsReadAccess = getIdData.smsReadAccess;

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};

        $scope.$broadcast('filterValue', filters);
    });

    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=19&id=' + $state.params.invoiceId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 19;        // For Supplier Invoice
        clone_obj.relatedObjectId = $state.params.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    
    $scope.action = "";
    
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
      
      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }
    
    var baseRoute = prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}
function SupplierInvoiceNotesTableCtrl($scope, $state, $http, prefix, $rootScope, getIdData, defaultPagingValue, canLoad, $q, $sce, emailService) {

    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;

    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notesDetails = getIdData.notesDetails;
        $scope.notes = $scope.notesDetails.notesDetails;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.notesImportantCount = $scope.notesDetails.notesCount;
        $scope.notesCount = $scope.notesDetails.notesCountMain;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierInvoiceNotesandCommunications", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId  + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId  + '/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: true});

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // TEst For Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=19&id=' + $state.params.invoiceId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 19;        // For Supplier Invoice
        clone_obj.relatedObjectId = $state.params.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.pageNumPhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.pageNumPhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.searchCommunications(1);
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        if($scope.contactId > 0) {
            $scope.notes = [];
            $scope.notesCount = 0;
            return;
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesImportantMain = data.notesCount;
            $scope.notesCount = data.notesCountMain;
            var message = {name: 'notesCount', value: $scope.notesImportantMain};
            $scope.$emit('event:changeInvoiceDetails', message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/listPhoneCalls?pagePhoneCalls=' + pageNum + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceller = null;
    $scope.fetchSmsMessages = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceller) {
            smsCanceller.resolve();
        }
        smsCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/'+ $state.params.id +'/invoice/' + $state.params.invoiceId + '/listSms?pageSmsMessages=' + pageNum + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: smsCanceller.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        var contactId = ($scope.contactId == undefined) ? '' : $scope.contactId;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/listEmail?pageEmailMessages=' + pageNum + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/notes/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notes = data.notesDetails.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.selectedId = data.navBarDetails.id;

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.triggerDelete = function(noteId) {
        $scope.selectedId = noteId;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function SupplierInvoiceNotesTimelineCtrl($scope, $state, $http, prefix, $rootScope, getIdData, $timeout, canLoad, $q, $sce, emailService) {
    $scope.supplierSubNav = false;
    $scope.returnNotes = true;
    $scope.selectedId = $state.params.id;
    $scope.supplierId = $scope.selectedId;

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

   // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierInvoiceNotesandCommunications", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=19&id=' + $state.params.invoiceId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 19;        // For Supplier Invoice
        clone_obj.relatedObjectId = $state.params.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    var url = '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/notes/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
/*
    $scope.setTime = false;
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;

    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.results = getIdData.records;
        $scope.attachedFilesDetails = getIdData.attachedFilesDetails;
    }
    $scope.shouldBeOpen = false;

    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: true});

    $timeout(function () {
        $scope.setTime = true;
    }, 1000);

    if($rootScope.flashMessage && $rootScope.flashMessage != '') {
        $scope.$emit('tabSupplier:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("SupplierInvoiceNotesandCommunications", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=19&id=' + $state.params.invoiceId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 19;        // For Supplier Invoice
        clone_obj.relatedObjectId = $state.params.invoiceId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchRecords(1);
    });

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.fetchRecords(1);
    });

    var canceler = null;
    $scope.fetchRecords = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/suppliers/' + $state.params.id + '/invoice/' + $state.params.invoiceId + '/notes/timeline?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent($scope.searchText) + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.results = data.records;
            $scope.notesImportantMain = data.navBarDetails.notesCount;
            var message = {name: 'notesCount', value: $scope.notesImportantMain};
            $scope.$emit('event:changeInvoiceDetails', message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.triggerDelete = function(noteId) {
        $scope.selectedId = noteId;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.fetchRecords(1);
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
*/
}
function SupplierInvoiceNotesNewCtrl($scope, $state, prefix, $http, $rootScope, datasets) {
    $scope.data = datasets;
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.saving = false;
                $rootScope.flashMessage = data.success;
                $state.transitionTo("loggedin.list_supplier.view.invoice.notes." + $state.params.mode, { 'id': $state.params.id, invoiceId : $state.params.invoiceId });
            })
    }
}
function SupplierInvoiceEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.invoiceId;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
        + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
            success(function (data, status) {
                if (status == 200) {
                    $scope.editNoteForm.$setPristine();
                    $rootScope.flashMessage = data;
                    $state.transitionTo("loggedin.list_supplier.view.invoice.notes." + $state.params.mode, { 'id': $state.params.id, invoiceId : $state.params.invoiceId });
                    $scope.updating = false;
                }
            });
    }
}
function SupplierCreditNotesNewSmsMessageCtrl($scope, $http, $state, datasets, $rootScope, $sce) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.data = datasets;
    $scope.$emit('event:changeCreditNoteScope', {name: 'showMiniNav', value: false});

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });

    SMSMessageControllers.call(this, $scope);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newSmsMessageForm.$setPristine();
                $rootScope.flashMessage = data.success;
                $state.transitionTo("loggedin.list_supplier.view.credit_note.notes." + $state.params.mode, {
                    'id': $state.params.id,
                    creditNoteId: $state.params.creditNoteId
                });
                $scope.saving = false;
            })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}
function SupplierInvoiceNewSmsMessageCtrl($scope, $http, $state, datasets, $rootScope, $sce) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.data = datasets;
    $scope.$emit('event:changeInvoiceScope', {name: 'showMiniNav', value: false});
    $scope.$emit('event:changeInvoiceScope', {name: 'showInvoiceTabs', value: false});

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });

    SMSMessageControllers.call(this, $scope);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.newSmsMessageForm.$setPristine();
                $rootScope.flashMessage = data.success;
                $state.transitionTo("loggedin.list_supplier.view.invoice.notes." + $state.params.mode, { 'id': $state.params.id, invoiceId : $state.params.invoiceId });
                $scope.saving = false;
            })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function WolseleySendPOCtrl($scope, $http,prefix, $state, $rootScope, canLoad,$q) {
    $scope.status = 0;
    $scope.showhistory = 0;
    $scope.limit  = 5;
    $scope.page = 1;
    $scope.poStatus = 0;
    $scope.poHistory = {};
    $scope.navBarDetails = {};
    $scope.poErrors = {};
    $scope.poData = {};
    $scope.loading = true;
      
    if( $state.params.jobId ) {
        if( $scope.maindata ) {
            $scope.supplierId = $scope.maindata.poDetails.navBarDetails.id;
        }
        
    } else {
        $scope.supplierId = $state.params.id;
    }
    
    $scope.thirdpartyIntegrationName = $rootScope.thirdpartyName;
    if( !$scope.thirdpartyIntegrationName ) {
	    $scope.thirdpartyIntegrationName = $rootScope.thirdpartyIntegrationName;
    }
    if(  $scope.supplierId ) {
    } else {
         $scope.supplierId = $rootScope.supplierId;
    }
    //alert(JSON.stringify($scope.poErrors));
    $scope.getPoHistory = function (pageNum) {
        $http.get(prefix + '/getPoStatus?purchaseorderId='+$state.params.purchaseOrderId+'&supplierId='+$scope.supplierId+'&page='+pageNum+'&limit=5&thirdpartyName='+$scope.thirdpartyIntegrationName).success(function(data) {
            $scope.loading = false;
            $scope.poStatus = data.currentStatus;
            $scope.poStatusMessage = data.poStatusMessage;
            $scope.wolseleyPoRef = data.wolseleyPoRef;
            $scope.navBarDetails = data.navBarDetails;
            
            $scope.poHistory = data.poHistory;
            $scope.totalRecords = data.totalRecords;
            $scope.poData = data.poData;
            
            if( $scope.poStatus >= 1 && data.totalRecords > 0)  {
                $scope.showhistory = 1;
            }
            $scope.page = pageNum;
            //(data.count);
        });
    }
    
    $scope.validatePO = function () {
        $http.get(prefix + '/validatePO?purchaseorderId='+$state.params.purchaseOrderId+'&supplierId='+$scope.supplierId+'&nohistory=1&thirdparty='+$rootScope.thirdpartyIntegrationName).success(function(data) {
            canceler = null;
            canLoad.setLoadValue(false);  // Turn off AJAX
            if (canceler) {
              canceler.resolve();
            }
            canceler = $q.defer();
            $scope.loading = false;
            $scope.navBarDetails = data.navBarDetails;

            $scope.poErrors = data.poErrors;
            
            $scope.poData = data.poData;
        });
    }
    if( $scope.supplierId ) {
        $scope.validatePO();
    }

    $scope.sendToWolseley = function () {
        $http.post(prefix + '/sendPOWolseley', "po[id]=" + $state.params.purchaseOrderId + "&po[supplierId]=" +$scope.supplierId+'&po[thirdparty]='+$rootScope.thirdpartyIntegrationName).
        success(function (data, status) {
            if (status == 200) {
                $scope.$emit('tabSupplier:successMessage', "Successfully sent to "+$rootScope.thirdpartyIntegrationName);
                if( $state.params.jobId ) {
                    $state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order",  { 'id': $state.params.id, 'type': $state.params.type, 'jobId': $state.params.jobId, 'purchaseOrderId': $state.params.purchaseOrderId }, {reload:true})
                } else {
                    $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $scope.supplierId , 'purchaseOrderId' : $state.params.purchaseOrderId  }, {reload:true});
                    
                }
            }
        });
    }
}
