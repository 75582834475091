commusoftCommon.directive('cannotdeletemodal', function($document, $timeout, $filter) {
    var handleCannotDelete = function handleCannotDelete(scope, element, attrs) {
        scope.title = $filter('translate')(attrs.title);
        scope.message = $filter('translate')(attrs.message, attrs.translateparams);
        scope.modal_id = attrs.identifier;

        scope.handleListenerClose = function() {
            $timeout(function() {
                // wait for the modal to close
                element.remove();
            }, 1000);
        }

        attrs.$observe('message', function(message) {
            scope.message = $filter('translate')(attrs.message, attrs.translateparams);
        });

        if (attrs.openOnCompile === "true") {
            $timeout(function(){
                $('#' + scope.modal_id).modal('show');
                if (attrs.removeOnClose === "true") {

                    $('.modal-backdrop').each(function(index, elem) {
                        elem.addEventListener('click', scope.handleListenerClose);
                    });

                    element.find('button.close, button.btn-close').each(function(index, elem) {
                        elem.addEventListener('click', scope.handleListenerClose);
                    });
                }
            }, 100);
        }

        scope.close = function close() {
            $('#' + scope.modal_id).modal('hide');
            if(scope.$parent.show_delete_modal_overlay) {
                scope.$emit('hide_cost_overlay');
            }
        }
    }

    return {
        restrict: 'A',
        templateUrl: '/template/shared/cannot_delete_modal.html',
        scope: {},
        link: handleCannotDelete
    }
});
