commusoftCommon.directive('cspdfdownloadoption', ['prefix', '$http', '$compile', 'warningModal', function (prefix, $http, $compile, warningModal) {
    return {
        transclude: true,
        // replace: true,
        restrict: 'E',
        scope: {
            pageName: '=',
            pdfRouteName: '=',
            excelRouteName: '=',
            recordId: '=',
            milestoneId: '=',
            filterType: '=',
            moreFilter: "=",
            searchName: "=",
            startDate: "=",
            endDate:"=",
            fileLocation: "=",
            helpText: "=",
            notificationType :"="
        },
        template:'<div></div>',
        controller: function ($scope, prefix) {
            $scope.prefix = prefix;
            $scope.$watchCollection('[filterType, moreFilter , searchName, startDate, endDate, pdfRouteName, excelRouteName]', function (newVal, oldVal) {
                var srcpdf = angular.element("#print_pdf_screen").attr('href');
                var srcexcel = angular.element("#download_screen").attr('href');
                var srcpdfscreen = angular.element("#printscreen").attr('href');
                var srcexcelscreen = angular.element("#downloadscreen").attr('href');
                if($scope.pageName == 'Current Stock Details'){
                    var srcpdf = angular.element("#new_print_screen").attr('href');
                    var srcexcel = angular.element("#new_download_screen").attr('href');
                }

                if(srcpdfscreen){
                    //Todo: We should be using the translation key rather than value
                    if ($scope.pageName == 'Service.Reminders.Due.In.The.Next.30Days'){
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '?page=' + $scope.pageName + '&filterType=' + $scope.filterType + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }else if($scope.pageName == 'Service.Reminders'){
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }else if($scope.pageName == 'Phone.Call.List'){
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '?page=' + $scope.pageName + '&filterType=' + $scope.filterType + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }
                    angular.element("#printscreen").attr('href',src);
                }

                if(srcexcelscreen){
                    if ($scope.pageName == 'Service.Reminders.Due.In.The.Next.30Days'){
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '?page=' + $scope.pageName + '&filterType=' + $scope.filterType + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }else if($scope.pageName == 'Service.Reminders'){
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName+ '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;

                    }else if($scope.pageName == 'Phone.Call.List'){
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '?page=' + $scope.pageName + '&filterType=' + $scope.filterType + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;

                    }
                    angular.element("#downloadscreen").attr('href',src);
                }


                if(srcpdf){
                    if ($scope.pageName == 'Branches List' || $scope.pageName == 'Work Address List' || $scope.pageName == 'Stock Supplier Details' || $scope.pageName == 'Stock Due Details' || $scope.pageName == 'Current Stock Details') {
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&filterType='+ $scope.filterType;
                    } else if($scope.pageName == 'Work.Address.Reminder.Due'){
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&filterType='+ $scope.filterType+ '&moreFilter=' + $scope.moreFilter + '&searchText=' + $scope.searchName + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    } else if($scope.pageName == 'Service Reminder Statement') {
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName +'&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    } else if($scope.milestoneId) {
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '/' + $scope.milestoneId +'?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    } else if($scope.pageName == 'Stock history' || $scope.pageName == 'Stock location history'){
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&moreFilter=' + angular.toJson($scope.moreFilter);
                    } else {
                        var src = $scope.prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }
                    if($scope.pageName == 'Current Stock Details'){
                        angular.element("#new_print_screen").attr('href',src);
                    }else if($scope.pageName == 'Stock Supplier Details'){
                        angular.element("#stock_supplier_download_screen").attr('href',src);
                    }else{
                        angular.element("#print_pdf_screen").attr('href',src);
                    }
                }
                if(srcexcel){
                    if ($scope.pageName == 'Branches List' || $scope.pageName == 'Work Address List' || $scope.pageName == 'Stock Supplier Details'|| $scope.pageName == 'Stock Due Details' || $scope.pageName == 'Current Stock Details' ||  $scope.pageName == 'Reorder parts from supplier') {
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&filterType='+ $scope.filterType;
                    } else if($scope.pageName == 'Work.Address.Reminder.Due'){
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&filterType='+ $scope.filterType + '&moreFilter=' + $scope.moreFilter + '&searchText=' + $scope.searchName + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }  else if($scope.pageName == 'Service Reminder Statement') {
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName +'&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    } else if($scope.milestoneId) {
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '/' + $scope.milestoneId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    } else if($scope.pageName == 'Stock history' || $scope.pageName == 'Stock location history'){
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&moreFilter=' + angular.toJson($scope.moreFilter);
                    }else {
                        var src = $scope.prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName + '&searchText=' + $scope.searchName + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
                    }
                    if($scope.pageName == 'Current Stock Details'){
                        angular.element("#new_download_screen").attr('href',src);
                    }else if($scope.pageName == 'Stock Supplier Details'){
                        angular.element("#stock_supplier_download_screen").attr('href',src);
                    }else{
                        angular.element("#download_screen").attr('href',src);
                    }
                }
            });
        },
        link: function (scope, elm, prefix) {
            let html = '';
            if (scope.pdfRouteName == 'contractpdfshow') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Download to PDF" data-original-title="Download to PDF" download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/' + scope.pdfRouteName + '?page=' + scope.pageName + '"></a>';
                html += '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print PDF" data-original-title="Print PDF" href="' + scope.prefix + '/' + scope.pdfRouteName + '?page=' + scope.pageName + '"></a>';
            }
            else if(scope.pdfRouteName == 'ImportFailed' || scope.pdfRouteName == 'parts_undelivered' ||  scope.pdfRouteName == 'supplier_reminder_emails_sent' || scope.pdfRouteName == 'settings_communication_preference' || scope.pdfRouteName == 'reorder_parts_from_supplier' || scope.pdfRouteName == 'statement_without_preference') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self"  download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>';
            }
            else if(scope.pdfRouteName == 'ImportFailedLink'   || scope.pdfRouteName == 'reorder_parts_from_supplierLink' || scope.pdfRouteName == 'parts_undeliveredLink' ||  scope.pdfRouteName == 'supplier_reminder_emails_sentLink' || scope.pdfRouteName == 'settings_communication_preferenceLink' || scope.pdfRouteName == 'statement_without_preferenceLink') {
                html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '">' + scope.pageName + '.' + scope.searchName + '</a>';
            }
            else if(scope.pdfRouteName == 'ExcelBackup') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self"  download="' + scope.pageName + '.zip"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>';
            }
            else if(scope.pdfRouteName == 'ExcelBackupLink') {
                html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.zip"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '">' + scope.pageName + '.' + scope.searchName + '</a>';
            }
            else if(scope.pdfRouteName == 'mass_send_statement') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>';
            }
            else if(scope.pdfRouteName == 'mass_send_statementLink') {
                html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '">' + scope.pageName + '.' + scope.searchName + '</a>';
            }
            else if(scope.pdfRouteName == 'MassPrintPdf') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>';
            }
            else if(scope.pdfRouteName == 'MassPrintPdfLink') {

                if(!scope.helpText){
                    html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '">' + scope.pageName + '.' + scope.searchName + '</a>';

                }else if(scope.helpText == 'Insufficient print and post credits for this client'){
                    html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/download/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '">' + scope.pageName + '.' + scope.searchName + '</a>';

                }else{
                    scope.generateLetterviaBackendCallAndShowWarningModalBox = function processAllServiceReminders() {
                        $http.post(scope.prefix + '/' + scope.excelRouteName +  '/'+ scope.recordId + '?type=print').success(function(data){
                            warningModal.show('Processing all service reminders. This may take some time, but you can close this window and continue to use Commusoft. We will send you a notification when the process is finished', 'Process all service reminders', 'process_all_service_reminders');
                            var html = '&nbsp;&nbsp;<a href="" ng-click="generateLetterviaBackendCallAndShowWarningModalBox()"><span ng-show=false>'+scope.pageName+'</span></a>';
                            elm.html ($compile(html)(scope));
                        });
                    }

                    html = '&nbsp;&nbsp;<a href="" ng-click="generateLetterviaBackendCallAndShowWarningModalBox()"><span ng-show=true>'+scope.pageName+'</span></a>';

                }


            }
            else if(scope.pageName == 'Letters To Print') {
                html = '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print PDF" data-original-title="Print PDF" href="' + scope.prefix + '/' + scope.pdfRouteName + '/' + scope.recordId+ '?page=' + scope.pageName + '&type=print"></a>';
            }
            else if(scope.pdfRouteName == 'downloadLettersProcessingPdfLink') {
                if(!scope.helpText){
                    html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/' + scope.excelRouteName +  '/'+ scope.recordId + '?type=print">' + scope.pageName + '.' + scope.searchName + '</a>';

                }else{
                    scope.generateLetterviaBackendCallAndShowWarningModalBoxForWAA = function processAllWorkAddressAccess() {
                        $http.post(scope.prefix + '/' + scope.excelRouteName +  '/'+ scope.recordId + '?type=print').success(function(data){
                            warningModal.show('Processing all work address access messages, this may take some time. You can close this window and continue to use Commusoft. You will receive a notification when the process is finished', 'Process all Work address access messages', 'process_all_workaddress_access_messages');
                            var html = '&nbsp;&nbsp;<a href="" ng-click="generateLetterviaBackendCallAndShowWarningModalBoxForWAA()"><span ng-show=false>'+scope.excelRouteName+'</span></a>';
                            elm.html ($compile(html)(scope));
                        });
                    }
                    html = '&nbsp;&nbsp;<a href="" ng-click="generateLetterviaBackendCallAndShowWarningModalBoxForWAA()"><span ng-show=true>'+scope.excelRouteName+'</span></a>';

                }
            }
            else if(scope.pdfRouteName == 'downloadLettersProcessingPdf') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_blank" href="' + scope.prefix + '/' + scope.excelRouteName + '/' + scope.recordId+ '?type=print"></a>';
            }
            else if(scope.pdfRouteName == 'printPurchaseOrderPdfLink') {
                html = '&nbsp;&nbsp;<a target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix + '/' + scope.excelRouteName  + '?type=print&isCopy=false">' + scope.pageName + '.' + scope.searchName + '</a>';
            }
            else if(scope.pdfRouteName == 'printPurchaseOrderPdf') {
                html = '&nbsp;&nbsp;<a class="icon ss-download" target="_blank" href="' + scope.prefix + '/' + scope.excelRouteName + '?type=print&isCopy=false"></a>';
            }
            else if(scope.pdfRouteName == 'printLettersProcessingPdf') {
                html = '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print PDF" data-original-title="Print PDF" href="' + scope.prefix + '/' + scope.pdfRouteName + '/' + scope.recordId+ '?page=' + scope.pageName + '"></a>';
            }else if(scope.pageName == 'Current Stock Details' ){
                html = '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="new_print_screen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print PDF" data-original-title="Print PDF" href="' + scope.prefix + '/'+ scope.pdfRouteName + '/' + scope.recordId + '?page=' + scope.pageName + '"></a>';
                html += '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" id="new_download_screen" rel="tooltip" tooltip-placement="top" tooltip="Download to Excel" data-original-title="Download to Excel" download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>';
            }else if(scope.pdfRouteName == 'printStockSupplierPdf' ){
                html = '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="stock_supplier_print_screen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print PDF" data-original-title="Print PDF" href="' + scope.prefix + '/'+ scope.pdfRouteName + '/' + scope.recordId + '?page=' + scope.pageName + '"></a>';
                html += '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" id="stock_supplier_download_screen" rel="tooltip" tooltip-placement="top" tooltip="Download to Excel" data-original-title="Download to Excel" download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>';
            }
            else if(scope.pdfRouteName == 'downloadPOPdf') {
                html = '<a class="icon ss-download ng-scope" target="_self"  download="' + scope.pageName + '.pdf"' + 'href="' + scope.prefix +  '/'+scope.pdfRouteName +  '/'+ scope.searchName +  '/' + scope.recordId + '?page=' + scope.pageName + '"> ' + scope.pageName + '</a>';
            }else if(scope.pdfRouteName == 'ReportPrintPdfLink'){
                html = '&nbsp;&nbsp;<a target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top" tooltip="Print PDF" data-original-title="Print PDF" href="'+scope.fileLocation+'">'+ scope.pageName + '.' + scope.searchName +'</a>';
            }else if(scope.pdfRouteName == 'ReportPrintPdf'){
                html = '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="top"  data-original-title="Print PDF" href="'+scope.fileLocation+'"></a>';
            }
            else {
                if(scope.recordId == '') {
                    html = scope.pdfRouteName ? '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="printscreen" data-placement="top" rel="tooltip" tooltip-placement="bottom" tooltip="Print report" data-original-title="Print PDF" href="' + scope.prefix + '/' + scope.pdfRouteName + '?page=' + scope.pageName + '"></a>' : '';
                    html += scope.excelRouteName ? '&nbsp;&nbsp;<a class="icon ss-download" target="_self" id="downloadscreen" data-placement="top" rel="tooltip" tooltip-placement="bottom" tooltip="Download to Excel" data-original-title="Download to Excel" download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/' + scope.excelRouteName + '?page=' + scope.pageName + '"></a>' : '';
                } else {
                    html = scope.pdfRouteName ? '&nbsp;&nbsp;<a class="icon ss-print" target="_blank" id="print_pdf_screen" data-placement="top" rel="tooltip" tooltip-placement="bottom" tooltip="Print report" data-original-title="Print PDF" href="' + scope.prefix + '/'+ scope.pdfRouteName + '/' + scope.recordId + '?page=' + scope.pageName + '"></a>' : '';
                    html += scope.excelRouteName ? '&nbsp;&nbsp;<a class="icon ss-download" target="_self" data-placement="top" id="download_screen" rel="tooltip" tooltip-placement="bottom" tooltip="Download to Excel" data-original-title="Download to Excel" download="' + scope.pageName + '.xls"' + 'href="' + scope.prefix + '/' + scope.excelRouteName +  '/'+ scope.recordId + '?page=' + scope.pageName + '"></a>' :  '';
                }
            }
            if(html !== '') {
                html = html.indexOf('&nbsp;&nbsp;')===0 ? html.substr(12) : html;
                elm.html ($compile(html)(scope));
            }
        }
    };
}]);
