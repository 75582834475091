'use strict';

function CustomersCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope, $timeout, $filter, $window, toastBox, $translate, $location, $compile) {
  $scope.data = datasets;
  $scope.showSubNavStatic = false;
  $rootScope.successMessage = '';
  $scope.estimateDesign = false;
  $scope.customerInvoiceDesign = false;
  $scope.customerAssetDesign = false;
  $scope.$state = $state;
  $scope.customerAssetScreen = false;
  $scope.multipleInvoiceScreen = false;

    $scope.$on('displayScreen', function (event, data) {
        $scope.estimateDesign = data;
    });

    $scope.$on('displayInvoiceScreen', function (event, data) {
        $scope.customerInvoiceDesign = data;
    });

    $scope.$on('displayAssetScreen', function (event, data) {
        $scope.customerAssetDesign = data;
        $scope.customerAssetScreen = false;
    });

  $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        if (toState.url === '/edit' || toState.url === '/new' || toState.url === '/new_phone_call') {
            $scope.handleFormBreadcrumbs(toState.name);
            $scope.hideSubNav = true;
        } else {
            $scope.hideSubNav = false;
        }

        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)$/);

        if (matches === null) {
            return;
        }
        $scope.jobId = null;
        $scope.returnState = matches[3];
        $scope.hideSubNavCustomerName = false;
        $scope.returnStateLink = false;
        $scope.invoiceSubNav = false;
        if ($scope.returnState === 'view.property') {
            // this is used on /customers/work_address/{id}/view/property/view
            $scope.returnState = $translate('Property');
            $scope.returnSubState = '';
            $scope.estimateId = '';
        }
        if ($scope.returnState === 'view.propertyedit') {
            $scope.returnState = $translate('Property');
            $scope.formBreadcrumbStr = 'Edit.Property';
            $scope.hideSubNav = true;
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.notes.table') {
            $scope.returnState = $translate('.Communications');
            $scope.tableview = true;
            $scope.timelineview = false;
            $rootScope.mode = 'table';
            $scope.hideSubNavCustomerName = true;
        }
    $scope.contractInvoiceLink='';

        if ($state.current.name === 'loggedin.customer_list.view.credit_note.details') {
            $scope.returnState = $translate('Credit.note');
            $scope.returnSubState = $translate('Details');
            $scope.hideSubNavCustomerName = true;
            $scope.jobSubNavStatic = true;
            $scope.invoiceSubNav = true;
            $scope.returnAddState = false;
            $scope.returnSubStateLink = false;
            $scope.returnInvoiceStateLink = false;
        }

        $scope.creditNoteLink = false;
        if ($state.current.name === 'loggedin.customer_list.view.credit_note.details.add_allocation') {
            $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
            $scope.returnState = 'New allocation';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
        }

        if ($state.current.name === 'loggedin.customer_list.view.credit_note.details.edit_credit_note') {
            $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
            $scope.returnState = 'Edit';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
        }

        if ($state.current.name === 'loggedin.customer_list.view.credit_note.details.edit_customer_receipt') {
            $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
            $scope.returnState = 'Allocation no.'+ $state.params.receiptId +' / Edit';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
        }

        if ($state.current.name === 'loggedin.customer_list.view.credit_note.details.edit_allocation') {
            $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
            $scope.returnState = 'Edit allocation';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
        }

        if ($state.current.name === 'loggedin.customer_list.view.credit_note.customer_receive_credit') {
            $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
            $scope.returnState = 'Receive credit';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
        }

        if ($scope.returnState === 'view.notes.timeline') {
            $scope.returnState = $translate('.Communications');
            $scope.tableview = false;
            $scope.timelineview = true;
            $rootScope.mode = 'timeline';
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.invoice_setup') {
            $scope.returnState = $translate('Invoice.Address.Setup');
            $scope.hideSubNavCustomerName = true;
        }

        if ($scope.returnState === 'view.notes_new') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNavCustomerName = true;
            $scope.formBreadcrumbStr = 'Add.New.Note';
            $scope.hideSubNav = true;
        }
        if ($scope.returnState === 'view.notes_edit') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNavCustomerName = true;
            $scope.formBreadcrumbStr = 'Edit.Note';
            $scope.hideSubNav = true;
        }

        if ($scope.returnState === 'view.branches') {
            $scope.returnState = $translate('.Branches');
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.new_branch') {
            $scope.returnState = $translate('.Branches');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New.Branch';
        }

        if ($scope.returnState === 'view.edit_branch') {
            $scope.returnState = $translate('.Branches');
            $scope.formBreadcrumbStr = 'Edit.Branch';
            $scope.hideSubNav = true;
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.workaddresses') {
            $scope.returnState = 'Work.Addresses';
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.new_work') {
            $scope.returnState = 'Work.Addresses';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New.Work.Address';
        }
        if ($scope.returnState === 'view.bulk_work') {
            $scope.returnState = 'Work.Addresses';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New.Work.Address';
        }
        if ($scope.returnState === 'view.files') {
            $scope.returnState = $translate('.Files');
            $scope.hideSubNavCustomerName = true;
        }

        if ($scope.returnState === 'view.new_file') {
            $scope.returnState = $translate('.Files');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New.File';
        }
        if ($scope.returnState === 'view.invoice_addresses') {
            $scope.returnState = "Invoice.Address";
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.search_invoice_address') {
            $scope.returnState = 'Invoice.Address';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Search.Invoice.Address';
        }
        if ($scope.returnState === 'view.new_invoice_address') {
            $scope.returnState = 'Invoice.Address';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'New.Invoice.Address';
        }
        if ($scope.returnState === 'view.enable_landlord_invoice_address') {
            $scope.returnState = 'Invoice.Address';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Landlord.Edit';
        }
        if ($scope.returnState === 'view.enable_workaddress_invoice_address') {
            $scope.returnState = 'Invoice.Address';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Work.Address.Edit';
        }
        if ($scope.returnState === 'view.reminders') {
            $scope.returnState = $translate('.Reminders');
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.new_reminder') {
            $scope.returnState = $translate('.Reminders');
            $scope.hideSubNavCustomerName = true;
            $scope.formBreadcrumbStr = 'New.Reminder';
            $scope.hideSubNav = true;
        }
        if ($scope.returnState === 'view.edit_reminder') {
            $scope.returnState = $translate('.Reminders');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Edit.Reminder';
        }
        if ($scope.returnState === 'view.edit_after_sales_care') {
            $scope.returnState = $translate('.Reminders');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Edit.After.Sales.Care';
        }
        if ($scope.returnState === 'view.edit_automatic_statements') {
            $scope.returnState = $translate('.Reminders');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Edit.Automatic.Statements';
        }
        if ($scope.returnState === 'view.edit_automatic_debtchasing') {
            $scope.returnState = $translate('.Reminders');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Edit.Automatic.DebtChasing';
        }
        if ($scope.returnState === 'view.contacts') {
            $scope.hideSubNavCustomerName = true;
            $scope.returnState = $translate('.Contacts');
        }
        if ($scope.returnState === 'view.new_contact') {
            $scope.returnState = $translate('.Contacts');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Add.Contact';

        }
        if ($scope.returnState === 'view.edit_contact') {
            $scope.returnState = $translate('.Contacts');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Edit.Contact';
        }
        if ($scope.returnState === 'view.new_phone_call') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNavCustomerName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Add.Phone.Call';
        }
        if ($scope.returnState === 'view.new_sms_message') {
            $scope.returnState = $translate('.Communications');
            $scope.hideSubNavCustomerName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Send.New.SMS';
        }
        if ($scope.returnState === 'view.new_estimate') {
            $scope.returnState = 'Add.Estimate';
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.new_estimate_project') {
            $scope.returnState = 'Add.New.Project';
            $scope.hideSubNavCustomerName = true;
        }

        if ($scope.returnState === 'view.appliances') {
            $scope.returnState = $translate('.Appliances');
            $scope.hideSubNavCustomerName = true;
        }
        if ($scope.returnState === 'view.new_appliance') {
            $scope.returnState = $translate('.Appliances');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'New.Appliance';
        }
        if ($scope.returnState === 'view.edit_appliance') {
            $scope.returnState = $translate('.Appliances');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Edit.Appliance';
        }
        if ($scope.returnState === 'view.customer_technicalreference') {
            $scope.returnState = $translate('.Appliances');
            $scope.hideSubNavCustomerName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Edit.technical.reference.location';
        }
        if ($scope.returnState === 'view.technicalreference') {
            $scope.returnState = $translate('Technical.Reference');
            $scope.hideSubNavCustomerName = true;
            $scope.returnStateLink = false;
            $scope.hideSubNav = false;
        }
        if ($scope.returnState === 'view.customer_edit_technicalreference') {
            $scope.returnState = $translate('Technical.Reference');
            $scope.hideSubNavCustomerName = true;
            $scope.hideSubNav = true;
            $scope.formBreadcrumbStr = 'Edit.technical.reference.location';
        }
        if ($scope.returnState === 'view.new_job' || $scope.returnState === 'view.service_reminder_new_job' || $scope.returnState === 'view.asset_new_job' ) {
            $scope.returnState = 'Add.New.Job';
            $scope.hideSubNavCustomerName = true;
        }
        if($state.current.name === 'loggedin.customer_list.view.customer_add_payment'){
            $scope.returnState = $translate('Account.Credit');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = 'Add.Credit';
        }
        if($state.current.name === 'loggedin.customer_list.view.edit_customer_account_credit'){
            $scope.returnState = $translate('Account.Credit');
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = $translate('Edit.Credit');
        }
        if ($state.current.name === 'loggedin.customer_list.view.account_credit') {
            $scope.returnState = $translate('Account.Credit');
            $scope.hideSubNavCustomerName = true;
        }
        if ($state.current.name === 'loggedin.customer_list.view.stored_cards') {
            $scope.returnState = $translate('Stored.cards');
            $scope.hideSubNavCustomerName = true;
        }
        if ($state.current.name === 'loggedin.customer_list.view.stored_cards.new') {
            $scope.returnState = $translate('Stored.cards');
            $scope.formBreadcrumbStr = 'New card';
            $scope.hideSubNavCustomerName = true;
            $scope.hideSubNav = true;
        }

        $scope.$on('estimateId:selected', function (event, data) {
            if (data.estimateId == null || data.estimateId == undefined || data.estimateId == "") {
                $scope.estimateId = data;
            } else {
                $scope.estimateId = data.estimateId;
            }
            if ($scope.returnState === 'view.estimate_details.survey' || $scope.returnState === 'view.project_estimate_details.survey') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.survey') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('.Survey');
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
            }
            if ($scope.returnState === 'view.estimate_details.survey_edit' || $scope.returnState === 'view.project_estimate_details.survey_edit') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.survey_edit') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('.Survey');
                $scope.returnAddState = $translate('.Edit');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($scope.returnState === 'view.estimate.new_estimate_reminder' || $scope.returnState === 'view.estimate.edit_estimate_reminder') {
                if($scope.returnState === 'view.estimate.new_estimate_reminder'){
                    $scope.returnAddState = 'New.Reminder';
                }
                if($scope.returnState === 'view.estimate.edit_estimate_reminder'){
                    $scope.returnAddState = 'Edit.Reminder';
                }
                $scope.returnState = 'Estimate.no';
                $scope.returnSubState = $translate('.Reminders');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($scope.returnState === 'view.estimate_details.introduction' || $scope.returnState === 'view.project_estimate_details.introduction') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.introduction') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = '.Introduction';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.additional_option' || $scope.returnState === 'view.project_estimate_details.additional_option') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.additional_option') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
            }
            if ($scope.returnState === 'view.estimate_details.accept_reject' || $scope.returnState === 'view.project_estimate_details.accept_reject') {
                if ($scope.returnState === 'view.estimate_details.accept_reject') {
                    $scope.returnState = 'Estimate.no';
                }
                if ($scope.returnState === 'view.project_estimate_details.accept_reject') {
                    $scope.returnState = 'Project.no';
                }
                $scope.returnSubState = 'Accept.or.reject';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.body' || $scope.returnState === 'view.project_estimate_details.body') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.body') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = '.Estimate.Body';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.parts' || $scope.returnState === 'view.project_estimate_details.parts') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.parts') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Parts';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.todos' || $scope.returnState === 'view.project_estimate_details.todos') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.todos') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('.ToDos');
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.cost' || $scope.returnState === 'view.project_estimate_details.cost') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.cost') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Budget.cost'
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.price' || $scope.returnState === 'view.project_estimate_details.price') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.price') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('.Price');
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.invoice_schedule' || $scope.returnState === 'view.project_estimate_details.invoice_schedule') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.invoice_schedule') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Invoice.schedule'
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate_details.sendtocustomer' || $scope.returnState === 'view.project_estimate_details.sendtocustomer') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.sendtocustomer') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Send.to.customer'
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
            }
            if ($scope.returnState === 'view.estimate.estimate_reminders' || $scope.returnState === 'view.project.estimate_reminders') {
                $scope.returnState = ($scope.returnState === 'view.estimate.estimate_reminders') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('.Reminders');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.estimateDesign = false;
                $scope.returnSubStateLink = false;
            }
            if ($state.current.name === 'loggedin.customer_list.view.estimate.notes' || $state.current.name === 'loggedin.customer_list.view.project.notes' || $state.current.name === 'loggedin.customer_list.view.project.notes.table' || $state.current.name === 'loggedin.customer_list.view.estimate.notes.table' || $state.current.name === 'loggedin.customer_list.view.project.notes.timeline' || $state.current.name === 'loggedin.customer_list.view.estimate.notes.timeline') {
                if($state.current.name === 'loggedin.customer_list.view.estimate.notes.timeline' ||$state.current.name === 'loggedin.customer_list.view.project.notes.timeline'){
                    $scope.timelineview = true;
                    $scope.tableview = false;
                }
                if($state.current.name === 'loggedin.customer_list.view.estimate.notes.table' || $state.current.name === 'loggedin.customer_list.view.project.notes.table'){
                    $scope.timelineview = false;
                    $scope.tableview = true;
                }

                if($state.current.name === 'loggedin.customer_list.view.estimate.notes.timeline'|| $state.current.name === 'loggedin.customer_list.view.estimate.notes' || $state.current.name === 'loggedin.customer_list.view.estimate.notes.table') {
                    $scope.returnState = 'Estimate.no';
                }
                if($state.current.name === 'loggedin.customer_list.view.project.notes' || $state.current.name === 'loggedin.customer_list.view.project.notes.table' || $state.current.name === 'loggedin.customer_list.view.project.notes.timeline') {
                    $scope.returnState = 'Project.no';
                }

                $scope.returnSubState = $translate('.Communications');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.estimateDesign = false;
                $scope.returnSubStateLink = false;
            }
            if ($state.current.name === 'loggedin.customer_list.view.estimate.notes_new' || $state.current.name === 'loggedin.customer_list.view.estimate.notes_edit' || $state.current.name === 'loggedin.customer_list.view.estimate.new_smsmessage' || $state.current.name === 'loggedin.customer_list.view.estimate.new_phonecall') {
                if($state.current.name === 'loggedin.customer_list.view.estimate.notes_new'){
                    $scope.returnState = 'Estimate.no';
                    $scope.returnAddState = 'Add.New.Note';
                }
                if($state.current.name === 'loggedin.customer_list.view.estimate.notes_edit'){
                    $scope.returnState = 'Estimate.no';
                    $scope.returnAddState = 'Edit.Note';
                }
                if($state.current.name === 'loggedin.customer_list.view.estimate.new_smsmessage'){
                    $scope.returnState = 'Estimate.no';
                    $scope.returnAddState = 'New.SMS.Message';
                }
                if($state.current.name === 'loggedin.customer_list.view.estimate.new_phonecall'){
                    $scope.returnState = 'Estimate.no';
                    $scope.returnAddState = 'Add.Phone.Call';
                }

                if($state.current.name === 'loggedin.customer_list.view.estimate.notes.timeline'|| $state.current.name === 'loggedin.customer_list.view.estimate.notes' || $state.current.name === 'loggedin.customer_list.view.estimate.notes.table') {
                    $scope.returnState = 'Estimate.no';
                }

                if($state.current.name === 'loggedin.customer_list.view.project.notes' || $state.current.name === 'loggedin.customer_list.view.project.notes.table' || $state.current.name === 'loggedin.customer_list.view.project.notes.timeline') {
                    $scope.returnState = 'Project.no';
                }

                $scope.returnSubState = $translate('.Communications');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($scope.returnState === 'view.estimate.files' || $scope.returnState === 'view.project.files') {
                $scope.returnState = ($scope.returnState === 'view.estimate.files') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('.Files');
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.returnAddState = '';
                $scope.returnSubStateLink = false;
            }
            if ($scope.returnState === 'view.estimate.new_file' || $scope.returnState === 'view.project.new_file') {
                $scope.returnState = ($scope.returnState === 'view.estimate.new_file') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = '.Files';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.returnAddState = 'New.Files';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate_details.edit_option.body' || $scope.returnState === 'view.project_estimate_details.edit_option.body') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.edit_option.body') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.optionNo = 'Option.no';
                $scope.optionId = data.optionId;
                $scope.returnAddState = 'Edit.Estimate.Body';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate_details.edit_option.parts' || $scope.returnState === 'view.project_estimate_details.edit_option.parts') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.edit_option.parts') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.optionNo = 'Option.no';
                $scope.optionId = data.optionId;
                $scope.returnAddState = 'Edit.Parts';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate_details.edit_option.todos' || $scope.returnState === 'view.project_estimate_details.edit_option.todos') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.edit_option.todos') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.optionNo = 'Option.no';
                $scope.optionId = data.optionId;
                $scope.returnAddState = 'Edit.Estimate.Todos';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate_details.edit_option.cost' || $scope.returnState === 'view.project_estimate_details.edit_option.cost') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.edit_option.cost') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.optionNo = 'Option.no';
                $scope.optionId = data.optionId;
                $scope.returnAddState = 'Edit.Estimate.Cost';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate_details.edit_option.price' || $scope.returnState === 'view.project_estimate_details.edit_option.price') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.edit_option.price') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.optionNo = 'Option.no';
                $scope.optionId = data.optionId;
                $scope.returnAddState = 'Edit.Estimate.Price';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate_details.edit_option.invoice_schedule' || $scope.returnState === 'view.project_estimate_details.edit_option.invoice_schedule') {
                $scope.returnState = ($scope.returnState === 'view.estimate_details.edit_option.invoice_schedule') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = 'Additional.Options';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.optionNo = 'Option.no';
                $scope.optionId = data.optionId;
                $scope.returnAddState = 'Edit.Invoice.Schedule';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.estimate.reserve_list' || $scope.returnState === 'view.project.reserve_list') {
                $scope.returnState = ($scope.returnState === 'view.estimate.reserve_list') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = '.Estimate.Details';
                $scope.hideSubNavCustomerName = true;
                $scope.returnSubStateLink = true;
                $scope.returnAddState = '.Reserve.List';
            }
            if ($state.current.name === 'loggedin.customer_list.view.estimate.estimate_office_task') {
                $scope.returnState = ($state.current.name === 'loggedin.customer_list.view.estimate.estimate_office_task') ? 'Estimate.no' : 'Project.no';
                $scope.returnSubState = $translate('Office task');

                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.estimateDesign = false;
                $scope.returnSubStateLink = false;
            }
        });

        $scope.$on('jobId:selected', function (event, data) {
            $scope.paymentsLink = false;
            $scope.returnInvoiceNotesLink = false;
            $scope.estimateId = null;
            $scope.estimateDesign = false;
            if (data.jobId == null || data.jobId == undefined || data.jobId == "") {
                $scope.jobId = data;
            } else {
                $scope.jobId = data.jobId;
            }
            if(data.jobType) {
                $scope.jobType = data.jobType;
            }
            if(data.additionalWorkId) {
                $scope.additionalWorkId = data.additionalWorkId;
            }
            if(data.invoiceId) {
                $scope.invoiceLinkId = data.invoiceId;
            } else {
                $scope.invoiceLinkId = '';
            }
            $scope.contractInvoiceLink=false;
            if ($state.current.name === 'loggedin.customer_list.view.invoice_view.view_invoice_contract') {
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= true;
                $scope.contractInvoice='Invoice no. ' +data.invoiceNo + ' / View';
                $scope.paymentsLink='';
                $scope.customerMode = $state.params.type;
            }else if($state.current.name === 'loggedin.customer_list.view.invoice_view.view_notes.timeline' || $state.current.name ==='loggedin.customer_list.view.invoice_view.view_notes.table') {
                $scope.customerMode = $state.params.type;
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= true;
                $scope.contractInvoice='Invoice no. ' +data.invoiceNo + ' / Communications';
                $scope.paymentsLink='';
                if($state.current.name === 'loggedin.customer_list.view.invoice_view.view_notes.timeline'){
                    $scope.timelineview=true;
                    $scope.tableview = false;
                }else if($state.current.name ==='loggedin.customer_list.view.invoice_view.view_notes.table'){
                    $scope.timelineview=false;
                    $scope.tableview = true;
                }
            }else if($state.current.name ==='loggedin.customer_list.view.invoice_view.contract_new_payment'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceId ;
                $scope.paymentDetails='New Payment';
                $scope.paymentsLink='';
                $scope.customerMode = $state.params.type;

            }else if($state.current.name ==='loggedin.customer_list.view.invoice_view.notes_new_phonecall'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceId ;
                $scope.paymentDetails='Add phone call';
                $scope.paymentsLink=false;
                $scope.communicationsLink='Communications';
                $scope.customerMode = $state.params.type;

            }else if($state.current.name ==='loggedin.customer_list.view.invoice_view.notes_new_smsmessage'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceId ;
                $scope.paymentDetails='Send new sms';
                $scope.paymentsLink=false;
                $scope.communicationsLink='Communications';
                $scope.customerMode = $state.params.type;

            }else if($state.current.name ==='loggedin.customer_list.view.invoice_view.notes_new'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceId ;
                $scope.paymentDetails='Add new note';
                $scope.paymentsLink=false;
                $scope.communicationsLink='Communications';
                $scope.customerMode = $state.params.type;

            }else if($state.current.name ==='loggedin.customer_list.view.invoice_view.view_invoice_payment'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceNo ;
                $scope.paymentDetails='View Payment';
                $scope.paymentsLink='';
                $scope.customerMode = $state.params.type;
            }
            else if($state.current.name ==='loggedin.customer_list.view.invoice_view.edit_invoice_payment'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceNo ;
                $scope.paymentDetails='Edit Payment';
                $scope.paymentsLink = 'Payment no.' + $state.params.paymentId;
                $scope.communicationsLink='';
                $scope.customerMode = $state.params.type;
            }
            else if($state.current.name ==='loggedin.customer_list.view.invoice_view.new_credit_note'){
                $scope.contractInvoiceLink = 'Contract no. ' + $state.params.contractId;
                $scope.returnState = '';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnStateLink = false;
                $scope.returnSubStateLink = false;
                $scope.returnSubState = '';
                $scope.contractInvoicecheck= false;
                $scope.contractInvoice='Invoice no. ' +data.invoiceNo ;
                $scope.paymentDetails='creadit notes / New';
                $scope.paymentsLink='';
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.details') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Details');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.returnStateLink = false;

            }
            else if ($scope.returnState === 'view.job.visits.view') {
                $scope.returnState = $translate('Project.no');
                $scope.returnSubState = $translate('.Visits');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;

            }
            else if ($scope.returnState === 'view.job.todos.view') {
                $scope.returnState = $translate('Project.no');
                $scope.returnSubState = $translate('.Todos');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;

            }
            else if ($scope.returnState === 'view.job.parts.view_parts') {
                $scope.returnState = $translate('Job.no.');
                $scope.returnSubState = $translate('.Parts');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.returnStateLink = false;
            }
            else if ($scope.returnState === 'view.job.parts.view' || $scope.returnState === 'view.job.parts' ) {
                $scope.returnState = 'Project.no';
                $scope.returnSubState = $translate('.Parts');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.returnStateLink = false;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.cost.projectCostView' || $state.current.name === 'loggedin.customer_list.view.job.viewcost') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('.Costs');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.returnPoStateLink = false;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = 'Additional.works';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.estimateDesign = false;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.new') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('New.additional.work/Details');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.estimateDesign = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.details') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = $translate('.Details');
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.todos') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = $translate('.Todos');
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.budgetcost') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = 'Budget.cost';
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.price') {
                $scope.returnState = 'Job.no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = 'price';
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.invoice_schedule') {
                $scope.returnState = 'Job.no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = 'Invoice.schedule';
                $scope.returnSubStateLink = true;
            }
            else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.send_to_customer') {
                $scope.returnState = 'Job.no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = 'Send.to.customer';
                $scope.returnSubStateLink = true;
            }
            else if ($state.current.name === 'loggedin.customer_list.view.job.additional_works.view.accept_or_reject') {
                $scope.returnState = 'Job.no.';
                $scope.returnSubState = 'Additional.work';
                $scope.hideSubNavCustomerName = true;
                $scope.estimateDesign = true;
                $scope.additionalWorkNo = 'No. ';
                $scope.returnAddState = 'Accept.or.reject';
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.details.new_job_part_purchase_order' || $state.current.name === 'loggedin.customer_list.view.job.details.new_job_cost_purchase_order' || $state.current.name === 'view.job.details.new_job_part_purchase_order_operational' || $state.current.name === 'loggedin.customer_list.view.job.details.new_job_part_purchase_order_operational') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnStateLink = true;
                $scope.returnAddState = 'New.Purchase.Order';
                if($scope.selectedTab == 'JobDetails'){
                    $scope.returnSubState = $translate('.Details');
                }else{
                    $scope.returnSubState = $translate('.Costs');
                }
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.details.new_job_part_purchase_order_milestone' || $state.current.name === 'loggedin.customer_list.view.job.details.new_job_cost_purchase_order_milestone') {
                $scope.returnAddState = 'New.Purchase.Order';
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnStateLink = true;
                $scope.returnAddState = 'New.Purchase.Order';
                if($scope.selectedTab == 'Parts'){
                    $scope.returnSubState = $translate('.Parts');
                }else{
                    $scope.returnSubState = $translate('.Costs');
                }
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.details.add_travel_milestone' || $state.current.name === 'loggedin.customer_list.view.job.details.add_travel' ) {
                $scope.returnState = 'Job.no.';
                $scope.returnAddState = $translate('Travel.New');
                $scope.returnSubState = $translate('.Costs');
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.details.edit_travel' || $state.current.name === 'loggedin.customer_list.view.job.details.edit_travel_milestone' ) {
                $scope.returnState = 'Job.no.';
                $scope.returnAddState = $translate('Travel.Edit');
                $scope.returnSubState = $translate('.Costs');
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            }
            else if ($state.current.name === 'loggedin.customer_list.view.job.invoices') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
                $scope.returnInvoiceStateLink = false;
            } else if ($state.current.name === 'loggedin.customer_list.view.job.invoices.new' || $state.current.name === 'loggedin.customer_list.view.job.invoices.newmilestone') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.returnAddState = data.activeInvoiceType;
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
                $scope.returnInvoiceStateLink = false;
            }else if ($state.current.name === 'loggedin.customer_list.view.job.invoices.view.edit' && data.invoiceId) {
 $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
        $scope.returnSubState = $translate('Invoices');
        $scope.returnAddState = (data.draft === true) ? 'Invoice no. (Draft) / Edit / '+ $translate(data.activeInvoiceType) :'Invoice no. '+data.invoiceNo+' / Edit / ' +$translate(data.activeInvoiceType);
        $scope.hideSubNavCustomerName = true;
        $scope.jobSubNavStatic = true;
        $scope.returnSubStateLink = true;
        $scope.returnStateLink = true;
        $scope.returnInvoiceStateLink = false;
            } else if ($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice' && data.invoiceId) {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.returnAddState = (data.draft === true) ? 'Invoice no. (Draft) / View':'Invoice no. '+ data.invoiceNo+' / View';
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.invoiceSubNav = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
                $scope.returnInvoiceStateLink = false;
            } else if (($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_notes' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_notes.table' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_notes.timeline') && data.invoiceId) {
                if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_notes.table'){
                    $scope.timelineview = false;
                    $scope.tableview = true;
                }
                else if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_notes.timeline'){
                    $scope.timelineview = true;
                    $scope.tableview = false;
                }
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = (data.draft === true) ? 'Invoice no. (Draft) ':'Invoice no. '+ data.invoiceNo;
                $scope.returnAddState = 'Communications';
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.jobInvoiceSubNav = true;
                $scope.invoiceSubNav = true;
                $scope.returnSubStateLink = false;
                $scope.returnStateLink = true;
                $scope.returnInvoiceStateLink = false;
            }  else if (($state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_edit' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new_smsmessage' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new_phonecall') && data.invoiceId) {
                if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new'){
                    $scope.returnAddState = 'Add.New.Note';
                }
                else if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_edit'){
                    $scope.returnAddState = 'Edit.Note';
                }
                else if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new_smsmessage'){
                    $scope.returnAddState = 'New.SMS.Message';
                }
                else if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new_phonecall'){
                    $scope.returnAddState = 'Add.Phone.Call';
                }
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.invoiceLinkId = data.invoiceId;
                $scope.returnInvoiceState = (data.draft === true) ? 'Invoice no. (Draft) ':'Invoice no. '+data.invoiceNo;
                $scope.returnInvoiceNotesLink = true;
                $scope.returnInvoiceNotesTab = $translate('Communications');
                $scope.returnInvoiceStateLink = true;
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.invoiceSubNav = false;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            } else if ($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.new_payment' && data.invoiceId) {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.invoiceLinkId = data.invoiceId;
                $scope.returnInvoiceState = 'Invoice no. '+$scope.invoiceLinkId;
                $scope.returnAddState = 'New payment';
                $scope.returnInvoiceStateLink = true;
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.invoiceSubNav = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            } else if ($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.new_credit_note') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.invoiceLinkId = data.invoiceId ? data.invoiceId : $state.params.invoiceId;
                $scope.returnInvoiceState = 'Invoice no. '+$scope.invoiceLinkId;
                $scope.returnAddState = 'Credit notes / New';
                $scope.returnInvoiceStateLink = true;
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.invoiceSubNav = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            } else if (($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment') && data.invoiceNo) {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.invoiceLinkId = data.invoiceId;
                $scope.returnInvoiceState = 'Invoice no. '+ data.invoiceNo;
                $scope.returnAddState = 'Payment no.' + $state.params.paymentId + ' / View';
                $scope.returnInvoiceStateLink = true;
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.invoiceSubNav = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            } else if ($state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment.edit' && data.invoiceId) {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job.no.' : 'Project no.';
                $scope.returnSubState = $translate('Invoices');
                $scope.invoiceLinkId = data.invoiceId;
                $scope.returnInvoiceState = 'Invoice no. '+ data.invoiceNo;
                $scope.paymentsLink = 'Payment no.' + $state.params.paymentId;
                $scope.returnAddState = 'Edit';
                $scope.returnInvoiceStateLink = true;
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.invoiceSubNav = true;
                $scope.returnSubStateLink = true;
                $scope.returnStateLink = true;
            } else if (($state.current.name === 'loggedin.customer_list.view.view_multiple_invoice_payment') && data.invoiceNo) {
          $scope.returnState = true;
          $scope.returnSubState = $translate('Invoices');
          $scope.invoiceLinkId = data.invoiceId;
          $scope.returnInvoiceState = 'Invoice no. '+ data.invoiceNo;
          $scope.returnAddState = 'Payment no.' + $state.params.paymentId + ' / View';
          $scope.multipleInvoiceScreen = true;
          $scope.hideSubNavCustomerName = true;
          $scope.jobSubNavStatic = true;
          $scope.invoiceSubNav = true;
          $scope.returnSubStateLink = true;
          $scope.returnStateLink = false;
          $scope.returnInvoiceStateLink = true;
          $scope.customerMode = $state.params.type;
          $scope.customerTabName = "Customers.List";
          $scope.customerSubNavStatic = true;
      } else if (($state.current.name === 'loggedin.customer_list.view.edit_multiple_invoice_payment') && data.invoiceId) {
          $scope.returnState = true;
          $scope.returnSubState = $translate('Invoices');
          $scope.invoiceLinkId = $state.params.invoiceId;
          $scope.returnInvoiceState = 'Invoice no. '+ data.invoiceNo;
          $scope.paymentsLink = 'Payment no.' + $state.params.paymentId;
          $scope.returnAddState = 'Edit';
          $scope.multipleInvoiceScreen = true;
          $scope.returnInvoiceStateLink = true;
          $scope.hideSubNavCustomerName = true;
          $scope.jobSubNavStatic = true;
          $scope.invoiceSubNav = true;
          $scope.returnSubStateLink = true;
          $scope.returnStateLink = false;
          $scope.customerMode = $state.params.type;
          $scope.customerTabName = "Customers.List";
          $scope.customerSubNavStatic = true;
      } else if (($state.current.name === 'loggedin.customer_list.view.new_multiple_invoice_payment') && data.invoiceId) {
          $scope.returnState = true;
          $scope.returnSubState = $translate('Invoices');
          $scope.invoiceLinkId = $state.params.invoiceId;
          $scope.returnInvoiceState = 'Invoice no. '+data.invoiceId;
          $scope.returnAddState = 'New payment';
          $scope.multipleInvoiceScreen = true;
          $scope.returnInvoiceStateLink = true;
          $scope.hideSubNavCustomerName = true;
          $scope.jobSubNavStatic = true;
          $scope.invoiceSubNav = true;
          $scope.returnSubStateLink = true;
          $scope.returnStateLink = false;
          $scope.hideSubNavCustomers = true;
          $scope.customerMode = $state.params.type;
          $scope.customerTabName = "Customers.List";
          $scope.customerSubNavStatic = true;
      }
            if ($state.current.name === 'loggedin.customer_list.view.job.details.new_supplier_invoice') {
                $scope.returnState = 'Job.no.';
                $scope.returnStateLink = true;
                $scope.returnAddState = 'New.supplier.invoice';
                $scope.returnSubState = $translate('.Costs');
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.details.new_supplier_invoice_milestone') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnStateLink = true;
                $scope.returnAddState = 'New.supplier.invoice';
                $scope.returnSubState = $translate('.Costs');
                $scope.hideSubNavCustomerName = true;
                $scope.jobSubNavStatic = true;
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.job.job_reminders') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Reminders');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
            }
            if ($scope.returnState === 'view.job.new_job_reminder' || $scope.returnState === 'view.job.edit_job_reminder') {
                if($scope.returnState === 'view.job.new_job_reminder'){
                    $scope.returnAddState = 'New.Reminder';
                }
                if($scope.returnState === 'view.job.edit_job_reminder'){
                    $scope.returnAddState = 'Edit.Reminder';
                }
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Reminders');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.notes' || $state.current.name === 'loggedin.customer_list.view.job.notes.table' || $state.current.name === 'loggedin.customer_list.view.job.notes.timeline') {
                if($state.current.name === 'loggedin.customer_list.view.job.notes.timeline'){
                    $scope.timelineview = true;
                    $scope.tableview = false;
                }
                if($state.current.name === 'loggedin.customer_list.view.job.notes.table'){
                    $scope.timelineview = false;
                    $scope.tableview = true;
                }
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Communications');
                $scope.returnAddState = false;
                $scope.hideSubNavCustomerName = true;
                $scope.returnSubStateLink = false;
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.notes_new' || $state.current.name === 'loggedin.customer_list.view.job.notes_edit' || $state.current.name === 'loggedin.customer_list.view.job.new_smsmessage' || $state.current.name === 'loggedin.customer_list.view.job.new_phonecall') {
                if($state.current.name === 'loggedin.customer_list.view.job.notes_new'){
                    $scope.returnAddState = 'Add.New.Note';
                }
                if($state.current.name === 'loggedin.customer_list.view.job.notes_edit'){
                    $scope.returnAddState = 'Edit.Note';
                }
                if($state.current.name === 'loggedin.customer_list.view.job.new_smsmessage'){
                    $scope.returnAddState = 'New.SMS.Message';
                }
                if($state.current.name === 'loggedin.customer_list.view.job.new_phonecall'){
                    $scope.returnAddState = 'Add.Phone.Call';
                }
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Communications');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($scope.returnState === 'view.job.files') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = '.Files';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
            }
            if ($scope.returnState === 'view.job.new_file') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = '.Files';
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = 'New.File';
                $scope.returnSubStateLink = true;
            }
            if ($scope.returnState === 'view.job.new_part') {
                $scope.returnAddState = 'New';
                $scope.returnStateLink = true;
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Parts');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($scope.returnState === 'view.job.new_job_part_milestone') {
                $scope.returnAddState = $translate('.New');
                $scope.returnStateLink = true;
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Parts');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }

            if ($scope.returnState === 'view.job.certificates') {
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Certificates');
                $scope.hideSubNavCustomerName = true;
                $scope.returnAddState = false;
                $scope.returnSubStateLink = false;
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.reserve_list') {
                $scope.returnAddState = 'Reserve.List';
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Details');
                $scope.hideSubNavCustomerName = true;
                $scope.returnSubStateLink = true;
            }

            if ($scope.returnState === 'view.job.edit_job') {
                $scope.returnAddState = $translate('.Edit');
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Details');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }

            if ($state.current.name === 'loggedin.customer_list.view.job.place_job_on_hold') {
                $scope.returnAddState = 'Place.Job.On.Hold';
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Details');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.free_of_charge') {
                $scope.returnAddState = 'Free.of.charge.job';
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = $translate('.Details');
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }
            if ($state.current.name === 'loggedin.customer_list.view.job.abort_job') {
                $scope.returnAddState = 'Abort.job';
                $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
                $scope.returnSubState = 'Details';
                $scope.returnSubStateLink = true;
                $scope.hideSubNavCustomerName = true;
            }

        if ($scope.returnState === 'view.job.recalls') {
            $scope.returnAddState = 'New.Recall';
            $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
            $scope.returnSubState = $translate('.Details');
            $scope.returnSubStateLink = true;
            $scope.hideSubNavCustomerName = true;
        }
        $scope.opportunityInvoiceLink=false;
        if ($state.current.name === 'loggedin.customer_list.view.opportunity_invoice_view.view_invoice') {
            $scope.opportunityInvoiceLink = 'Opportunity no. ' + $state.params.opportunityId;
            $scope.returnState = '';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
            $scope.opportunityInvoicecheck= true;
            $scope.opportunityInvoice='Invoice no. ' +data.invoiceNo + ' / View';
            $scope.paymentsLink='';
            $scope.customerMode = $state.params.type;
        }
        else if($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.opportunity_new_payment'){
            $scope.opportunityInvoiceLink = 'Opportunity no. ' + $state.params.opportunityId;
            $scope.returnState = '';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
            $scope.opportunityInvoicecheck= false;
            $scope.opportunityInvoice='Invoice no. ' +data.invoiceId ;
            $scope.paymentDetails='New Payment';
            $scope.paymentsLink='';
            $scope.customerMode = $state.params.type;
            //$scope.customerTabName = "Customers.List"; // Need to check this
        }
        else if($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.view_invoice_payment'){
            $scope.opportunityInvoiceLink = 'Opportunity no. ' + $state.params.opportunityId;
            $scope.returnState = '';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
            $scope.opportunityInvoicecheck= false;
            $scope.opportunityInvoice='Invoice no. ' +data.invoiceNo ;
            $scope.paymentDetails='View Payment';
            $scope.paymentsLink='';
            $scope.customerMode = $state.params.type;
        }
        else if($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.edit_invoice_payment'){
            $scope.opportunityInvoiceLink = 'Opportunity no. ' + $state.params.opportunityId;
            $scope.returnState = '';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
            $scope.opportunityInvoicecheck= false;
            $scope.opportunityInvoice='Invoice no. ' +data.invoiceNo ;
            $scope.paymentDetails='Edit Payment';
            $scope.paymentsLink = 'Payment no.' + $state.params.paymentId;
            $scope.communicationsLink='';
            $scope.customerMode = $state.params.type;
        }else if($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.notes_new_phonecall' ||
            $state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.notes_new_smsmessage' ||
            $state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.notes_new'){
            $scope.opportunityInvoiceLink = 'Opportunity no. ' + $state.params.opportunityId;
            $scope.returnState = '';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
            $scope.opportunityInvoicecheck= false;
            $scope.opportunityInvoice='Invoice no. ' +data.invoiceNo ;
            $scope.paymentsLink=false;
            $scope.communicationsLink='Communications';
            $scope.customerMode = $state.params.type;
            if ($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.notes_new_phonecall') {
                $scope.paymentDetails='Add phone call';
            } else if ($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.notes_new_smsmessage'){
                $scope.paymentDetails='Send new sms';
            } else if ($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.notes_new'){
                $scope.paymentDetails='Add new note';
            }

        } else if($state.current.name === 'loggedin.customer_list.view.opportunity_invoice_view.view_notes.timeline' || $state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.view_notes.table') {
            $scope.customerMode = $state.params.type;
            $scope.opportunityInvoiceLink = 'Opportunity no. ' + $state.params.opportunityId;
            $scope.returnState = '';
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnStateLink = false;
            $scope.returnSubStateLink = false;
            $scope.returnSubState = '';
            $scope.opportunityInvoicecheck= true;
            $scope.opportunityInvoice='Invoice no. ' +data.invoiceNo + ' / Communications';
            $scope.paymentsLink='';
            if($state.current.name === 'loggedin.customer_list.view.opportunity_invoice_view.view_notes.timeline'){
                $scope.timelineview=true;
                $scope.tableview = false;
            }else if($state.current.name ==='loggedin.customer_list.view.opportunity_invoice_view.view_notes.table'){
                $scope.timelineview=false;
                $scope.tableview = true;
            }
            $scope.customerTabName = "Customers.List";
        }
    });
    if ($scope.returnState === 'view.new_service_reminder') {
      $scope.returnState = $translate('.Reminders');
      $scope.hideSubNavCustomerName = true;
      $scope.formBreadcrumbStr = 'New service reminder';
      $scope.hideSubNav = true;
    }
    if ($scope.returnState === 'view.edit_customer_service_reminder') {
      $scope.returnState = $translate('.Reminders');
      $scope.hideSubNavCustomerName = true;
      $scope.handleSpecialCases(toState.name);
      $scope.formBreadcrumbStr = 'Edit Service Reminder';
    }
    if ($scope.returnState === 'view.work_address_reminders') {
      $scope.returnState = $translate('.Reminders');
      $scope.hideSubNavCustomerName = true;
      $scope.hideSubNav = true;
      $scope.formBreadcrumbStr = 'Work Address Reminders';
    }
    if ($scope.returnState === 'view.service_reminder.overview') {
      $scope.returnState = $translate('.Reminders');
      $scope.hideSubNavCustomerName = true;
      $scope.overview = true;
      $scope.summary = false;
      $scope.hideSubNav = true;
      $scope.formBreadcrumbStr = 'Service Reminder Statement / Overview';
    }
    if ($scope.returnState === 'view.service_reminder.summary') {
      $scope.returnState = $translate('.Reminders');
      $scope.hideSubNavCustomerName = true;
      $scope.overview = false;
      $scope.summary = true;
      $scope.hideSubNav = true;
      $scope.formBreadcrumbStr = 'Service Reminder Statement / Summary';
    }
    if ($state.current.name === 'loggedin.customer_list.view.cash_allocation') {
      $scope.returnState = 'New cash allocation';
      $scope.hideSubNavCustomerName = true;
    }
    if ($state.current.name === 'loggedin.customer_list.view.property.new_credit_note') {
      $scope.returnState = 'Credit notes / New';
      $scope.hideSubNavCustomerName = true;
    }
    if ($state.current.name === 'loggedin.customer_list.view.job.details.add_new_labour' || $state.current.name === 'loggedin.customer_list.view.job.details.add_new_labour_milestone' ||$state.current.name === 'loggedin.customer_list.view.job.labour_edit' ||$state.current.name === 'loggedin.customer_list.view.job.labour_edit_milestone') {
        $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
        if($state.current.name === 'loggedin.customer_list.view.job.details.add_new_labour' || $state.current.name === 'loggedin.customer_list.view.job.details.add_new_labour_milestone') {
            $scope.returnAddState = $translate('Labour.New');
        } else if($state.current.name === 'loggedin.customer_list.view.job.labour_edit' || $state.current.name === 'loggedin.customer_list.view.job.labour_edit_milestone') {
            $scope.returnAddState = $translate('Labour.Edit');
        }
        $scope.returnSubState = $translate('.Costs');
        $scope.hideSubNavCustomerName = true;
        $scope.jobSubNavStatic = true;
        $scope.returnSubStateLink = true;
    }
      if ($state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order' || $state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order_milestone') {
        $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
          $scope.returnStateLink = true;
          $scope.returnAddStateLink = true;
          $scope.returnAddState = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId + ' / ' + 'Details';
          $scope.returnSubState = $translate('.Costs');
          $scope.hidenavBarDetails = true;
          $scope.returnSubStateLink = true;
          $scope.returnPoStateLink = false;
          $scope.hideSubNavCustomerName = true;
          $scope.jobSubNavStatic = false;

        }if ($state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order.complete_purchase_order' || $state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order.complete_purchase_order_milestone') {
            $scope.hidenavBarDetails = true;
            $scope.returnPoState = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnAddState = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId + ' / ' + $translate('Complete.Purchase.Order');
            $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
            if($scope.selectedTab == 'Costs'){
                $scope.returnSubState = $translate('.Costs');
            }else {
                $scope.returnSubState = $translate('.Details');
            }
            $scope.returnPoStateLink = false;
            $scope.returnAddStateLink = true;
            $scope.returnStateLink = true;
            $scope.returnSubStateLink = true;
            $scope.hideSubNavCustomerName = true;
            $scope.jobSubNavStatic = false;

        }
        if ($state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order.edit_purchase_order' || $state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order.edit_purchase_order_milestone') {
            $scope.hidenavBarDetails = false;
            $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
            $scope.returnPoState = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId;
            $scope.returnAddState = $translate('Purchase.Order.no') + ' ' + $state.params.purchaseOrderId + ' / ' + $translate('.Edit');
            if($scope.selectedTab == 'Costs'){
                $scope.returnSubState = $translate('.Costs');
            }else {
                $scope.returnSubState = $translate('.Details');
            }
            $scope.purchaseOrderNav = true;
            $scope.formBreadcrumbStr = '';
            $scope.hideSubNavcustomerName = true;
            $scope.jobSubNavStatic = false;
            $scope.returnPoStateLink = false;
            $scope.returnAddStateLink = false;
            $scope.returnStateLink = true;
            $scope.returnSubStateLink = true;
        }
        if ($state.current.name === 'loggedin.customer_list.view.job.job_office_task') {
            $scope.returnState = ($state.current.name === 'loggedin.customer_list.view.job.job_office_task') ? 'Job no.' : 'Project no.';
            $scope.returnSubState = $translate('Office task');
            $scope.hideSubNavCustomerName = true;
            $scope.returnAddState = false;
            $scope.returnSubStateLink = false;
        }
        if ($scope.returnState === 'view.service_plan') {
            $scope.returnState = $translate('Service.plan.view');
            $scope.hideSubNavCustomerName = true;
        }

        if ($scope.returnState === 'view.new_service_plan') {
            $scope.returnState = 'Service.plan';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = $translate('New.service.plan');
        }

        if ($scope.returnState === 'view.edit_service_plan') {
            $scope.returnState = 'Service.plan';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = $translate('Edit.service.plan');
        }

      if ($scope.returnState === 'view.assets') {
          $scope.returnState = $translate('.Assets');
          $scope.hideSubNavCustomerName = true;
          $scope.returnStateLink = false;
          $scope.hideSubNav = false;
          $scope.assetId = '';
          $scope.customerAssetScreen = false;
      }
      if ($scope.returnState === 'view.add_customer_asset') {
          $scope.returnState = $translate('.Assets');
          $scope.hideSubNavCustomerName = true;
          $scope.handleSpecialCases(toState.name);
          $scope.hideSubNav = true;
          $scope.formBreadcrumbStr = 'New.Asset';
      }
      if ($state.current.name === 'loggedin.customer_list.view.job.pricing_items') {
          $scope.returnState = ($scope.jobType === 'Job') ? 'Job no.' : 'Project no.';
          $scope.returnSubState = $translate('Items.to.invoice');
          $scope.returnAddState = false;
          $scope.hideSubNavCustomerName = true;
          $scope.returnSubStateLink = false;
      }
      if ($state.current.name === 'loggedin.customer_list.view.job.getJobOpportunities') {
          $scope.returnState = ($state.current.name === 'loggedin.customer_list.view.job.getJobOpportunities') ? 'Job no.' : 'Project no.';
          $scope.returnSubState = $translate('Opportunities');
          $scope.hideSubNavCustomerName = true;
          $scope.returnAddState = false;
          $scope.returnSubStateLink = false;
      }

      $scope.$on('assetId:selected', function(event, data){
          $scope.customerAssetScreen = true;
          $scope.assetId = data.assetId ? data.assetId : '';
          $scope.parentAssetId = data.parentAssetId ? data.parentAssetId : '';
          $scope.superParentAssetId = data.superParentAssetId ? data.superParentAssetId : '';
          $scope.parentAssetIdDescription = data.parentAssetIdDescription? data.parentAssetIdDescription : '';
          $scope.assetIdDescription = data.assetIdDescription? data.assetIdDescription : '';
          $scope.breadCrumbAssetDescription = '';
          $scope.returnSub_StateLink = false;

          if($scope.returnState === 'view.add_sub_customer_asset') {
              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Associated asset no. ' +$scope.assetId;
              $scope.returnSubState = $scope.breadCrumbAssetDescription;
              $scope.returnSubStateLink = true;

              $scope.returnAddState =   $translate('New.Asset');
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
          }else if($scope.returnState === 'view.add_associated_customer_asset') {

              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $scope.parentAssetIdDescription ? $scope.parentAssetIdDescription : ' Asset no. ' +$scope.parentAssetId;
              $scope.returnSubStateLink = true;

              $scope.returnSub_State = $scope.assetIdDescription ? $scope.assetIdDescription : ' Associated asset no. ' +$scope.assetId;
              $scope.returnSub_StateLink = true;

              $scope.returnAddState = $translate('New.Asset');
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;

          }else if ($scope.returnState === 'view.assets.details.view_asset' && $scope.assetId) {

              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $translate('Asset');
              $scope.returnSubStateLink = false;

              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Asset no. ' +$scope.assetId;
              $scope.returnAddState = $scope.breadCrumbAssetDescription + ' / View';
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
              //$scope.assetSubNav = true;
          }
          else if($scope.returnState === 'view.assets.details.associated_asset' && $scope.assetId) {
              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $translate('Asset');
              $scope.returnSubStateLink = false;

              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Asset no. ' +$scope.assetId;
              $scope.returnAddState = $scope.breadCrumbAssetDescription +' / '+$translate('Associated.asset');
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
             // $scope.assetSubNav = true;
          }
          else if($scope.returnState === 'view.assets.details.associated_asset_list' && $scope.parentAssetId && $scope.assetId) {
              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $scope.parentAssetIdDescription ? $scope.parentAssetIdDescription : ' Asset no. ' +$scope.parentAssetId;
              $scope.returnSubStateLink = true;
              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Associated asset no. ' +$scope.assetId;
              $scope.returnAddState = $scope.breadCrumbAssetDescription + ' / List';
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
              //$scope.assetSubNav = true;
          }
          else if ($scope.returnState === 'view.assets.details.view_sub_asset' && $scope.parentAssetId && $scope.assetId) {
              $scope.returnStateLink = true;
              $scope.returnState = 'Assets';

              $scope.returnSubStateLink = true;
              $scope.returnSubState = $scope.parentAssetIdDescription ? $scope.parentAssetIdDescription : ' Asset no. ' +$scope.parentAssetId;

              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Associated asset no. ' +$scope.assetId;
              $scope.returnAddState = $scope.breadCrumbAssetDescription +' / View';
              $scope.hideSubNavCustomerName = true;

              $scope.assetSubNavStatic = true;
              //$scope.assetSubNav = true;
          }
          else if ($scope.returnState === 'view.assets.details.edit' && $scope.assetId) {
              $scope.returnAddState = $translate('.Edit');

              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $scope.assetIdDescription ? $scope.assetIdDescription : ' Associated asset no. ' +$scope.assetId;
              $scope.returnSubStateLink = true;

              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
          }
          else if ($scope.returnState === 'view.assets.details.associated_edit' && $scope.parentAssetId && $scope.assetId) {

              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $scope.parentAssetIdDescription ? $scope.parentAssetIdDescription : ' Associated asset no. ' +$scope.parentAssetId;
              $scope.returnSubStateLink = true;

              $scope.returnSub_State = $scope.assetIdDescription ? $scope.assetIdDescription : ' Asset no. ' +$scope.assetId;
              $scope.returnSub_StateLink = true;

              $scope.returnAddState = $translate('.Edit');
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
          }
          else if($scope.returnState === 'view.assets.details.job_history' && $scope.assetId) {

              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $translate('Asset');
              $scope.returnSubStateLink = false;

              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Associated asset no. ' +$scope.assetId;
              $scope.returnAddState = $scope.breadCrumbAssetDescription +' / ' + $translate('Associated.job');
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
          }
          else if($scope.returnState === 'view.assets.details.associated_job_history' && $scope.parentAssetId && $scope.assetId) {

              $scope.returnState = 'Assets';
              $scope.returnStateLink = true;

              $scope.returnSubState = $scope.parentAssetIdDescription ? $scope.parentAssetIdDescription : ' Associated asset no. ' +$scope.parentAssetId;
              $scope.returnSubStateLink = true;

              $scope.breadCrumbAssetDescription = $scope.assetIdDescription ? $scope.assetIdDescription : ' Asset no. ' +$scope.assetId;
              $scope.returnAddState = $scope.breadCrumbAssetDescription + ' / ' + $translate('Associated.job');
              $scope.hideSubNavCustomerName = true;
              $scope.assetSubNavStatic = true;
          }
      });

  });

    $scope.handleSpecialCases = function handleSpecialCases(state) {
        $scope.hideSubNav = true;
        var matches = state.match(/^(.*?)\.(.*?)\.(.*?)$/);
        $scope.formBreadcrumbStr = $scope.formatStr(matches[3]);
    }

    $scope.formatStr = function formatStr(str) {
        var formatted_str = str.split('_');
        for (var i = 0; i < formatted_str.length; i++) {
            formatted_str[i] = formatted_str[i].charAt(0).toUpperCase() + formatted_str[i].slice(1);
        }
        return formatted_str.join(' ');
    }
    $scope.goBacktoJobs = function goBack(selectedId, jobId, customerMode) {
        $scope.customerMode = customerMode;
        $scope.selectedId = selectedId;
        $scope.jobId = jobId;
        if ($scope.returnAddState == 'New.Reminder' || $scope.returnAddState == 'Edit.Reminder' && $scope.jobId) {
            $scope.viewJobReminders($scope.selectedId, $scope.customerMode,  $scope.jobId);
        } if($scope.returnAddState == 'Add.New.Note' && !$state.params.invoiceId || $scope.returnAddState == 'Edit.Note' && !$state.params.invoiceId || $scope.returnAddState == 'New.SMS.Message' && !$state.params.invoiceId || $scope.returnAddState == 'Add.Phone.Call' && !$state.params.invoiceId) {
            if($state.params.mode == 'timeline'){
                $state.transitionTo("loggedin.customer_list.view.job.notes.timeline", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'mode': $state.params.mode});
            } else {
                $state.transitionTo("loggedin.customer_list.view.job.notes.table", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'mode': $state.params.mode});
            }
        }else if($scope.returnAddState == 'New.Purchase.Order' && $scope.jobId && !$state.params.milestoneId && $scope.selectedTab == 'Costs' || $scope.returnAddState == 'Labour.New' && $state.params.jobId && !$state.params.milestoneId || $scope.returnAddState == 'Labour.Edit' && $state.params.jobId && !$state.params.milestoneId){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if($scope.returnAddState == 'New.Purchase.Order' && $scope.jobId && $state.params.milestoneId && $scope.selectedTab == 'Costs'  || $scope.returnAddState == 'Labour.New' && $state.params.jobId && $state.params.milestoneId || $scope.returnAddState == 'Labour.Edit' && $state.params.jobId && $state.params.milestoneId){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });
        }else if($scope.returnAddState == 'New.supplier.invoice' && $scope.jobId && !$state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if($scope.returnAddState == 'New.supplier.invoice' && $scope.jobId && $state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });
        }
        else if (($scope.returnAddState == 'New' && $scope.jobId && $scope.jobType == 'Job' && $state.current.name === "loggedin.customer_list.view.job.new_part")) {
            $state.transitionTo("loggedin.customer_list.view.job.parts.view_parts", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $scope.jobId});
        }
        else if (($scope.returnAddState == 'New' && $scope.jobId && $scope.jobType == 'Job') || ($scope.returnAddState == 'New.Purchase.Order' && $scope.jobId && $scope.selectedTab == 'JobDetails') || ($scope.returnAddState == 'Edit' && $scope.jobId)) {
            $scope.viewJobDetails($scope.selectedId, $scope.customerMode,  $scope.jobId);
        }
        else if($state.current.name === "loggedin.customer_list.view.job.details.view_purchase_order.complete_purchase_order" && $scope.jobId && !$state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if($state.current.name === "loggedin.customer_list.view.job.details.view_purchase_order.complete_purchase_order_milestone" && $scope.jobId && $state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });
        } else if($state.current.name === "loggedin.customer_list.view.job.details.view_purchase_order.edit_purchase_order" && $scope.jobId && !$state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if($state.current.name === "loggedin.customer_list.view.job.details.view_purchase_order.edit_purchase_order_milestone" && $scope.jobId && $state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });    }
        else if($state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order' && $scope.jobId && !$state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if($state.current.name === 'loggedin.customer_list.view.job.details.view_purchase_order_milestone' && $scope.jobId && $state.params.milestoneId && $scope.selectedTab == 'Costs'){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });
        }
        else if (($scope.returnAddState == 'New' && $scope.jobId && $scope.jobType == 'Job') || ($scope.returnAddState == 'New Purchase Order' && $scope.jobId && $scope.selectedTab == 'JobDetails') || ($scope.returnAddState == 'Edit' && $scope.jobId)) {
            $scope.viewJobDetails($scope.selectedId, $scope.customerMode,  $scope.jobId);
        }
        else if ($scope.returnAddState == 'New' && $scope.jobId && $scope.jobType == 'Project' || ($scope.returnAddState == 'New.Purchase.Order' && $scope.jobId && $scope.selectedTab == 'Parts' && $scope.jobType == 'Project')) {
            $scope.viewJobParts($scope.selectedId, $scope.customerMode,  $scope.jobId, $state.params.milestoneId);
        } else if ($scope.returnAddState == 'New.File' && $scope.jobId) {
            $scope.viewJobFiles($scope.selectedId, $scope.jobId, $scope.customerMode);
        } else if (($scope.returnAddState == 'Reserve.List' && $scope.jobId) || ($scope.returnAddState == 'New.Recall' && $scope.jobId)) {
            $scope.viewJobDetails($scope.selectedId, $scope.customerMode, $scope.jobId);
        } else if($scope.returnAddState == "Place.Job.On.Hold" && $scope.jobId) {
            $scope.viewJobDetails($scope.selectedId, $scope.customerMode,  $scope.jobId);
        }else if(($scope.returnAddState == 'Travel / New' && $scope.jobId && !$state.params.milestoneId) || ($scope.returnAddState == 'Labour / New' && $scope.jobId && !$state.params.milestoneId)){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if(($scope.returnAddState == 'Travel / New' && $scope.jobId && $state.params.milestoneId) || ($scope.returnAddState == 'Labour / New' && $scope.jobId && $state.params.milestoneId)){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });
        }
        else if(($scope.returnAddState == 'Travel / Edit' && $scope.jobId && !$state.params.milestoneId) || ($scope.returnAddState == 'Labour / Edit' && $scope.jobId && !$state.params.milestoneId)){
            $state.transitionTo("loggedin.customer_list.view.job.viewcost", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }else if(($scope.returnAddState == 'Travel / Edit' && $scope.jobId && $state.params.milestoneId) || ($scope.returnAddState == 'Labour / Edit' && $scope.jobId && $state.params.milestoneId)){
            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId' : $state.params.milestoneId });
        }
        else if($scope.returnAddState == "Free.of.charge.job" || $scope.returnAddState == "Abort.job" && $scope.jobId) {
            $scope.viewJobDetails($scope.selectedId, $scope.customerMode,  $scope.jobId);
        }
        else if(($scope.returnAddState == "Accept.or.reject") || ($scope.returnAddState == "Send.to.customer") ||($scope.returnAddState == "Todos") || ($scope.returnAddState == "Budget.cost") || ($scope.returnAddState == "price") || ($scope.returnAddState == "Invoice.schedule") || ($scope.returnAddState == "Details")) {
            $scope.jobAdditionalWork($scope.selectedId, $scope.jobId, $scope.customerMode);
        }
        else if(($scope.returnAddState == "partial") || ($scope.returnAddState == "final")) {
            $state.transitionTo("loggedin.customer_list.view.job.invoices", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }
        else if($state.current.name === 'loggedin.customer_list.view.job.invoices.view.edit' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.new_payment' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.view_payment' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.view_invoice.new_credit_note' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_edit' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new_smsmessage' || $state.current.name === 'loggedin.customer_list.view.job.invoices.view.notes_new_phonecall') {
            $state.transitionTo("loggedin.customer_list.view.job.invoices", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId}, {'reload': true});
        }
        else if($state.current.name === 'loggedin.customer_list.view.job.new_job_office_task') {
            $state.transitionTo("loggedin.customer_list.view.job.job_office_task", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        } else if($state.current.name === 'loggedin.customer_list.view.job.getJobOpportunities') {
            $rootScope.changableState = true;
            $state.transitionTo("loggedin.customer_list.view.job.getJobOpportunities", {'id': $scope.selectedId, 'type': $scope.customerMode, 'jobId': $state.params.jobId});
        }
    }


    $scope.goBacktoJobInvoiceNotes = function(id, jobId, invoiceId, customerMode) {
        $rootScope.changableState = true;
        if($state.params.mode == 'timeline') {
            $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_notes.timeline', {'id': id, 'type': customerMode, 'jobId': jobId, 'invoiceId': invoiceId}, {reload:true});
        } else {
            $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_notes.table', {'id': id, 'type': customerMode, 'jobId': jobId, 'invoiceId' : invoiceId}, {reload:true});
        }
    }
      $scope.goBacktoContractInvoiceNotes = function(id, contractId, invoiceId, customerMode) {
          $rootScope.changableState = true;
          if($state.params.mode == 'timeline') {
              $state.transitionTo('loggedin.customer_list.view.invoice_view.view_notes.timeline', {'id': id, 'type': customerMode, 'contractId': contractId, 'invoiceId': invoiceId}, {reload:true});
          } else {
              $state.transitionTo('loggedin.customer_list.view.invoice_view.view_notes.table', {'id': id, 'type': customerMode, 'contractId': contractId, 'invoiceId' : invoiceId}, {reload:true});
          }
      }

    $scope.goBacktoOpportunityInvoiceNotes = function(id, opportunityId, invoiceId, customerMode) {
        $rootScope.changableState = true;
        if($state.params.mode == 'timeline') {
            $state.transitionTo('loggedin.customer_list.view.opportunity_invoice_view.view_notes.timeline', {'id': id, 'type': customerMode, 'opportunityId': opportunityId, 'invoiceId': invoiceId}, {reload:true});
        } else {
            $state.transitionTo('loggedin.customer_list.view.opportunity_invoice_view.view_notes.table', {'id': id, 'type': customerMode, 'opportunityId': opportunityId, 'invoiceId' : invoiceId}, {reload:true});
        }
    }

    $scope.jobAdditionalWork = function (id, jobId, customerMode) {
        $rootScope.changableState = true;
        $state.transitionTo("loggedin.customer_list.view.job.additional_works", {'id': id, 'type': customerMode, 'jobId': jobId}, {reload:true});
    }

    $scope.goBackToEstimates = function goBack(selectedId, estimateId, customerMode) {
        $scope.customerMode = customerMode;
        $scope.estimateId = estimateId;
        $scope.selectedId = selectedId;

        var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
        $scope.dynamicState = (rootMatch[4] === 'estimate_details') ? 'estimate_details' : 'project_estimate_details';

        if(rootMatch[5] == 'new_file'){
            $scope.dynamicState = (rootMatch[4] === 'estimate') ? 'estimate' : 'project';
            $scope.estimateFile($scope.selectedId, $scope.estimateId, $scope.customerMode, $scope.dynamicState);
        }
        if($scope.returnAddState == 'Add.New.Note' || $scope.returnAddState == 'Edit.Note' || $scope.returnAddState == 'New.SMS.Message' || $scope.returnAddState == 'Add.Phone.Call') {
            if($state.params.mode == 'timeline'){
                $state.transitionTo("loggedin.customer_list.view.estimate.notes.timeline", {'id': $scope.selectedId, 'type': $scope.customerMode, 'estimateId': $state.params.estimateId, 'mode': $state.params.mode});
            } else {
                $state.transitionTo("loggedin.customer_list.view.estimate.notes.table", {'id': $scope.selectedId, 'type': $scope.customerMode, 'estimateId': $state.params.estimateId, 'mode': $state.params.mode});
            }
        }
        if ($scope.returnAddState == 'Edit') {
            $scope.estimateViewDetails($scope.selectedId, $scope.estimateId, $scope.customerMode, $scope.dynamicState);
        }
        if ($scope.returnAddState == '.Reserve.List') {
            if(rootMatch[4] === 'estimate'){
                $scope.dynamicState = 'estimate_details';
            }
            if(rootMatch[4] === 'project'){
                $scope.dynamicState = 'project_estimate_details';
            }
            $scope.estimateViewDetails($scope.selectedId, $scope.estimateId, $scope.customerMode, $scope.dynamicState);
        }
        if ($scope.returnAddState == 'New.Files' || $scope.returnAddState == 'Edit.Files') {
            $scope.estimateFile($scope.selectedId, $scope.estimateId, $scope.customerMode, $scope.dynamicState);
        }
        if ($scope.returnAddState == 'New.Reminder' || $scope.returnAddState == 'Edit.Reminder' && $scope.estimateId) {
            $scope.estimateReminder($scope.selectedId, $scope.estimateId, $scope.customerMode);
        }

        if ($scope.returnAddState == 'Edit.Estimate.Todos' || $scope.returnAddState == 'Edit.Estimate.Body' || $scope.returnAddState == 'Edit.Estimate.Cost' || $scope.returnAddState == 'Edit.Estimate.Price' || $scope.returnAddState == 'Edit.Invoice.Schedule' || $scope.returnAddState == 'Edit.Parts') {
            $scope.estimateAdditionalOptional($scope.selectedId, $scope.estimateId, $scope.customerMode, $scope.dynamicState);
        }
    }

    $scope.estimateAdditionalOptional = function (id, estimateId, customerMode, dynamicState) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.' + dynamicState + '.additional_option', {'id': id, 'estimateId': estimateId, 'type': customerMode});
    }
    $scope.estimateViewDetails = function (id, estimateId, customerMode, dynamicState) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.' + dynamicState + '.survey', {'id': id, 'estimateId': estimateId, 'type': customerMode}, {reload:true});
    }
    $scope.estimateFile = function (id, estimateId, customerMode, dynamicState) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.' + dynamicState + '.files', {'id': id, 'estimateId': estimateId, 'type': customerMode});
    }
    $scope.estimateReminder = function (id, estimateId, customerType) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.estimate.estimate_reminders', {'id': id, 'estimateId': estimateId, 'type': customerType});
    }
    $scope.viewJobDetails = function (selectedId, customerType, jobId) {
        $rootScope.changableState = true;
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': selectedId, 'type': customerType, 'jobId': jobId },{reload:true});
    }
    $scope.viewJobParts = function (selectedId, customerType, jobId, milestoneId) {
        $rootScope.changableState = true;
        $state.transitionTo("loggedin.customer_list.view.job.parts.view", { 'id': selectedId, 'type': customerType, 'jobId': jobId, 'milestoneId' : milestoneId });
    }
    $scope.viewAssets = function (selectedId, customerType) {
        $rootScope.changableState = true;
        // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
            $location.path('/customers/'+customerType+'/'+selectedId+'/view_v2/assets/view');
        // }else{
        //     $state.transitionTo("loggedin.customer_list.view.assets", { 'id': selectedId, 'type': customerType },{reload:true});
        // }
    }
    $scope.viewAssetDetails = function (selectedId, customerType, assetId, parentAssetId) {
        $rootScope.changableState = true;
        if(parentAssetId) {
            $state.transitionTo("loggedin.customer_list.view.assets.details.view_sub_asset", { 'type': customerType, 'id': selectedId, 'parentAssetId': parentAssetId, 'assetId': assetId });
        } else {
            $state.transitionTo("loggedin.customer_list.view.assets.details.view_asset", { 'type': customerType, 'id': selectedId, 'assetId': assetId });
        }
    }
    $scope.viewCustomerMultipleInvoiceDetails = function(selectedId, customerType, invoiceLinkId){
      $location.path('/customers/' + customerType + '/' + selectedId + '/multiple_invoice/' + invoiceLinkId + '/view')
    }
  $scope.getBack = function getBack(customerMode) {
    $scope.customerMode = customerMode;

    if ($scope.returnState == 'Property') {
      $scope.customerViewDetails($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Communications' && ($state.params.mode && $state.params.mode == 'table')) {
      $scope.customerNotesTableList($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Communications' && ($state.params.mode && $state.params.mode == 'timeline')) {
      $scope.customerNotesTimeLineList($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Branches') {
      $scope.viewBranches($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Work.Addresses') {
      $scope.viewWorkAddress($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Files') {
      $scope.viewFiles($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Invoice.Address') {
      $scope.viewInvoiceAddress($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Contacts') {
      $scope.customerContacts($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Appliances') {
      $scope.customerAppliances($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Technical reference') {
         $scope.customerTechnicalReference($scope.selectedId, $scope.customerMode);
    }
    if ($scope.returnState == 'Reminders') {
      $scope.viewReminders($scope.selectedId, $scope.customerMode);
    }
    if($scope.returnState == 'Account credit') {
       $scope.viewCustomerAccountCredits($scope.selectedId, $scope.customerMode)
    }
    if ($scope.returnState == 'Service.plan') {
      $scope.viewServicePlan($scope.selectedId, $scope.customerMode);
    }
    if($scope.returnState == 'Stored cards') {
        $scope.viewStoredCard($scope.selectedId, $scope.customerMode);
    }
      if ($scope.returnState == 'Assets') {
          $scope.customerAssets($scope.selectedId, $scope.customerMode);
      }
      else if($state.current.name === 'loggedin.customer_list.view.assets.details.view_asset' || $state.current.name === 'loggedin.customer_list.view.assets.details.associated_asset') {
          $scope.customerAssets($scope.selectedId, $scope.customerMode);
      }
  }

    $scope.customerViewDetails = function (id, customerType) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.property', {'id': id, 'type': customerType});
    }
    $scope.customerNotesTableList = function (id, customerType) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.notes.table', {'id': id, 'type': customerType});
    }
    $scope.estimateNotesTableList = function (id, customerType, estimateId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.estimate.notes.table', {'id': id, 'type': customerType, 'estimateId': estimateId});
    }
    $scope.jobNotesTableList = function (id, customerType, jobId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.job.notes.table', {'id': id, 'type': customerType, 'jobId': jobId});
    }
      $scope.contractInvoiceNotesTableList = function (id, customerType, contractId, invoiceId) {
          $rootScope.changableState = true;
          $state.transitionTo('loggedin.customer_list.view.invoice_view.view_notes.table', {'id': id, 'type': customerType, 'contractId': contractId, 'invoiceId' : invoiceId});
      }
    $scope.jobInvoiceNotesTableList = function (id, customerType, jobId, invoiceId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_notes.table', {'id': id, 'type': customerType, 'jobId': jobId, 'invoiceId' : invoiceId});
    }
    $scope.opportunityInvoiceNotesTableList = function (id, customerType, opportunityId, invoiceId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.opportunity_invoice_view.view_notes.table', {'id': id, 'type': customerType, 'opportunityId': opportunityId, 'invoiceId' : invoiceId});
    }
    $scope.customerNotesTimeLineList = function (id, customerType) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.notes.timeline', {'id': id, 'type': customerType});
    }
    $scope.estimateNotesTimeLineList = function (id, customerType, estimateId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.estimate.notes.timeline', {'id': id, 'type': customerType, 'estimateId': estimateId});
    }
    $scope.jobNotesTimeLineList = function (id, customerType, jobId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.job.notes.timeline', {'id': id, 'type': customerType, 'jobId': jobId});
    }
    $scope.jobInvoiceNotesTimeLineList = function (id, customerType, jobId, invoiceId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.job.invoices.view.view_notes.timeline', {'id': id, 'type': customerType, 'jobId': jobId, 'invoiceId': invoiceId});
    }
    $scope.contractInvoiceNotesTimeLineList = function (id, customerType, contractId, invoiceId) {
          $rootScope.changableState = true;
          $state.transitionTo('loggedin.customer_list.view.invoice_view.view_notes.timeline', {'id': id, 'type': customerType, 'contractId': contractId, 'invoiceId': invoiceId});
    }
    $scope.opportunityInvoiceNotesTimeLineList = function (id, customerType, opportunityId, invoiceId) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.customer_list.view.opportunity_invoice_view.view_notes.timeline', {'id': id, 'type': customerType, 'opportunityId': opportunityId, 'invoiceId': invoiceId});
    }
  $scope.viewBranches = function (id, customerType) {
    $rootScope.changableState = true;
    // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
        $location.path('/customers/'+customerType+'/'+id+'/view_v2/branches/view');
    // }else{
    //     $state.transitionTo('loggedin.customer_list.view.branches', {'id': id, 'type': customerType});
    // }
  }
  $scope.newBranches = function (id, customerType) {
    // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
         $location.path('/customers/'+customerType+'/'+id+'/view_v2/branches/view');
    // }else{
    //      $state.transitionTo('loggedin.customer_list.view.branches', {'id': id, 'type': customerType});
    // }
  }
  $scope.viewWorkAddress = function (id, customerType) {
    $rootScope.changableState = true;
      // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
          $location.path('/customers/'+customerType+'/'+id+'/view_v2/work/view');
      // }else{
      //     $state.transitionTo('loggedin.customer_list.view.workaddresses', {'id': id, 'type': customerType});
      // }
  }
  $scope.viewFiles = function (id, customerType) {
    $rootScope.changableState = true;
    // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
        $location.path('/customers/'+customerType+'/'+id+'/view_v2/files/view');
    // }else{
    //     $state.transitionTo('loggedin.customer_list.view.files', {'id': id, 'type': customerType});
    // }
  }
  $scope.viewEstimateFiles = function (id, estimateId, customerType) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.estimate.files', {'id': id, 'estimateId': estimateId, 'type': customerType});
  }
  $scope.viewInvoiceAddress = function (id, customerType) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.invoice_addresses', {'id': id, 'type': customerType});
  }
  $scope.viewCustomerAccountCredits = function (id, customerType) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.account_credit', {'id': id, 'type': customerType});
  }

  $scope.viewReminders = function (id, customerType) {
    $rootScope.changableState = true;
    // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
        $location.path('/customers/'+customerType+'/'+id+'/view_v2/service_reminder/view');
    // }else{
    //     $state.transitionTo('loggedin.customer_list.view.reminders', {'id': id, 'type': customerType});
    // }
  }
  $scope.viewEstimateReminders = function (selectedId, customerType, estimateId) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.estimate.estimate_reminders', {'id': selectedId, 'type': customerType, 'estimateId': estimateId});
  }
  $scope.viewJobReminders = function (selectedId, customerType, jobId) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.job.job_reminders', {'id': selectedId, 'type': customerType, 'jobId': jobId});
  }
  $scope.customerContacts = function (id, customerType) {
    // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
       $location.path('/customers/'+customerType+'/'+id+'/view_v2/contacts/view');
    // }else{
    //     $state.transitionTo('loggedin.customer_list.view.contacts', {'id': id, 'type': customerType});
    // }
  }
  $scope.customerAppliances = function (id, customerType) {
    // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
        $location.path('/customers/'+customerType+'/'+id+'/view_v2/appliances/view');
    // }else{
    //     $state.transitionTo('loggedin.customer_list.view.appliances', {'id': id, 'type': customerType});
    // }
  }
  $scope.customerTechnicalReference = function (id, customerType) {
      $state.transitionTo('loggedin.customer_list.view.technicalreference', {'id': id, 'type': customerType});
  }

  $scope.viewJobFiles = function (id, jobId, customerType) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.job.files', {'id': id, 'jobId': jobId, 'type': customerType});
  }
  $scope.viewAdditionalWork = function (selectedId, customerType, jobId, additionalWorkId) {
    $rootScope.changableState = true;
    $state.transitionTo("loggedin.customer_list.view.job.additional_works.view.details", { 'id': selectedId, 'type': customerType, 'jobId': jobId, 'additionalWorkId': additionalWorkId });
  }
  $scope.viewServicePlan = function (id, customerType) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.service_plan', {'id': id, 'type': customerType});
  }
  $scope.viewStoredCard = function (id, customerType) {
    $rootScope.changableState = true;
    $state.transitionTo('loggedin.customer_list.view.stored_cards', {'id': id, 'type': customerType}, {reload:true});
  }
    $scope.customerAssets = function (id, customerType) {
        // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
            $location.path('/customers/'+customerType+'/'+id+'/view_v2/assets/view');
        // }else{
        //     $state.transitionTo('loggedin.customer_list.view.assets', {'id': id, 'type': customerType});
        // }
    }


  $scope.$on('tabRouteCustomer:selected', function (event, data) {
    if (data.customerTabName != undefined && data.customerTabName != '') {
      $scope.customerMode = data.customerTabName;
      if ($scope.customerMode == 'work_address') {
        $scope.customerTabName = "Work.Addresses.List";
      } else if ($scope.customerMode == 'customer') {
        $scope.customerTabName = "Customers.List";
      } else if ($scope.customerMode == 'customer_list') {
        $scope.customerTabName = "Property.List";
      }
    }
    $scope.selectedTab = data.selectedTab;
    $scope.invoiceSelectedTab = data.invoiceSelectedTab;
    $scope.customerSubNavStatic = data.customerSubNavStatic;
    $scope.estimateSubNavStatic = data.estimateSubNavStatic;
    $scope.jobSubNavStatic = data.jobSubNavStatic;
    $scope.assetSelectedTab = data.assetSelectedTab;
  });

  $scope.$on('tabCustomer:selected', function (event, data, debtsDetailAllow) {
    if (data != undefined && data != '') {
      $scope.hideSubNavCustomers = true;
      $scope.type = data.customerType;
      $scope.tabCustomerSurname = data.customerName;
      //$scope.parentPropertyName = data.parentPropertyName;
      $scope.enableWorkAddress = (data.workaddressSetting == "Enabled");
      $scope.invoiceAddressId = data.invoiceAddressId;
      $scope.debtorInvoiceAddressId = '';

          $scope.tabAddressline1 = $scope.tabAddressline2 = $scope.tabAddressline3 = $scope.tabTown = $scope.tabCounty = $scope.tabPostcode = '';
          if(data.addressline1){ $scope.tabAddressline1 = data.addressline1; }
          if(data.addressline2){ $scope.tabAddressline2 = ', '+ data.addressline2; }
          if(data.addressline3){ $scope.tabAddressline3 = ', '+ data.addressline3; }
          if(data.town){ $scope.tabTown = ', '+ data.town; }
          if(data.county){ $scope.tabCounty = ', '+ data.county; }
          if(data.postcode){ $scope.tabPostcode = ', '+ data.postcode; }
          if ($scope.type != 'workaddress') {
              $scope.debtorInvoiceAddressId = $scope.invoiceAddressId;

          } else if ($scope.type == 'workaddress' && data.parentCustomerDetails!= undefined) {
              $scope.debtorInvoiceAddressId = $scope.invoiceAddressId;
          }

          if($scope.debtorInvoiceAddressId && debtsDetailAllow) {
              InvoiceAddressAndCustomerDebtsDetails($scope.debtorInvoiceAddressId, $http, prefix, $filter, $scope,$rootScope);

          }

          $scope.customerAddress = $scope.tabAddressline1 + $scope.tabAddressline2 + $scope.tabAddressline3 + $scope.tabTown + $scope.tabCounty + $scope.tabPostcode;

          $scope.selectedId = data.id;
          $scope.allowBranch = data.allowBranch;
          $scope.branchesCount = data.branchesCount;
          $scope.notesCount = data.notesCount;
          $scope.filesCount = data.filesCount;
          $scope.workAddressName = data.workAddressName;
          $scope.workAddressCount = data.workAddressCount;
          $scope.serviceRemindersCount = data.serviceRemindersCount;
          $scope.assetsCount = data.assetsCount;

          $scope.contactsCount = data.contactsCount;
          $scope.customerType = data.customerType;
      } else {
          $scope.hideSubNavCustomers = false;
          $scope.formBreadcrumbStr = '';
          $scope.returnState = '';
          $scope.selectedId = '';
          $scope.debts = {};
          $scope.tabCustomerName = '';
          $scope.tabCustomerSurname = '';
          $scope.branchesCount = 0;
          $scope.filesCount = 0;
          $scope.workAddressName = '';
          $scope.workAddressCount = 0;
          $scope.serviceRemindersCount = 0;
          $scope.notesCount = 0;
          $scope.contactsCount = 0;
          $scope.customerType = '';
          $scope.assetsCount = 0;
      }
  });

    $scope.$on('tabCustomer:successMessage', function (event, data) {
        $rootScope.successMessage = data;
        toastBox.show($rootScope.successMessage, 4000);

    });
    $scope.$on("event:changeTabTopCountScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });

    $scope.$on('breakdown-sidepanel-template' ,function (evt, lineItem) {
        $http.get(prefix+'/getLabourRateBreakup/'+lineItem['line_item_id']).success(function(response) {
            $scope.dataToAppend = angular.toJson(response);

            var side_panel_tpl = $compile("<span id='view_labour_rate_breakup' sidepanel template='labour_rate_breakup' title='view' remove_on_close='true' data='{{ dataToAppend }}'></span>")($scope);
            const panel = angular.element('#view_labour_rate_breakup');
            if (typeof panel[0] == 'undefined') {
                angular.element('body').append(side_panel_tpl);

                $timeout(function () {
                    angular.element('#view_labour_rate_breakup').scope().$$childTail.initialise();
                }, 100);
            }

        });
        $rootScope.$on('parent:updated', function(evt) {
            if (angular.element('#view_labour_rate_breakup')) {
                angular.element('#view_labour_rate_breakup').remove();
            };
        });
    });
}

function PermissionCheck($scope){
    $scope.noPermission = false;
    $scope.$on('displayPermission', function(event, data, scopeFeatureData) {
        $scope.noPermission = true;
        $scope[scopeFeatureData] = false;
        $scope.shouldBeOpen = false;
    });

    $scope.$on('displayPermissionEnable', function(event, scopeFeatureData) {
        $scope[scopeFeatureData] = true;
        $scope.noPermission = false;
    });
}

String.prototype.replaceAll = function (find, tagValue) {
    var str = this;
    return str.split(find).join(tagValue);
};

function SendToCustomerOption($scope, prefix, $http, viewOption){

    if($scope.jobId && $scope.additionalWorkId) {
        var printUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/' + $scope.additionalWorkId + '/sendToCustomerViewOption';
    } else {
        var printUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/sendToCustomerViewOption';
    }
    $http.post(printUrl, "estimate[viewOption]=" + $scope.viewOption).
    success(function (data, status) {
        if (status == 200) {
            // success message
        }
    });
}

function FullscreenController($scope, $rootScope, $timeout){
    /*==========================================================================================
   Full screen control
   ==========================================================================================*/
    $scope.fullscreen = function fullscreen(id) {
        $scope.in_fullscreen_mode = true;

        var elm_to_fullscreen = document.querySelector('#'+id);

        var offsets = elm_to_fullscreen.getBoundingClientRect(),
            top_offset = offsets.top,
            height_offset = offsets.height,
            left_offset = offsets.left,
            right_offset = window.innerWidth - offsets.right,
            cloned_elm = elm_to_fullscreen.cloneNode(true);

        cloned_elm.classList.add('cloned-elm')

        elm_to_fullscreen.style.position = 'fixed';
        elm_to_fullscreen.style.zIndex = 9999;
        elm_to_fullscreen.style.top = top_offset + "px";
        elm_to_fullscreen.style.bottom = "auto";
        elm_to_fullscreen.style.left = left_offset + "px";
        elm_to_fullscreen.style.right = right_offset + "px";
        elm_to_fullscreen.style.width = "auto";
        elm_to_fullscreen.style.height = height_offset + "px";
        elm_to_fullscreen.style.margin = "0px";

        $timeout(function(){
            elm_to_fullscreen.style.transition = "all .6s";
            elm_to_fullscreen.parentNode.appendChild(cloned_elm);
            elm_to_fullscreen.classList.add('add-in-fullscreen-mode');
        }, 10);
    }

    $scope.exitFullscreen = function exit_fullscreen(id) {
        $scope.in_fullscreen_mode = false;

        var elm_in_fullscreen_mode = document.querySelector('#'+id+'.add-in-fullscreen-mode'),
            cloned_elm = document.querySelector('#'+id+'.cloned-elm');

        elm_in_fullscreen_mode.classList.remove('add-in-fullscreen-mode');

        $timeout(function(){
            cloned_elm.parentNode.removeChild(cloned_elm);
            $(elm_in_fullscreen_mode).removeAttr('style');
        }, 600);
    }
}

function SubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $timeout, $compile, emailService, $location) {
    if(getIdData.deleteOrNull== 'Deleted') {
        var message = getIdData.deleteOrNull;
        warningModal.show('This Property has been  '+message, 'Property','Deleted' );
        $state.transitionTo('loggedin.customer_list', {reload: true});
        return false;
    }
    var data = getIdData.customerNav;
    $scope.selectedId = data.id;
    $scope.customerType = data.customerTabName || $state.params.type;
    $scope.customerDeleteShouldBeOpen = false;
    $scope.parentPropertyName = data.parentPropertyName;
    $scope.enableWorkAddress = (data.workaddressSetting == 'Enabled');
    $scope.invoiceAddressId = data.invoiceAddressId;
    $scope.workAddressName = data.workAddressName;
    $scope.supportsStoredCards = data.supportsStoredCards;
    $scope.multipleJobInvoice = data.multipleJobInvoice;
  $scope.isSalesOnlyClient = data.isSalesOnlyClient;

    $scope.triggerCustomerDelete = function () {
        $scope.customerId = $scope.selectedId;
        $http.get(prefix + '/is_customer_delete?id=' + $scope.customerId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $scope.customerName = $scope.tabCustomerName;
                $scope.customerSurname = $scope.tabCustomerSurname;
                $scope.customerDeleteShouldBeOpen = true;
            }
        });
    }
    $scope.triggerCustomerExport = function () {
        $scope.customerId = $scope.selectedId;
        $http.get(prefix + '/customers/'+ $scope.customerId+'/exportCsv').success(function(data) {
            warningModal.show(data.message, data.title, data.id);
        });
    }

    $scope.fetchDeleteCustomers = function () {
        $scope.flashMessage = 'Customer Deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.customer_list');
    }
    $scope.invoiceSetup = function (id, customerMode) {
        $state.transitionTo('loggedin.customer_list.view.invoice_setup', {'id': id, 'type': customerMode},{reload:true});
    }

  $scope.$on('tabCustomer:selected', function (event, data) {
    if (data != undefined && data != '') {
      $scope.hideSubNavCustomers = true;
      $scope.type = data.customerType;
      $scope.tabCustomerSurname = data.customerName;
      $scope.tabAddressline1 = data.addressline1;
      $scope.tabAddressline2 = data.addressline2;
      $scope.tabAddressline3 = data.addressline3;
      $scope.tabTown = data.town;
      $scope.tabCounty = data.county;
      $scope.tabPostcode = data.postcode;
      $scope.selectedId = data.id;
      $scope.allowBranch = data.allowBranch;
      $scope.branchesCount = data.branchesCount;
      $scope.notesCount = data.notesCount;
      $scope.filesCount = data.filesCount;
      $scope.remindersCount = data.remindersCount;
      $scope.workAddressName = data.workAddressName;
      $scope.workAddressCount = data.workAddressCount;
      $scope.contactsCount = data.contactsCount;
      $scope.customerType = data.customerType;
      $scope.assetsCount = data.assetsCount;
    }
  });
    $scope.handleSendStatement = function handleSendStatement() {
        var getDataRequest = "/customers/" +  $scope.customerTabName + "/" + $scope.selectedId + "/check_send_statement";
        var side_panel_tpl = $compile('<span id="send_statement" ' +
            'sidepanel template="send_statement" ' +
            'title="Send statement" ' +
            'remove_on_close="true" ' +
            'index="' + this.$id + '"' +
            'get-data-request="' + getDataRequest + '"' +
            'print_and_post_message="Your statement is on its way to the customer" ' +
            'pdf_url="' + Routing.generate('send_statement', { type: $scope.customerTabName, id: $scope.selectedId }) +'"'+
            'supports_print="true" supports_email="true" supports_print_and_post="'+data.printAndPostModule+'" additional_data="'+data.sendStatements+'"'+
            'printpost_params="tableType=61&tablePKID='+$scope.selectedId+'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);

        $timeout(function() {
            angular.element('#send_statement').scope().$$childTail.initialise();
        }, 600);
    }

    $scope.handleAddEditSla = function handleAddEditSla() {
        var side_panel_tpl = $compile('<span id="configure_sla_customer" ' +
            'sidepanel template="configure_sla_customer" ' +
            'title="Customer SLA Add/Edit" ' +
            'index="' + this.$id + '"' +
            'data="'+$scope.selectedId +'"'+
            '</span>')($scope);

        angular.element('body').append(side_panel_tpl);

        $timeout(function() {
            angular.element('#configure_sla_customer').scope().$$childTail.initialise();
        }, 600);
    }


    $scope.handleConsolidatedInvoice = function handleConsolidatedInvoice(){
      // $timeout(function(){
      //   if($scope.multipleJobInvoice){
            $location.path('/customers/customer/' + $scope.selectedId + '/multiple_invoice/new')
      //   }else{
      //       warningModal.show('Consolidated invoice needs atleast one job', 'Consolidated Invoice');
      //   }
      // },10);
    }

    $scope.defaultStatementSubject = data.defaultStatementSubject;
    $scope.defaultStatementMessage = data.defaultStatementMessage;

    $scope.$on('email:send', function (evt, data) {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=61&id=' + $scope.selectedId;
        clone_obj.subject = $scope.defaultStatementSubject;
        clone_obj.message = $scope.defaultStatementMessage;
        clone_obj.relatedObjectType = 61;
        clone_obj.relatedObjectId = $scope.selectedId;
        clone_obj.attached_files = [
            {
                id: "STATEMENT|" + $scope.selectedId + "|" + $scope.customerTabName + "|" + data,
                file_name: 'Statement. ' + $scope.selectedId,
                file_size: 2,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    });

    //check if portal link already shared
    $scope.features = {
        viewjob: true,
        viewproperty: true,
        viewdiaries: true,
        viewcertificate: true,
        viewinvoice: true,
        addjob: true,
        addproperty : true,
    };
    $scope.isPortalIntegrated = 0;
    $rootScope.portalCategory = "share";
    $scope.isPortalShared = function () {
      $scope.customerId = $scope.selectedId;

        $scope.customerName = $scope.tabCustomerName;
        $scope.customerSurname = $scope.tabCustomerSurname;
        $http.get(prefix + '/isPortalShared/'+$scope.customerId).success(function(data) {
          if (data.isPortalIntegrated ) {
             $scope.isPortalIntegrated = data.isPortalIntegrated;
             
          } else {
              $rootScope.portalCategory = 'manage';
          }
          if( data.features ) {
            $scope.features = data.features;
         }
        });
    }
    
    $scope.setAccess = function() {
        
    }
    $scope.isPortalShared();
    $scope.saving = false;
    $scope.confirmRemoveValidity = true;
    $scope.confirmRemoveText = "";
    $scope.triggerManagePortalAccess = function(modalName) {
        $(modalName).modal('show');
        $scope.customerId = $scope.selectedId;
        $rootScope.portalCategory = "manage";
        $scope.customerName = $scope.tabCustomerName;
        $scope.customerSurname = $scope.tabCustomerSurname;
        
        $scope.portalTitle="manage.shared.property";
        $scope.portalValidMessage="manage.share.property.validation";
        
        $scope.portalConfirmMessage = "manage.share.property.confirmtext";
        $scope.portalHelpText = "manage.remove.share.property.helptext";
        $scope.customerName = $scope.tabCustomerName;
        $scope.customerSurname = $scope.tabCustomerSurname;
        //$scope.manageCustomerPortalOpen = true;
    }
   
    $scope.closeModal = function(modalName) {
        $scope.saving = false;
        $(modalName).modal('hide');
    };
    
    
    $scope.showRemoveButton = function (confirmRemoveText) {
        $scope.confirmRemoveValidity = false;
        $scope.confirmRemoveText = confirmRemoveText;
        if ( confirmRemoveText.toLowerCase() == "confirm" ) {
            $scope.confirmRemoveValidity = true;
        }
    }
    $scope.triggerRemoveCustomerPortalLink = function () {
        $scope.customerId = $scope.selectedId;
        $rootScope.portalCategory = "remove";
        $scope.customerName = $scope.tabCustomerName;
        $scope.customerSurname = $scope.tabCustomerSurname;
        
        $scope.portalTitle="remove.shared.property";
        $scope.portalValidMessage="remove.share.property.validation";
        $scope.portalTextConfirm ="confirm";
        
        $scope.portalConfirmMessage = "remove.share.property.confirmtext";
        $scope.portalHelpText = "remove.share.property.helptext";
        $scope.customerName = $scope.tabCustomerName;
        $scope.customerSurname = $scope.tabCustomerSurname;
        //$scope.customerPortalLinkOpen = true;
        $scope.portalFunction = "removePortalAccess()";
    }

    // customer portal to share link
      $scope.triggerCustomerPortalLink = function () {
        $scope.customerId = $scope.selectedId;

        $scope.customerName = $scope.tabCustomerName;
        $scope.customerSurname = $scope.tabCustomerSurname;
        
        $scope.portalTitle="share.property";
        $scope.portalValidMessage="share.property.validation";
        $scope.portalTextConfirm ="confirm";
        $scope.portalConfirmMessage = "share.property.confirmtext";
        $scope.portalHelpText = "share.property.helptext";
        $rootScope.portalCategory = "share";
        $scope.portalFunction = "fetchPortalSuccess";
        $http.get(prefix + '/customers/'+$scope.customerId+'/getCustomerPortalLink?checkValid=1').success(function(data) {
          if (data.error ) {
            warningModal.show(data.message, data.title, data.id);
          } else {
            $scope.customerName = $scope.tabCustomerName;
            $scope.customerSurname = $scope.tabCustomerSurname;
            $scope.customerPortalLinkOpen = true;
          }
        });
      }
    
    $scope.triggerPortalAction = function (modalName) {
        if( $rootScope.portalCategory == 'share' ) {
            $http.get(prefix + '/customers/'+$scope.customerId+'/getCustomerPortalLink').success(function(data) {
              if (data.error ) {
                warningModal.show(data.message, data.title, data.id);
              } else {
                $scope.flashMessage = 'The email link has been successfully sent';
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.customerId, 'type': 'customer_list' }, {reload: true});
              }
            });
            
        } else if($rootScope.portalCategory == 'manage') {
            $scope.saving = true;
            
            $http.post(prefix + '/managePortalAccess/'+$scope.customerId,"access="+angular.toJson($scope.features)).success(function(data) {
                $scope.flashMessage = 'Portal access has been successfully updated';
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.customerId, 'type': 'customer_list' }, {reload: true});
                $scope.closeModal(modalName);
            });
            
        } else {
            $http.post(prefix + '/removePortalAccess/'+$scope.customerId).success(function(data) {
                  if (data.error ) {
                    warningModal.show(data.message, data.title, data.id);
                  } else {
                    $scope.flashMessage = 'Portal access has been successfully removed';
                    $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                    $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.customerId, 'type': 'customer_list' }, {reload: true});
                  }
                  $scope.closeModal(modalName);
            });
            
        }
    }

}

function CustomersListCtrl($scope, $state, prefix, $http, customersresolver, defaultPagingValue, $rootScope) {
    $scope.customers = customersresolver.customers;
    $scope.totalCustomers = customersresolver.count;
    $scope.$emit('tabCustomer:selected', '');
    $scope.customerType = customersresolver.mode;
    $scope.$emit('displayScreen', false);

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("CustomerPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Customer List';
            var excelRouteName = 'downloadCustomerExcel';
            var download = prefix + '/' + excelRouteName + '?page=' + pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Customer List';
            var pdfRouteName = 'printCustomerPdf';
            var print = prefix + '/' + pdfRouteName + '?page=' + pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    if ($scope.customerType == 'customer'){
        $scope.canShow = false;
    } else if($scope.customerType == 'work_address'){
        $scope.canShow = true;
    }

    if (!$scope.customerType) {
        $scope.customerType = 'customer_list';
    }
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerType, 'selectedTab': '', 'customerSubNavStatic': ''};
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.fetchCustomers = function (pagenum, customerMode) {
        if (customerMode == 'work_address') {
            $http.get(prefix + '/listCustomers' + '?page=' + pagenum + '&limit=' + $scope.limit + '&mode=' + 'work_address').success(function (data) {
                $scope.customers = data.customers;
                $scope.totalcustomers = data.count;
            })
        } else if (customerMode == 'customer') {
            $http.get(prefix + '/listCustomers' + '?page=' + pagenum + '&limit=' + $scope.limit + '&mode=' + 'customer').success(function (data) {
                $scope.customers = data.customers;
                $scope.totalcustomers = data.count;
            })
        } else {
            $http.get(prefix + '/listCustomers' + '?page=' + pagenum + '&limit=' + $scope.limit + '&mode=' + 'customer_list').success(function (data) {
                $scope.customers = data.customers;
                $scope.totalcustomers = data.count;
            })
        }
    }
}

function AddNewFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, Upload, NgConfigKBUrlService,$location) {
  $scope.selectedId = $state.params.id;
  $scope.newPropertyScreen = $rootScope.accessDetails.clientsconfig.NewPropertyView;
  $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
  $scope.errorMsg = null;
  $scope.selectedTab = 'Files';
  $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
  $scope.customerTabName = $state.params.type;
  $scope.jobId = $state.params.type;
  $scope.error = false;
  $scope.disk_space_error = false;
  $scope.max_size_error = false;
  $scope.testDrag = true;
  $scope.dragDropActive = false;
  $scope.customerSubNavStatic = true;
  $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
  $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
  $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
  $scope.action = '/upload_customer_file?mode=customers&selectedId=' + $scope.selectedId;
  $scope.uploadAction = $scope.action;
  $scope.$emit('jobId:selected', $scope.jobId);

    $scope.uploadCallback = function() {
        $scope.viewFiles($scope.selectedId, $scope.customerTabName);
    };

  $scope.onUploadSuccess = function (resp) {
    if (resp.error) {
      if (resp.status==0) {
          $scope.disk_space_error = true;
      }else if (resp.status==2) {
          $scope.max_size_error = true;
          $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
      }else{
          $scope.error = true;
          $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
      }
    } else {
      $scope.error = false;
      $rootScope.flashMessage = "File.Uploaded";
      // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
        $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/files/view')
      // }
      // else{
      //   $scope.viewFiles($scope.selectedId, $scope.customerTabName);
      // }
    }
  }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}

function UploadedFilesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, tableCollection, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};
    tableCollection.setData('attached_files', getIdData.attached_files);

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerAttachedFile", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "preview") {
            $scope.previewFile(myObject);
        } else if (action == "delete") {
            $scope.deleteFile(myObject.id, myObject.name);
        } else if (action == "download") {
            DownloadFunction.call(this, $scope, myObject.location, myObject.name);
        }

    });

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {

        $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_customer_files', { id: $scope.selectedId, type: $scope.customerTabName }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.$emit('tabCustomer:selected', data.customerDetails);
            tableCollection.setData('attached_files', data.attached_files);
            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }

    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });

    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });
    /*
  $scope.$on("file:preview", function(evt, data) {
      var data = tableCollection.getSidepanelData(data.index, 'attached_files');
    var file = {
      'name': data['File name'],
      'location': data['location'],
      'mimeType': data['Type'],
      'createdondatetime': data['createdondatetime'],
      'type': data['Type'],
      'id': data['id']
    };
    $scope.previewFile(file);
  });
  $scope.previewFile = function (fileObj) {
    $scope.enablePreviewFiles = "CustomerAttachedFile";
    $scope.imageFile = false;
    $scope.loadFromObject = false;
    $scope.selectedRow = fileObj.id;
    $scope.file = fileObj;

    $scope.image360 = false;
    $scope.videoFile = false;
    $scope.viewableFile = false;
    $scope.image360 = $scope.detect360Images(fileObj.location);
    $scope.fileTypeDetectionFinished = false;

    if (fileObj.type.toLowerCase() == 'jpg' || fileObj.type.toLowerCase() == 'jpeg') {
	    $scope.image360.then(function(isImage360) {
		    if (isImage360 == true) {
		      $scope.image360 = true;
		      $scope.viewableFile = true;

		      pannellum.viewer('panorama', {
			  'type': 'equirectangular',
			  'panorama': fileObj.location,
			  'autoLoad': true,
			  'autoRotate': -5,
			  'autoRotateStopDelay': 4000
		      });
		    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
		      $scope.imageFile = true;
		      $scope.viewableFile = true;
		    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
		      $scope.loadFromObject = true;
		    }

		    $scope.fileTypeDetectionFinished = true;

		    $scope.$apply();
	    });
    }
    else {
	    if (fileObj.type.toLowerCase() == 'mp4' || fileObj.type.toLowerCase() == 'webm' || fileObj.type.toLowerCase() == 'ogm') {
	      $scope.videoFile = true;
	      $scope.viewableFile = true;
	      $scope.fileTypeDetectionFinisheed = true;
	    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
	      $scope.imageFile = true;
	      $scope.viewableFile = true;
	    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
	      $scope.loadFromObject = true;
	    }

	    $scope.fileTypeDetectionFinished = true;
    }
    $scope.fileSelected = true;
  }

  $scope.closePreview = function () {
    $scope.enablePreviewFiles = "";
    $scope.selectedRow = "";
    $scope.file = "";
    $scope.fileSelected = false;
    $scope.recordArgs = '';
    $rootScope.selection = '';
    $rootScope.$broadcast('closeall');
  }

  $scope.detect360Images = function(fileUrl) {
    var element = {};
    element['src'] = fileUrl;

    var async = $q.defer();

    EXIF.enableXmp();
    EXIF.getData(element, function() {
	var make = EXIF.getTag(this, "Make");
	var model = EXIF.getTag(this, "Model");
        var panoramaFound = false;
        if (make) {
	  if (make.toLowerCase().indexOf('ricoh') > -1) {
              panoramaFound = true;
              async.resolve(true);
	  }
        }
        if (model && !panoramaFound) {
	  if (model.toLowerCase().indexOf('ricoh') > -1) {
              panoramaFound = true;
              async.resolve(true);
	  }
        }
	async.resolve(false);
    });

    return async.promise;
  }
*/
}

function CustomerPropertyCtrl($scope, $state, $filter, webservicePrefix, prefix, $http, getIdData, $timeout, $rootScope, defaultPagingValue, warningModal, $location) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        $state.transitionTo('loggedin.customer_list', {reload: true});
        return false;
    }
    $scope.customerTabName = getIdData.customerTabName || $state.params.type;
    $scope.selectedId = getIdData.id;
    $scope.selectedTab = "Property";
    $scope.customerSubNavStatic = false;
    $scope.customerDetails = getIdData;
    $scope.parentCustomerDetails = getIdData.parentCustomerDetails;
    $scope.workAddress = getIdData.workAddressName;
    $scope.enableWorkAddress = (getIdData.workaddressSetting == 'Enabled');
    $scope.jobRec = getIdData.jobRec;
    $scope.canWork = getIdData.canWork;
    $scope.selectedBranchName = getIdData.branchName;
    $scope.data_loadedhistory = false;
    $scope.fetchingHistory = false;
    $scope.data_loadedongoing = false;
    $scope.fetchingOnGoingWork = false;
    $scope.servicePlanProperties = getIdData.servicePlanPropties;
    $scope.customerServicePlanProperties = [];
    $scope.historyFilter = [];
    $scope.historyDescription = '';
    $scope.limitHistory = 15;
    $scope.historySearch = false;
    $scope.historySelfOnly = false;
    $scope.numberOfActiveFilters = 0;
  $scope.activeContracts = getIdData.contractDetails;

    $scope.handleHistoryFilter = function(filter) {
        var oldNumberOfFilters = $scope.numberOfActiveFilters;
        var index = $scope.historyFilter.indexOf(filter);
        if (index > -1) {
            $scope.historyFilter.splice(index, 1);
            $scope.numberOfActiveFilters--;
        }
        else {
            $scope.historyFilter.push(filter);
            $scope.numberOfActiveFilters++;
        }
        if ($scope.historyFilter.length > 0 ) {
            $scope.historySearch = true;
        }
        $scope.fetchingHistory = false;
        if (oldNumberOfFilters !== $scope.numberOfActiveFilters) {
            $scope.currentPageHistory = 1;
            $scope.fetchHistory($scope.currentPageHistory);
        }
        else {
            $scope.fetchHistory($scope.currentPageHistory);
        }
    };

    $scope.isHistoryFilterActive = function(filter) {
        return ($scope.historyFilter.indexOf(filter) > -1);
    };

    $scope.toggleHistorySelfOnly = function() {
        $scope.historySelfOnly = !$scope.historySelfOnly;
        var oldNumberOfFilters = $scope.numberOfActiveFilters;
        if ($scope.historySelfOnly) {
            $scope.historySearch = true;
            $scope.numberOfActiveFilters++;
        }
        else {
            $scope.numberOfActiveFilters--;
        }
        $scope.fetchingHistory = false;
        if (oldNumberOfFilters !== $scope.numberOfActiveFilters) {
            $scope.currentPageHistory = 1;
            $scope.fetchHistory($scope.currentPageHistory);
        }
        else {
            $scope.fetchHistory($scope.currentPageHistory);
        }
    };

    $scope.determineIfSearchEnabled = function() {
        if (!$scope.historySelfOnly && $scope.historyFilter.length == 0 && $scope.historyDescription.length == 0) {
            $scope.historySearch = false;
        }
    }

    $scope.beginSearchingHistory = function() {
        var oldNumberOfFilters = $scope.numberOfActiveFilters;
        $timeout.cancel($scope.historySearchTimer);
        $scope.historySearchTimer = $timeout(function() {
            $scope.fetchingHistory = false;
            if ($scope.historyDescription.length > 0) {
                $scope.historySearch = true;
                $scope.numberOfActiveFilters++;
            }
            else {
                $scope.numberOfActiveFilters--;
            }
            if (oldNumberOfFilters !== $scope.numberOfActiveFilters) {
                $scope.currentPageHistory = 1;
                $scope.fetchHistory($scope.currentPageHistory);
            }
            else {
                $scope.fetchHistory($scope.currentPageHistory);
            }
        }, 500);
    };

    angular.forEach($scope.servicePlanProperties, function(val,key) {
        $scope.customerServicePlanProperties.push({id:val.id, description: val.description, expireDate: moment(val.expiredate.date), enableServicePlan: true, servicePlanCustomerRelationShip: val.relationShip});
    });
    $scope.AIAccountSetup = getIdData.AIAccountSetup;
    if ($state.current.data.limit) {
        $scope.limitOnGoingWork = parseInt($state.current.data.limit);
    } else {
        $scope.limitOnGoingWork = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPageOnGoingWork = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPageOnGoingWork = 1;
    }

    if ($state.current.data.limitHistory) {
        $scope.limitHistory = parseInt($state.current.data.limitHistory);
    } else {
        $scope.limitHistory = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPageHistory = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPageHistory = 1;
    }

  $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
  $scope.$emit('tabCustomer:selected', getIdData, true);
  $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
  $scope.$emit('displayScreen', false);
  $scope.$emit('displayInvoiceScreen', false);
  $scope.$emit('displayAssetScreen', false);

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    $scope.fetchOnGoingWork = function (pageNum) {
        if ($scope.fetchingOnGoingWork) {
            return false;
        }
        $scope.fetchingOnGoingWork = true;
        return $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/view/property/view/ongoing?currentPageOnGoingWork=' + pageNum + '&limitOnGoingWork=' + $scope.limitOnGoingWork+ '&id=' + $scope.selectedId).
        success(function (data) {
            $scope.onGoingWork = data.onGoingWork;
            $scope.onGoingWorkCount = data.onGoingWorkCount;
            $scope.data_loadedongoing = true;
            $scope.fetchingOnGoingWork = false;

            if ($('#ongoingwork').offset().top < 162) {
                $rootScope.$broadcast('smoothscroll', {target: '#ongoingwork', offset: 162});
            }
        });
    }

    $scope.processTooltipData = function (data) {
        var total = 0;
        var vat = 0;
        var grand_total = 0;
        var balance = 0;

        if (data.invoices) {
            angular.forEach(data.invoices, function (v, i) {
                total += parseFloat(data.invoices[i]['total']);
                vat += parseFloat(data.invoices[i]['taxes']);
                grand_total += parseFloat(data.invoices[i]['grand_total']);
                balance += parseFloat(data.invoices[i]['balance']);
            });
        }

        total = $filter('currency')(total, $rootScope.CustomCurrency, 2);
        vat = $filter('currency')(vat,$rootScope.CustomCurrency, 2);
        grand_total = $filter('currency')(grand_total, $rootScope.CustomCurrency, 2);
        balance = $filter('currency')(balance, $rootScope.CustomCurrency, 2);

        if (data.vatCompanyNumber.length > 0) {
            return '<span class="invoice-tooltip-content"><span>Total Excl. VAT: ' + total + '</span><span>VAT: ' + vat + '</span><span>Total: ' + grand_total + '</span><span>Remainder to pay: ' + balance + '</span><div class="clearfix"></div>';
        }

        return '<span class="invoice-tooltip-content"><span>Total: ' + grand_total + '</span><span>Remainder to pay: ' + balance + '</span><div class="clearfix"></div>';
    }

    $scope.loadTooltip = function(estimate) {
        if (estimate['invoiceTooltip'] == 'Loading...') {
            if (estimate['jobId'] && (estimate['project'] == 'Job' || estimate['project'] == 'Project Job') && estimate['status'] == 'Invoiced') {
                //if it's job
                $http.get(prefix + '/customers/' + estimate['propertyId'] + '/jobs/' + estimate['jobId'] + '/invoices').success(function (data) {
                    estimate['invoiceTooltip'] = $scope.processTooltipData(data);
                });
            }
            else if (estimate['jobId'] && estimate['project'] == 'Invoice' && (estimate['status'] == 'Partial' || estimate['status'] == 'Final')){
                //if it's an invoice
                $http.get(prefix + '/customers/' + estimate['propertyId'] + '/jobs/' + estimate['jobId'] + '/invoices/' + estimate['InvoiceId']).success(function(data) {
                    estimate['invoiceTooltip'] = $scope.processTooltipData(data);

                });
            }
        }
    }

    $scope.fetchHistory = function (pageNum) {
        if ($scope.fetchingHistory) {
            return false;
        }
        $scope.fetchingHistory = true;
        var historyDescription = ($scope.historyDescription.length > 0) ? '&description=' + $scope.historyDescription : '';
        var historyFilter = ($scope.historyFilter) ? '&types=' + $scope.historyFilter.join(',') : '';
        var selfOnly = ($scope.historySelfOnly) ? '&selfonly=1' : '&selfonly=0';

        return $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/view/property/view/history?currentPageHistory=' + pageNum + '&limitHistory=' + $scope.limitHistory+ '&id=' + $scope.selectedId + historyDescription + historyFilter + selfOnly).
        success(function (data) {
            $scope.history = data.history;
            $scope.data_loadedhistory = true;

            angular.forEach($scope.history, function(v, i) {
                if ($scope.history[i]['jobId'] && ($scope.history[i]['project'] == 'Job' || $scope.history[i]['project'] == 'Project Job') && $scope.history[i]['status'] == 'Invoiced') {
                    //if it's job
                    $scope.history[i]['invoiceTooltip'] = 'Loading...';
                }
                else if ($scope.history[i]['jobId'] && $scope.history[i]['project'] == 'Invoice' && ($scope.history[i]['status'] == 'Partial' || $scope.history[i]['status'] == 'Final')){
                    // if it's an invoice
                    $scope.history[i]['invoiceTooltip'] = 'Loading...';
                }

                $scope.historyCount = data.historyCount;
                $scope.data_loadedhistory = true;
                $scope.fetchingHistory = false;
                $scope.determineIfSearchEnabled();

                if ($('#history').offset().top < 162) {
                    $rootScope.$broadcast('smoothscroll', {target: '#history', offset: 162});
                }
            });
        });
    }

    if($scope.selectedId != undefined){
        $scope.fetchOnGoingWork($scope.currentPageOnGoingWork);
        $scope.fetchHistory($scope.currentPageHistory);
    }

    $scope.userViewPreference = getIdData.userViewPreference;
    $scope.screenName='NewPropertyView';
    $scope.labelName='Try out the new property view screen';
    $scope.oldUrl='/customers/'+$scope.customerDetails['customerTabName']+'/'+$scope.customerDetails['id']+'/view/property/view';
    $scope.newUrl='/customers/'+$scope.customerDetails['customerTabName']+'/'+$scope.customerDetails['id']+'/view_v2/property/view';

    $scope.showAlreadyAccepted = function (){
        warningModal.show('You cannot view this estimate becuase it has already been accepted and converted into a job', 'View estimate');
    }

  $rootScope.$on('parent:updated', function(evt) {
      if (angular.element('#send_statement')) {
          angular.element('#send_statement').remove();
      };
      if (angular.element('#configure_sla_customer')) {
          angular.element('#configure_sla_customer').remove();
      };
  });
}

function EditCustomerDetailsCtrl($scope, $state, prefix, $http, getIdData, $rootScope, $timeout, warningModal,$filter, $translate) {
    $scope.screenType ='editCustomer';
    $scope.addProvince = $translate('Add.province');
  PostCodeCtrl.call(this, $scope, $state, $http, prefix);
  GetAdvertisingTypeCtrl .call(this, $scope, $state, $http, prefix,getIdData.selectedAdvertisingType);
  PermissionCheck.call(this, $scope);
  $scope.customerSubNavStatic = true;
  $scope.customerTabName = getIdData.customerTabName || $state.params.type;
  $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
  $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
  $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
  $rootScope.shouldBeOpenLord = false;
  $scope.customerDetails = getIdData.customerDetails;
    $scope.hasUPRNPermission = getIdData.hasUPRNPermission;
    $scope.uprnNumber = $scope.customerDetails.uprnNumber;
    $scope.showCompany = false;
    if($scope.customerDetails.companyname == null) {
        $scope.showCompany = true;
    }
    $scope.customerType = getIdData.customerDetails.customerType;
    $scope.customerMobileNumber = getIdData.customerMobileNumber;
    $scope.customerLandlineNumber = getIdData.customerLandlineNumber;
    $scope.workAddress = getIdData.customerDetails.workAddressName;


  if ($scope.customerLandlineNumber == 0) {
    $scope.customerLandlineNumber = '';
  }
  if ($scope.customerMobileNumber == 0) {
    $scope.customerMobileNumber = '';
  }
  $scope.mobile_code = getIdData.customerMobileNumberCountyCode;
  $scope.telephone_code = getIdData.customerLandlineNumberCountyCode;
  $scope.buildingTypeLists = getIdData.buildingTypesRecord;
  $scope.titleLists = getIdData.titlesRecord;
  $scope.countyLists = getIdData.countyRecords;
  $scope.branches = getIdData.branchList;
  $scope.pricebooksLists = getIdData.settingsPriceBooksList;
  $scope.selectedPriceBook = getIdData.selectedPriceBook;
  $scope.selectedAdvertisingType = (getIdData.selectedAdvertisingType[0])?getIdData.selectedAdvertisingType[0]:'';
  $scope.selectedTitle = getIdData.selectedTitle;
  $scope.selectedBuildingTypes = getIdData.selectedBuildingType;
  $scope.selectedCustomerBranchId = getIdData.selectedBranchType;
  $scope.selectedVacantPropertyType = getIdData.customerDetails.vacant;
  $scope.addressline1 = getIdData.customerDetails.addressline1;
  $scope.addressline2 = getIdData.customerDetails.addressline2;
  $scope.addressline3 = getIdData.customerDetails.addressline3;
  $scope.town = getIdData.customerDetails.town;
  $scope.county = getIdData.customerDetails.county;
  $scope.countyId = getIdData.customerDetails.countyId;
  $scope.postcode = getIdData.customerDetails.postcode;
  $scope.status = parseInt(getIdData.customerDetails.status);
  $scope.selectedLandlordId = (getIdData.customerDetails.landlord[0])?getIdData.customerDetails.landlord[0]: '';
  $scope.customerTypes = getIdData.customerTypes;
  $scope.workAddress = getIdData.workAddress;
  $scope.selectedId = $state.params.id;
  $scope.totalCustomers = getIdData.totalCustomers || 0;
  $scope.branchAllow = getIdData.branchAllow;
  $scope.customerStatus = getIdData.customerStatus;
  $scope.customerServicePlanProperties = getIdData.servicePlans;
  $scope.AIAccountSetup = getIdData.AIAccountSetup;
  $scope.enableServicePlan = false;
  $scope.servicePlans = [];
  angular.forEach($scope.customerServicePlanProperties, function(val,key) {
          $scope.servicePlans.push({id:val.id, description: val.description, expireDate: moment(val.expiredate.date), enableServicePlan: true, servicePlanCustomerRelationShip: val.relationShip, isServicePlanDisable: val.isServicePlanDisable});
  });
  $scope.deleteServicePlanProperties = [];
  $scope.isChecked = function(servicecPlan) {
      var unSelectedServicePlan = servicecPlan;
      if(unSelectedServicePlan.enableServicePlan == true) {
          $scope.deleteServicePlanProperties.push(unSelectedServicePlan.id);
      } else {
          $scope.deleteServicePlanProperties.pop(unSelectedServicePlan.id);
      }
    }

    $scope.triggerAddLandlord = function () {
        $rootScope.shouldBeOpenLord = true;
    }

    $scope.workReload = function (id, text) {
        $timeout(function () {
            $scope.selectedLandlordId = {id:id, text:text};
        }, 100);
        $state.transitionTo('loggedin.customer_list.view.propertyedit', {'id': $scope.selectedId, 'type': $scope.customerTabName});
    }
    GetAllLanlordsCtrl.call(this, $scope, $state, $http, prefix, $scope.selectedLandlordId);

    $scope.vacantProperties = [
        { id: 1, description: "Yes" },
        { id: 2, description: "No" },
        { id: 3, description: "N/A" }
    ];

    $scope.backTo = function () {
        $scope.customerViewDetails($scope.selectedId, $scope.customerTabName);
    }

    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.countyLists[data.id] = data.description;
        $timeout(function () {
            $scope.countyId = data.id;
        },100);
    });

  $scope.previousStatus = angular.copy($scope.status);
  $scope.editCustomer = function ($event, formStatus) {
    //onchange of customer third party reference enabled save button
    $scope.enableCustomerAccountingReference = function () {
        $scope.customerSaveButtonDisabled = false;
        $scope.validateReference          = false
    }

    $event.preventDefault();
    if (!formStatus) {
      return;
    }
    $scope.updating = true;
    $scope.current = this;
    var currentStatus = this.status;
    var county = _.findWhere($scope.countyLists,{'id':parseInt(this.countyId)});

    $http.post(prefix + '/update_customer', "customertype[id]=" + this.selectedId
      + "&customertype[selectedBranchType]=" + encodeURIComponent(this.selectedCustomerBranchId)
      + "&customertype[name]=" + encodeURIComponent(this.customerDetails.name)
      + "&customertype[landlord]=" + encodeURIComponent((this.selectedLandlordId.id)? this.selectedLandlordId.id:'')
      + "&customertype[surname]=" + encodeURIComponent(this.customerDetails.surname)
      + "&customertype[companyname]=" + encodeURIComponent(this.customerDetails.companyname)
      + "&customertype[settingsTitlesid]=" + encodeURIComponent(this.selectedTitle)
      + "&customertype[email]=" + encodeURIComponent(this.customerDetails.emailAddress)
      + "&customertype[landlinecountrycode]=" + encodeURIComponent(this.telephone_code)
      + "&customertype[landline]=" + encodeURIComponent(this.customerLandlineNumber)
      + "&customertype[mobilecountrycode]=" + encodeURIComponent(this.mobile_code)
      + "&customertype[mobile]=" + encodeURIComponent(this.customerMobileNumber)
      + "&customertype[addressline1]=" + encodeURIComponent(this.addressline1)
      + "&customertype[addressline2]=" + encodeURIComponent(this.addressline2)
      + "&customertype[addressline3]=" + encodeURIComponent(this.addressline3)
      + "&customertype[town]=" + encodeURIComponent(this.town)
      + "&customertype[county]=" + encodeURIComponent(county ? county.description : '')
      + "&customertype[settingsstateprovinceid]=" + (parseInt(this.countyId) > 0 ? this.countyId : '')
      + "&customertype[postcode]=" + encodeURIComponent(this.postcode)
      + "&customertype[status]=" + encodeURIComponent(this.status)
      + "&customertype[uprnNumber]=" + encodeURIComponent(this.uprnNumber)
      + "&customertype[settingsBuildingtypesid]=" + encodeURIComponent(this.selectedBuildingTypes)
      + "&customertype[settingsAdvertisingsid]=" + encodeURIComponent((this.selectedAdvertisingType.id)?this.selectedAdvertisingType.id:'')
      + "&customertype[vacantproperty]=" + encodeURIComponent(this.selectedVacantPropertyType)
      + "&customertype[thirdparty]=" + encodeURIComponent(this.customerDetails.accountingsystemreference)
      + "&customertype[customerType]=" + encodeURIComponent($scope.customerType)
      + "&customertype[deleteServicePlanProperties]=" + encodeURIComponent(angular.toJson($scope.deleteServicePlanProperties))
        + "&customertype[settingsPricebookid]=" + encodeURIComponent($scope.selectedPriceBook)).
      success(function (data, status) {
        //show validation error if third party accounting reference is invalid
        if (data.error && data.error == 'Invalid third party accounting reference') {
            $scope.validateReference          = true;
            $scope.updating                   = false;
            $scope.customerSaveButtonDisabled = true;
        } else {
            $scope.validateReference = false;

            if (status == 200) {
                $scope.id               = data.id;
                // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
                  $scope.parentPropertyId = getIdData.customerNav.id;
                  $location.path('/customers/'+$scope.customerTabName+'/'+$scope.parentPropertyId+'/view_v2/work/view')
                // }
                // else{
                //   $rootScope.flashMessage = "Customer Updated";
                //   $scope.customerViewDetails($scope.selectedId, $scope.customerTabName);
                //   $scope.updating = false;
                // }
                if($scope.previousStatus == 0 && currentStatus == 1 && $scope.customerType != 'workaddress' && data.workAddressCount <= 1000) {
                  warningModal.show('Re-activating this customer will make all the workaddress-es active', 'Message');
                }
                else if($scope.previousStatus == 0 && currentStatus == 1 && $scope.customerType != 'workaddress' && data.workAddressCount > 1000) {
                  warningModal.show('Re-activating this customer will make all the workaddress-es active. It may take several minutes for this to finish. Because, there are thousands of work addresses', 'Message', 'property_status_update_active');
                }
                else if($scope.previousStatus == 1 && currentStatus == 0 && $scope.customerType != 'workaddress' && data.workAddressCount && data.workAddressCount > 1000) {
                  warningModal.show('It may take several minutes for this to finish. Because, there are thousands of work addresses', 'Message', 'property_status_update');
                }
            }
        }
      });
    };

    $scope.customerAddressInfo = [$scope.customerDetails.addressline1,$scope.customerDetails.addressline2,$scope.customerDetails.addressline3].filter(Boolean);
    /** to load customer type changing model in advance option section */

    $scope.saving = false;

    $scope.modalInitiate = function (modalName) {
        $scope.newCustomerType = {
            newCustomerTypeId:'',
            customerTitleId:$scope.selectedTitle,
            companyName:'',
            name:'',
            surName:''};
        $scope.showCustomerTypeFields = false;
        $scope.showCompanyTypeFields = false;
        $scope.showAdditionalFields = false;
        $(modalName).modal('show');
        $scope.$$childTail.convertCustomerType.$setPristine();
    };

    $scope.closeModal = function(modalName) {
        $scope.saving = false;
        $(modalName).modal('hide');
    };

    $scope.confirmToConvert = function (modalName) {
        $scope.saving = true;
        $http.post(prefix + '/convert_CustomerType', "customerParams[id]=" + this.selectedId
            + "&customerParams[customerType]=" + encodeURIComponent($scope.customerType)
            + "&customerParams[email]=" + encodeURIComponent(this.customerDetails.emailAddress)
            + "&customerParams[landlinecountrycode]=" + this.telephone_code
            + "&customerParams[landline]=" + this.customerLandlineNumber
            + "&customerParams[mobilecountrycode]=" + this.mobile_code
            + "&customerParams[mobile]=" + this.customerMobileNumber
            + "&customerParams[newCustomerType]=" + encodeURIComponent(angular.toJson($scope.newCustomerType))).
        success(function (data, status) {
            if (status == 200 && data.error == false) {
                $scope.id = data.id;
                $rootScope.flashMessage = "Customer type is converted";
                $scope.customerViewDetails($scope.selectedId, $scope.customerTabName);
                $scope.saving = false;
                $scope.closeModal(modalName);
            }
        }).error(function(data, status) {
            $scope.saving = false;
        });

    };

    $scope.updateNewCustomerType = function () {
        var CustomerType = $scope.customerDetails.settingsCustomerType.type,
            newCustomerTypeId = parseInt($scope.newCustomerType.newCustomerTypeId),
            customerTypeObj =  _.where($scope.customerTypes,{id:newCustomerTypeId});
        $scope.updatedCustomerType= customerTypeObj[0].type;
        $scope.disableInputFields = false;
        $scope.newCustomerType['name'] = $scope.newCustomerType['companyName'] = $scope.newCustomerType['surName'] = '';

        if(CustomerType === $scope.updatedCustomerType)
        {
            $scope.disableInputFields = true;
            $scope.newCustomerType['customerTitleId'] = $scope.selectedTitle;
            $scope.newCustomerType['companyName'] = $scope.customerDetails.companyname;
            $scope.newCustomerType['name'] = $scope.customerDetails.name;
            $scope.newCustomerType['surName'] = $scope.customerDetails.surname;
        }

        $scope.showAdditionalFields = true;
        if( $scope.updatedCustomerType === 'customer')
        {
            $scope.showCustomerTypeFields = true;
            $scope.showCompanyTypeFields = false;

        }else if($scope.updatedCustomerType === 'company')
        {
            $scope.showCompanyTypeFields = true;
            $scope.showCustomerTypeFields = false;
        }
    };
    $scope.customerTypeModelDescription = $filter('translate')('Change.customer.type.from.settingsCustomerType.description',{"settingsCustomerTypeDescription":$scope.customerDetails.settingsCustomerType.description});
}

function CustomerInvoiceSetupCtrl($scope, $http, $state, datasets, $rootScope, getIdData, prefix) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope, prefix);

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;

    if($scope.customerTabName == 'invoice_address')  {
        $scope.hideSubNavCustomers = true;
        $scope.$emit('tabInvoice:selected', getIdData.invoiceNav);
        $scope.$emit('tabRouteInvoice:selected', {'selectedTab': '', 'invoiceSubNavStatic': true });
    }
    else {
        $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
        $scope.$emit('tabRouteCustomer:selected', $scope.customerTabName);
        $scope.customerSubNavStatic = true;
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.invoiceSetupForm.$setPristine();
            $scope.saving = false;
            $scope.selectedId = data.id;
            $rootScope.flashMessage = "Invoice.Setup.Saved";
            if($scope.customerTabName == 'invoice_address')  {
                $state.transitionTo('loggedin.invoice_address.view.details', {'id': $scope.selectedId});
            }
            else {
                $state.transitionTo('loggedin.customer_list.view.property', {'id': $scope.selectedId, 'type': $scope.customerTabName});
            }
        })
    }
}

function CustomerCommunicationsCtrl($scope, $state, $http, prefix, getIdData, $rootScope, dateRange, emailService) {
    $scope.selectedId = $state.params.id;
    $scope.customerId = $scope.selectedId;
    $scope.selectedTab = "Notes";
    $scope.customerSubNavStatic = false;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.searchText = '';
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.contacts = getIdData.contacts;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.canWork = getIdData.canWork;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
  $scope.smsReadAccess = getIdData.smsReadAccess;

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.customerId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=2&id=' + $scope.customerId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 2;        // For Customers
        clone_obj.relatedObjectId = $scope.customerId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    $scope.action = "";
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
        $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    };
    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if( message.action ) {
            $scope.action = message.action;
        }
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action};

        $scope.$broadcast('filterValue', filters);
    });

    var baseRoute = prefix + '/customers/' + $state.params.id;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}

function CommunicationNotesListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope, canLoad, $q, $sce, emailService) {
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;

    $scope.customerOffices = getIdData.navBarDetails;
    $scope.customerId = $scope.customerOffices.id;
    $scope.notesDetails = getIdData.notesDetails;
    $scope.contacts = getIdData.contacts;
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.customerSubNavStatic = false;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notes = getIdData.notesDetails.notesDetails;
        $scope.notesid = $scope.notesDetails.notesid;
        $scope.notesCount = $scope.notesDetails.notesCount;
        $scope.notesCountMain = $scope.notesDetails.notesCountMain;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.customerId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=2&id=' + $scope.customerId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 2;        // For Customers
        clone_obj.relatedObjectId = $scope.customerId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerNotes", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });
    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.customerId;
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadnotes';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.customerId;
            $scope.pageName = 'Notes List';
            $scope.pdfRouteName = 'printnotes';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.currentPagePhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.currentPagePhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.searchCommunications(1);
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesCount = data.notesCount;
            $scope.notesCountMain = data.notesCountMain;
            //$scope.$emit('tabCustomer:selected', data.navBarDetails);
            $scope.$parent.$parent['notesCount'] = data.notesCount; // The scope that shows the count is up because of ng-ifs

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceller = null;
    $scope.fetchPhoneCalls = function (pageNumPhoneCalls) {
        canLoad.setLoadValue(false);
        if (phoneCallCanceller) {
            phoneCallCanceller.resolve();
        }
        phoneCallCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/listPhoneCalls?pagePhoneCalls=' + pageNumPhoneCalls + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: phoneCallCanceller.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceller = null;
    $scope.fetchSmsMessages = function (pageNumSmsMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceller) {
            smsCanceller.resolve();
        }
        smsCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/listSms?pageSmsMessages=' + pageNumSmsMessages + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: smsCanceller.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNumEmailMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + $scope.customerId + '/listEmail?pageEmailMessages=' + pageNumEmailMessages + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + $scope.customerId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + $scope.customerId + '/notes/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notes = data.notesDetails.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
            $scope.selectedId = data.navBarDetails.id;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

  $scope.downloadCallRecording = function(fileLink){
      let link = document.createElement('a');
      link.href = fileLink;

      if (link.download !== undefined) {
          link.download = 'call-recording.wav';
      }
      if (document.createEvent) {
          let e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          link.dispatchEvent(e);
      }
  };
  EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function CustomerNewSmsMessageCtrl($scope, $http, $state, datasets,  prefix, smsTemplates, $rootScope, formService, getIdData, sidePanel, $sce) {
  EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
  $scope.notes = getIdData.customerOffices;
  smsTemplates.setTemplatesValue(getIdData.templateDetails);
  $scope.smsTemplates = getIdData.templateDetails;
  $scope.selectedId = $state.params.id;
  $scope.selectedTab = "Notes";
  $scope.customerSubNavStatic = true;
  $scope.modeChange = $state.params.mode;
  $scope.$emit('tabCustomer:selected', getIdData);
  $scope.customerTabName = $state.params.type || getIdData.customerTabName;
  $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
  $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });

    SMSMessageControllers.call(this, $scope);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newSmsMessageForm.$setPristine();
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.customerNotesTimeLineList($scope.selectedId, $scope.customerTabName);
            } else if ($scope.modeChange == 'table') {
                $scope.customerNotesTableList($scope.selectedId, $scope.customerTabName);
            }
            $scope.saving = false;
        })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function CustomerNewNotesCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.customerSubNavStatic = true;
    $scope.modeChange = $state.params.mode;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$emit('tabCustomer:selected', getIdData);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newNoteForm.$setPristine();
            $scope.selectedId = data.tablepkid;
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.customerNotesTimeLineList($scope.selectedId, $scope.customerTabName);
            } else if ($scope.modeChange == 'table') {
                $scope.customerNotesTableList($scope.selectedId, $scope.customerTabName);
            }
            $scope.saving = false;
        })
    }
}

function CustomerEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.supplierSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.customerOffices = getIdData.navBarDetails;
    $scope.customerId = $scope.customerOffices.id;
    $scope.customerSubNavStatic = true;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.selectedTab = "Notes";
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
            + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
        success(function (data, status) {
            if (status == 200) {
                $scope.editNoteForm.$setPristine();
                $rootScope.flashMessage = data;
                if ($scope.modeChange == 'timeline') {
                    $scope.customerNotesTimeLineList($scope.selectedId, $scope.customerTabName);
                } else if ($scope.modeChange == 'table') {
                    $scope.customerNotesTableList($scope.selectedId, $scope.customerTabName);
                }
                $scope.updating = false;
            }
        });
    }
}

function CommunicationNotesTimeViewCtrl($scope, $state, $http, prefix, getIdData, $timeout, $rootScope, canLoad, $q, $sce, emailService) {
    $scope.selectedId = $state.params.id;
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=2&id=' + $scope.selectedId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 2;        // For Customers
        clone_obj.relatedObjectId = $scope.customerId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerNotes", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    //TODO: Need to emit the Navigation details
    var url = '/customers/customer_list/' + $state.params.id + '/notes/view/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
}

function HandleAllTimeLineViews($scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, getRecordsUrl, prefix) {
    $scope.setTime = false;
    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.action = $scope.$parent.action;

    if(getIdData) {
        $scope.smsReadAccess = getIdData.smsReadAccess;
        $scope.smsWriteAccess = getIdData.smsWriteAccess;

        if ($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '' && $scope.action == '') {
            $scope.results = getIdData.records[0];
            $scope.count = getIdData.count;
            $scope.attachedFilesDetails = getIdData.attachedFilesDetails;
        }
    }
    $scope.searchResult = false;

    $timeout(function () {
        $scope.setTime = true;
    }, 1000);

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.previewAttachedFile = function(file) {
        $rootScope.$broadcast('email_attachment:preview', file);
    }

    var canceler = null;
    $scope.loadingNotes = false;
    $scope.fetchRecords = function (pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $scope.loadingNotes = true;
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        let limit = ($scope.limit == undefined) ? 200 : $scope.limit;

        $http.get(prefix + getRecordsUrl + '?page=' + pageNum + '&limit=' + limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId + '&action='+ $scope.$parent.action, {timeout: canceler.promise}).success(function (data) {
            $scope.index = 0;
            $scope.count = data.count;
            $scope.results = data.records[$scope.index];
            $scope.dateValue = data.records;
            $scope.attachedFilesDetails = data.attachedFilesDetails;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);
            var message = {name: 'notesCount', value: data.notesCount};
            $scope.$emit('event:changeJobScope', message);

            var scope = {name: 'notesCount', value: data.notesCount};
            $rootScope.$emit('event:changeJobInvoiceScope', scope);
            //$scope.onFetchRecordsComplete(data);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
            $scope.loadingNotes = false;
        })
    }

    $scope.more = function(){
        $scope.index++;
        if($scope.dateValue.length > $scope.index){
            angular.forEach($scope.dateValue[$scope.index],function(value,index){
                $scope.results.push(value);
            });
        }
    };
    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.action = '';
        $('#loading-indicator').show();
        if( message.action ) {
            $scope.action = message.action;
        }
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.fetchRecords(1);
    });
    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchRecords(1);
    });

  $scope.renderHtml = function (html_code) {
    return $sce.trustAsHtml(html_code);
  }

  $scope.triggerDelete = function (id) {
    $scope.selectedId = id;
    $scope.shouldBeOpen = true;
  }
  $scope.fetchRecords(1);

  $scope.downloadCallRecording = function(fileLink){
      let link = document.createElement('a');
      link.href = fileLink;

      if (link.download !== undefined) {
          link.download = 'call-recording.wav';
      }
      if (document.createEvent) {
          let e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          link.dispatchEvent(e);
      }
  };

  EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);

  $scope.showMoreChats = function ($date,$id,$customerId,$firstMessageId,$lastMessageId,$type) {
    $scope.loadingChat = true;
    $scope.showChatModal = true;
    $scope.messageDate = $date;
    $scope.id = $id;
    $scope.selectedId = $customerId;
    $scope.firstMessageId = $firstMessageId;
    $scope.lastMessageId = $lastMessageId;

    var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
    var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
    var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;

    $http.get(prefix + '/chat/' + $scope.selectedId + '/'+ $type+'/' + $scope.id +  '/listChatMessages?searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + $scope.messageDate + '&endDate=' + $scope.messageDate).success(function (data) {
      $scope.chatMessages = data.chatMessages;

      $scope.chatMessagesCount = data.chatMessagesCount;
      $scope.chatApi = data.chatApi;
      $scope.loadingChat = false;
      $scope.showChats = true;
    })

  }
}

function CustomerNewPhoneCallCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.customerSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newPhoneCallForm.$setPristine();
            $scope.selectedId = data.tablepkid;
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.customerNotesTimeLineList($scope.selectedId, $scope.customerTabName);
            } else if ($scope.modeChange == 'table') {
                $scope.customerNotesTableList($scope.selectedId, $scope.customerTabName);
            }
            $scope.saving = false;
        })
    }
}

function CustomerBranchListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, canLoad, $q, $rootScope, warningModal) {
    $scope.selectedId = $state.params.id;
    if(getIdData.allow == "dontallow"){
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.selectedId, 'type': $state.params.type });
    }
    $scope.branches = getIdData.customerOffices;
    $scope.branchesCount = getIdData.count;
    $scope.selectedTab = "Branches";
    $scope.customerSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerBranches", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
    });
    // This steps used only for keyboard shotcuts screen.
    $scope.$on("BranchesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Branches List';
            $scope.excelRouteName = 'downloadCustomerBranches';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Branches List';
            $scope.pdfRouteName = 'printCustomerBranches';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.fetchBranches($scope.currentPage);
        }
    });

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    var canceler = null;
    $scope.fetchBranches = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/branches/view?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.branches = data.customerOffices;
            $scope.branchesCount = data.count;
            $scope.$emit('tabCustomer:selected', data.customerDetails);
            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    $scope.triggerDelete = function (branchId, selectedId, branchName, branchOption) {
        $scope.isDelete = branchOption;
        if($scope.isDelete == false){
            warningModal.show('This.branch.is.associated.with.property.contact', 'Branch');
        } else{
            $scope.branchId = branchId;
            $scope.branchName = branchName;
            $scope.customerId = selectedId;
            $scope.shouldBeOpen = true;
        }
    }
}

function CreateNewBranchCtrl($scope, $state, $http, prefix, getIdData, $rootScope,ProvinceService,$location) {
  PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);
  PostCodeCtrl.call(this, $scope, $state, $http, prefix);
  $scope.selectedId = $state.params.id;
  $scope.customerTabName = getIdData.customerTabName;
  $scope.customerSubNavStatic = true;
  $scope.selectedTab = "Branches";
  $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
  $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$emit('tabCustomer:selected', getIdData);
    ProvinceService.getProvinces().then(function(data){
        $scope.countyLists = data;
    });
  $scope.formSubmit = function (formElem) {
    $http.post($scope.actionVal, formElem.serialize()).
      success(function (data, status) {
        $scope.newBranchForm.$setPristine();
        $rootScope.flashMessage = "Branch.Saved";
        // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
            $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/branches/view')
        // }else{
        //     $scope.newBranches($scope.selectedId, $scope.customerTabName);
        // }
      })
  }
}

function CustomerContactsCtrl($scope, $state, $http, prefix, getIdData, $rootScope, warningModal) {
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Contacts";
    $scope.customerSubNavStatic = false;

    $scope.contactslist = getIdData.contacts;
    $scope.contactsid = $scope.contactslist.contactsid;
    $scope.contactsCount = $scope.contactslist.contactsCount;
    $scope.showPrimaryOption = $scope.contactslist.showPrimaryOption;
    $scope.customerDetails = getIdData.navBarDetails;
    $scope.customerId = $scope.customerDetails.id;
    $scope.tableType = $scope.customerDetails.tabletype;
    $scope.customerType = $scope.customerDetails.customerType;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;

    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);


    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerContacts", function (event, action, data) {
        var myObject = eval('(' + data + ')');
        if (action == "primary") {
            if ($scope.customerType == 'company' || $scope.showPrimaryOption == false) {
                angular.element('#cannotsetprimarycontact').trigger('click');
                return;
            }
            $scope.triggerPrimaryContact(myObject.id, myObject.name);
        } else if (action == "delete") {
            if (myObject.maincontact && myObject.maincontact === true) {
                angular.element('#cannotdelete').trigger('click');
                return;
            }
            $scope.triggerDelete(myObject.id, myObject.name);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.deleteContacts = function(pageNum) {
        $scope.fetchContacts(pageNum).then(function() {
            $scope.$emit('tabCustomer:successMessage', 'Contact Deleted');
        });
    }

    $scope.fetchContacts = function (pageNum) {
        return $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/contacts/view?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.contactslist = data.contacts;
            $scope.contactsCount = data.contacts.contactsCount;
            $scope.selectedId = data.navBarDetails.id;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);
        });
    }
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.triggerDelete = function (id, contacts, customerid) {
        $scope.selectedContactId = id;
        $scope.description = contacts;
        $scope.customerId = customerid;

        $http.get(prefix + '/Checkdeletecustomercontacts/'+ $scope.selectedContactId + '/' + $scope.customerId).success(function (data){
            if(data.type === 'warning'){
                warningModal.show(data.message, data.title, data.id);
            }else if(data.type === 'transferAndDelete'){
                $scope.shouldBeTransferOrDelete = true;
                $scope.canDelete = data.canDelete;
            }else if(data.type === '' && data.canDelete == true){
                $scope.shouldBeOpen = true;
            }
        })
    }
    $scope.triggerPrimaryContact = function (id, name, surname) {
        var contactName = '';
        var contactSurname = '';
        if(name != null){ contactName = name; }
        if(surname != null){ contactSurname = surname; }
        $scope.selectedContactId = id;
        $scope.description = contactName+' '+contactSurname;
        $scope.shouldBeOpenPrimary = true;
    }
}

function PreferenceCtrl($scope){
    $scope.numbers_collection = [];
    $scope.emailValid = false;
    $scope.phoneValid = false;
    $scope.$watch('emailaddress', function(newValue, oldValue) {
        var EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+?:\.[A-Za-z]{4}$/;
        if (!newValue || EMAIL_REGEXP.test(newValue)) {
            $scope.emailValid = false;
            $scope.emailValue = '';
        } else {
            $scope.emailValid = true;
            // edit contact preference - email automatically checked
            if(oldValue != newValue && $scope.communicationPreference[2] == 1){
                $scope.emailValue = 'Email';
            }
            $scope.contactletterValue = false;
            // edit contact preference -letter get unchecked and email get checked
            // if (oldValue != newValue && $scope.letterValue == 'Letter'){
            //     $scope.letterValue = '';
            // }
        }
    });
    $scope.$on("numbersCollection", function (event, data) {
        $scope.numbers_collection = data;
        $scope.canAddSMSPreference = false; // Default to false, then figure if mobile is there
        $scope.smsValue = '';
        $scope.addOrDelete = false;
        if( $scope.numbers_collection['selected']){
            $scope.selected = $scope.numbers_collection['selected'];
            delete $scope.numbers_collection.selected;
        }else{
            $scope.addOrDelete = true;
        }

        if($scope.numbers_collection.length == 0){
            $scope.phoneCallValue = '';
            $scope.smsValue = '';
        }
        if($scope.numbers_collection.length !== 0){
            if($scope.communicationPreference[0] == 1){
                $scope.phoneCallValue ='PhoneCall';
            }
        }
        angular.forEach($scope.numbers_collection, function(val,key) {
            if (val.hasOwnProperty('type_id') && val.type_id == 1) {  // 1 for mobile
                $scope.canAddSMSPreference = true;
                if($scope.communicationPreference[1] == 1 && ($scope.selected || $scope.addOrDelete)){
                    $scope.smsValue = 'SMS';
                }else if($scope.communicationPreference[1] !== 1 && !$scope.selected){
                    $scope.smsValue = '';
                }else if($scope.communicationPreference[1] !== 1 && $scope.addOrDelete){
                    $scope.smsValue = '';
                }else if($scope.communicationPreference[1] !== 1 && $scope.selected){
                    if($('#contact_preference_sms').is(':checked')){
                        $scope.smsValue ='SMS';
                    }else{
                        $scope.smsValue = '';
                    }
                }
            }
        });
    });

    $scope.checkValidNumber = true;
    $scope.$on('GetErrorMessage', function (event, data) {
        $scope.checkValidNumber = data;
    });
}

function CustomerNewContactsCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, countries,$location) {
    $scope.communicationPreference = getIdData.servicereminderpreference;
    PreferenceCtrl.call(this, $scope);
    countries.setNumberTypes(getIdData['numberTypes']);
    $scope.customerSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.customerDetails = getIdData;
    $scope.customerType = $scope.customerDetails.customerType;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.saving = false;
    $scope.show_date_picker = false;
    $scope.selectedDate = '';
    $scope.selectedTab = "Contacts";
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.canAddSMSPreference = false;

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

  $scope.formSubmit = function (formElem) {
    $http.post($scope.actionVal, formElem.serialize()).
      success(function (data, status) {
        $rootScope.flashMessage = "Contact Saved";
        // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
          $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/contacts/view')
        // }
        // else{
        //   $scope.customerContacts($scope.selectedId, $scope.customerTabName);
        // }
      });
  }
}

function CustomerDashboardCtrl($scope, $http, $state, prefix, datasets, $rootScope, shortcutsContainer) {
  RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);

    $http.get(prefix + '/get_customer_object').success(function (data) {
        shortcutsContainer.setData( data.customerObject);
    })
}

function servicePrefStrCtrl($scope, str) {
    if (str != null) {
        var preferenceString = str.split(',');
        for (var i = 0; i < preferenceString.length; i++) {
            if (preferenceString[i] == 'PhoneCall') {
                $scope.phoneCallValue = preferenceString[i];
            } else if (preferenceString[i] == 'SMS') {
                $scope.smsValue = preferenceString[i];
            } else if (preferenceString[i] == 'Email') {
                $scope.emailValue = preferenceString[i];
            } else if (preferenceString[i] == 'Letter') {
                $scope.letterValue = preferenceString[i];
            }
        }
    }
}

function CustomerEditContactsCtrl($scope, $state, $http, prefix, getIdData, $rootScope, countries, warningModal,$location) {
    PreferenceCtrl.call(this, $scope);
    countries.setNumberTypes(getIdData.numberTypes);
    $scope.customerSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.contactsDetails = getIdData.contacts;
    $scope.communicationPreference = getIdData.communicationPreference;
    servicePrefStrCtrl.call(this, $scope, $scope.contactsDetails.servicereminderpreference);
    $scope.customerDetails = getIdData.navBarDetails;
    $scope.customerId = $scope.customerDetails.id;
    $scope.branchesDetail = getIdData.branchesDetails;
    $scope.branchAllow = getIdData.contacts.branchAllow;
    $scope.workAddressContact = getIdData.contacts.workAddressContact;
    $scope.loginAccess = getIdData.loginAccess;
    $scope.countries = [];
    $scope.numbers_collection = $scope.contactsDetails.numbers_collection;
    $scope.countries = countries.getCountries();
    $scope.canAddSMSPreference = false;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.associatedToSR = getIdData.associatedToSR;
    $scope.titlesList = getIdData.contacts.titleLists;
    $scope.title = (getIdData.contacts.title != null) ? getIdData.contacts.title : 1;

    for (var n = 0; n < $scope.numbers_collection.length; n++) {
        if ($scope.numbers_collection[n].hasOwnProperty('type_id') && $scope.numbers_collection[n].type_id == 1) { // 1 ->mobile
            $scope.canAddSMSPreference = true;
        }

        for (var i = 0; i < $scope.countries.length; i++) {
            if (parseInt($scope.countries[i].code) === $scope.numbers_collection[n].code) {
                $scope.numbers_collection[n].image_id = $scope.countries[i].code;
            }
        }
    }
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.selectedTab = "Contacts";


    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);


    $scope.contactId = $scope.contactsDetails.id;
    $scope.contactName = $scope.contactsDetails.name;
    $scope.customerName = $scope.contactsDetails.customerName;
    $scope.contactSurname = $scope.contactsDetails.surname;
    $scope.mainContact = $scope.contactsDetails.maincontact;
    $scope.customerMainContact = 0;
    if($scope.mainContact == true){
        $scope.customerMainContact = 1;
    }
    $scope.show_date_picker = false;
    if ($scope.contactsDetails.birthdaydate != null) {
        $scope.selectedDate = moment($scope.contactsDetails.birthdaydate.date);
    } else {
        $scope.selectedDate = '';
    }
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.emailaddress = $scope.contactsDetails.emailaddress;
    $scope.twitterhandle = $scope.contactsDetails.twitterhandle;
    $scope.facebookurl = $scope.contactsDetails.facebookurl;
    $scope.linkedinurl = $scope.contactsDetails.linkedinurl;
    $scope.branchOfficesid = $scope.contactsDetails.branchOfficesid;
    $scope.position = $scope.contactsDetails.position;
    $scope.loginAccessRole = $scope.contactsDetails.loginAccessRole;
    $scope.show_date_picker = false;
    $scope.customerIds = '';
    $scope.contactIds = '';
    $http.get(prefix + '/get_customer_contacts?id=' + $scope.customerId).success(function (data) {
        $scope.contactList = data.contactList;
        $scope.customers = data.customers;
    })
    $scope.selectToMerge = function (contactIds) {
        $scope.contactIds = contactIds;
    }
    //Transfer contact one customer to another customer

    $scope.triggerTransfer = function (id, contactSurname, mergeId, customerId) {
        $scope.mergeandtransferfunction = '/transfer_contact';
        $scope.selectedContent = true;
        $scope.customerId = customerId;
        $scope.selectedId = id;
        $scope.mergeId = mergeId;
        $scope.titleName = 'Transfer Contact';
        $scope.description = contactSurname;
        $scope.buttonValue = 'Confirm Transfer Contact';
        $scope.textToConfirm = 'TRANSFER';
        $scope.validationMessage = "'TRANSFER' to Transfer this Contact";
        $http.get(prefix + '/Checktransfercustomercontacts/'+ $scope.selectedId + '/' + $scope.customerId).success(function (data){
            if(data.warning == true){
                warningModal.show(data.message, data.title, data.id);
            }else{
                if ($scope.mergeId != '') {
                    $scope.shouldBeOpen = true;
                } else {
                    $scope.shouldBeOpen = false;
                }
            }
        })
    }


    //Merge contact permanently (you cannot undo this) with another contact at the same customer.

    $scope.triggerMerge = function (contactId, contactSurname, contactIds, customerId, contactlist) {
        $scope.mergeandtransferfunction = '/customer_merge_contact';
        $scope.contactdetails = contactlist;
        $scope.selectedContent = false;
        $scope.customerId = customerId;
        $scope.selectedId = contactId;
        $scope.mergeId = contactIds;
        $scope.titleName = 'Merge contact';
        $scope.description = contactSurname;
        $scope.buttonValue = 'Confirm Merge Contact';
        $scope.textToConfirm = 'MERGE';
        $scope.validationMessage = "'MERGE' to Merge this Contact";
        if ($scope.contactIds != '' && $scope.mergeId != $scope.selectedId) {
            $scope.shouldBeOpen = true;
        } else {
            $scope.shouldBeOpen = false;
        }
    }

    $scope.editContact = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        $scope.updating = true;
        $scope.current = this;
        if (this.selectedDate == '') {
            var editDate = '';
        } else {
            var editDate = moment(this.selectedDate).format("YYYY-MM-DD");
        }
        $scope.pereferenceCollection = [this.phoneCallValue,this.smsValue,this.emailValue,this.letterValue];

        if(($scope.associatedToSR == true) && (this.phoneCallValue == undefined || this.phoneCallValue == '') && (this.smsValue == 'SMS') && (this.emailValue == undefined || this.emailValue == '') && (this.letterValue == undefined || this.letterValue == '')) {
            warningModal.show('This contact has been chosen to receive the service reminder communication. Please select at least one communication preference excluding SMS, or edit the contact chosen for service reminders', 'Edit contact', 'edit_contact');
            $scope.updating = false;
            return;
        }

    $http.post(prefix + '/edit_customer_contact', "contact[contactId]=" + $scope.contactId
      + "&contact[settingsTitlesid]=" + encodeURIComponent(this.title)
      + "&contact[name]=" + encodeURIComponent(this.contactName)
      + "&contact[surname]=" + encodeURIComponent(this.contactSurname)
      + "&contact[emailaddress]=" + encodeURIComponent(this.emailaddress)
      + "&contact[contactstelephone]=" + encodeURIComponent(angular.toJson($scope.numbers_collection))
      + "&contact[position]=" + encodeURIComponent(this.position)
      + "&contact[selectedDate]=" + editDate
      + "&contact[branchOfficesid]=" + this.branchOfficesid
      + "&contact[twitterhandle]=" + encodeURIComponent(this.twitterhandle)
      + "&contact[facebookurl]=" + encodeURIComponent(this.facebookurl)
      + "&contact[linkedinurl]=" + encodeURIComponent(this.linkedinurl)
      + "&contact[servicereminderpreference]=" + encodeURIComponent($scope.pereferenceCollection)
      + "&contact[settingsCustomerloginrolesid]=" + this.loginAccessRole).
      success(function (data, status) {
        if (status == 200) {
            if (data.error) {
                $scope.updating = false;
                warningModal.show(data.error, 'Warning');
            } else {
                $scope.editContactForm.$setPristine();
                $rootScope.flashMessage = data;
                // if ($rootScope.accessDetails.clientsconfig.NewPropertyView) {
                    $location.path('/customers/' + $scope.customerTabName + '/' + $scope.customerId + '/view_v2/contacts/view')
                // } else {
                //     $scope.customerContacts($scope.customerId, $scope.customerTabName);
                // }
            }
        }
      });
  }

    $scope.$on("contactTransfer", function (event, message) {
        $scope.contactId = message.contactId;
        $scope.mergeId = message.mergeId;
    });

    $scope.fetchCustomerContent = function (contactId, mergeId, titleName, customerId) {
        $scope.titleName = titleName;
        if ($scope.titleName == "Merge contact") {
            $rootScope.flashMessage = "Contact Merged";
            $state.transitionTo("loggedin.customer_list.view.edit_contact", {'id': customerId, 'contactsid': $scope.mergeId, 'type': $state.params.type || getIdData.customerTabName});
        } else if ($scope.titleName == "Transfer Contact") {
            $rootScope.flashMessage = "Contact Transferred";
            $state.transitionTo("loggedin.customer_list.view.edit_contact", {'id': $scope.mergeId, 'contactsid': $scope.contactId, 'type': $state.params.type || getIdData.customerTabName});
        }
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_customers',
            data: function (term, page) {
                return { id: $scope.customerId, 'q': term, 'excludeCustomers': $scope.customerId};
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }

    $scope.canShowTransferButton=function() {
        return ($scope.transferCustomerId == null || $scope.transferCustomerId.id == null ||
            $scope.transferCustomerId.id == '');
    }

    $scope.preference = true;
    $scope.$watchCollection('[phoneCallValue, emailValue, letterValue]', function(newValue, oldValue) {
        if (newValue != undefined && newValue != '') {
            $scope.selectedValue = newValue;
            if($scope.selectedValue[0] == 'PhoneCall' || $scope.selectedValue[1] == 'Email' || $scope.selectedValue[2] == 'Letter'){
                $scope.preference = false;
            } else {
                $scope.preference = false;
            }
        } else {
            $scope.preference = true;
        }
    });

    $scope.smsValueCheck = false;
    $scope.$watch("smsValue", function (newValue, oldValue) {
        if (newValue != undefined && newValue != '') {
            $scope.smsValue = newValue;
            if($scope.smsValue == "SMS"){
                $scope.smsValueCheck = true;
                if($scope.workAddressContact == true && $scope.phoneCallValue =='' && $scope.emailValue ==''  && $scope.letterValue =='') {
                    $scope.preference = true;
                }
            } else {
                $scope.smsValueCheck = false;
            }
        } else {
            $scope.smsValueCheck = false;
            if($scope.workAddressContact == true && $scope.phoneCallValue =='' && $scope.emailValue ==''  && $scope.letterValue =='') {
                $scope.preference = false;
            }
        }

    });
}

function EditCustomerBranchCtrl($scope, $state, $http, prefix, getIdData, $rootScope,$location) {
  PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);
  BranchMergeAndDeleteCtrl.call(this, $scope);
  PostCodeCtrl.call(this, $scope, $state, $http, prefix);
  $scope.selectedId = $state.params.id;
  $scope.customerSubNavStatic = true;
  $scope.branches = getIdData.branches;
  $scope.branchDetails = getIdData.branchDetails;
  $scope.addressline1 = getIdData.branchDetails.addressline1;
  $scope.addressline2 = getIdData.branchDetails.addressline2;
  $scope.addressline3 = getIdData.branchDetails.addressline3;
  $scope.town = getIdData.branchDetails.town;
  $scope.county = getIdData.branchDetails.county;
  $scope.postcode = getIdData.branchDetails.postcode;
  $scope.showPostCodeLookUp = getIdData.showPostCodeLookUp;

  $scope.countyLists = getIdData.countyRecords;
  $scope.countyId = getIdData.branchDetails.countyId;

  if (getIdData.customerDetails.companyname != null) {
    $scope.customerName = getIdData.customerDetails.companyname;
  } else {
    $scope.customerName = getIdData.customerDetails.name + ' ' + getIdData.customerDetails.surname;
  }

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "Branches";
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);

    $scope.editBranch = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
    $scope.saving = true;
    $scope.current = this;
    var county = _.findWhere($scope.countyLists,{'id':parseInt(this.countyId)});

    $http.post(prefix + '/update_customerbranch', "branch[selectedId]=" + this.selectedId
      + "&branch[branchId]=" + encodeURIComponent(this.branchDetails.branchId)
      + "&branch[branchname]=" + encodeURIComponent(this.branchDetails.branchname)
      + "&branch[addressline1]=" + encodeURIComponent(this.addressline1)
      + "&branch[addressline2]=" + encodeURIComponent(this.addressline2)
      + "&branch[addressline3]=" + encodeURIComponent(this.addressline3)
      + "&branch[town]=" + encodeURIComponent(this.town)
      + "&branch[county]=" + encodeURIComponent(county ? county.description : '')
      + "&branch[settingsstateprovinceid]=" + (this.countyId > 0 ? this.countyId : '')
      + "&branch[postcode]=" + encodeURIComponent(this.postcode)).
      success(function (data, status) {
        if (status == 200) {
          $scope.selectedId = data.propertiesid;
          $rootScope.flashMessage = "Branch Updated";
            // if($rootScope.accessDetails.clientsconfig.NewPropertyView) {
                $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/branches/view')
            // }else{
            //     $scope.newBranches($scope.selectedId, $scope.customerTabName);
            // }
        }
      });
  }


  $scope.backTo = function () {
      // if($rootScope.accessDetails.clientsconfig.NewPropertyView) {
          $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/branches/view')
      // }else{
      //     $scope.newBranches($scope.selectedId, $scope.customerTabName);
      // }
  }

  $scope.fetchBranch = function (selectedId, mergeId, titleName) {
    $scope.titleName = titleName;
    if ($scope.titleName == "Merge branch") {
      $rootScope.flashMessage = "Branch Merged";
        $scope.newBranches($scope.selectedId, $scope.customerTabName);
    } else if ($scope.titleName == "Disable Branch") {
      $rootScope.flashMessage = "Branch Disabled";
      $scope.newBranches($scope.selectedId, $scope.customerTabName);
    }

  }
}

function CustomerWorkAddressListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, canLoad, $q, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Work Addresses";
    $scope.customerSubNavStatic = false;
    $scope.workAddress = getIdData.workAddress;
    $scope.workAddressCount = getIdData.count;
    $scope.canWork = getIdData.canWork;
    $scope.$emit('tabCustomer:selected', getIdData.customerNav);

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerWorkAddress", function (event, action, data) {
        var myObject = eval('(' + data + ')');
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("WorkAddressPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'WorkAddress List';
            $scope.excelRouteName = 'downloadworkaddress';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'WorkAddress List';
            $scope.pdfRouteName = 'printworkaddress';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$watch('searchText', function (newVal, oldVal) {
        if ((newVal != undefined) && (newVal != oldVal)) {
            $scope.fetchWork($scope.currentPage);
        }
    });
    $scope.workAddressTab = 1; // Active
    $scope.quickFilter = function quickFilter(tab) {
        $scope.workAddressTab = tab;
        $scope.fetchWork($scope.currentPage);
    }
    var canceler = null;
    $scope.fetchWork = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/work/view?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText)+ '&selectedTab=' + $scope.workAddressTab, {timeout: canceler.promise}).success(function (data) {
            $scope.workAddress = data.workAddress;
            $scope.workAddressCount = data.count;
            $scope.$emit('tabCustomer:selected', data.customerNav);

            if (searchText != '') {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }
}

function CreateNewWorkAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope, $timeout,ProvinceService,$location) {
    $scope.screenType ='newProperty';
    GetStateProvinceCtrl.call(this,$scope,$rootScope,ProvinceService,$timeout);
    PostCodeCtrl.call(this, $scope, $state, $http, prefix);
  PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);
  $scope.selectedId = $state.params.id;
  $scope.customerSubNavStatic = true;
  $scope.selectedTab = "Work.Addresses";

    $scope.$emit('tabCustomer:selected', getIdData);

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.close = function () {
        $scope.shouldBeOpen = false;
    }

    $scope.workReload = function (id, text) {
        $timeout(function () {
            $scope.landlordsparentpropertiesid = {id:id, text:text};
        }, 100);
  }

  $scope.triggerAddLandlord = function () {
    $rootScope.shouldBeOpenLord = true;
  }

    GetAllLanlordsCtrl.call(this, $scope, $state, $http, prefix);

    $scope.searchLandPostcode = function () {
        if ($scope.postcode != '') {
            $http.get(prefix + '/search_postcode?postcode=' + $scope.postcode).success(function (data) {
                $scope.addressArray = data.address;
                $scope.error = data.error;
                $scope.errorMessage = data.errorMessage;
                $scope.shouldBeOpen = true;
            })
        }
    }

    $scope.cancel = function(){
        $state.transitionTo('loggedin.customer_list.view.workaddresses', {'id': $scope.selectedId, 'type': $scope.customerTabName});
    }

    $scope.shouldBeOpenDuplicate = false;
    $scope.customers = [];

    $scope.formSubmit = function (formElem) {
        $scope.formElem = formElem;
        if($scope.postcode != '') {
            $scope.checkDuplicateCustomers('work_address');
        }
        else {
            $scope.submitForm();
        }
    }
    $scope.submitForm = function submitForm() {
        $http.post($scope.actionVal,  $scope.formElem.serialize()).
            success(function (data, status) {
                $scope.newWorkForm.$setPristine();
                $scope.selectedId = data.parentPropertiesId;
                $scope.workAddressId = data.workAddressId;
                // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
                  $location.path('/customers/'+$scope.customerMode+'/'+$scope.selectedId+'/view_v2/work/view')
                // }
                // else{
                //   $state.transitionTo("loggedin.customer_list.view.workaddresses", { 'id': $scope.selectedId, 'type': $scope.customerMode });
                // }
                $rootScope.flashMessage = "Work Address Saved";
                $scope.saving = false;
            })
    }

    PostCodeDuplicateCustomersCtrl.call(this, $scope, $state, $http, prefix);
}

function CustomerBulkWorkAddressCtrl($scope, $stateParams, $location, $http, $state, datasets, prefix, $modal, getIdData, warningModal, $translate) {

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Work.Address';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.columns = [
        {name: 'Branch', isinuse: false},
        {name: 'Landlord Title', isinuse: false},
        {name: 'Landlord Name', isinuse: false},
        {name: 'Landlord Surname', isinuse: false},
        {name: 'Landlord Company Name', isinuse: false},
        {name: 'Landlord Address Line1', isinuse: false},
        {name: 'Landlord Address Line2', isinuse: false},
        {name: 'Landlord Address Line3', isinuse: false},
        {name: 'Landlord Postcode', isinuse: false},
        {name: 'Landlord Town', isinuse: false},
        {name: 'Landlord County', isinuse: false},
        {name: 'Title', isinuse: false},
        {name: 'Name', isinuse: false},
        {name: 'Surname', isinuse: false},
        {name: 'Company Name', isinuse: false},
        {name: 'Telephone', isinuse: false},
        {name: 'Mobile', isinuse: false},
        {name: 'Email', isinuse: false},
        {name: 'Postcode', isinuse: false},
        {name: 'Address Line 1', isinuse: false},
        {name: 'Address Line 2', isinuse: false},
        {name: 'Address Line 3', isinuse: false},
        {name: 'Town', isinuse: false},
        {name: 'County', isinuse: false},
        {name: 'Service Reminder Name1', isinuse: false},
        {name: 'Service Reminder Date1', isinuse: false},
        {name: 'Service Reminder Name2', isinuse: false},
        {name: 'Service Reminder Date2', isinuse: false},
        {name: 'Notes', isinuse: false},
        {name: 'UPRN', isinuse: false},
        {name: 'Job Description', isinuse: false},
        {name: 'Notes for Engineer', isinuse: false},
        {name: 'Customer Reference', isinuse: false},
        {name: 'Is service', isinuse: false},
        {name: 'Type of service', isinuse: false},
        {name: 'Priority', isinuse: false},
        {name: 'Access Communication', isinuse: false}
    ];

    HandleCsvImports.call(this, $scope, $modal, warningModal, $translate);

    $scope.fetchPreviousRecords = function () {
        $http.get(prefix + '/getImportWorkaddress' + '?parentPropertyId=' + $scope.selectedId).success(function (data) {
            $scope.previousRecords = data.pastRecords;
        });
    }
    $scope.fetchPreviousRecords();

    $scope.triggerDeleteReactivate = function (selectedId, action) {
        $scope.deleteSelectedId = selectedId;
        if (action == "Delete") {
            $scope.shouldBeOpenDelete = true;
        } else {
            $scope.shouldBeOpenReactivate = true;
        }
    }

    $scope.submitForm = function () {
        $scope.csvProps.csvImportIsSaving = true;
        $scope.csvProps.onSave = false;
        $scope.csvProps.onSubmit = false;
        $http.post(prefix + '/import_records_from_csv/work_address', $.param({ flds: $scope.columnHeaders, csvfile: $scope.uploadedFile, containsHeader: $scope.containsHeader, parentPropertyId: $scope.selectedId})).
            success(function (data) {
                $scope.csvProps.onSave = true;
                $scope.csvProps.onSubmit = true;
                $scope.csvProps.csvImportHasSaved = true;
                $scope.csvProps.csvImportIsSaving = false;
                $scope.fetchPreviousRecords();
            });
    }
}

function CustomerInvoiceAddressCtrl($scope, $http, $state, datasets, prefix, getIdData, defaultPagingValue, canLoad, $q, $rootScope, warningModal) {

    $scope.selectedId = $state.params.id;
    $scope.landlordId = getIdData.landlordId;
    $scope.selectedTab = 'Invoice.Address';
    $scope.invoiceAddress = getIdData.invoiceResults;
    $scope.invoiceAddressCount = getIdData.count;
    $scope.enableWorkAddress = getIdData.workaddressSetting;
    $scope.enableLandlord = getIdData.landlordSetting;
    $scope.showLandlord = getIdData.showLandlord;
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.AIAccountSetup = getIdData.AIAccountSetup;
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    var canceler = null;
    $scope.fetchInvoiceAddress = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/invoice_addresses/view?page=' + pageNum + '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            $scope.invoiceAddress = data.invoiceResults;
            $scope.invoiceAddressCount = data.count;
            $scope.enableWorkAddress = getIdData.workaddressSetting;
            $scope.enableLandlord = getIdData.landlordSetting;

        })
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.getEnableInvoiceAddressValidation = function getEnableInvoiceAddressValidation(screen) {
      $scope.enableScreen = screen;
        $http.get(prefix + '/check_enable_invoiceAddress/' + $scope.selectedId + '/' + $scope.enableScreen).success(function (data) {
            $scope.enableInvoiceAddress = data;
                if($scope.enableScreen == "landlord"){
                    if ($scope.enableInvoiceAddress['showWarning'] && $scope.enableInvoiceAddress['landLordDisable'] == true) {
                        warningModal.show($scope.enableInvoiceAddress.message, 'Warning', "CONFIRM_" + Date.now());
                    } else {
                        $state.transitionTo("loggedin.customer_list.view.enable_landlord_invoice_address", {
                            'id': $scope.selectedId,
                            'type': $scope.customerMode
                        });
                    }

                }else if($scope.enableScreen == "workaddress"){
                    if ($scope.enableInvoiceAddress['showWarning'] && $scope.enableInvoiceAddress['workAddressDisable'] == true) {
                        warningModal.show($scope.enableInvoiceAddress.message, 'Warning', "CONFIRM_" + Date.now());
                    } else {
                        $state.transitionTo("loggedin.customer_list.view.enable_workaddress_invoice_address", {
                            'id': $scope.selectedId,
                            'type': $scope.customerMode
                        });
                    }
                }
        })
    };
}

function CustomerInvoiceAddressSearchCtrl($scope, $http, $state, datasets, prefix, getIdData, defaultPagingValue, canLoad, $q, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Invoice.Address';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.disable_add_new_invoice_address_button = true;
    $scope.loading = false;
    $scope.searchText = '';

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabRouteCustomer:selected', $scope.customerTabName);

    $scope.handleSearch = function handleSearch() {
        if (this.searchText.length > 2) {
            $scope.currentPage = 1;
            $scope.fetchSearchInvoiceAddress($scope.currentPage);
        }
        else if(this.searchText.length <= 2){
            $scope.invoiceAddress = '';
            $scope.disable_add_new_invoice_address_button = true;
        }
    }

    $scope.enableButton = function (val) {
        $scope.searchDisable = false;
        $scope.select_invoice = val;
    }

    $scope.submitValue = function (selected_invoice) {
        $scope.select_invoice = selected_invoice;

        if ($scope.select_invoice) {
            $http.post(prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/invoice_addresses/search_invoice_address', "invoiceaddress[select_invoice]=" + $scope.select_invoice).
            success(function (data, status) {
                if (status == 200) {

                    $scope.selectedId = data.id;
                    $rootScope.flashMessage = data.message;
                    $scope.viewInvoiceAddress($scope.selectedId, $scope.customerMode);
                }
            });
        }
    }

    var canceler = null;
    $scope.fetchSearchInvoiceAddress = function (pageNum) {
        $scope.disable_add_new_invoice_address_button = true;
        $scope.loading = true;

        $scope.searchDisable = false;
        $scope.select_invoice = '';

        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        $scope.invoiceAddress = '';
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/invoice_addresses/search_invoice_address?page=' + pageNum+ '&limit=' + $scope.limit +'&searchText=' + encodeURIComponent(searchText), {timeout: canceler.promise}).success(function (data) {
            $scope.invoiceAddressCount = data.count;
            $scope.loading = false;
            if(data.invoiceResults) {
                $scope.invoiceAddress = data.invoiceResults;
            } else {
                $scope.invoiceAddress = '';
            }
            if ($scope.searchText.length > 0) {
                $scope.disable_add_new_invoice_address_button = false;
            }
        })
    }
}

function CreateNewInvoiceAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope,ProvinceService) {
  //onchange of invoice address third party reference enabled save button
  $scope.enableCustomerAccountingReference = function () {
    $scope.customerSaveButtonDisabled = false;
    $scope.validateReference          = false
  }
    $scope.selectedId = $state.params.id;
    $scope.customerSubNavStatic = true;
    $scope.selectedTab = "Invoice.Address";

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.screenType ='newProperty';
    PostCodeCtrl.call(this, $scope, $state, $http, prefix);
  PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);

    ProvinceService.getProvinces().then(function(data){
      $scope.countyLists = data;
    });

  $scope.formSubmit = function (formElem) {
    $http.post($scope.actionVal, formElem.serialize()).
      success(function (data, status) {
        if (data.error && data.error == 'Invalid third party accounting reference') {
            $scope.validateReference          = true;
            $scope.saving                     = false;
            $scope.customerSaveButtonDisabled = true;
        } else {
          $scope.validateReference = false;
          if (status == 200) {
            $scope.newInvoiceForm.$setPristine();
            $scope.saving = false;
            $scope.selectedId = data.propertiesId;
            $rootScope.flashMessage = data.message;
            $scope.viewInvoiceAddress($scope.selectedId, $scope.customerMode);
          }
        }
      })
  }
}

function EnableLandlordInvoiceAddressCtrl($scope, $http, $state, datasets, prefix, getIdData, defaultPagingValue, $rootScope) {

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Invoice.Address';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.enableInvoiceLandlordForm.$setPristine();
            $scope.saving = false;
            $scope.selectedId = data.propertiesId;
            $rootScope.flashMessage = data.message;
            $scope.viewInvoiceAddress($scope.selectedId, $scope.customerMode);
        })
    }
}

function EnableWorkaddressInvoiceAddressCtrl($scope, $http, $state, datasets, prefix, getIdData, defaultPagingValue, $rootScope) {

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Invoice.Address';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.enableInvoiceWorkaddressForm.$setPristine();
            $scope.saving = false;
            $scope.selectedId = data.propertiesId;
            $rootScope.flashMessage = data.message;
            $scope.viewInvoiceAddress($scope.selectedId, $scope.customerMode);
        })
    }
}

function CustomerRemindersListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope, warningModal) {
    $scope.selectedId = $scope.customerSelectedId = $state.params.id;
    $scope.selectedTab = "Reminders";
    $scope.customerSubNavStatic = false;
    $scope.reminders = getIdData.reminders;
    $scope.remindersCount = getIdData.remindersCount;
    $scope.serviceReminders = getIdData.serviceReminders;
    $scope.serviceRemindersCount = getIdData.serviceRemindersCount;
    $scope.automaticStatement = getIdData.automaticStatement;
    $scope.automaticDebtChasing = getIdData.automaticDebtChasing;
    $scope.afterSalesCare = getIdData.afterSalesCare;
    $scope.canWork = getIdData.canWork;
    $scope.$emit('tabCustomer:selected', getIdData.customerNav);

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    if ($state.current.data.serviceReminderLimit) {
        $scope.serviceReminderLimit = parseInt($state.current.data.serviceReminderLimit);
    } else {
        $scope.serviceReminderLimit = defaultPagingValue;
    }

    if ($state.current.data.serviceReminderPageNum) {
        $scope.serviceReminderCurrentPage = parseInt($state.current.data.serviceReminderPageNum);
    } else {
        $scope.serviceReminderCurrentPage = 1;
    }
    $scope.deleteReminder = function (id) {
        $scope.reminderId = id;
        $scope.shouldBeOpen = true;

  }
  $scope.deleteServiceReminder = function (id, service_type) {
    $http.get(prefix + '/is_associated_to_job?reminderId=' + id).success(function(data) {
      if (data.warning === true) {
        warningModal.show(data.message, data.title, data.id);
      }  else {
        $scope.currentPage = $scope.serviceReminderCurrentPage;
        $scope.reminderId = id;
        $scope.service_type = service_type;
        $scope.contactName = $scope.tabCustomerSurname;
        $scope.shouldBeOpenServiceReminder = true;
      }
    });
  }
  $scope.fetchReminders = function (pageNum) {
    $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.customerSelectedId + '/reminders/view?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
      $scope.reminders = data.reminders;
      $scope.remindersCount = data.remindersCount;
      $scope.$emit('tabCustomer:selected', data.customerNav);
    })
  }
  $scope.fetchServiceReminders = function (pageNum) {
    $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.customerSelectedId + '/reminders/service_reminder/view?page=' + pageNum + '&limit=' + $scope.serviceReminderLimit).success(function (data) {
      $scope.serviceReminders = data.serviceReminders;
      $scope.serviceRemindersCount = data.count;
      var message = {name: 'serviceRemindersCount', value: data.count};
      $scope.$emit('event:changeTabTopCountScope', message);
    })
  }
}

function CreateNewReminderCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.customerSubNavStatic = true;
    $scope.selectedTab = "Reminders";
    $scope.displayError = false;

    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);

    var currentDate = moment();
    $scope.minDate = currentDate.add('1','day');
    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    ReminderDateCtrl.call(this, $scope, getIdData, '', '', 'reminderAdd', $state);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: {date:$scope.minDate.format('YYYY-MM-DD')}});
    });

    $scope.$watch('userDetails', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }

        }
    });


    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newReminderForm.$setPristine();
            $scope.saving = false;
            $rootScope.flashMessage = "Reminder Saved";
            $scope.viewReminders($scope.selectedId, $scope.customerTabName);
        })
    }
}

function EditReminderCtrl($scope, $state, getIdData, $http, prefix, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.customerSubNavStatic = true;
    $scope.selectedTab = "Reminders";
    $scope.displayError = false;

    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);

    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.reminderId = $state.params.reminderId;
    $scope.userDetails = getIdData.selectedUsers;


    angular.forEach(getIdData.reminderDetails, function (key, val) {
        $scope.editReminderNote = key.remindernote;
        $scope.reminderDate = key.reminderdate;
    });
    ReminderDateCtrl.call(this, $scope, getIdData, '', $scope.reminderDate, 'edit', $state);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$watch('userDetails', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }

        }
    });

    $scope.editReminder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.app = [];
        $scope.updating = true;
        $scope.current = this;
        if (this.userDetails != '') {
            $scope.selectedUsers = new Array(); var i=0;
            angular.forEach(this.userDetails, function (val, key) {
                $scope.selectedUsers[i] = val.id;
                i++;
            });
        }

        $http.post(prefix + '/edit_reminder', "reminder[remindernote]=" + encodeURIComponent($scope.editReminderNote)
            + "&reminder[reminderId]=" + $scope.reminderId
            + "&reminder[remindersettings][reminderusers]=" + $scope.selectedUsers
            + "&reminder[reminderdate]=" + moment(this.reminderSelectedDate, 'L').format('YYYY-MM-DD')).
        success(function (data, status) {
            if (status == 200) {
                if (data.error) {
                    $scope.errorDateMessage = true;
                } else {
                    $scope.viewReminders($scope.selectedId, $scope.customerTabName);
                    $rootScope.flashMessage = data;
                }
                $scope.updating = false;
            }
        });
    }
}

function CreateNewServiceReminderCtrl($scope, $state, $http, getIdData, $rootScope,$location) {
  $scope.selectedId = $state.params.id;
  $scope.customerSubNavStatic = true;
  $scope.selectedTab = "Reminders";
  $scope.displayError = false;
  $scope.attributesArray = getIdData.attributesArray;

    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);

    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    ReminderDateCtrl.call(this, $scope, getIdData, '', '', 'add', $state);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

  $scope.formSubmit = function (formElem) {
    $http.post($scope.actionVal, formElem.serialize()).
      success(function (data, status) {
        $scope.newServiceReminderForm.$setPristine();
        $scope.saving = false;
        $scope.selectedId = data.propertyId;
        $rootScope.flashMessage = data.successMessage;
        // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
            $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/service_reminder/view')
        // }
        // else{
        //     $scope.viewReminders($scope.selectedId, $scope.customerTabName);
        // }
      })
  }
}

function EditCustomerReminders($scope, $state, getIdData, $http, $rootScope, notificationType) {
    $scope.selectedId = $state.params.id;
    $scope.customerSubNavStatic = true;
    $scope.selectedTab = "Reminders";

    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);

    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.notificationValue = getIdData.notificationValue;

    $scope.clickedNotification = function() {

        $scope.updating = true;

        var params = { 'id': $scope.selectedId, 'type': $scope.customerTabName};
        $http.post(Routing.generate(notificationType, params), "notificationValue=" + $scope.notificationValue).
        success(function (data, status) {
            if (status == 200) {
                $scope.viewReminders($scope.selectedId, $scope.customerTabName);
                $rootScope.flashMessage = data;
                $scope.updating = false;
            }
        });
    }
}

function EditAfterSalesCareCtrl($scope, $state, getIdData, $http, $rootScope) {
    EditCustomerReminders.call(this, $scope, $state, getIdData, $http, $rootScope, 'edit_after_sales_care');
}

function EditAutomaticStatementsCtrl($scope, $state, getIdData, $http, $rootScope) {
    EditCustomerReminders.call(this, $scope, $state, getIdData, $http, $rootScope, 'edit_automatic_statements');
}

function EditAutomaticDebtChasingCtrl($scope, $state, getIdData, $http, $rootScope) {
    EditCustomerReminders.call(this, $scope, $state, getIdData, $http, $rootScope, 'edit_automatic_debtchasing');
}

function EditServiceReminderCtrl($scope, $state, getIdData, $http, prefix, $rootScope,$location) {
  $scope.selectedId = $state.params.id;
  $scope.customerSubNavStatic = true;
  $scope.selectedTab = "Reminders";
  $scope.displayError = false;

    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);

    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.reminderId = $state.params.reminderId;

    $scope.serviceTypes = getIdData.settingsServiceReminder;
    $scope.contacts = getIdData.customerAddress.contacts;

    angular.forEach(getIdData.serviceReminderDetails, function (key, val) {
        $scope.propertyServiceReminderId = key.service_type;
        $scope.dueDate = key.dueDate;
        $scope.contactId = key.contactId;
    });

    ReminderDateCtrl.call(this, $scope, getIdData, '', $scope.dueDate, 'edit', $state);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.editServiceReminder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.app = [];
        $scope.updating = true;
        $scope.current = this;

    $http.post(prefix + '/save_service_reminder', "servicereminder[propertyservicereminderid]=" + encodeURIComponent($scope.propertyServiceReminderId)
      + "&servicereminder[reminderId]=" + $scope.reminderId
      + "&servicereminder[contactid]=" + $scope.contactId
      + "&servicereminder[duedate]=" + moment(this.reminderSelectedDate, 'L').format('YYYY-MM-DD')).
      success(function (data, status) {
        if (status == 200) {
          if (data.error) {
            $scope.errorDateMessage = true;
          } else {
              // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
                  $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/service_reminder/view')
              // }else{
              //     $scope.viewReminders($scope.selectedId, $scope.customerTabName);
              // }
            $rootScope.flashMessage = data;
          }
          $scope.updating = false;
        }
      });
  }
}

function NewCustomerCtrl($scope, $stateParams, $http, $state, prefix, datasets, $rootScope, $timeout,ProvinceService) {
  RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.screenType ='newProperty';
    GetStateProvinceCtrl.call(this,$scope,$rootScope,ProvinceService,$timeout);
  PostCodeCtrl.call(this, $scope, $state, $http, prefix);
  PostcodeSearchCtrl.call(this, $scope, $http, prefix, $rootScope);

    $scope.shouldBeOpenDuplicate = false;
    $scope.customers = [];

    $scope.formSubmit = function (formElem) {
        $scope.formElem = formElem;
        if($scope.postcode != '') {
            $scope.checkDuplicateCustomers('customer');
        }
        else {
            $scope.submitForm();
        }
    }

  $scope.submitForm = function submitForm() {
      $http.post($scope.actionVal, $scope.formElem.serialize()).
          success(function (data, status) {
              $scope.newCustomerForm.$setPristine();
              $scope.customerId = data;
              $scope.customerMode = 'customer_list';
              $state.transitionTo("loggedin.customer_list.view.property", {
                  'id': $scope.customerId,
                  'type': $scope.customerMode
              });
              $rootScope.flashMessage = "Customer.Saved";
              $scope.saving = false;
          })
  }
    PostCodeDuplicateCustomersCtrl.call(this, $scope, $state, $http, prefix);
    GetAdvertisingTypeCtrl.call(this, $scope, $state, $http, prefix);
}
function GetStateProvinceCtrl($scope,$rootScope,ProvinceService,$timeout){
    $scope.countyChange = function (settingsstateprovinceid) {
        if(typeof settingsstateprovinceid === 'object')
        {
            $timeout(function () {
                $scope.county = settingsstateprovinceid.description;
                $('select[ng-model="settingsstateprovinceid"]').val(settingsstateprovinceid.id).trigger('change');
            },100);
        }
    }
    ProvinceService.getProvinces().then(function(data){
        $scope.countyLists = data;
    });
    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        var newCounty = {'id' : data.id,'description' : data.description};
        $scope.countyLists.push(newCounty);
        $scope.countyChange(newCounty);
    });
}

//fetch advertising types
function GetAdvertisingTypeCtrl($scope, $state, $http, prefix,preselect) {
    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_advertising_type',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            if(preselect){
                var data = {
                    id: preselect[0],
                    text: preselect[1]
                };
                callback(data);
            }else{
                callback($(element).data('$ngModelController').$modelValue);
            }
        }
    }
}
function PostCodeDuplicateCustomersCtrl($scope, $state, $http, prefix) {
    $scope.checkDuplicateCustomers = function checkDuplicateCustomers(mode) {
        $http.post(prefix + '/postcode_match_properties', "postcode=" + encodeURIComponent($scope.postcode) +'&mode=' + mode).
        success(function (data, status) {
            if (status == 200 && data.customersCount > 0) {
                $scope.customersCount = data.customersCount;
                $scope.customers = data.customers;
                $scope.shouldBeOpenDuplicate = true;
                $scope.saving = false;
            }
            else {
                $scope.saving = true;
                $scope.submitForm();
            }
        });
    }

    $scope.$on("postcode:matched_property", function (event, customerId) {
        if(customerId != undefined) {
            $state.transitionTo("loggedin.customer_list.view.property", {'id': customerId, 'type': 'customer_list'});
            $scope.saving = false;
        }
        else {
            $scope.saving = true;
            $scope.submitForm();
        }
    });
}

function NewCompanyCtrl($scope, $stateParams, $http, $state, prefix, datasets, $rootScope, $timeout,ProvinceService) {
  RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.screenType ='newProperty';
    GetStateProvinceCtrl.call(this,$scope,$rootScope,ProvinceService,$timeout);
    PostCodeCtrl.call(this, $scope, $state, $http, prefix);

    $scope.formSubmit = function (formElem) {
        $scope.formElem = formElem;
        if($scope.postcode != '') {
            $scope.checkDuplicateCustomers('customer');
        }
        else {
            $scope.submitForm();
        }
    }

    $scope.submitForm = function submitForm() {
        $http.post($scope.actionVal, $scope.formElem.serialize()).
        success(function (data, status) {
            $scope.newCompanyForm.$setPristine();
            $scope.customerId = data;
            $scope.customerMode = 'customer_list';
            $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.customerId, 'type': $scope.customerMode });
            $rootScope.flashMessage = "Company.Saved";
            $scope.saving = false;
        })
    }

    PostCodeDuplicateCustomersCtrl.call(this, $scope, $state, $http, prefix);

    $scope.$watchCollection('[name, landlinenumber, mobilenumber, contactsoptionalemail, surname, position]', function (newVal, oldVal) {
        if ($scope.surname) {
            $scope.companyOptionalName = false;
        } else {
            if ($scope.landlinenumber || $scope.mobilenumber || $scope.contactsoptionalemail || $scope.name || $scope.position) {
                $scope.companyOptionalName = true;
            } else {
                $scope.companyOptionalName = false;
            }
        }
    });
    GetAdvertisingTypeCtrl.call(this, $scope, $state, $http, prefix);
}

function CustomerApplianceViewCtrl($scope, getIdData, tableCollection, $document, formPanelCollection, $state){
    tableCollection.setData('appliances', getIdData.appliances);
    formPanelCollection.setFilterData(getIdData.Appliancegroup);
    $scope.technicalReferences = getIdData.technicalReferences;
    $scope.fuelTypeList = getIdData.fuelTypes;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customerSubNavStatic = false;
    $scope.selectedTab = "Appliances";
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    // Any Local filter change, send to sidepanel so that the filters from the sidepanel can be aggregrated before its
    // sent to smart table fetchrecords.
    $scope.$watch('selectedFuelType', function (newVal, oldVal) {
        if (newVal == '' || newVal != undefined) {
            $scope.selectedFuelType = '';
        }

        $scope.selectedFuelType = newVal;

        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'fuelTypeId',
                'value': $scope.selectedFuelType
            }
        );

        $scope.countActiveFilters();
    });

    // This is required because the pagination sends data here, which it then sends back to the panelwithform to
    // collect data from the more filters. Add all the current page filter data in this.
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'fuelTypeId',
                'value': $scope.selectedFuelType
            }
        );
    });

    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:appliances', function(evt, data) {
        $scope.filterData = {};

        angular.forEach(data, function(val, key) {
            $scope.filterData[val.model_name] = val.value;
        });

        //Store the filters from the main page
        if ($scope.selectedFuelType) {
            $scope.filterData['fuelTypeId'] = $scope.selectedFuelType;
        }

        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.countActiveFilters();
    });



    /* ==============================================================================================================
    Clear the filters on the page and broadcast to the smart table and more-filters sidepanel
  ============================================================================================================== */
    $scope.clearFilters = function() {
        $scope.selectedFuelType = undefined;
        $scope.filterData = {};

        $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.countActiveFilters();
    }

    $scope.countActiveFilters = function() {
        $scope.show_clear_filter_link = false;
        $scope.number_of_filters = 0;

        for (var key in $scope.filterData) {
            var value = $scope.filterData[key],
                value_exists = value &&
                    value !== "all" &&
                    value.toString().length > 0

            if (value_exists) {
                $scope.show_clear_filter_link = true;
                $scope.number_of_filters ++;
            }
        }
    }
}

function CustomerApplianceAddCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, tableCollection, formPanelCollection, $location) {
  $scope.customerSubNavStatic = true;
  $scope.selectedId = $state.params.id;

    formPanelCollection.setMoreFilterData('new_appliance_make', getIdData.new_appliance_make);
    formPanelCollection.setMoreFilterData('new_appliance_model', getIdData.new_appliance_model);
    $scope.installedOnDate = '';
    $scope.commissionedOn = '';
    $scope.warrantyExpiresOnDate = '';
    $scope.warrantyStartOnDate = '';
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customerDetails = getIdData.customerDetails;
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.addburner = false;
    $scope.burners = [];
    $scope.add_burner = function(index){
        $scope.addburner = true;
        if($scope.burners.length < index + 1){
            $scope.burners.push({index:index+1, events:[{id:1},{id:2}], burnerTypeVal:''});
        }
    }
    $scope.burnerTypeValue = [];
    $scope.validateBurnerType = function validateBurnerType(val, index){
        if(val == null){
            $scope.burnerTypeValue[index] = true;
        }else {
            $scope.burnerTypeValue[index] = false;
        }
    }

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

  $scope.cancel = function(){
      // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
          $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/appliances/view');
      // }else{
      //     $state.transitionTo('loggedin.customer_list.view.appliances', {'id': $scope.selectedId, 'type': $scope.customerTabName});
      // }
  }
  $scope.applianceType = '';
  $scope.fuelType = '';
  $scope.makeVal = '';
  $scope.changeApplianceGroupType = function(id, modelName, entityName, filterTypeField){
    if(filterTypeField == 'settingsAppliancetype'){
      $scope.settingsAppliancetypesid = id;
      $scope.applianceType = "&settingsAppliancetypesid=" + $scope.settingsAppliancetypesid;
      $scope.fuelType = '';
      $scope.makeVal = '';
      $scope.selectedFuelType = '';
      $scope.settingsFuelTypes = [];
      $scope.settingsMakesid = '';
      $scope.settingsMakes = [];
      $scope.settingsModelsid = '';
      $scope.settingsModels = [];
    }else if(filterTypeField == 'settingsFuelType'){
      $scope.settingsFuelTypesid = id;
      $scope.fuelType = "&settingsFuelTypesid=" + $scope.settingsFuelTypesid;
      $scope.makeVal = '';
      $scope.settingsMakesid = '';
      $scope.settingsMakes = [];
      $scope.settingsModelsid = '';
      $scope.settingsModels = [];
    }else if(filterTypeField == 'settingsMakes'){
      $scope.settingsModelsid = '';
      $scope.settingsModels = [];
      $scope.settingsMakesid = id;
      $scope.makeVal = "&settingsMakesid=" + $scope.settingsMakesid;
    }
    $http.post(prefix + '/listApplianceGroupTypeValue', "modelName=" + modelName + "&entityName=" + entityName + $scope.applianceType + $scope.fuelType + $scope.makeVal ).success(function (data) {
      $scope[modelName] = data;
    });
  }

    $scope.addEvent = function(idx, burnerIndex) {
        for(var i = 0; i < $scope.burners.length; i++) {
            if($scope.burners[i].index == burnerIndex){
                if($scope.burners[i].events.length < idx + 1){
                    $scope.burners[i].events.push({id:idx+1});
                    break;
                }
            }
        }
    }

    $scope.deleteBurner = function deleteBurner(index) {
        $scope.burners.splice(index, 1);
    }

  $scope.formSubmit = function (formElem) {
      $http.post($scope.actionVal, formElem.serialize()).
          success(function (data, status) {
              tableCollection.setPageValue(true);
              // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
                  $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/appliances/view');
              // }else{
              //     $state.transitionTo('loggedin.customer_list.view.appliances', {'id': $scope.selectedId, 'type': $scope.customerTabName});
              // }
          })
  }

    $scope.interval = null;
    $scope.setWarrantyEndDate = function setWarrantyEndDate(year) {
        if(!this.warrantyStartOnDate) {
            return false;
        }
        $scope.interval = year;

        var warrentyearadd = moment(this.warrantyStartOnDate, 'L').add('years', $scope.interval);
        var warrentdaysub = moment(warrentyearadd).subtract('days', 1);

        $scope.warrantyExpiresOnDate = warrentdaysub._d;
        $scope.$broadcast('event:change_specified_date', {scopeName: 'warrantyExpiresOnDate', date: $scope.warrantyExpiresOnDate});
        $scope.validateDates();
    }

    $scope.changeDate = false;
    $scope.$watch('warrantyStartOnDate', function (newVal, oldVal) {
        $scope.validateDates();
    });
    $scope.$watch('warrantyExpiresOnDate', function (newVal, oldVal) {
        $scope.validateDates();
    });

    $scope.validateDates = function() {
        var start = moment($scope.warrantyStartOnDate),
            end = moment($scope.warrantyExpiresOnDate);

        if (moment(end).isBefore(start)) {
            $scope.changeDate = true;
        } else {
            $scope.changeDate = false;
        }
    }
}

function CustomerApplianceEditCtrl($scope, $stateParams, $http, $state, prefix, getIdData, $rootScope, formPanelCollection, $location){
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.applianceGroupName = _.keys(getIdData.appliancetypeslist);
    $scope.appliancetypeslist = getIdData.appliancetypeslist;
    $scope.settingsMakes = getIdData.editResultMakes;
    $scope.settingsFuelTypes = getIdData.editResultFuelTypes;
    $scope.settingsModels = getIdData.editResultModels;
    $scope.settingsLocations = getIdData.settingsLocation;
    $scope.customerDetails = getIdData.customerDetails;
    $scope.userDetails = getIdData.userDetails;
    $scope.sprayPatterns = getIdData.sprayPattern;

    formPanelCollection.setMoreFilterData('new_appliance_make', getIdData.new_appliance_make);
    formPanelCollection.setMoreFilterData('new_appliance_model', getIdData.new_appliance_model);
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);

    $scope.customerSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.add_burner = true;
    $scope.appliance = [];
    $scope.appliance.applianceoil = [];
    $scope.appliance.applianceoil.applianceoilburners = [];
    $scope.appliance = getIdData.appliance;
    if(getIdData.appliance.installedondate){
        $scope.appliance.installedondate = moment(getIdData.appliance.installedondate.date);
    }else{
        $scope.appliance.installedondate = '';
    }
    if(getIdData.appliance.commissionedon){
        $scope.appliance.commissionedon = moment(getIdData.appliance.commissionedon.date);
    }else{
        $scope.appliance.commissionedon = '';
    }
    if(getIdData.appliance.warrantyexpiresondate){
        $scope.appliance.warrantyexpiresondate = moment(getIdData.appliance.warrantyexpiresondate.date);
    }else{
        $scope.appliance.warrantyexpiresondate = '';
    }
    if(getIdData.appliance.warrantystartondate){
        $scope.appliance.warrantystartondate = moment(getIdData.appliance.warrantystartondate.date);
    }else{
        $scope.appliance.warrantystartondate = '';
    }

    $scope.appliance.status = getIdData.appliance.status == null ? '0' : getIdData.appliance.status;
    $scope.appliance.flue = getIdData.appliance.flue == null ? '' : getIdData.appliance.flue;
    $scope.appliance.applianceoil.fueltype = getIdData.appliance.applianceoil.fueltype == null ? '' : getIdData.appliance.applianceoil.fueltype;
    $scope.appliance.applianceoil.fluegastemperature = getIdData.appliance.applianceoil.fluegastemperature == null ? '' : getIdData.appliance.applianceoil.fluegastemperature;
    $scope.appliance.applianceoil.firevalvetype = getIdData.appliance.applianceoil.firevalvetype == null ? '' : getIdData.appliance.applianceoil.firevalvetype;
    $scope.appliance.applianceoil.oilfiltertype = getIdData.appliance.applianceoil.oilfiltertype == null ? '' : getIdData.appliance.applianceoil.oilfiltertype;

    $scope.applianceType = '';
    $scope.fuelType = '';
    $scope.makeVal = '';
    $scope.changeApplianceGroupType = function(id, modelName, entityName, filterTypeField){
        if(filterTypeField == 'settingsAppliancetype'){
            $scope.applianceType = "&settingsAppliancetypesid=" + $scope.appliance.settingsAppliancetypesid;
            $scope.fuelType = '';
            $scope.makeVal = '';
            $scope.appliance.settingsFuelTypesid = '';
            $scope.settingsFuelTypes = [];
            $scope.appliance.settingsMakesid = '';
            $scope.settingsMakes = [];
            $scope.appliance.settingsModelsid = '';
            $scope.settingsModels = [];
        }else if(filterTypeField == 'settingsFuelType'){
            $scope.applianceType = "&settingsAppliancetypesid=" + $scope.appliance.settingsAppliancetypesid;
            $scope.fuelType = "&settingsFuelTypesid=" + $scope.appliance.settingsFuelTypesid;
            $scope.makeVal = '';
            $scope.appliance.settingsMakesid = '';
            $scope.settingsMakes = [];
            $scope.appliance.settingsModelsid = '';
            $scope.settingsModels = [];
        }else if(filterTypeField == 'settingsMakes'){
            $scope.applianceType = "&settingsAppliancetypesid=" + $scope.appliance.settingsAppliancetypesid;
            $scope.fuelType = "&settingsFuelTypesid=" + $scope.appliance.settingsFuelTypesid;
            $scope.appliance.settingsModelsid = '';
            $scope.settingsModels = [];
            $scope.settingsMakesid = id;
            $scope.makeVal = "&settingsMakesid=" + $scope.settingsMakesid;
        }
        $http.post(prefix + '/listApplianceGroupTypeValue', "modelName=" + modelName + "&entityName=" + entityName + $scope.applianceType + $scope.fuelType + $scope.makeVal ).success(function (data) {
            $scope[modelName] = data;
        });
    }


    $scope.add_burner = function(index){
        $scope.addburner = true;
        if($scope.appliance.applianceoil.applianceoilburners.length < index + 1){
            $scope.appliance.applianceoil.applianceoilburners.push({index:index+1, applianceoilburnernozzles:[{id:0},{id:0}], burnerTypeVal:''});
        }
    }

    if($scope.appliance.applianceoil.applianceoilburners){
        angular.forEach($scope.appliance.applianceoil.applianceoilburners, function (key, value) {
            if(!key.applianceoilburnernozzles){
                $scope.appliance.applianceoil.applianceoilburners[value].applianceoilburnernozzles = [];
                $scope.appliance.applianceoil.applianceoilburners[value].applianceoilburnernozzles.push({id:'0'});
            }else{
                $scope.appliance.applianceoil.applianceoilburners[value].applianceoilburnernozzles.push({id:'0'});
            }
        });
    }

    $scope.burnerTypeValue = [];
    $scope.validateBurnerType = function validateBurnerType(val, index){
        if(val == null){
            $scope.burnerTypeValue[index] = true;
        }else {
            $scope.burnerTypeValue[index] = false;
        }
    }

    $scope.addEvent = function(idx, burnerIndex) {
        for(var i = 0; i < $scope.appliance.applianceoil.applianceoilburners.length; i++) {
            if($scope.appliance.applianceoil.applianceoilburners[i].id == burnerIndex){
                if($scope.appliance.applianceoil.applianceoilburners[i].applianceoilburnernozzles.length < idx + 2){
                    $scope.appliance.applianceoil.applianceoilburners[i].applianceoilburnernozzles.push({id:0});
                    break;
                }
            }
        }
    }

    $scope.deleteBurner = function(idx, burnerindex){
        for(var i = 0; i < $scope.appliance.applianceoil.applianceoilburners.length; i++) {
            if($scope.appliance.applianceoil.applianceoilburners[i].id == burnerindex){
                $scope.appliance.applianceoil.applianceoilburners.splice(i, 1);
                break;
            }
        }
    }

    $scope.editApplianceSave = function (appliance) {
        if (appliance.settingsFuelTypesid == '2') {
            appliance.applianceoil = [];
        } else if (appliance.settingsFuelTypesid == '1') {
            appliance.appliancegas = [];
        }
        $scope.saving = true;
        $scope.updateAppliance = angular.copy(appliance);
        $scope.updateAppliance.installedondate = moment(this.appliance.installedondate, 'L').format('YYYY-MM-DD');
        $scope.updateAppliance.commissionedon = moment(this.appliance.commissionedon, 'L').format('YYYY-MM-DD');
        $scope.updateAppliance.warrantyexpiresondate = moment(this.appliance.warrantyexpiresondate, 'L').format('YYYY-MM-DD');
        $scope.updateAppliance.warrantystartondate = moment(this.appliance.warrantystartondate, 'L').format('YYYY-MM-DD');

        $http.post(prefix + '/update_appliance', "customerappliance=" + encodeURIComponent(angular.toJson($scope.updateAppliance))).success(function (data) {
            $location.path('/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/view_v2/appliances/view');
        });
    };


    $scope.interval = null;
    $scope.setWarrantyEndDate = function setWarrantyEndDate(year) {
        if(!$scope.appliance.warrantystartondate) {
            return false;
        }

        $scope.interval = year;

        var warrentyearadd = moment($scope.appliance.warrantystartondate, 'L').add('years', $scope.interval);
        var warrentdaysub = moment(warrentyearadd).subtract('days', 1);

        $scope.appliance.warrantyexpiresondate = warrentdaysub._d;
        $scope.$broadcast('event:change_specified_date', {scopeName: 'appliance.warrantyexpiresondate', date: warrentdaysub});
        // if($scope.changeDate == true){$scope.changeDate = false;}
        $scope.validateDates();
    }

    // $scope.changeDate = false;
    // $scope.$watch('appliance.warrantystartondate', function (newVal, oldVal) {
    //     if ($scope.interval != null || $scope.interval != undefined) {
    //         $scope.changeDate = true;
    //     } else {
    //         $scope.changeDate = false;
    //     }});
    // $scope.$watch('appliance.warrantyexpiresondate', function (newVal, oldVal) {
    //     if($scope.changeDate == true){$scope.changeDate = false;}
    // });

    $scope.changeDate = false;
    $scope.$watch('appliance.warrantystartondate', function (newVal, oldVal) {
        $scope.validateDates();
    });
    $scope.$watch('appliance.warrantyexpiresondate', function (newVal, oldVal) {
        $scope.validateDates();
    });

    $scope.validateDates = function() {
      var start = moment($scope.appliance.warrantystartondate),
          end = moment($scope.appliance.warrantyexpiresondate);

        if (moment(end).isBefore(start)) {
            $scope.changeDate = true;
        } else {
            $scope.changeDate = false;
        }
    }


    $scope.cancel = function(){
        // if($rootScope.accessDetails.clientsconfig.NewPropertyView){
            $location.path('/customers/'+$scope.customerTabName+'/'+$scope.selectedId+'/view_v2/appliances/view');
        // }else{
        //     $state.transitionTo('loggedin.customer_list.view.appliances', {'id': $scope.selectedId, 'type': $scope.customerTabName});
        // }
    }
}

function CustomerTechReferenceCtrl($scope, $stateParams, $http, $state, prefix, getIdData, $rootScope){
    $scope.customerSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.customerLocationId = getIdData.techId || $state.params.techrefid;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.customerLocation = getIdData.description;
    $scope.editCustomerLocation = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.saving = true;
        $scope.current = this;
        $http.post(prefix + '/update_customerlocation', "customerlocation[techRefId]=" + this.customerLocationId
            + "&customerlocation[description]=" + encodeURIComponent(this.customerLocation)
            + "&customerlocation[customerid]=" + $scope.selectedId).
        success(function (data, status) {
            if (data.error) {
                $scope.editCustomerLocationForm['customerLocation'].$error.required = true;
            }else {
                $rootScope.flashMessage = data.message;
                $state.transitionTo('loggedin.customer_list.view.appliances', {'id': $scope.selectedId, 'type': $scope.customerTabName});
                $scope.saving = false;
            }
        });
    }
}

function TechReferenceCtrl($scope, getIdData, $state, $http, prefix){
    $scope.customerSubNavStatic = false;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "TechnicalReference";
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.technicalReferences = getIdData.technicalReferences;
    $scope.technicalReferenceCount = getIdData.technicalReferenceCount;

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.pageNum = parseInt($state.current.data.pageNum);
    } else {
        $scope.pageNum = 1;
    }

    var fetchUrl = prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/technical_reference/view';

    $scope.fetchTechnicalReference = function (pageNum) {
        $http.get(fetchUrl + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.technicalReferences = data.technicalReferences;
            $scope.technicalReferenceCount = data.technicalReferenceCount;
        })
    }
}

function CustomerEditTechReferenceCtrl($scope, $stateParams, $http, $state, prefix, getIdData, $rootScope){
    $scope.customerSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.customerLocationId = getIdData.techId || $state.params.techrefid;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.customerLocation = getIdData.description;
    $scope.editCustomerLocation = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.saving = true;
        $scope.current = this;
        $http.post(prefix + '/update_customerlocation', "customerlocation[techRefId]=" + this.customerLocationId
            + "&customerlocation[description]=" + encodeURIComponent(this.customerLocation)
            + "&customerlocation[customerid]=" + $scope.selectedId).
        success(function (data, status) {
            if (data.error) {
                $scope.editCustomerLocationForm['customerLocation'].$error.required = true;
            }else {
                $rootScope.flashMessage = data.message;
                $state.transitionTo('loggedin.customer_list.view.technicalreference', {'id': $scope.selectedId, 'type': $scope.customerTabName});
                $scope.saving = false;
            }
        });
    }
}

function sortableTableColumnsCtrl($scope){
    $scope.$on('smart_table:columns_loaded', function(event, table_columns) {
        $scope.sortable_items = table_columns;
    });

    $scope.sortableColumns = {
        stop: function(e, ui) {
            $scope.$emit('smart_table:order_updated');
        }
    }
}

function InvoiceAddressCtrl($scope, $state, $http, prefix, datasets, formService, $rootScope, $timeout, $window, $translate, $location) {
    $scope.data = datasets;
    $scope.showSubNavStatic = false;
    $rootScope.successMessage = '';

    $scope.$state = $state;

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        if (toState.url === '/edit' || toState.url === '/new') {
            $scope.handleFormBreadcrumbs(toState.name);
            $scope.hideSubNav = true;
        } else {
            $scope.hideSubNav = false;
        }

        var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)$/);

        if (matches === null) {
            return;
        }

        $scope.returnState = matches[3];
        $scope.hideSubNavInvoiceName = false;
        $scope.fromAllocation = false;
        if ($scope.returnState === 'view.details') {
            $scope.returnState = 'Reminder.Details';
        }
        else if ($scope.returnState === 'view.edit') {
            $scope.returnState = 'Edit';
        }
        else if ($scope.returnState === 'view.notes.table') {
            $scope.returnState = 'Communications';
            $scope.tableview = true;
            $scope.timelineview = false;
            $rootScope.mode = 'table';
            $scope.hideSubNavInvoiceName = true;
        }
        else if ($scope.returnState === 'view.notes.timeline') {
            $scope.returnState = 'Communications';
            $scope.tableview = false;
            $scope.timelineview = true;
            $rootScope.mode = 'timeline';
            $scope.hideSubNavInvoiceName = true;
        }
        else if ($scope.returnState === 'view.notes_new') {
            $scope.returnState = 'Communications';
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'Add.New.Note';
            $scope.hideSubNav = true;
        }
        else if ($scope.returnState === 'view.notes_edit') {
            $scope.returnState = 'Communications';
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'Edit.Note';
            $scope.hideSubNav = true;
        }
        else if ($scope.returnState === 'view.new_sms_message') {
            $scope.returnState = 'Communications';
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'Send.New.SMS';
            $scope.hideSubNav = true;
        }
        else if ($scope.returnState === 'view.new_phone_call') {
            $scope.returnState = 'Communications';
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'New.Phone.Call';
            $scope.hideSubNav = true;
        }
        else if ($scope.returnState === 'view.reminders') {
            $scope.returnState = 'Reminders';
            $scope.hideSubNavInvoiceName = true;
        }
        else if($scope.returnState === 'view.reminders_automatic_statement') {
            $scope.returnState = 'Reminders';
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'Automatic.Statement.Edit';
            $scope.hideSubNav = true;
        }
        else if($scope.returnState === 'view.reminders_automatic_debt') {
            $scope.returnState = 'Reminders';
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'Debt.Chasing.Edit';
            $scope.hideSubNav = true;
        }
        else if ($state.current.name === 'loggedin.invoice_address.view.account_credit') {
            $scope.returnState = $translate('Account.Credit');
            $scope.hideSubNavInvoiceName = true;
        }
        else if ($state.current.name === 'loggedin.invoice_address.view.invoice_address_add_payment') {
            $scope.returnState = $translate('Account.Credit');
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'Add.Credit';
            $scope.hideSubNav = true;
        }
        else if ($state.current.name === 'loggedin.invoice_address.view.edit_account_credit') {
            $scope.returnState = $translate('Account.Credit');
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = $translate('Edit.Credit');
            $scope.hideSubNav = true;
        } else if ($state.current.name === 'loggedin.invoice_address.view.cash_allocation') {
            $scope.returnState = false;
            $scope.hideSubNavInvoiceName = true;
            //$scope.tabInvoiceSurname = true;
            $scope.formBreadcrumbStr = 'Allocation / New';
            $scope.fromAllocation = true;
            $scope.hideSubNav = false;
        } else if ($state.current.name === 'loggedin.invoice_address.view.new_invoice_address_credit_note') {
            $scope.returnState = false;
            $scope.hideSubNavInvoiceName = true;
            //$scope.tabInvoiceSurname = true;
            $scope.formBreadcrumbStr = 'Credit note / New';
            $scope.fromAllocation = true;
            $scope.hideSubNav = false;
        } else if ($scope.returnState === 'view.service_plan') {
            $scope.returnState = $translate('Service.plan.view');
            $scope.hideSubNavCustomerName = true;
        } else if ($scope.returnState === 'view.new_service_plan') {
            $scope.returnState = 'Service.plan';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = $translate('New.service.plan');
        } else if ($scope.returnState === 'view.edit_service_plan') {
            $scope.returnState = 'Service.plan';
            $scope.hideSubNavCustomerName = true;
            $scope.handleSpecialCases(toState.name);
            $scope.formBreadcrumbStr = $translate('Edit.service.plan');
        }
        else if ($scope.returnState === 'view.invoice_address_setup') {
            $scope.returnState = 'Invoice address setup';
            $scope.hideSubNavInvoiceName = true;
        }
        else if ($state.current.name === 'loggedin.invoice_address.view.stored_cards') {
            $scope.returnState = $translate('Stored.cards');
            $scope.hideSubNavInvoiceName = true;
        }
        else if ($state.current.name === 'loggedin.invoice_address.view.stored_cards.new') {
            $scope.returnState = $translate('Stored.cards');
            $scope.hideSubNavInvoiceName = true;
            $scope.formBreadcrumbStr = 'New card';
            $scope.hideSubNav = true;
        }
    else if($state.current.name === 'loggedin.invoice_address.view.multiple_invoice_payment'){
        $scope.returnInvoiceStateLink = true;
        $scope.returnState = '';
        $scope.hideSubNavInvoiceName = true;
        $scope.fromAllocation = true;
        $scope.formBreadcrumbStr = 'New payment';
        $scope.invoiceSubNavStatic = true;
    }
    else if($state.current.name === 'loggedin.invoice_address.view.view_multiple_invoice_payment'){
        $scope.returnInvoiceStateLink = true;
        $scope.returnState = '';
        $scope.hideSubNavInvoiceName = true;
        $scope.fromAllocation = true;
        $scope.paymentsLink = '';
        $scope.formBreadcrumbStr = 'Payment no.' + $state.params.paymentId + ' / View';
        $scope.invoiceSubNavStatic = true;
    }
    else if($state.current.name === 'loggedin.invoice_address.view.edit_multiple_invoice_payment'){
        $scope.returnInvoiceStateLink = true;
        $scope.returnState = '';
        $scope.hideSubNavInvoiceName = true;
        $scope.fromAllocation = true;
        $scope.paymentsLink = 'Payment no.' + $state.params.paymentId;
        $scope.formBreadcrumbStr = 'edit';
        $scope.invoiceSubNavStatic = true;
    }
    else if($state.current.name === 'loggedin.invoice_address.view.invoice_address_receive_credit'){
        $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
        $scope.returnState = 'Receive credit';
        $scope.hideSubNavCustomerName = true;
        $scope.returnAddState = false;
        $scope.returnStateLink = false;
        $scope.returnSubStateLink = false;
        $scope.returnSubState = '';

    }else if($state.current.name === 'loggedin.invoice_address.view.edit_invoice_address_receive_credit'){
        $scope.creditNoteLink = 'Credit note no.' + $state.params.creditNoteId;
        $scope.returnState = 'Allocation no.'+ $state.params.receiptId +' / Edit';
        $scope.hideSubNavCustomerName = true;
        $scope.returnAddState = false;
        $scope.returnStateLink = false;
        $scope.returnSubStateLink = false;
        $scope.returnSubState = '';
    }
    });

    $scope.handleSpecialCases = function handleSpecialCases(state) {
        $scope.hideSubNav = true;
        var matches = state.match(/^(.*?)\.(.*?)\.(.*?)$/);
        $scope.formBreadcrumbStr = $scope.formatStr(matches[3]);
    }

    $scope.formatStr = function formatStr(str) {
        var formatted_str = str.split('_');
        for (var i = 0; i < formatted_str.length; i++) {
            formatted_str[i] = formatted_str[i].charAt(0).toUpperCase() + formatted_str[i].slice(1);
        }
        return formatted_str.join(' ');
    }

    $scope.getBack = function getBack(customerMode) {
        $scope.customerMode = customerMode;

    if ($scope.returnState == 'Details') {
      $scope.viewDetails($scope.selectedId);
    }
    if ($scope.returnState == 'Communications') {
        $scope.viewNotesTimeLine($scope.selectedId);
    }
    if ($scope.returnState == 'Reminders') {
      $scope.viewReminders($scope.selectedId);
    }
    if ($scope.returnState == 'Account credit') {
      $scope.viewInvoiceAddressAccountCredits($scope.selectedId)
    }
    if ($scope.returnState == 'Service.plan') {
      $scope.viewInvoiceAddressServicePlan($scope.selectedId)
    }
    if ($scope.returnState == 'Stored cards') {
      $scope.viewInvoiceAddressStoredCards($scope.selectedId)
    }
  }

    $scope.viewDetails = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.details', {'id': id});
    }

    $scope.viewNotes = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.notes.table', {'id': id});
    }

    $scope.viewNotesTimeLine = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.notes.timeline', {'id': id});
    }

    $scope.viewReminders = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.reminders', {'id': id});
    }
    $scope.viewInvoiceAddressAccountCredits = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.account_credit', {'id': id});
    }

    $scope.viewInvoiceAddressServicePlan = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.service_plan', {'id': id});
    }

    $scope.viewInvoiceAddressStoredCards = function (id) {
        $rootScope.changableState = true;
        $state.transitionTo('loggedin.invoice_address.view.stored_cards', {'id': id}, {reload:true});
  }

  $scope.viewInvoiceAddressMultipleInvoiceDetails = function( id, invoiceLinkId, creditNoteID){
      if($state.params.creditNoteId){
          $location.path('/invoice_address/' + id + '/multiple_invoice/' + invoiceLinkId + '/credit_note/'+ creditNoteID +'/view')
      }else{
          $location.path('/invoice_address/' + id + '/multiple_invoice/' + invoiceLinkId + '/view')
      }
    }

    $scope.$on('tabRouteInvoice:selected', function (event, data) {

        $scope.selectedTab = data.selectedTab;
        $scope.invoiceSubNavStatic = data.invoiceSubNavStatic;

    });

    $scope.$on('tabInvoice:selected', function (event, data) {
        if (data != undefined && data != '') {
            $scope.hideSubNavInvoiceName = true;
      $scope.multipleInvoiceScreen = false;
            $scope.tabInvoiceCustomerName = data.customerName;

            $scope.tabAddressline1 = $scope.tabAddressline2 = $scope.tabAddressline3 = $scope.tabTown = $scope.tabCounty = $scope.tabPostcode = '';
            if(data.addressline1){ $scope.tabAddressline1 = data.addressline1; }
            if(data.addressline2){ $scope.tabAddressline2 = ', '+ data.addressline2; }
            if(data.addressline3){ $scope.tabAddressline3 = ', '+ data.addressline3; }
            if(data.town){ $scope.tabTown = ', '+ data.town; }
            if(data.county){ $scope.tabCounty = ', '+ data.county; }
            if(data.postcode){ $scope.tabPostcode = ', '+ data.postcode; }

            $scope.invoiceAddress = $scope.tabAddressline1 + $scope.tabAddressline2 + $scope.tabAddressline3 + $scope.tabTown + $scope.tabCounty + $scope.tabPostcode;

            $scope.selectedId = data.id;

            $scope.notesCount = data.notesCount;
        if(data.customerInvoiceId){
            $scope.invoiceId = data.customerInvoiceId;
        }
        if($state.current.name == 'loggedin.invoice_address.view.multiple_invoice_payment' || $state.current.name == 'loggedin.invoice_address.view.edit_multiple_invoice_payment' || $state.current.name == 'loggedin.invoice_address.view.view_multiple_invoice_payment'){
            $scope.invoiceId = data.invoice.id;
            $scope.invoiceNo = 'Invoice no. '+data.invoice.invoice_no;
        }
        } else {
            $scope.hideSubNavInvoiceName = false;
            $scope.formBreadcrumbStr = '';
            $scope.returnState = '';
            $scope.selectedId = '';
            $scope.tabInvoiceName = '';
            $scope.tabInvoiceSurname = '';
        }
    });

    $scope.$on('tabInvoice:successMessage', function (event, data) {
        $rootScope.successMessage = data;
        $timeout(function () {
            $("#saveMessage").fadeIn();
            $("#saveMessage").fadeOut(4000);
            $("#saveMessage").show();
        }, 1000);

    });

    $scope.$on('change:invoice_scope', function (event, data) {
        $scope[data.name] = data.value;
    });
}

function InvoiceSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, $compile, $timeout, emailService,warningModal, $location) {
    var data = getIdData.invoiceNav;
    $scope.selectedId = data.id;
    $scope.canDelete = getIdData.canDelete;
    $scope.supportsStoredCards = getIdData.supportsStoredCards;

    $scope.$on('tabInvoice:selected', function (event, data) {
        if (data != undefined && data != '') {
            $scope.hideSubNavInvoiceName = true;

            $scope.tabInvoiceName = data.name;
            $scope.tabInvoiceSurname = data.surname;
            $scope.tabAddressline1 = data.addressline1;
            $scope.tabAddressline2 = data.addressline2;
            $scope.tabAddressline3 = data.addressline3;
            $scope.tabTown = data.town;
            $scope.tabCounty = data.county;
            $scope.tabPostcode = data.postcode;
            $scope.selectedId = data.id;

        }
    });

    $scope.triggerDelete = function () {
        $scope.invoiceAddressId = $scope.selectedId;
        $http.get(prefix + '/is_invoice_address_deletable?invoiceAddressId=' + $scope.invoiceAddressId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            } else {
                $scope.ShouldBeOpen = true;
            }
        });
    }

    $scope.fetchDeleteInvoiceAddress = function () {
        $scope.flashMessage = 'Invoice.Address.Deleted';
        $scope.$emit('tabInvoice:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.search');
    }
    $scope.customerTabName = 'invoice_address';

    $scope.handleSendStatement = function handleSendStatement() {
        var side_panel_tpl = $compile('<span id="send_statement" ' +
            'sidepanel template="send_statement" ' +
            'title="Send statement" ' +
            'remove_on_close="true" ' +
            'index="' + this.$id + '"' +
            'print_and_post_message="Your statement is on its way to the customer" ' +
            'pdf_url="' + Routing.generate('send_statement', { type: $scope.customerTabName, id: $scope.selectedId }) +'"'+
            'supports_print="true" supports_email="true" supports_print_and_post="'+getIdData.printAndPostModule+'" additional_data="'+data.sendStatements+'" '+
            'printpost_params="tableType=64&tablePKID='+$scope.selectedId+'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);

        $timeout(function() {
            angular.element('#send_statement').scope().$$childTail.initialise();
        }, 600);
    }
    $scope.defaultStatementSubject = getIdData.defaultStatementSubject;
    $scope.defaultStatementMessage = getIdData.defaultStatementMessage;

    $scope.$on('email:send', function (evt, data) {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_invoice_address_email?id=' + $scope.selectedId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=64&id=' + $scope.selectedId;
        clone_obj.subject = $scope.defaultStatementSubject;
        clone_obj.message = $scope.defaultStatementMessage;
        clone_obj.relatedObjectType = 64;
        clone_obj.relatedObjectId = $scope.selectedId;
        clone_obj.attached_files = [
            {
                id: "STATEMENT|" + $scope.selectedId + "|" + $scope.customerTabName + "|" + data,
                file_name: 'Statement. ' + $scope.selectedId,
                file_size: 2,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    });

    $scope.handleConsolidatedInvoice = function handleConsolidatedInvoice(){
        // $timeout(function(){
        //     if($scope.multipleJobInvoice){
                $location.path('/invoice_address/' + $scope.selectedId + '/multiple_invoice/new')
        //     }else{
        //         warningModal.show('Consolidated invoice needs atleast one job', 'Consolidated Invoice');
        //     }
        // },10);
    }

}

function InvoiceAddressDetailsCtrl($scope, $state, prefix, $http, getIdData, $rootScope, canLoad, $q, defaultPagingValue, $filter) {
    $scope.selectedId = getIdData.invoiceValues.id;

    // Enable the Tabs
    $scope.selectedTab = "Details";
    $scope.invoiceSubNavStatic = false;
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);
    InvoiceAddressAndCustomerDebtsDetails($scope.selectedId, $http, prefix, $filter, $scope,$rootScope);
    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);

    $scope.invoiceDetails = getIdData.invoiceValues;
    $scope.propertyId = getIdData.invoiceValues.propertiesid;
    $scope.type = getIdData.type;
    $scope.customerInvoices = getIdData.invoices;
    $scope.customerInvoicesCount = getIdData.invoicesCount;
    $scope.customerInvoiceLimit = defaultPagingValue;
    $scope.invoicePage = 1;
    $scope.credit = getIdData.creditNotes;
    $scope.creditNotesCount = getIdData.creditNotesCount;
    $scope.creditLimit = defaultPagingValue;
    $scope.creditPage = 1;
    $scope.AIAccountSetup = getIdData.AIAccountSetup;

    if ($rootScope.flashMessage) {
        $scope.$emit('tabInvoice:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }


    var invoiceCanceler = null;

    $scope.fetchInvoices = function(pageNum) {
        canLoad.setLoadValue(false);
        if (invoiceCanceler) {
            invoiceCanceler.resolve();
        }
        invoiceCanceler = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        $http.get(prefix + '/invoice_address/' + $scope.selectedId + '/list_invoice_address_invoices?page=' + pageNum + '&customerInvoiceLimit=' + $scope.customerInvoiceLimit, {timeout: invoiceCanceler.promise}).success(function (data) {
            $scope.customerInvoices = data.invoices;
            $scope.customerInvoicesCount = data.invoicesCount;
        })
    }
    var creditNoteCanceler = null;

    $scope.fetchCredit = function(pageNum) {
        canLoad.setLoadValue(false);
        if (creditNoteCanceler) {
            creditNoteCanceler.resolve();
        }
        creditNoteCanceler = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        $http.get(prefix + '/invoice_address/' + $scope.selectedId + '/list_invoice_address_credit_notes?page=' + pageNum + '&creditLimit=' + $scope.creditLimit, {timeout: creditNoteCanceler.promise}).success(function (data) {
            $scope.credit = data.credit;
            $scope.creditNotesCount = data.creditNotesCount;
        })
    }
}

function EditInvoiceAddressCtrl($scope, $state, prefix, $http, getIdData, $rootScope) {
  //onchange of edit invoice address third party reference enabled save button
  $scope.enableCustomerAccountingReference = function () {
    $scope.customerSaveButtonDisabled = false;
    $scope.validateReference          = false
  }
    PostCodeCtrl.call(this, $scope, $state, $http, prefix);


    // Hide the Tabs
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceDetails);

    $scope.shouldBeOpenLord = false;
    $scope.titleLists = getIdData.titlesRecord;
    $scope.invoiceDetails = getIdData.invoiceDetails;
    $scope.showPostCodeLookUp = getIdData.showPostCodeLookUp;

    $scope.selectedTitle = getIdData.invoiceDetails.title;
    $scope.invoiceName = getIdData.invoiceDetails.name;
    $scope.invoiceSurname = getIdData.invoiceDetails.surname;
    $scope.invoiceCompanyname = getIdData.invoiceDetails.companyname;
    $scope.invoiceAddressline1 = getIdData.invoiceDetails.addressline1;
    $scope.invoiceAddressline2 = getIdData.invoiceDetails.addressline2;
    $scope.invoiceAddressline3 = getIdData.invoiceDetails.addressline3;
    $scope.invoiceTown = getIdData.invoiceDetails.town;
    $scope.invoiceCounty = getIdData.invoiceDetails.county;
    $scope.postcode = getIdData.invoiceDetails.postcode;
    $scope.selectedId = $state.params.id;
    $scope.invoiceAccountingRef = getIdData.invoiceDetails.accountingsystemreference;
    $scope.creditordaysid = getIdData.invoiceDetails.creditordaysid;
    $scope.creditorDays = getIdData.creditorDays;
    $scope.AIAccountSetup = getIdData.AIAccountSetup;

  $scope.countyLists = getIdData.countyRecords;
  $scope.countyId = getIdData.invoiceDetails.countyId;

  $scope.telephone_code = getIdData.invoiceDetails.landlineCountryCode;
  $scope.invoiceTelephone = getIdData.invoiceDetails.landlineNumber;
  $scope.invoiceMobile = getIdData.invoiceDetails.mobileNumber;
  $scope.mobile_code = getIdData.invoiceDetails.mobileCountyCode;
  $scope.invoiceEmail = getIdData.invoiceDetails.emailaddress;

  $scope.$on('smartpostcode:address', function (evt, data) {
    $scope.invoiceAddressline1 = data.addressLine1;
    $scope.invoiceAddressline2 = data.addressLine2;
    $scope.invoiceAddressline3 = data.addressLine3;
    $scope.invoiceTown = data.town;
    $scope.invoiceCounty = data.country;

    var countyId = _.indexOf($scope.countyLists,$scope.invoiceCounty);
    $scope.countyId = countyId ? countyId : '';
  });


    $scope.editInvoice = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        var county = $scope.countyLists[this.countyId] ? $scope.countyLists[this.countyId] : '';

        $http.post(prefix + '/invoiceAddressUpdate', "invoiceaddress[id]=" + this.selectedId
            + "&invoiceaddress[name]=" + encodeURIComponent(this.invoiceName)
            + "&invoiceaddress[surname]=" + encodeURIComponent(this.invoiceSurname)
            + "&invoiceaddress[companyname]=" + encodeURIComponent(this.invoiceCompanyname)
            + "&invoiceaddress[settingsTitlesid]=" + encodeURIComponent(this.selectedTitle)
            + "&invoiceaddress[email]=" + encodeURIComponent(this.invoiceEmail)
            + "&invoiceaddress[landlinecountrycode]=" + encodeURIComponent(this.telephone_code)
            + "&invoiceaddress[landline]=" + encodeURIComponent(this.invoiceTelephone)
            + "&invoiceaddress[mobilecountrycode]=" + encodeURIComponent(this.mobile_code)
            + "&invoiceaddress[mobile]=" + encodeURIComponent(this.invoiceMobile)
            + "&invoiceaddress[addressline1]=" + encodeURIComponent(this.invoiceAddressline1)
            + "&invoiceaddress[addressline2]=" + encodeURIComponent(this.invoiceAddressline2)
            + "&invoiceaddress[addressline3]=" + encodeURIComponent(this.invoiceAddressline3)
            + "&invoiceaddress[town]=" + encodeURIComponent(this.invoiceTown)
            + "&invoiceaddress[county]=" + encodeURIComponent(county)
            + "&invoiceaddress[settingsstateprovinceid]=" + this.countyId
            + "&invoiceaddress[postcode]=" + encodeURIComponent(this.postcode)
            + "&invoiceaddress[thirdparty]=" + encodeURIComponent(this.invoiceAccountingRef)
            + "&invoiceaddress[creditordaysid]=" + encodeURIComponent(this.creditordaysid)).
        success(function (data, status) {
            if (data.error && data.error == 'Invalid third party accounting reference') {
                $scope.validateReference          = true;
                $scope.updating                   = false;
                $scope.customerSaveButtonDisabled = true;
            } else {
                $scope.validateReference = false;

                if (status == 200) {
                    $scope.id = data;
                    $scope.viewDetails($scope.selectedId);
                    $scope.updating = false;
                }
            }
      });
  }
}

function NoteInvoiceAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope, dateRange, emailService) {
    $scope.selectedId = $state.params.id;
    $scope.contacts = getIdData.contacts;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
  $scope.smsReadAccess = getIdData.smsReadAccess;

    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.searchText = '';
    $scope.contactId = '';

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId};

        $scope.$broadcast('filterValue', filters);
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_invoice_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=7&id=' + $state.params.id;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 7;        // For Invoice Address
        clone_obj.relatedObjectId = $state.params.id;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.action = "";

    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }

      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }

  var baseRoute = prefix + '/invoice_address/' + $state.params.id;
  handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
  handleNotesAndCommunicationFilter.call(this, $scope);

}

function NoteListInvoiceAddressCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope, canLoad, $q, $sce, emailService) {
    $scope.selectedId = $state.params.id;

    // Enable the Tabs
    $scope.invoiceSubNavStatic = false;
    $scope.tabRouteInvoice = { 'selectedTab': 'Notes', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceDetails);

    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notesDetails = getIdData.notesDetails;
        $scope.notes = $scope.notesDetails.notesDetails;
        $scope.notesid = $scope.notesDetails.notesid;
        $scope.notesCount = $scope.notesDetails.notesCount;
        $scope.notesCountMain = $scope.notesDetails.notesCountMain;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
        $scope.contactId = '';
        $scope.searchResult = false;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabInvoice:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.currentPagePhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.currentPagePhoneCalls = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_invoice_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=7&id=' + $state.params.id;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 7;        // For Invoice Address
        clone_obj.relatedObjectId = $state.params.id;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = (message.contactId) ? message.contactId : '';
        if($scope.searchText == '' && $scope.startDate == '' && $scope.startDate == '' && $scope.contactId == '') {
            $scope.searchEnabled = false;
        } else {
            $scope.searchEnabled = true;
        }
        $scope.searchCommunications(1);
    });

    var searchCanceler = null;
    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (searchCanceler) {
            searchCanceler.resolve();
        }
        searchCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/invoice_address/' + $scope.selectedId + '/notes/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: searchCanceler.promise}).success(function (data) {
            if($scope.contactId > 0) {
                $scope.notes = [];
                $scope.notesCountMain = 0;
            } else {
                $scope.notesDetails = data.notesDetails;
                $scope.notes = data.notesDetails.notesDetails;
                $scope.notesCount = data.notesDetails.notesCount;
                $scope.notesCountMain = data.notesDetails.notesCountMain;
            }
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
            $scope.searchResult = $scope.searchEnabled;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/invoice_address/' + $state.params.id + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesCount = data.notesCount;
            $scope.notesCountMain = data.notesCountMain;
            $scope.$emit('change:invoice_scope', {value: $scope.notesCount, name: 'notesCount'});
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        });
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNumPhoneCalls) {
        canLoad.setLoadValue(false);
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/invoice_address/' + $state.params.id + '/listPhoneCalls?pagePhoneCalls=' + pageNumPhoneCalls + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceler = null;
    $scope.fetchSmsMessages = function (pageNumSmsMessages) {
        canLoad.setLoadValue(false);
        if (smsCanceler) {
            smsCanceler.resolve();
        }
        smsCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/invoice_address/' + $state.params.id + '/listSms?pageSmsMessages=' + pageNumSmsMessages + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: smsCanceler.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNumEmailMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/invoice_address/' + $state.params.id + '/listEmail?pageEmailMessages=' + pageNumEmailMessages + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/invoice_address/' + $state.params.id + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }
    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerInvoiceAddressNotesandCommunications", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix + '/invoice_address/' + $state.params.id + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix + '/invoice_address/' + $state.params.id +'/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    $scope.triggerDelete = function (id) {
        $scope.notesid = id;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function NoteListInvoiceAddressTimeCtrl($scope, $state, $http, prefix, getIdData, $timeout, $rootScope, canLoad, $q, $sce, emailService) {

    $scope.selectedId = $state.params.id;

    // Enable the Tabs
    $scope.selectedTab = "Notes";
    $scope.invoiceSubNavStatic = false;
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.onFetchRecordsComplete = function(data) {
        $scope.$emit('tabInvoice:selected', data.navBarDetails);
    }

    $scope.onFetchRecordsComplete(getIdData);

    if ($rootScope.flashMessage) {
        $scope.$emit('tabInvoice:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_invoice_emailaddresses?id=' + $state.params.id;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=7&id=' + $state.params.id;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 7;        // For Invoice Address
        clone_obj.relatedObjectId = $state.params.id;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("CustomerInvoiceAddressNotesandCommunications", function (event, action, data) {

        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.pageName = 'Notes List';
            $scope.excelRouteName = 'downloadExcel';
            var download = prefix + '/invoice_address/' + $state.params.id + '/downloadNotesCommExcel?&page='+$scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.pdfRouteName = 'printPdf';
            $scope.pageName = 'Notes List';
            var print = prefix + '/invoice_address/' + $state.params.id +'/printNotesCommPdf?&page='+$scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    var url = '/invoice_address/' + $state.params.id + '/notes/view/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);

    $scope.triggerDelete = function (id) {
        $scope.notesid = id;
        $scope.shouldBeOpen = true;
    }
}

function NewNoteInvoiceAddressCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {

    $scope.selectedId = $state.params.id;

    // Hide the Tabs
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);

    // Display view for table or timeline
    $scope.modeChange = $state.params.mode;

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newNoteForm.$setPristine();
            $scope.selectedId = data.tablepkid;
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.viewNotesTimeLine($scope.selectedId);
            } else if ($scope.modeChange == 'table') {
                $scope.viewNotes($scope.selectedId);
            }
            $scope.saving = false;
        })
    }
}

function EditNoteInvoiceAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.invoiceSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.notesid = $scope.noteDetails.notesid;

    $scope.$emit('tabInvoice:selected', getIdData.navBarDetails);
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.selectedTab = "Notes";

    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
            + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
        success(function (data, status) {
            if (status == 200) {
                $scope.editNoteForm.$setPristine();
                $rootScope.flashMessage = data;
                if ($scope.modeChange == 'timeline') {
                    $scope.viewNotesTimeLine($scope.selectedId);
                } else if ($scope.modeChange == 'table') {
                    $scope.viewNotes($scope.selectedId);
                }
                $scope.updating = false;
            }
        });
    }
}

function NewSmsMessageInvoiceAddressCtrl($scope, $http, $state, datasets,  prefix, smsTemplates, $rootScope, formService, getIdData, sidePanel, $sce) {
  EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope, prefix);

    smsTemplates.setTemplatesValue(getIdData.templateDetails);
    $scope.smsTemplates = getIdData.templateDetails;

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.invoiceSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });
    SMSMessageControllers.call(this, $scope);
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newSmsMessageForm.$setPristine();
            $rootScope.flashMessage = data.success;

            if ($scope.modeChange == 'timeline') {
                $scope.viewNotesTimeLine($scope.selectedId);
            } else if ($scope.modeChange == 'table') {
                $scope.viewNotes($scope.selectedId);
            }
            $scope.saving = false;
        })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function NewPhoneCallInvoiceAddressCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = "Notes";
    $scope.invoiceSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newPhoneCallForm.$setPristine();
            $scope.selectedId = data.tablepkid;
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.viewNotesTimeLine($scope.selectedId);
            } else if ($scope.modeChange == 'table') {
                $scope.viewNotes($scope.selectedId);
            }
            $scope.saving = false;
        })
    }
}

function ReminderInvoiceAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {

    $scope.selectedId = $state.params.id;

    // Enable the Tabs
    $scope.selectedTab = "Reminders";
    $scope.invoiceSubNavStatic = false;
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.$emit('tabInvoice:selected', getIdData.invoiceDetails);

    if(getIdData.reminders.automaticstatement) {
        $scope.automaticStatement = "On";
    } else {
        $scope.automaticStatement = "Off";
    }

    if(getIdData.reminders.automaticdebtchasing) {
        $scope.automaticdebtchasing = "On";
    } else {
        $scope.automaticdebtchasing = "Off";
    }


    if ($rootScope.flashMessage) {
        $scope.$emit('tabInvoice:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
}

function AutomaticStatementInvoiceAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.invoiceSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.invoiceDetails = getIdData.invoiceDetails;

    $scope.$emit('tabInvoice:selected', getIdData.invoiceDetails);

    if(getIdData.reminders.automaticstatement) {
        $scope.automaticstatement = getIdData.reminders.automaticstatement;
    } else {
        $scope.automaticstatement = 0;
    }


    $scope.selectedTab = "Reminders";

    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.updateAutomaticStatement = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/invoiceAddressAutomaticStatementUpdate', "propertypreference[id]=" + $scope.selectedId + "&propertypreference[automaticstatement]=" + encodeURIComponent(this.automaticstatement)).
        success(function (data, status) {
            if (status == 200) {
                $scope.automaticStatementForm.$setPristine();
                $rootScope.flashMessage = data;
                $scope.viewReminders($scope.selectedId);
                $scope.updating = false;
            }
        });
    }
}

function AutomaticDebtInvoiceAddressCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.invoiceSubNavStatic = true;
    $scope.selectedId = $state.params.id;
    $scope.invoiceDetails = getIdData.invoiceDetails;

    $scope.$emit('tabInvoice:selected', getIdData.invoiceDetails);

    if(getIdData.reminders.automaticdebtchasing) {
        $scope.automaticdebtchasing = getIdData.reminders.automaticdebtchasing;
    } else {
        $scope.automaticdebtchasing = 0;
    }
    $scope.selectedTab = "Reminders";

    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.updateAutomaticDebt = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/invoiceAddressAutomaticDebtUpdate', "propertypreference[id]=" + $scope.selectedId + "&propertypreference[automaticdebtchasing]=" + encodeURIComponent(this.automaticdebtchasing)).
        success(function (data, status) {
            if (status == 200) {
                $scope.automaticDebtForm.$setPristine();
                $rootScope.flashMessage = data;
                $scope.viewReminders($scope.selectedId);
                $scope.updating = false;
            }
        });
    }
}

function CreateNewEstimateCtrl($scope, $state, $http, prefix, formPanelCollection, getIdData, $rootScope, $timeout) {
    $scope.selectedId = $state.params.id;
    $scope.bookDiaryEvent = false;
    $scope.customerTabName = getIdData.customerTabName;
    formPanelCollection.setFilterData(getIdData.contactCreate);
    $scope.customerSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$emit('tabCustomer:selected', getIdData);

    $scope.jobDurationTime = getIdData.jobDurationTime;
    $scope.endDateError = false;
    $scope.dateError = false;
    $scope.setJobDuration = false;
    $scope.durationWarning = false;
    if($scope.jobDurationTime != undefined) {
        $scope.setJobDuration = true;
        var duration = parseInt($scope.jobDurationTime);
        $scope.startDateTime = moment().add(15 - (moment().minute() % 15), "minutes").subtract(moment().second(),"seconds").toISOString();
        $scope.demodate = $scope.startDateTime;
        $scope.endDateTime = moment($scope.demodate).add(duration, "minutes").toISOString();
        DiaryDateTimeInputs.call(this, $scope, $timeout);
    }

  $scope.formSubmit = function (formElem) {
    $http.post($scope.actionVal, formElem.serialize()).
      success(function (data, status) {
        var estimateId = "";
        var routeToForward = "";
        var type = "";
        if (data.estimateProjectId) {
            type = 'project_estimate';
          estimateId = data.estimateProjectId;
          routeToForward = "loggedin.customer_list.view.project_estimate_details.survey";
          $scope.newEstimateProjectForm.$setPristine();
          $rootScope.flashMessage = "Project.Saved";
        } else {
            type = 'estimate';
          routeToForward = "loggedin.customer_list.view.estimate_details.survey";
          estimateId = data.estimateId;
          $scope.newEstimateForm.$setPristine();
          $rootScope.flashMessage = "Estimate.Saved";
        }
        $scope.customerId = data.customerId;

            if($scope.bookDiaryEvent) {
                $state.transitionTo("loggedin.diary_from_job_estimate", { 'typeId': estimateId, 'type': type });
            } else {
                $state.transitionTo(routeToForward, { 'id': $scope.customerId, 'type': $scope.customerTabName, 'estimateId': estimateId });
            }
        })
    }
    $scope.templates = getIdData.templates;
    $scope.contacts = getIdData.contacts;
    $scope.contactid = '';
    if($scope.contacts.length != 0){
        $scope.contactid = $scope.contacts[0].contactid;
    }

    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.contactid = data.contactId;
        $scope.contacts.unshift({contactid:data.contactId, surname: data.contactFullName});
    });

    $scope.backTo = function () {
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.selectedId, 'type': $scope.customerTabName });
    }

    $scope.handleSpecialEventValues = function () {
        return;
    }
}

function WorkAddressReminderDueCtrl($scope, $state, $http, prefix, getIdData, $rootScope, dateRange, formPanelCollection, tableCollection, $translate) {
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    if(getIdData.redirectTo == true){
        $scope.id = getIdData.id;
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.selectedId, 'type': $scope.customerTabName });
    } else {
        tableCollection.setData('work_reminders_view_pending', getIdData.work_reminders_view_pending);
        formPanelCollection.setFilterData(getIdData.workAddressReminderGroup);
        $scope.serviceRemindersDataCount = getIdData.work_reminders_view_pending.count;
        $scope.customerSubNavStatic = true;
        $scope.show_daterange = false;
        $scope.filterStatusList = getIdData.filterStatus;
        $scope.dateFilterOptions = dateRange.getFilterOptions();
        $scope.startDate = '';
        $scope.endDate = '';
        $scope.searchText = '';
        $scope.workAddressStatus = true;
        $scope.$emit('tabCustomer:selected', getIdData.customerSubNavDetails);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    }

    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $rootScope.startDate = '';
    $rootScope.endDate = '';
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined) {
            $scope.selectedRange = message.string;
            $rootScope.startDate = message.startdate;
            $rootScope.endDate = message.enddate;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $rootScope.dateValue = {'startDate': $rootScope.startDate, 'endDate': $rootScope.endDate};
            WorkAddressReminderDueMoreFilter.call(this, $scope,$rootScope);
        } else {
            $rootScope.dateValue = undefined;
        }
        WorkAddressReminderDueMoreFilter.call(this, $scope, $rootScope);

    });

    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("WorkAddressReminderDuePrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Work address reminder due';
            var excelRouteName = 'downloadWorkAddressReminderDueExcel';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&filterType='+ $scope.selectedStatusType + '&searchText=' + $scope.searchText + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Work address reminder due';
            var pdfRouteName = 'printWorkAddressReminderDuePdf';
            var print = prefix + '/' + pdfRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&filterType='+ $scope.selectedStatusType + '&searchText=' + $scope.searchText + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    $scope.$watch('selectedStatusType', function (newVal, oldVal) {
        if (newVal != undefined) {
            $rootScope.selectedStatusType = newVal;
            if($rootScope.selectedStatusType == 'pending'){
                $scope.workAddressStatusValue = $translate('Pending.Work.Address.Reminders');
                $scope.workAddressStatus = true;
            }
            if($rootScope.selectedStatusType == 'authorised'){
                $scope.workAddressStatusValue = $translate('Authorised.Work.Address.Reminders');
                $scope.workAddressStatus = false;
            }
            if($rootScope.selectedStatusType == 'not_authorised'){
                $scope.workAddressStatusValue = $translate('Not.Authorised.Work.Address.Reminders');
                $scope.workAddressStatus = false;
            }
            if($rootScope.selectedStatusType == 'waiting_customer'){
                $scope.workAddressStatusValue = $translate('Waiting.Customer.Work.Address.Reminders');
                $scope.workAddressStatus = false;
            }
            WorkAddressReminderDueMoreFilter.call(this, $scope,  $rootScope);
        }
    });
    $scope.$on("smarttable:actions:remove", function (event) {
        $scope.workAddressStatus = ($rootScope.selectedStatusType == 'pending') ? true : false ;
    });
    $scope.$watch('searchText', function(newVal, oldVal){
        if (newVal != undefined) {
            $rootScope.searchText=newVal;
            WorkAddressReminderDueMoreFilter.call(this, $scope,  $rootScope);
        }
    });
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'searchText',
                'value': $scope.searchText
            },
            {
                'model_name': 'selectedStatusType',
                'value': $scope.selectedStatusType
            }
        );
    });

    $rootScope.$on("smart_table:active_row", function (event, message) {
        $rootScope.selection = {rowCount: message};
    });
    $scope.moreFiltervalue= '';

    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:work_reminders_view_pending', function(evt, data) {
        $scope.moreFilter= data;
        WorkAddressReminderDueMoreFilter.call(this,$scope, $rootScope);
    });
    $scope.clearBatchProcess = function clearBatchProcess(){
        $scope.$broadcast('batch_process:clear');
    }
    $scope.$on('sidepanelfilter:filtervalue', function(evt, data) {
        $scope.$broadcast('smart_tablefilter:filtervalue', data);
    });
}

function WorkAddressReminderDueMoreFilter($scope,$rootScope){
    var filterData = {};
    angular.forEach($scope.moreFilter, function(val, key) {
        filterData[val.model_name] = val.value;
        $scope.moreFiltervalue=filterData[val.model_name];
        $scope.moreFiltervalue = val.value;
    });
    filterData['searchText'] = $rootScope.searchText;
    filterData['statusType'] = $rootScope.selectedStatusType;
    filterData['dateRange'] = $rootScope.dateValue;
    $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson(filterData));
}

function WorkRemindersSidepanel($scope, $rootScope, tableCollection, sidePanel, $http, prefix){

    $scope.selectedDate = '';
    if($scope.$parent.sidepanelData){
        $scope.singleServiceDate = moment($scope.$parent.sidepanelData.duedate.date);
    } else {
        $scope.singleServiceDate = '';
    }

    $scope.access_methods = tableCollection.getAccessMethods('work_reminders_view_pending');
    $scope.smsReadAccess = tableCollection.getSmsAccessMethods('work_reminders_view_pending');
    $scope.handleCheckbox = function handleCheckbox(e, type){
        if (type == 'phone'){
            if ($scope.sidepanelData.detail[0].communication_method_phone_disabled == 'true'){
                e.preventDefault();
            }
        }
        if (type == 'sms'){
            if ($scope.sidepanelData.detail[0].communication_method_sms_disabled == 'true'){
                e.preventDefault();
            }
        }
        if (type == 'email'){
            if ($scope.sidepanelData.detail[0].communication_method_email_disabled == 'true'){
                e.preventDefault();
            }
        }
        if (type == 'letter'){
            if ($scope.sidepanelData.detail[0].communication_method_letter_disabled == 'true'){
                e.preventDefault();
            }
        }

    }
    $scope.$on('batch_process:clear', function(event) {
        $scope.sidepanelData.authorised = 'pleasechoose';
        $scope.sidepanelData.action = 'pleasechoose';
        $scope.sidepanelData.access_method = 'pleasechoose';
        $scope.sidepanelData.communication_method = '';
        $scope.sidepanelData.postpone_date = '';
    });
    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.rows_store = rows_store;
        $scope.selected_reminders = [];
        $scope.selected_date = [];
        for(var i = 0, l = $scope.rows_store.length; i < l; i++) {
            var original_index_of_row = $scope.rows_store[i][0].row,
                original_row_data = tableCollection.getBasicDetails('work_reminders_view_pending', original_index_of_row),
                selected_date_array = tableCollection.getBasicDetails('work_reminders_view_pending', original_index_of_row).duedate.date;

            $scope.selected_reminders.push(original_row_data);
            $scope.selected_date.push(selected_date_array);
        }
        $scope.maxDate = $scope.selected_date.sort().pop();
        $scope.minDate = moment($scope.maxDate);
        // debugger
    });

    /*$scope.$watch('selectedDate', function (newVal, oldVal) {

        $scope.seletedDueDate = $scope.sidepanelData.Due_date;
        $scope.minDate = $scope.seletedDueDate;

        if (newVal != undefined && newVal != '' && newVal != oldVal) {
            $scope.actionValue = newVal;
            var oldDate = $scope.seletedDueDate;
            var newDate = moment($scope.actionValue).format("YYYY-MM-DD");
            if (newDate > oldDate) {
                $scope.dueDateMessage = true;
            } else {
                $scope.dueDateMessage = false;
            }
        }
    });*/

    /*$scope.$watch('sidepanelData.postpone_date', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '' && newVal != oldVal) {
            $scope.actionValue = newVal;
            var oldDate = $scope.maxDate;
            var newDate = moment($scope.actionValue).format("YYYY-MM-DD");
            if (newDate > oldDate) {
                $scope.dueDateMessage = true;
            } else {
                $scope.dueDateMessage = false;
            }
        }
    });*/

    $scope.onDisableApply = function (value) {
        if (!$scope.sidepanelData.detail && $scope.sidepanelData.authorised != 'pleasechoose' && ($scope.sidepanelData.action != 'pleasechoose' || $scope.sidepanelData.access_method != 'pleasechoose')) {
            if (value == 'authorised' && (($scope.sidepanelData.access_method == 'communicate_with_work_address' && $scope.sidepanelData.communication_method == '') || $scope.sidepanelData.access_method=='pleasechoose')) {
                return true;
            } else if (value == 'not_authorised' && (($scope.sidepanelData.action == 'postpone_reminder' && $scope.sidepanelData.postpone_date == '') || $scope.sidepanelData.action == 'pleasechoose')) {
                return true;
            } else {
                return false;
            }
        } else if (!$scope.sidepanelData.detail) {
            return true;
        } else if ($scope.sidepanelData.detail[0] && $scope.sidepanelData.detail[0].authorised != 'pleasechoose' && ($scope.sidepanelData.detail[0].action != 'pleasechoose' || $scope.sidepanelData.detail[0].access_method != 'pleasechoose')) {
            if (value == 'authorised' && (($scope.sidepanelData.detail[0].access_method == 'communicate_with_work_address' && $scope.sidepanelData.detail[0].communication_method == '') || $scope.sidepanelData.detail[0].communication_method=='pleasechoose')) {
                return true;
            } else if (value == 'not_authorised' && (($scope.sidepanelData.detail[0].action == 'postpone_reminder' && $scope.serviceDateToDisplay == undefined) || $scope.sidepanelData.detail[0].action=='pleasechoose')) {
                return true;
            } else {
                return false;
            }
        } else if ($scope.sidepanelData.detail[0]) {
            return true;
        }
    };

  $scope.processWorkReminder = function processWorkReminder(){
      if($scope.serviceDateToDisplay) {
          $scope.sidepanelData.detail[0].postpone_date = (moment($scope.serviceDateToDisplay, 'L').format('YYYY-MM-DD') != "Invalid date" ) ? moment($scope.serviceDateToDisplay, 'L').format('YYYY-MM-DD') : moment($scope.serviceDateToDisplay).format('YYYY-MM-DD');
      }


        $scope.$emit('smart_tablefilter:filtervalue', angular.toJson({'servicereminder':$scope.sidepanelData, 'dateRange':$rootScope.dateValue, 'statusType': $rootScope.selectedStatusType, 'searchText': $rootScope.searchText}));

        $rootScope.$broadcast('smart_table:remove_all_pinned_rows');
    }
    /*====================================================
   Process work reminders --
   This happens when clicking 'process' from the
   batch configure work reminders sidepanel, it
   processes animations on the DOM
   Also remove table rows from the DOM and close
   the sidepanel
   ====================================================*/
  $scope.processWorkReminders = function processWorkReminders(){
      if($scope.sidepanelData.postpone_date) {
          $scope.sidepanelData.postpone_date = (moment($scope.sidepanelData.postpone_date, 'L').format('YYYY-MM-DD') != "Invalid date" ) ? moment($scope.sidepanelData.postpone_date, 'L').format('YYYY-MM-DD') : moment($scope.sidepanelData.postpone_date).format('YYYY-MM-DD');
      }

        for(var i = 0, l = $scope.selected_reminders.length; i < l; i++) {
            $scope.selected_reminders[i].detail[0] = $scope.sidepanelData;
        }

        $scope.$emit('sidepanelfilter:filtervalue', angular.toJson({'servicereminder':$scope.selected_reminders, 'dateRange':$rootScope.dateValue, 'statusType': $rootScope.selectedStatusType, 'searchText': $rootScope.searchText}));

        $('.single-reminder .ss-check').addClass('processed');
        var number_of_processed_rows = $scope.rows_store.length;
        var self = this;
        setTimeout(function(){
            $rootScope.$broadcast('smart_table:remove_all_pinned_rows');
            sidePanel.close();
        }, (number_of_processed_rows*300)+800);
    }
    $scope.cancelSidePanel = function cancelSidePanel(){
        $rootScope.$emit('sidepanel:close');
    }
    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });
}

function CustomersServiceRemindersCtrl($scope, $state, $http, prefix, datasets, $rootScope) {
    $scope.data = datasets;
    $scope.$state = $state;

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        if (toState.url === '/customers/service_reminders/view' ) {
            $scope.processing_reminders = true;
        } else {
            $scope.processing_reminders = false;
        }

    });
}

function CustomersServiceRemindersListCtrl($scope, $state, prefix, $http, customersserviceremindersresolver, tableCollection,setDataCollection,formPanelCollection, $rootScope, dateRange, warningModal, toastBox) {
    tableCollection.setData('viewAllServiceReminder', customersserviceremindersresolver.viewAllServiceReminder);
    formPanelCollection.setFilterData(customersserviceremindersresolver.serviceRemindersType);
    $scope.settingsServiceReminderList = customersserviceremindersresolver.settingsServiceReminders;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.selectedServiceReminder = 0;
    $scope.processReminderModal = false;
    $scope.viewAllServiceRemindersCount = customersserviceremindersresolver.viewAllServiceReminder.count;
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
        }else{
            $scope.filterByDueDate = undefined;
            $scope.startDate = $scope.endDate = '';
        }
        ViewAllServiceReminderMoreFilter.call(this, $scope)
    });
    $scope.$watch('selectedServiceReminder', function (newVal, oldVal) {
        if (newVal != undefined) {

            $scope.filterByDueDate = ($scope.filterByDueDate != undefined)?{'startDate': $scope.startDate, 'endDate': $scope.endDate}:undefined;
            ViewAllServiceReminderMoreFilter.call(this, $scope);
        }
    });
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'settingsServiceReminderId',
                'value': $scope.selectedServiceReminder
            }
        );
    });
    $scope.moreFilter=[];
    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:service_reminders_type', function(evt, data) {
        $scope.moreFilterValue = data;
        ViewAllServiceReminderMoreFilter.call(this, $scope);
    });
    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }
    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.downloadReport = function() {
        var excelRouteName ='serviceReminderDownload';
        var pageName = 'View All Service Reminders';
        $('#loading-indicator').show();
        $http.get(prefix + '/confirm_download_service_reminder?page=' + excelRouteName + '&searchText=' + '' + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate).success(function (data) {
            if(data.value== true){
                toastBox.show("You cannot start another `Download to Excel` action as you already have one processing", 4000);
            }else{
                $http.post(prefix + '/' + excelRouteName + '?page=' + pageName + '&searchText=' + '' + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate).
                success(function (data, status) {
                    if(status == 200 && data.data == "RecordsCount"){
                        var warningMessage = 'This report cannot be downloaded/printed due to the high amount of data. Please add filters to the report to reduce the size of the file';
                        warningModal.show(warningMessage, "Service Reminder Download/Print", "report_warning_message");
                        $('#loading-indicator').hide();
                    }
                    else if (status == 200) {
                        toastBox.show('You will be notified after Excel file is ready to download', 4000);
                        $('#loading-indicator').hide();
                    }
                });
            }
        });
    }
    $scope.printReport = function() {
        $('#loading-indicator').show();
        var pdfRouteName ='printServiceReminderPdf';
        var pageName = 'View All Service Reminders';
        $http.get(prefix + '/confirm_download_service_reminder?page=' + pdfRouteName + '&searchText=' + '' + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate).success(function (data) {
            if(data.value== true){
                toastBox.show("You cannot start another `Print` action as you already have one processing", 4000);
            }else {
                $http.post(prefix + '/' + pdfRouteName + '?page=' + pageName + '&searchText=' + '' + '&moreFilter=' + $scope.moreFilter + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate).success(function (data, status) {
                    if (status == 200 && data.data == "RecordsCount") {
                        var warningMessage = 'This report cannot be downloaded/printed due to the high amount of data. Please add filters to the report to reduce the size of the file';
                        warningModal.show(warningMessage, "Report Download/Print", "report_warning_message");
                        $('#loading-indicator').hide();
                    } else if (status == 200) {
                        toastBox.show('You will be notified after pdf file is ready to print', 4000);
                        $('#loading-indicator').hide();
                    }
                });
            }
        });
    }
    setDataCollection.setData(customersserviceremindersresolver.instances);
    $rootScope.$on("smart_table:active_row_id", function (event, message) {
        if(message.length > 0) {
            setDataCollection.setData(message);
        }
        else {
            setDataCollection.setData(customersserviceremindersresolver.instances);
        }
    });
    $scope.processAllInstances = customersserviceremindersresolver.instances;
    $rootScope.$on("smart_table:milestoneUpdate", function (event, data) {
        setDataCollection.setData(data.instances);
        $scope.processAllInstances = data.instances;
    });
    $scope.moreFilterData = true;

    $scope.enableProcessAllServiceReminders = function() {
        if($scope.moreFilter == '{"settingsServiceReminderId":0}' || $scope.moreFilter == '{"settingsServiceReminderId":"0"}'){
            $scope.moreFilterData = true;
        }else{
            $scope.moreFilterData = false;
        }
        $scope.postParams = 'instancesId='+ $scope.processAllInstances + '&filter='+$scope.moreFilter;
        $http.get(prefix + '/confirm_process_service_reminder?filterData=' + $scope.moreFilter).success(function (data) {
            if(data.value== true){
                toastBox.show('Already running one process for this client', 4000);
            }else{
                $scope.processReminderModal = true;
            }
        });

    }


    $scope.processAllServiceReminders = function processAllServiceReminders() {
        warningModal.show('Processing all service reminders. This may take some time, but you can close this window and continue to use Commusoft. We will send you a notification when the process is finished', 'Process all service reminders', 'process_all_service_reminders');
    }
}

function ViewAllServiceReminderMoreFilter($scope){
    var filterData = {};
    $scope.moreFilter=[];
    angular.forEach($scope.moreFilterValue, function(val, key) {
        if(val.input_type == "smart_filter_dynamic"){
            filterData[val.model_name] = val.value;
        }else{
            filterData[val.model_name] = "1";
        }
    });
    filterData['settingsServiceReminderId']=$scope.selectedServiceReminder;
    filterData['filterByDueDate']=$scope.filterByDueDate;
    $scope.moreFilter = angular.toJson(filterData);
    $scope.$broadcast('smarttable_and_sidepanel:filtervalues',  angular.toJson(filterData));
}

function ServiceReminderCtrl($scope, $state, $http, datasets, $rootScope) {
    $scope.data = datasets;
}

function ServiceReminderListCtrl($scope, $state, $http, servicereminderresolver, tableCollection, $rootScope, prefix, dateRange){
    tableCollection.setData('serviceReminderDueDays', servicereminderresolver.serviceReminderDueDays );
    $scope.serviceTypeList = servicereminderresolver.serviceTypeList;
    $scope.serviceRemindersData = servicereminderresolver.serviceRemindersData;
    $scope.selectedServiceType = 0;

    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.selectedServiceReminder = 0;
    $scope.$on("serviceReminderFilter", function (event, message) {
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        if(message.selectedServiceType) {
            $scope.selectedServiceType = message.selectedServiceType;
        }

        $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};

        var filterData = {};
        filterData['selectedServiceType'] = $scope.selectedServiceType;
        filterData['filterByDueDate'] = $scope.filterByDueDate;

        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson(filterData));
    });

    $scope.$on('fromsmarttable:filters', function(evt, data) {
        ServiceRemindersDueMoreFilter.call(this,$scope);
    });

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("ServiceReminderDueDaysPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Service reminders due in the next 30 days';
            var excelRouteName = 'downloadServiceReminderDueExcel';
            var download = prefix + '/' + excelRouteName + '?page=' + pageName + '&filterType=' + $scope.selectedServiceType + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Service reminders due in the next 30 days';
            var pdfRouteName = 'printServiceReminderDuePdf';
            var print = prefix + '/' + pdfRouteName + '?page=' + pageName + '&filterType=' + $scope.selectedServiceType  + '&startDate=' + $scope.startDate  + '&endDate=' + $scope.endDate;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    var baseRoute = prefix;
    handleServiceReminderPDF.call(this, $scope, baseRoute);
    handleServiceReminderFilter.call(this, $scope);
}

function handleServiceReminderPDF($scope, baseRoute){

    $scope.filterStr = 'startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
    $scope.pdfRoute = baseRoute + '/printServiceReminderDuePdf?' + $scope.filterStr;
    $scope.excelRoute = baseRoute + '/downloadServiceReminderDueExcel?' + $scope.filterStr;
    $scope.selectedServiceType = 0;

    $scope.$watchCollection('[startdate,endDate,selectedServiceType]', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.filterStr = 'selectedServiceType=' + $scope.selectedServiceType + '&startDate=' + $scope.startDate + '&endDate=' + $scope.endDate;
            $scope.pdfRoute = baseRoute + '/printServiceReminderDuePdf?' + $scope.filterStr;
            $scope.excelRoute = baseRoute + '/downloadServiceReminderDueExcel?' + $scope.filterStr;
        }
    });
}

function handleServiceReminderFilter($scope){
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            var filters = {'selectedServiceType': $scope.selectedServiceType, 'startDate': $scope.startDate, 'endDate': $scope.endDate};
        }else{
            var filters = {'selectedServiceType': $scope.selectedServiceType};
        }
        $scope.$broadcast('serviceReminderFilter', filters);
    });

    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }

    $scope.$watch('selectedServiceType', function (newVal, oldVal) {
        $scope.selectedServiceType = newVal;

        var filter = {'selectedServiceType': $scope.selectedServiceType, 'startDate': $scope.startDate, 'endDate': $scope.endDate};
        $scope.$broadcast('serviceReminderFilter', filter);
    });
}

function ServiceRemindersDueMoreFilter($scope){
    var filterData = {};
    filterData['selectedServiceType'] = $scope.selectedServiceType;
    filterData['filterByDueDate'] = $scope.filterByDueDate;
    $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson(filterData));
}

function ServiceReminderPhoneCallCtrl($scope, $state, $http, datasets, $rootScope) {
    $scope.data = datasets;
}

function ServiceReminderPhoneCallListCtrl($scope, $state, $http, servicereminderphonecallresolver, tableCollection, $rootScope, prefix, setDataCollection, formPanelCollection, dateRange){
    tableCollection.setData('service_reminders_phone_call_list', servicereminderphonecallresolver.service_reminders_phone_call_list);
    formPanelCollection.setFilterData(servicereminderphonecallresolver.serviceRemindersPhoneCall);
    $scope.serviceTypeValue = servicereminderphonecallresolver.serviceType;
    $scope.count = servicereminderphonecallresolver.count;
    $scope.serviceRemindersPhoneCallData = servicereminderphonecallresolver.service_reminders_phone_call_list.data;
    $rootScope.serviceType = 0;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
        }else{
            $scope.filterByDueDate = undefined;
        }
        ServiceReminderPhoneCallListMoreFilter.call(this,$scope)
    });
    $scope.$watch('serviceType', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
            ServiceReminderPhoneCallListMoreFilter.call(this,$scope);
        }
    });
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'serviceType',
                'value': $scope.serviceType
            }
        );
    });
    $scope.$on('smart_table_with_filter:filtervalues', function(evt, data) {
        $scope.filterData = angular.fromJson(data);
        $scope.filterData['filterByDueDate'] = $scope.filterByDueDate;
        $scope.$broadcast('smart_tablefilter:filtervalue',angular.toJson($scope.filterData));
    });
    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });
    $scope.moreFilter=[];
    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:service_reminders_phone_call_list', function(evt, data) {
        $scope.moreFilterValue = data;
        ServiceReminderPhoneCallListMoreFilter.call(this,$scope);
    });
    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("ServiceReminderPhoneCllPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Service Reminders Phone Call List';
            var excelRouteName = 'downloadServiceReminderPhoneCallExcel';
            var download = prefix + '/' + excelRouteName + '?page=' + pageName + '&filterType=' + $rootScope.serviceType;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Service Reminders Phone Call List';
            var pdfRouteName = 'printServiceReminderPhoneCallPdf';
            var print = prefix + '/' + pdfRouteName + '?page=' + pageName + '&filterType=' + $rootScope.serviceType;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
}
function ServiceReminderPhoneCallListMoreFilter($scope){
    var filterData = {};
    $scope.moreFilter=[];
    angular.forEach($scope.moreFilterValue, function(val, key) {
        filterData[val.model_name] = val.value;
    });
    filterData['serviceType']=$scope.serviceType;
    filterData['filterByDueDate']=$scope.filterByDueDate;
    $scope.moreFilter= angular.toJson(filterData);

    $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson(filterData));
}
function ServiceReminderProcessingCtrl($scope, $state,prefix, getIdData, $rootScope) {
    if( getIdData ==undefined)
    {
        $state.transitionTo('loggedin.service_reminders');
    }
    $scope.smsCount=(getIdData.smsCount)?getIdData.smsCount:0;
    $scope.emailCount=(getIdData.emailCount)?getIdData.emailCount:0;
    $scope.letterCount=(getIdData.letterCount)?getIdData.letterCount:0;
    $scope.phoneCall=(getIdData.phoneCall)?getIdData.phoneCall:0;
    $scope.letterSentCount=(getIdData.letterSentCount)?getIdData.letterSentCount:0;
    $scope.letterContentObj=(getIdData.letterContent)?getIdData.letterContent:'';
    $scope.letterContent=[];
    angular.forEach($scope.letterContentObj, function(val, key) {
        $scope.letterContent.push(val);
    });
    $scope.smsModule = getIdData.smsModule;
    $scope.printAndPostModule = getIdData.printAndPostModule;
    $scope.pdfRouteName = 'serviceReminderPrintLettersProcessingPdf/'+getIdData.executionID;
}

function CustomerServiceReminderStatementCtrl($scope, $state, $http, prefix, getIdData, $rootScope, dateRange, formPanelCollection) {
    $scope.selectedId = $state.params.id;
    $scope.hideSubNavCustomers =true;
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    if(getIdData.redirectTo == true){
        $scope.id = getIdData.id;
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.selectedId, 'type': $scope.customerTabName });
    } else {
        $scope.selectedTab = "Service";
        $scope.show_daterange = false;
        $scope.dateFilterOptions = dateRange.getFilterOptions();
        $scope.settingsServiceReminderList = getIdData.settingsServiceReminders;
        $scope.startDate = '';
        $scope.endDate = '';
        $scope.selectedServiceReminder = 0;
        $scope.$emit('tabCustomer:selected', getIdData.customerSubNav);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    }



    $scope.$on("printDownloadServiceReminderStatement", function (event, message) {
        $scope.modePdf=message.modePdf;
        $scope.modeDownload = message.modeDownload;
    });

    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
        } else {
            $scope.filterByDueDate = undefined;
        }
        ViewAllServiceReminderStatementMoreFilter.call(this, $scope, $state, formPanelCollection);
    });

    $scope.$watch('selectedServiceReminder', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
            ViewAllServiceReminderStatementMoreFilter.call(this,$scope, $state, formPanelCollection);
        }
    });
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'settingsServiceReminderId',
                'value': $scope.selectedServiceReminder
            }
        );
    });
    $scope.moreFilter=[];
    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:service_reminders_type', function(evt, data) {
        $scope.moreFilterValue = data;
        ViewAllServiceReminderStatementMoreFilter.call(this,$scope, $state, formPanelCollection);
    });
    $scope.dateRangeClose = function (e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }
}

function ViewAllServiceReminderStatementMoreFilter($scope, $state, formPanelCollection){
    var filterData = {};
    $scope.moreFilter=[];
    angular.forEach($scope.moreFilterValue, function(val, key) {
        filterData[val.model_name] = "1";
        $scope.moreFilter.push(angular.toJson(filterData));
    });
    filterData['settingsServiceReminderId']=$scope.selectedServiceReminder;
    filterData['filterByDueDate']=$scope.filterByDueDate;
    formPanelCollection.setMoreFilterData('serviceRemindersStatement', filterData);
    $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson(filterData));
}

function CustomerServiceReminderStatementOverviewCtrl($scope, $state,prefix, getIdData, $rootScope, tableCollection,formPanelCollection) {
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customerSubNavStatic = true;
    if(getIdData.redirectTo == true){
        $scope.id = getIdData.id;
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.selectedId, 'type': $scope.customerTabName });
    } else {
        tableCollection.setData('serviceRemindersStatement', getIdData.serviceRemindersStatement);
        formPanelCollection.setFilterData(getIdData.serviceRemindersType);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    }


    $scope.pdfDowloadRoute = {
        'modePdf':'printServiceReminderOverviewPdf',
        'modeDownload': 'serviceReminderOverviewDownload'
    };
    $scope.$emit('printDownloadServiceReminderStatement', $scope.pdfDowloadRoute);
    ShortCutsFunctions.call(this, $scope, $rootScope);
    $scope.$on("ServiceRemindersPrintandDownloadOverview", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Service Reminder Statement';
            var excelRouteName = 'serviceReminderOverviewDownload';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Service Reminder Statement';
            var pdfRouteName = 'printServiceReminderOverviewPdf';
            var print = prefix + '/' + pdfRouteName + '/'+ $scope.selectedId+ '?page=' + pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
}

function CustomerServiceReminderStatementSummaryCtrl($scope, $state,prefix, getIdData, $rootScope, tableCollection, formPanelCollection) {
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customerSubNavStatic = true;
    if(getIdData.redirectTo == true){
        $scope.id = getIdData.id;
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $scope.selectedId, 'type': $scope.customerTabName });
    } else {
        tableCollection.setData('serviceReminderStatementOverDue', getIdData.serviceReminderStatementOverDue);
        tableCollection.setData('serviceReminderStatementDueBooked', getIdData.serviceReminderStatementDueBooked);
        tableCollection.setData('serviceReminderStatementDueNotBooked', getIdData.serviceReminderStatementDueNotBooked);
        tableCollection.setData('serviceReminderStatementOverNot', getIdData.serviceReminderStatementOverNot);
        tableCollection.setData('serviceRemindersStatementCompleted', getIdData.serviceRemindersStatementCompleted);
        formPanelCollection.setFilterData(getIdData.serviceRemindersType);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': '', 'customerSubNavStatic':$scope.customerSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    }

    $scope.pdfDowloadRoute = {
        'modePdf':'printServiceReminderSummaryPdf',
        'modeDownload': 'serviceReminderSummaryDownload'
    };
    $scope.$emit('printDownloadServiceReminderStatement', $scope.pdfDowloadRoute);

    ShortCutsFunctions.call(this, $scope, $rootScope);
    $scope.$on("ServiceRemindersPrintandDownloadSummary", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var recordId = $scope.selectedId;
            var pageName = 'Service Reminder Statement';
            var excelRouteName = 'serviceReminderSummaryDownload';
            var download = prefix + '/' + excelRouteName + '/' + recordId + '?page=' + pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var recordId = $scope.selectedId;
            var pageName = 'Service Reminder Statement';
            var pdfRouteName = 'printServiceReminderSummaryPdf';
            var print = prefix + '/' + pdfRouteName + '/'+ recordId + '?page=' + pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
}

function BatchPartsChangeStatus ($scope, $rootScope, $state, $stateParams, $timeout, changeStatusDrop, $http, prefix, tableCollection, warningModal) {

    $scope.clientJobType = $rootScope.clientJobType;
    $rootScope.$on('smart_table:pinned_rows_part_category', function(event, category) {
        $scope.selectedStatus = undefined;
        $scope.category = category;
        $scope.jobId = tableCollection.getJobId(category);
        $scope.changeStatusValues = changeStatusDrop.getData(category);
        if($scope.FulfillType && $scope.FulfillType != 'OrderFromSupplier'){
            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
        }
        if($scope.FulfillType && $scope.FulfillType != 'FromStock'){
            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned_To_Stock');
        }
    });

    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
        if($scope.chosenParts.length > 0){
            $scope.FulfillType = $scope.chosenParts[0].part.FulfillType;
            $scope.partStatus = $scope.chosenParts[0].part.partStatus;
        }
        $scope.changeStatusValues = changeStatusDrop.getData($scope.category);
        var omitReturned = true;
        var omitCancelled = false;
        for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            var chosen_part = $scope.chosenParts[i];
            if (chosen_part.part.FulfillType == 'OrderFromSupplier' || chosen_part.part.FulfillType == 'FromStock') {
                omitReturned = false;
            }
            if (chosen_part.part.FulfillType != 'OrderFromSupplier') {
                omitCancelled = false;
            }
        }
        if(omitCancelled == true) {
            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Cancelled');
        }
        if(omitReturned == true) {
            $scope.changeStatusValues = _.omit($scope.changeStatusValues, 'Returned');
        }

        $scope.checkIfAnyPartsAreInvalid();
    });

    $scope.saveBatchPartStatus = function saveBatchPartStatus(selectedStatus, partsData){
        $scope.error = false;

        $scope.partIds = [];
        angular.forEach(partsData, function (key, value) {
            if((parseFloat(key.quantity) > 0 && selectedStatus === 'Cancelled' && (key.part.FulfillType == '' || key.part.FulfillType == 'General'))
                || (parseFloat(key.quantity) > 0 && (key.part.FulfillType == 'OrderFromSupplier' || key.part.FulfillType == 'FromStock') && (selectedStatus === 'Returned' || selectedStatus === 'Returned_To_Stock'))
                || (parseFloat(key.quantity) > 0 && ($scope.clientJobType == 1 ||  $scope.clientJobType == 2 ||  $scope.clientJobType == 3) && key.part.FulfillType == 'OrderFromSupplier' && selectedStatus === 'Cancelled')
                || selectedStatus === 'Available'
                || selectedStatus === 'Installed')
            {
                $scope.partIds.push({
                    'id': key.part.id,
                    'totalQuantity': key.part.Quantity,
                    'allocatedQuantity': key.quantity
                });
            }
        });

        $http.post(Routing.generate('part_batch_status_update',{'jobId' : $scope.jobId,'currentStatus': $scope.partStatus.replace(/\s+/g, '_'),'updateStatus' : selectedStatus}), 'partsData=' + angular.toJson($scope.partIds) + '&moduleId=' + $rootScope.clientJobType + '&jobtodos=' + $rootScope.clientJobTodos ).success(function (data) {
            $rootScope.$broadcast('parts:countupdated', data);
            $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category });
            $scope.selectedStatus = "";
        }).error(function(data,statusCode){
            if(statusCode == 400){
                var message = title = identifier = '';
                if(data.errorMsg == "supplier_invoice_raised_bulk"){
                    identifier = data.errorMsg;
                    title = 'parts.cannot.cancelled.due.completed.po.title';
                    message = 'parts.cannot.cancelled.due.completed.po.description';
                } else if(data.errorMsg == "supplier_invoice_raised"){
                    identifier = data.errorMsg;
                    title = 'supplier.invoice.raised.title';
                    message = 'supplier.invoice.raised.description';
                } else if(data.errorMsg == "multiple_invoice_raised_po"){
                    identifier = data.errorMsg;
                    title = 'supplier.invoice.raised.title';
                    message = 'multiple.invoice.raised.po';
                }
                warningModal.show(message, title, identifier);
            }
        });
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('smart_table:unpin_all_pinned_rows');
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.initAvailableQuantity = function initAvailableQuantity(part) {
        part.error = false;
        part.available_quantity = parseFloat(part.part.Quantity);
        // Set default on input
        part.quantity = part.available_quantity;
    }

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateQuantity = function validateQuantity(part) {
        var input_is_number = !isNaN(parseFloat(part.quantity)),
            quantity_valid;

        if(input_is_number) {
            $scope.isQuantityInBounds(part);
        } else {
            part.error = true;
            part.error_message = 'Please enter a valid quantity';
        }
        $scope.checkIfAnyPartsAreInvalid();
    }

    $scope.isQuantityInBounds = function isQuantityInBounds(part) {
        if ((parseFloat(part.quantity) > parseFloat(part.available_quantity)) || parseFloat(part.quantity) < 0) {
            part.error = true;
            $scope.quantityError = true;

            if (parseFloat(part.quantity) < 0) {
                part.error_message = 'Quantity cannot be negative';
            }
            else if (parseFloat(part.quantity) == 0) {
                part.error_message = 'Please enter a quantity greater than zero';
            }
            else {
                if($scope.selectedStatus =='Cancelled'){var status = 'cancel'}
                if($scope.selectedStatus =='Returned'){var status = 'return'}
                part.error_message = 'There aren\'t enough parts to '+status+' this many';
            }
        } else {
            part.error = false;
            $scope.quantityError = false;
        }
        if (parseFloat(part.quantity) == 0 ){
            part.error = true;
            part.error_message = 'Please enter a quantity greater than zero';
            $scope.quantityError = true;
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.error = false;
        for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            var chosen_part = $scope.chosenParts[i];
            if (chosen_part.error === true) {
                $scope.error = true;
            }
        }
    }
    $scope.checkSelectedStatus = function checkSelectedStatus(selectedStatus) {
        $scope.selectedStatus = selectedStatus;
        for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            $scope.chosenParts[i].quantity = $scope.chosenParts[i].available_quantity;
            $scope.chosenParts[i].error_message = undefined;
            $scope.chosenParts[i].error = false;
        }
        if(selectedStatus != '' && selectedStatus != undefined) {
            $scope.quantityError = false;
            $scope.error = false;
        }
        $scope.selectedStatus = selectedStatus;
    }
}

function AllPartsChangeStatus ($scope, $rootScope, $state, $stateParams, $timeout, changeStatusDrop, $http, prefix, tableCollection) {

    $scope.chosenParts = $scope.sidepanelData['fulfillment'];

    $scope.changeStatusValues = changeStatusDrop.getData('job_parts');

    $scope.jobId = tableCollection.getJobId('job_parts');

    if($scope.chosenParts.length > 0) {
        $scope.FulfillType = $scope.chosenParts[0].fulfilltype;
        $scope.partStatus = $scope.chosenParts[0].partstatus;
    }

    $scope.fulfillmentParts = [];
    angular.forEach( $scope.chosenParts, function (fulfillment, key) {
        $scope.fulfillmentParts = {
            'fulfillmentId': fulfillment.fulfilledId,
            'fulfillType': fulfillment.fulfillment,
            'partStatus': fulfillment.partstatus,
            'quantity': fulfillment.quantity
        };
    });

    $scope.initAvailableQuantity = function initAvailableQuantity(part) {
        $scope.error = true;
        part.available_quantity = parseFloat(part.quantity);
        // Set default on input
        part.quantity = part.available_quantity;
        part.selectedStatus = '';
    }

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateQuantity = function validateQuantity(part) {
        var input_is_number = !isNaN(parseFloat(part.available_quantity)),
            quantity_valid;

        if(input_is_number) {
            $scope.isQuantityInBounds(part);
        } else {
            $scope.error = true;
            part.error_message = 'Please enter quantity';
        }
    }
    $scope.validateStatus = function validateStatus(part) {
        $scope.error = true;
        for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            var chosen_part = $scope.chosenParts[i];
            if (chosen_part.selectedStatus != '') {
                $scope.error = false;
            }
        }
    }

    $scope.isQuantityInBounds = function isQuantityInBounds(part) {
        if ((parseFloat(part.available_quantity) > parseFloat(part.quantity)) || parseFloat(part.available_quantity) < 0) {
            $scope.error = true;
            $scope.quantityError = true;

            if (parseFloat(part.available_quantity) < 0) {
                part.error_message = 'Quantity cannot be negative';
            } else {
                part.error_message = 'There aren\'t enough parts to ' + $scope.selectedStatus;
            }
        } else {
            $scope.error = false;
            $scope.quantityError = false;
        }
        if (parseFloat(part.available_quantity) == 0 ){
            $scope.error = true;
            $scope.quantityError = true;
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.error = false;
        for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            var chosen_part = $scope.chosenParts[i];
            if (chosen_part.error === true) {
                $scope.error = true;
            }
        }
    }
    $scope.saveAllPartStatus = function saveAllPartStatus(chosenParts){
        $scope.error = false;
        $http.post(prefix + '/all_part_status_update', 'partsData=' + angular.toJson(chosenParts) + '&jobId=' + $scope.jobId ).success(function (data) {
            $rootScope.$broadcast('parts:countupdated', data);
            $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category });
        });
    }
}

function MarkPartAsAvailable ($scope, $state, $stateParams, $timeout) {
    /*====================================================
     Set data on the scope
     ====================================================*/
    if ($scope.sidepanelData['Status'] === 'In Progress') {
        $scope.available_quantity = parseFloat($scope.sidepanelData['Quantity Left to Install']);
    } else {
        $scope.available_quantity = parseFloat($scope.sidepanelData['Quantity'])
    }
    $scope.quantity = $scope.available_quantity;

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateQuantity = function validateQuantity(quantity) {
        var input_is_number = !isNaN(parseFloat(quantity)),
            quantity_valid;

        if(input_is_number) {
            $scope.isQuantityInBounds(quantity);
        }else{
            $scope.error = true;
        }
    }
    $scope.isQuantityInBounds = function isQuantityInBounds(quantity) {
        if ((parseFloat(quantity) > parseFloat($scope.available_quantity) ) || quantity< 0) {
            $scope.error = true;
        } else {
            $scope.error = false;
        }

        console.log('isQuantityInBounds: ' + quantity);
    }
    $scope.checkSelectedStatus = function checkSelectedStatus(selectedStatus) {
        $scope.quantity = $scope.available_quantity;
        if(selectedStatus == 'Installed') {
            $scope.error = false;
        }
    }
};

function CreditNoteSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, $compile, $timeout) {
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.show_tabs = true;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.creditNoteDetail = getIdData.creditNoteDetail;
    $scope.shoudBeOpen = false;
    $scope.creditNoteId = $state.params.creditNoteId;
    $scope.show_infotabs = false;

    $scope.$on("event:changeCustomerCreditNoteScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });

    $scope.redirectToCustomerPage = function() {
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': $state.params.id, 'type': $state.params.type });
    }

    $scope.triggerCustomerCreditNoteDelete = function () {
        $http.get(prefix + '/check_customer_credit_note/' + $scope.creditNoteId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.shouldBeOpen = true;
            }
        });
    }
}

function CreditNoteDetailsCtrl($scope, $state, prefix, $http, getIdData, warningModal, $rootScope, emailService, $compile, $timeout) {
    $scope.selectedId = $state.params.id || getIdData.id;
    var customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.customerTabName = $state.params.type || customerSubNavDetails.customerTabName;
    $scope.$emit('tabCustomer:selected', customerSubNavDetails);
    $scope.$emit('displayInvoiceScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.hideSubNavCustomerName = true;
    $scope.creditNoteDetail = getIdData.creditNoteDetail;
    $scope.vatType = getIdData.creditNoteDetail.vatType;
    $scope.creditNoteItemDetails = getIdData.creditNoteItemDetails;
    $scope.allocationHistory = getIdData.allocationHistory;
    $scope.hasAllocation = getIdData.hasAllocation;
    $scope.printAndPostModule = getIdData.printAndPostModule;
    $scope.invoiceAddressId = getIdData.invoiceAddressId;
    $scope.audits = getIdData.audits;
    $scope.aiAccountStatus = getIdData.aiAccountStatus;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', {name:'show_tabs', value: true});
    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', { name:'show_infotabs', value : true});

    $scope.prefix = prefix;
    $scope.watermark = 0;
    $scope.creditNoteDefaultSubject = getIdData.creditNoteDefaultSubject;
    $scope.creditNoteDefaultMessage = getIdData.creditNoteDefaultMessage;

    $scope.handleCustomerCreditNoteEdit = function() {
        $state.transitionTo("loggedin.customer_list.view.credit_note.details.edit_credit_note", { id: $state.params.id, type: $state.params.type, creditNoteId:$state.params.creditNoteId });
    }

    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 57) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.audits = data;
            });
        }

    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_invoice_address_email?id=' + $scope.invoiceAddressId + '&propertyId=' + $scope.selectedId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=57&id=' + $scope.selectedId + '&jobId='+ $scope.jobId + '&creditNoteId=' + $state.params.creditNoteId;
        clone_obj.subject = $scope.creditNoteDefaultSubject;
        clone_obj.message = $scope.creditNoteDefaultMessage;
        clone_obj.relatedObjectType = 57;
        clone_obj.relatedObjectId = $state.params.creditNoteId;
        clone_obj.attached_files = [
            {
                id: "CREDITNOTE|" + $scope.selectedId + "|" + $state.params.creditNoteId,
                file_name: 'Credit note no. ' + $state.params.creditNoteId,
                file_size: 0,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    $scope.showpdf = function showpdf(){
        var side_panel_tpl = $compile('<span id="view_pdf" ' +
            'sidepanel template="print_and_post" ' +
            'supports_print="true"' +
            'supports_print_and_post="'+ $scope.printAndPostModule +'"' +
            'print_and_post_message="Your credit note is on its way to the customer"' +
            'pdf_url="'+ prefix + '/customers/' + $scope.customerTabName + '/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/printCreditNote"' +
            'title="Print and post credit note"'+
            'watermark="'+ $scope.watermark  + '"' +
            'printpost_params="type=print_post&tableType=57&tablePKID=' + $state.params.creditNoteId + '"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_pdf').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_pdf')) {
            angular.element('#view_pdf').remove();
        };
    });

    $scope.creditNoteDetail['isHideVat'] = !(_.max(_.pluck(_.flatten(_.pluck($scope.creditNoteItemDetails,'records')),'taxItemName')) > null);
}

function CustomerCreditAllocationCtrl($scope, $state, prefix, $http, getIdData, $timeout,warningModal,$rootScope, confirmationBoxHelper) {
    $scope.selectedId = $state.params.id || getIdData.id;
    var customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.customerTabName = $state.params.type || customerSubNavDetails.customerTabName;
    $scope.$emit('tabCustomer:selected', customerSubNavDetails);
    $scope.jobSubNavStatic = false;
    $scope.hideSubNavCustomerName = true;
    $scope.creditNoteDetail = getIdData.creditNoteDetail;
    $scope.remainingCredit = getIdData.creditNoteDetail.remainingCredit;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.getListUrl = prefix + '/customers/'+$scope.selectedId+'/get_cash_allocation_data/new_customer_cash_allocation_invoices?mode=new&creditNoteId='+$state.params.creditNoteId;
    $scope.show_invoice_spreadsheet = false;
    $scope.spreadSheetTitle = 'Invoices';
    $scope.allocationHotId = "customerCreditNoteSpreadsheet";

    customerAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state,confirmationBoxHelper,$timeout);
    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', {name:'show_tabs', value: false});

    var saveAllocationUrl = prefix + '/customers/'+$state.params.type+'/'+$state.params.id+'/credit_note/'+$state.params.creditNoteId+'/allocation/save';
    $scope.addAllocation = function() {
        var date = moment(this.selectedDate).format('YYYY-MM-DD');
        var description = (!$scope.description) ? '' : $scope.description;
        $scope.saving = true;
        $http.post(saveAllocationUrl, 'invoices='+encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)) +
            '&date=' + date + '&description=' + encodeURIComponent(description)).
        success(function (data, status) {
            $scope.saving = false;
            if(data.error && data.error === true) {
                warningModal.clean('credit_allocation_warning');
                warningModal.show(data.errorMessage, 'Credit.allocation.save', 'credit_allocation_warning');
            } else {
                $state.transitionTo("loggedin.customer_list.view.credit_note.details", {id: $state.params.id, type: $state.params.type, creditNoteId: $state.params.creditNoteId}, {reload:true});
            }
        });
    }

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });
}

function EditCustomerCreditAllocationCtrl($scope, $state, prefix, $http,getIdData, warningModal, $timeout,$rootScope, confirmationBoxHelper) {
    $scope.selectedId = $state.params.id || getIdData.id;
    var customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.customerTabName = $state.params.type || customerSubNavDetails.customerTabName;
    $scope.$emit('tabCustomer:selected', customerSubNavDetails);
    $scope.jobSubNavStatic = false;
    $scope.hideSubNavCustomerName = true;
    $scope.creditNoteDetail = getIdData.creditNoteDetail;
    $scope.description = getIdData.description;
    $scope.remainingCredit = Math.round((parseFloat($scope.creditNoteDetail.remainingCredit)  + parseFloat($scope.creditNoteDetail.amountAllocated)) * 100) / 100;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.getListUrl = prefix + '/customers/'+$scope.selectedId+'/get_cash_allocation_data/new_customer_cash_allocation_invoices?creditNoteId='+$state.params.creditNoteId;
    $scope.deleteUrl = prefix + '/customers/'+$scope.selectedId+'/credit_note/'+$state.params.creditNoteId+'/allocation/delete';

    $scope.show_invoice_spreadsheet = false;
    $scope.spreadSheetTitle = 'Invoices';
    $scope.allocationHotId = "customerCreditNoteSpreadsheet";

    customerAllocationSpreadsheet.call(this,$scope, prefix, $http, $rootScope, $state,confirmationBoxHelper, $timeout);
    HandleDeleteRowFromSpreadsheet.call(this, $scope);
    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', {name:'show_tabs', value: false});

    var saveAllocationUrl = prefix + '/customers/'+$state.params.type+'/'+$state.params.id+'/credit_note/'+$state.params.creditNoteId+'/allocation/save';
    $scope.addAllocation = function() {
        var date = moment(this.selectedDate).format('YYYY-MM-DD');
        var rowsToDelete = ($scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $scope.saving = true;
        var description = (!$scope.description) ? '' : $scope.description;
        $http.post(saveAllocationUrl, 'rowsToDelete='+ rowsToDelete +'&invoices='+encodeURIComponent(angular.toJson($scope.spreadSheetInvoice)) +
            '&date=' + date + '&description=' + encodeURIComponent(description)).
        success(function (data, status) {
            $scope.saving = false;
            if(data.error && data.error === true) {
                warningModal.clean('credit_allocation_warning');
                warningModal.show(data.errorMessage, 'Credit.allocation.save', 'credit_allocation_warning');
            } else {
                $state.transitionTo("loggedin.customer_list.view.credit_note.details", {id: $state.params.id, type: $state.params.type, creditNoteId: $state.params.creditNoteId}, {reload:true});
            }
        });
    }

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.allocationDate});
        $scope.selectedDate = getIdData.allocationDate.date;
    });

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });
}

function EditCustomerCreditNoteReceiveCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData, $location) {
    $scope.selectedId = $state.params.id || getIdData.id;
    var customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.customerTabName = $state.params.type || customerSubNavDetails.customerTabName;
    if($state.current.name == 'loggedin.invoice_address.view.edit_invoice_address_receive_credit'){
        $scope.$emit('tabInvoice:selected', customerSubNavDetails);
        $scope.$emit('tabRouteInvoice:selected', {'selectedTab': '', 'invoiceSubNavStatic': true});
    }else{
    $scope.$emit('tabCustomer:selected', customerSubNavDetails);
    }
    $scope.jobSubNavStatic = false;
    $scope.hideSubNavCustomerName = true;
    $scope.creditNoteDetails = getIdData.creditNoteDetail;
    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', {name:'show_tabs', value: false});

    $scope.receiveCreditDetails = getIdData.receiveCreditDetails;
    $scope.nominalCodeRecords = getIdData.nominalCodeRecords;
    $scope.paymentMethods =  getIdData.paymentMethods;
    $scope.method =  parseInt($scope.receiveCreditDetails.paymentMethod);
    $scope.nominal =  parseInt($scope.receiveCreditDetails.nominalCode);
    $scope.amount =  $scope.receiveCreditDetails.amount;
    $scope.description =  $scope.receiveCreditDetails.description == null ? '' : $scope.receiveCreditDetails.description;
    $scope.reference =  $scope.receiveCreditDetails.reference == null ? '' : $scope.receiveCreditDetails.reference;
    $scope.receiveCreditDate = moment($scope.receiveCreditDetails.date.date);
    $scope.remainingToAllocate = parseFloat($scope.creditNoteDetails.remainingCredit) + parseFloat(getIdData.receiveCreditDetails.amount);

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: $scope.receiveCreditDetails.date});
    });

    if($state.params.type != null){
    var editUrl = prefix + '/customers/' + $state.params.type + '/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/receive_credit/' + $state.params.receiptId + '/save';
    }else{
        var editUrl = prefix + '/invoice_address/' + $state.params.id + '/credit_note/' + $state.params.creditNoteId + '/receive_credit/' + $state.params.receiptId + '/save';
    }

    $scope.$on("datepicker:datepicker_selected", function (event, message) {
        $scope.selectedDate = message.date;
    });

    $scope.editCreditNoteReceiveCredit = function () {
        $scope.saving = true;
        var date = moment($scope.selectedDate).format('YYYY-MM-DD');
        $http.post(editUrl, "receivecredit[paymentdate]=" + date
            + "&receivecredit[paymentmethod]=" + this.method
            + "&receivecredit[nominalcodeid]=" + this.nominal
            + "&receivecredit[amountallocated]=" + this.amount
            + "&receivecredit[description]=" + this.description
            + "&receivecredit[reference]=" + this.reference).
        success(function (data, status) {
            if (status == 200) {
                    if($scope.creditNoteDetails.viewoption == 5){
                        if($state.params.type == 'customer'){
                            $location.path('/customers/' + $state.params.type + '/' + $state.params.id + '/multiple_invoice/' + $scope.creditNoteDetails.invoiceId + '/credit_note/'+ $state.params.creditNoteId +'/view');
                        }else{
                            $location.path('/invoice_address/' + $state.params.id + '/multiple_invoice/' + $scope.creditNoteDetails.invoiceId + '/credit_note/'+ $state.params.creditNoteId +'/view');
                        }
                    }else{
                $state.transitionTo("loggedin.customer_list.view.credit_note.details", {id: $state.params.id, type: $state.params.type, creditNoteId: $state.params.creditNoteId}, {reload:true});
                    }
                $scope.saving = false;
            }
        });
    }

}

function NewCustomerCreditNoteReceiveCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData, $timeout, $location) {
    $scope.selectedId = $state.params.id || getIdData.id;
    var customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.customerTabName = $state.params.type || customerSubNavDetails.customerTabName;

    if($state.current.name == 'loggedin.invoice_address.view.invoice_address_receive_credit'){
        $scope.$emit('tabInvoice:selected', customerSubNavDetails);
        $scope.$emit('tabRouteInvoice:selected', {'selectedTab': '', 'invoiceSubNavStatic': true });
    }else{
    $scope.$emit('tabCustomer:selected', customerSubNavDetails);
    }
    $scope.jobSubNavStatic = false;
    $scope.hideSubNavCustomerName = true;
    $scope.creditNoteDetails = getIdData.creditNoteDetail;

    $timeout(function () {
        $scope.amountallocated = parseFloat($scope.creditNoteDetails.remainingCredit).toFixed(2);
    }, 100);

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $rootScope.$broadcast('event:changeCustomerCreditNoteScope', {name:'show_tabs', value: false});

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newCreditNoteReceiveCreditForm.$setPristine();
                if($scope.creditNoteDetails.viewoption == 5){
                    if($state.params.type == 'customer'){
                        $location.path('/customers/' + $state.params.type + '/' + $state.params.id + '/multiple_invoice/' + $scope.creditNoteDetails.invoiceId + '/credit_note/'+ $state.params.creditNoteId +'/view');
                    }else{
                        $location.path('/invoice_address/' + $state.params.id + '/multiple_invoice/' + $scope.creditNoteDetails.invoiceId + '/credit_note/'+ $state.params.creditNoteId +'/view');
                    }
                }else{
            $state.transitionTo("loggedin.customer_list.view.credit_note.details", {id: $state.params.id, type: $state.params.type, creditNoteId: $state.params.creditNoteId}, {reload:true});
                }
            $scope.saving = false;
        });
    }
}

function JobSubNavCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal, $translate, $compile, $timeout, emailService, $templateCache) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        warningModal.show('This Job has been ' + message, 'Job', 'Deleted');
        $state.transitionTo('loggedin.customer_list.view.property', {'id':  $state.params.id, 'type': $state.params.type},{reload: true});
    }
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.hideSubNavCustomers = true;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.job = getIdData.jobDetails;
    $rootScope.moduleType = getIdData.moduleResults;
    $rootScope.clientJobType = getIdData.clientJobType;
    $rootScope.clientJobTodos = getIdData.clientJobTodos;
    $scope.filesCount = getIdData.filesCount;
    $scope.futureRemindersCount = getIdData.futureRemindersCount;
    $scope.notesCount = getIdData.notesCount;
    $scope.certificateCount = getIdData.certificateCount;
    $scope.jobStatus = getIdData.jobDetails.status;
    $scope.relatedJobs = getIdData.relatedJobs;
    $scope.job_is_recall = ($scope.job.recallof != null)?true:false;
    $scope.removeJobOnHoldShouldBeOpen = false;
    $scope.removeJobReserveListShouldBeOpen = false;
    $scope.recallsettings = $scope.job.recallsettings;
    $scope.invoicesCount = getIdData.invoicesCount;
    $scope.officeTaskCount = getIdData.officeTaskCount;
    $scope.partsCount =  getIdData.partsCount;
    $scope.no_pending_additional_works =  getIdData.no_pending_additional_works;
    $scope.multipleInvoicesCount =  getIdData.multipleInvoicesCount;
    $scope.advancedInvoicingModule = getIdData.advancedInvoicingModule;
  $scope.opportunitiesCount =  getIdData.opportunitiesCount;
  $scope.parentPropertyName = getIdData.parentPropertyName;
    $scope.$on("officeTasks:openCountUpdated", function (event, message) {
        if(message && message > 0) {
            $scope.$apply(function () {
                $scope.officeTaskCount = message;
            });
        } else {
            $scope.$apply(function () {
                $scope.officeTaskCount = 0;
            });
        }
    });

    $rootScope.$on("parts:countupdated", function (event, partsCount) {
        $scope.partsCount =  partsCount;
    });

    $rootScope.$on("opportunities:countupdated", function (event, opportunitiesCount) {
        $scope.opportunitiesCount =  opportunitiesCount;
    });

    $rootScope.$on("additionalWork:countupdated", function (event, additionalWorkCount) {
        $scope.no_pending_additional_works =  additionalWorkCount;
    });

    $rootScope.$on("event:changeJobScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });

    $scope.waringModalParam = [
        {
            "Freeofchargejob": {
                "Invoiced": {
                    "title": "Free.of.charge.job",
                    "warningMessage": "You.cannot.free.of.charge.this.job.because.this.job.has.already.invoice.raised"
                },
                "Aborted": {
                    "title": "Free.of.charge.job",
                    "warningMessage": "You.cannot.free.of.charge.this.job.because.this.job.has.been.aborted"
                },
                "FreeOfCharge": {
                    "title": "Free.of.charge.job",
                    "warningMessage": "You.cannot.free.of.charge.this.job.because.this.job.has.been.freeofcharged"
                },
                "Completed": {
                    "title": "Free.of.charge.job",
                    "warningMessage": "You.cannot.free.of.charge.this.job.because.this.job.has.already.completed"
                }
            },
            "Abortjob": {
                "Invoiced": {
                    "title": "Abort.job",
                    "warningMessage": "You.cannot.abort.this.job.because.this.job.has.already.invoice.raised"
                },
                "Aborted": {
                    "title": "Abort.job",
                    "warningMessage": "You.cannot.abort.this.job.because.this.job.has.been.aborted"
                },
                "FreeOfCharge": {
                    "title": "Abort.job",
                    "warningMessage": "You.cannot.abort.this.job.because.this.job.has.been.freeofcharged"
                },
                "Completed": {
                    "title": "Abort.job",
                    "warningMessage": "You.cannot.abort.this.job.because.this.job.has.already.completed"
                }
            }
        }
    ];


    $scope.showWarningModal = function (screenName, reason){
        if(reason == ''){
            var reason = 'Completed';
        }

        if(screenName == 'Freeofchargejob'){
            $scope.message = $scope.waringModalParam[0][screenName][reason].warningMessage;
            $scope.title = $scope.waringModalParam[0][screenName][reason].title;
        }else if(screenName == 'Abortjob'){
            $scope.message = $scope.waringModalParam[0][screenName][reason].warningMessage;
            $scope.title = $scope.waringModalParam[0][screenName][reason].title;
        }else{
            $scope.message = "You.cannot.abort.this.job.because.this.job.has.been.freeofcharged";
            $scope.title = "";
        }
        warningModal.show($scope.message, $scope.title, screenName+reason);
    }

    $scope.triggerRemoveJobOnHold = function () {
        $scope.jobId = $state.params.jobId || getIdData.jobId;
        $scope.removeJobOnHoldShouldBeOpen = true;
    }

    $scope.triggerRemoveJobReserveList = function(){
        $scope.jobId = $state.params.jobId || getIdData.jobId;
        $scope.removeJobReserveListShouldBeOpen = true;
    }
    $scope.triggerLinkJob = function(){
        $scope.jobId = $state.params.jobId || getIdData.jobId;
        $scope.linkJobShouldBeOpen = true;
    }
    $scope.triggerUnLinkJob = function(){
        $scope.jobId = $state.params.jobId || getIdData.jobId;
        $scope.unLinkJobShouldBeOpen = true;
    }
    $scope.triggerJobDelete = function (jobId) {
        $http.get(prefix + '/is_job_delete?id=' + $scope.selectedId + '&jobId=' + jobId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }
            else {
                $scope.jobDeleteShouldBeOpen = true;
            }
        });
    }
    $scope.fetchCustomerDetails = function (){
        $scope.flashMessage =  $translate('Job.deleted');
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.customer_list.view.property', {'id': $scope.selectedId, 'type': $scope.customerTabName});
    }
    $scope.removeJobOnHold = function () {
        $scope.flashMessage = 'Removed.the.job.from.on-hold.status';
        $scope.jobStatus = 'ongoing';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId },{reload:true});
    }

    $scope.removeJobReserveList = function (){
        $scope.flashMessage = 'Removed.the.job.from.reserve.list.status';
        $scope.jobStatus = 'ongoing';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId },{reload:true});
    }
    $scope.linkJob = function (){
        $scope.flashMessage = 'Linked.job.as.recall.to.another.job';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId },{reload:true});
    }
    $scope.unlinkJob = function (){
        $scope.flashMessage = 'Changed.this.recall.to.a.job';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId },{reload:true});
    }
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type});

    $scope.showJobReport = function() {
        var side_panel_tpl = $compile('<span id="view_job_report" ' +
            'sidepanel template="print_and_post" ' +
            'title="View job report" ' +
            'remove_on_close="true" ' +
            'index="' + this.$id + '"' +
            'print_and_post_message="Your job report is on its way to the customer" ' +
            'pdf_url="' + Routing.generate('send_job_report', { type: $scope.customerTabName, id: $scope.selectedId, jobId: $scope.jobId  }) +'"'+
            'supports_print="true"' +
            'supports_email="true"' +
            'supports_print_and_post="'+getIdData.printAndPostModule+'"' +
            'printpost_params="tableType=60&tablePKID='+$scope.jobId+'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_job_report').scope().$$childTail.initialise();
        }, 600);
    }
    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_job_report')) {
            angular.element('#view_job_report').remove();
        };
    });
    $scope.defaultJobReportSubject = getIdData.defaultJobReportSubject;
    $scope.defaultJobReportMessage = getIdData.defaultJobReportMessage;

    $scope.$on('email:send', function (evt, data) {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        var propertyId = getIdData.customerType == 'workaddress' ? getIdData.parentPropertyId : $scope.selectedId;
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + propertyId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + $scope.jobId;
        clone_obj.subject = $scope.defaultJobReportSubject;
        clone_obj.message = $scope.defaultJobReportMessage;
        clone_obj.relatedObjectType = 60;
        clone_obj.relatedObjectId = $scope.jobId;
        clone_obj.attached_files = [
            {
                id: "JOBREPORT|" + $scope.selectedId + "|" + $scope.jobId + "|" +clone_obj.relatedObjectType,
                file_name: 'Job No. ' + $scope.jobId,
                file_size: 0,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    });
    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_job_sheet')) {
            angular.element('#view_job_sheet').remove();
        };
    });
}

// This function for to view the diary event details
function ViewDiaryEvent($scope, $rootScope, $state, $filter, $timeout, $stateParams, jobData, emailService, confirmationBoxHelper, toastBox, $http, prefix, $sce, warningModal, $window, $translate, diaryEventLockOption, checkEngineerShift, gpsServerConfig, $location) {
    let jobsTab = Object.assign({}, $rootScope.jobsTab);
    delete $rootScope.jobsTab;
    $scope.isNg7=false;
    if(jobsTab.hasOwnProperty('fromRequest') && jobsTab['fromRequest'] == 'ng7') {
        $state.params.id = jobsTab['id'];
        $scope.screenType = jobsTab['screenType'];
        $scope.isNg7 = true;
        $state.params.jobId = jobsTab['jobId'];
    }
    /*==========================================================================================
     Initial Variables
     ==========================================================================================*/
    $scope.todos_store = [];
    $scope.unassigned_todos_store = [];
    $scope.$state = $state;
    $scope.id = $state.params.id;
    if ($state.params.jobId) {
        $scope.customizeId = $state.params.jobId;
    } else if($state.params.estimateId) {
        $scope.customizeId = $state.params.estimateId;
    } else if($state.params.type == "opportunity") {
        $scope.customizeId = $state.params.typeId;
    }

    $scope.in_estimates = false;
    $scope.data_loaded = false;
    $scope.milestoneId = '';
    $scope.showTodoTab = true;
    $scope.dateTimeError = false;
    $scope.serviceWindowError = false;
    $scope.in_estimates = false;
    $scope.event_overlapping = false;
    $scope.quantity = $rootScope.quantity;
    $scope.clientJobType = $rootScope.clientJobType;
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
    $scope.timeTrackingRecords = [];
    $scope.prefix = prefix;

    var currentStateName = $state.current.name;
    $scope.isInDiaryScreen = currentStateName == 'loggedin.diary' || currentStateName == 'loggedin.diary_from_job_estimate' || currentStateName == 'loggedin.diary_from_milestone' || currentStateName == 'loggedin.view_diary';

    // For google places
    $scope.placesOptions = {
        types: ['(cities)'],
    };
    $scope.add_normal_event_inputs = {};
    $scope.add_normal_event_inputs.address = {
        name: '',
        place: '',
        components: {
            placeId: '',
            streetNumber: '',
            street: '',
            city: '',
            state: '',
            countryCode: '',
            country: '',
            postCode: '',
            district: '',
            location: {
                lat: '',
                long: ''
            }
        }
    };

    if($state.includes('loggedin.customer_list.view.estimate_details.survey') || $state.includes('loggedin.customer_list.view.project_estimate_details.survey')) {
        var in_estimates = true,
            in_jobs = false;

        $scope.screenType = "estimate";

      /*This code block is commented out because the concept 'project' has been stopped.*/
      /*if($scope.$parent.$parent.estimate.isproject != null) {
         $scope.screenType = "estimateproject";
       }*/

    } else if($state.includes('loggedin.customer_list.view.job.visits') || $state.includes('loggedin.customer_list.view.job.details')) {
        var in_jobs = true,
            in_estimates = false;

      $scope.screenType = "job";
      /*This code block is commented out because the concept 'project' has been stopped.*/
      /*if($scope.$parent.$parent.job.isproject != null) {
        $scope.screenType = "project";
        $scope.milestoneId = $state.params.milestoneId;
      }*/
    }

    if($scope.isInDiaryScreen && $rootScope.diaryEventViewData) {
        $scope.screenType = $rootScope.diaryEventViewData.screenType;
        $scope.id = $rootScope.diaryEventViewData.id;
        var primaryId = $rootScope.diaryEventViewData.primaryId;

        if($scope.screenType == 'opportunity'){
            $scope.customizeId = primaryId;
            $scope.showTodoTab = false;
        }

        $rootScope.diaryEventViewData = false;
        if ($scope.screenType == 'estimate' || $scope.screenType == 'project_estimate') {
            $scope.id_of_estimate = primaryId;
            $scope.customizeId = primaryId;
            $scope.in_estimates = true;
            $scope.showTodoTab = false;//No need to show for estimate events
        } else {
            $scope.id_of_job = primaryId;
            $scope.customizeId = primaryId;
        }
        if($scope.screenType == 'normal'){
            $scope.customizeId = 0;
        }

    }

    $scope.timeDiffArrayData = [];
    $scope.setTimeDifferenceArrayData = function (event) {
        var start = event.start.date;
        var end = event.end.date;
        var allDay = event.allDay;
        $scope.timeDiffArrayData = getTimeDifferenceArrayData(start, end, allDay);
    }

    $scope.handleSpecialEventValues = function () {
        var arrayLength = $scope.timeDiffArrayData.length;
        if(!arrayLength) {
            $scope.diary_event.special_event = false;
        } else {
            var maxValue = arrayLength * 30;
            if($scope.diary_event.actualTime > maxValue) {
                $scope.diary_event.actualTime = maxValue;
                $scope.actualTime = maxValue;
            }
        }
    }

    DiaryDateTimeInputs.call(this, $scope, $timeout);

    // Skills related logic
    $scope.jobSkills = [];
    $scope.showEngineerWarninig = false;
    $scope.partiallyMatching = false;

    $scope.skillsChanged = function() {
        if($scope.jobSkills && !$scope.jobSkills.length) {
            return;
        }
        $scope.showEngineerWarninig = false;
        $scope.partiallyMatching = false;
        var selectedSkills = $scope.jobSkills;
        var isUserHasSkill = false;

        var userId = $scope.diary_event.engineer_id;
        var user = _.where($scope.diary_event.engineers, {userId: userId});
        if(user && user.length) {
            user = user[0];
            if(user.skills) {
                var userSkills = user.skills;
                var skillsMatched = 0;
                angular.forEach(userSkills, function (value, key) {
                    var isAvailable = _.where(selectedSkills, {id: value.id});
                    if(isAvailable.length) {
                        isUserHasSkill = true;
                        skillsMatched ++;
                    }
                });
            }
        }

        $scope.showEngineerWarninig = !isUserHasSkill;
        if(isUserHasSkill && selectedSkills.length && skillsMatched != selectedSkills.length) {
            $scope.partiallyMatching = true;
        }
        $scope.error = !isUserHasSkill;
    }

    // Get the diary event details
    $scope.fetchDiaryEventRecords = function fetchDiaryEventRecords(diaryEventId) {
        if ($scope.isInDiaryScreen && $scope.updateDiary) {
            var msg = $scope.updateDiary === 'cancelled' ? {action: 'cancel'} : null;
            $scope.$emit('event:refresh_diary_events', msg);
        }

        // Display the loading text
        $scope.data_loaded = false;
        var getDiaryEventURL = Routing.generate('get_diary_event', { id: $scope.id, diaryEventId: diaryEventId, screenType: $scope.screenType });
        if($scope.screenType == 'normal') {
            getDiaryEventURL = prefix + '/get_normal_diary_event/' + diaryEventId +'/details';
        }
        $http.get(getDiaryEventURL).success(function (data) {
            if(data.diaryEvents == '' || data.diaryEvents == null){
                warningModal.show($translate("Diary.already.deleted"), $translate("delete.diary.event"), 'delete_diary');
                $scope.$emit('sidepanel:close');
                return false;
            }
            $scope.setTimeDifferenceArrayData(data.diaryEvents);

            if($scope.updateDiary && !$scope.isInDiaryScreen) {
                if($scope.screenType == "job") {
                    $scope.$parent.$parent.diaryEvents[$scope.$parent.repeat_index] = data.diaryEvents;
                } else if($scope.screenType == "estimate" || $scope.screenType == "estimateproject") {
                    $scope.$emit('estimateTimeline:reload');
                } else {
                    $scope.$emit('projectJobTimelineView:reload');
                }
                $scope.panel_title = 'view';
                $scope.updateDiary = false;
            }

            if($scope.panel_title == 'edit' || typeof $scope.panel_title == 'undefined') {
                var eventToEdit = data.diaryEvents;


                $scope.start = moment(eventToEdit.start.date);
                $scope.end = moment(eventToEdit.end.date);
                $scope.all_day = eventToEdit.allDay;
                $scope.disableStartDateTime = false;

                if(eventToEdit.statusConstant == 5) {
                    $scope.disableStartDateTime = true;
                }
                $scope.initializeValues();
            }

            if($scope.updateDiary && $scope.isInDiaryScreen) {
                $scope.panel_title = 'view';
                $scope.updateDiary = false;
            }

            $scope.diary_event = data.diaryEvents;
            $scope.service_windows_details = data.service_windows;
            $scope.service_windows = data.all_service_windows;
            /**
             * Todo event lock related logic
             */
            $scope.lock_events_options = data.lock_events_options;
            $scope.eventisLocked = $scope.diary_event.lock_event;
            $scope.disableCSDateTime = false;
            if($scope.diary_event.lock_event == diaryEventLockOption.lock_by_datetime || $scope.diary_event.lock_event == diaryEventLockOption.lock_by_engineer_and_datetime){
                //2 is locked by datetime and 3 locked by datetime engineer
                $scope.disableCSDateTime = true;
            }
            else if($scope.diary_event.lock_event == diaryEventLockOption.lock_by_service_window && $scope.service_windows_details){ // 4 is locked by service windows

                if($scope.service_windows_details.length > 0){
                    $scope.service_window = {'start':$scope.service_windows_details['startTime']['date'],'end':$scope.service_windows_details['endTime']['date']}
                }
            }

            if($scope.screenType != 'normal'){
                let lock_event_option = _.where($scope.lock_events_options, { id: $scope.diary_event.lock_event })[0]
                if(lock_event_option){
                    $scope.diary_event['event_lock_status'] = _.where($scope.lock_events_options, { id: $scope.diary_event.lock_event })[0]['option']
                }
            } else {
                $timeout(function() {
                    let tileServer = gpsServerConfig.mapTilesServer;
                    var map = L.DomUtil.get('normal_location_map');
                    if (map != null) {
                        map._leaflet_id = null;
                    }

                    if ($scope.diary_event.location == undefined || $scope.diary_event.location == null || $scope.diary_event.location == '') {
                        return;
                    }

                    // Setup variables for edit
                    $scope.add_normal_event_inputs.address.name = $scope.diary_event.location
                    $scope.add_normal_event_inputs.address.components.location.lat = $scope.diary_event.latitude
                    $scope.add_normal_event_inputs.address.components.location.long = $scope.diary_event.longitude

                    map = L.map('normal_location_map', {
                        center: [$scope.diary_event.latitude, $scope.diary_event.longitude],
                        zoom: 17,
                        zoomControl: false,
                        dragging: false
                    });
                    let tileLayer = L.tileLayer(tileServer, {attribution: ''});
                    tileLayer.addTo(map);
                    $scope.locationMap = map;
                    map.invalidateSize();
                }, 0);
            }
            $scope.diaryEventComplete = $scope.diary_event.complete;
            $scope.todoAddWarningMessage = $scope.diary_event.todoAddWarningMessage;
            $scope.todoEditWarningMessage = $scope.diary_event.todoEditWarningMessage;
            $scope.todoRemoveWarningMessage = $scope.diary_event.todoRemoveWarningMessage;

            $scope.diary_event.engineer_notes = data.diaryEvents['engineer_notes'];
            $scope.tableTypeConstant = data.tableTypeConstant;
            $scope.diary_event.notes = data.diaryEvents['engineer_notes'].replace(/<br\s*[\/]?>/gi, "\n");
            $scope.diary_event.notes = $scope.diary_event.notes.replace(/&lt;/gi, "<");
            $scope.diary_event.message = data.diaryEvents['message'];

            $scope.context_scope = data.diaryEvents;
            $scope.allDiaryStatus = data.diaryEvents.allDiaryStatus;
            $scope.landlordKeys = data.diaryEvents.landlordKeys;
            if($scope.screenType != 'normal') {
                $scope.screenType = $scope.diary_event.diary_event_type.toLowerCase();
            }

            $scope.is_special_event = $scope.diary_event.special_event;
            $scope.actualTime = $scope.diary_event.actualTime;

            /*customer contacts */
            $scope.customerTelephonenumber = [];
            $scope.customerEmail = [];
            $scope.customerEmailId = '';
            angular.forEach(data.customerModules, function (key, value) {
                if(key.telephonenumber && key.settingsTypesoftelephonenumbersid == 1 && $scope.number != key.settingsTypesoftelephonenumbersid) {
                    $scope.number = key.customername +' - '+ key.telephonenumber;
                    $scope.customerTelephonenumber.push({ 'text': $scope.number, 'customername': key.customername, 'id': key.id, 'contactTelephoneId': key.contactTelephoneId, 'maincontact': key.maincontact, 'settingsTypesoftelephonenumbersid': key.settingsTypesoftelephonenumbersid});
                }
                if(key.text && $scope.customerEmailId != key.id) {
                    $scope.customerEmailId = key.id;
                    $scope.emailAddress = key.customername +' - '+ key.text;
                    $scope.customerEmail.push({ 'text': $scope.emailAddress, 'customername': key.customername, 'id': key.id, 'maincontact': key.maincontact, 'settingsTypesoftelephonenumbersid': key.settingsTypesoftelephonenumbersid});
                }
            });

            /* Customer email address*/
            $scope.select2ContactsOptions = {
                placeholder: "--- Please choose ---",
                'multiple': true,
                id: function (obj) {
                    return obj.id;
                },
                'data': $scope.customerEmail,
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: function (item) {
                    if (item != '') {
                        return 'No results match "' + item + '"';
                    } else {
                        return "";
                    }
                }
            };

            /* Customer contact telephone number */
            $scope.select2ContactsSmsOptions = {
                placeholder: "--- Please choose ---",
                'multiple': true,
                id: function (obj) {
                    return obj.id;
                },
                'data': $scope.customerTelephonenumber,
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: function (item) {
                    if (item != '') {
                        return 'No results match "' + item + '"';
                    } else {
                        return "";
                    }
                }
            };

            /* workaddress contacts */
            $scope.workaddressTelephonenumber = [];
            $scope.workaddressEmail = [];
            $scope.email = '';
            angular.forEach(data.workAddressModules, function (key, value) {
                if(key.telephonenumber && key.settingsTypesoftelephonenumbersid == 1 && $scope.number != key.settingsTypesoftelephonenumbersid) {
                    $scope.number = key.customername +' - '+ key.telephonenumber;
                    $scope.workaddressTelephonenumber.push({ 'text': $scope.number, 'customername': key.customername, 'id': key.id, 'contactTelephoneId': key.contactTelephoneId, 'maincontact': key.maincontact, 'settingsTypesoftelephonenumbersid': key.settingsTypesoftelephonenumbersid});
                }
                if(key.text && $scope.email != key.text) {
                    $scope.email = key.text;
                    $scope.emailAddress = key.customername +' - '+ key.text;
                    $scope.workaddressEmail.push({ 'text': $scope.emailAddress, 'customername': key.customername, 'id': key.id, 'maincontact': key.maincontact, 'settingsTypesoftelephonenumbersid': key.settingsTypesoftelephonenumbersid});
                }
            });

            /* workaddress email address*/
            $scope.select2WorkAddressContactsOptions = {
                placeholder: "--- Please choose ---",
                'multiple': true,
                id: function (obj) {
                    return obj.id;
                },
                'data': $scope.workaddressEmail,
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: function (item) {
                    if (item != '') {
                        return 'No results match "' + item + '"';
                    } else {
                        return "";
                    }
                }
            };

            /* workaddress contact telephone number */
            $scope.select2WorkAddressContactsSmsOptions = {
                placeholder: "--- Please choose ---",
                'multiple': true,
                id: function (obj) {
                    return obj.id;
                },
                'data': $scope.workaddressTelephonenumber,
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: function (item) {
                    if (item != '') {
                        return 'No results match "' + item + '"';
                    } else {
                        return "";
                    }
                }
            };

            /* engineer contacts */
            $scope.engineerTelephonenumber = [];
            $scope.engineerEmail = [];
            angular.forEach(data.engineerModules, function (key, value) {
                if(key.telephonenumber) {
                    $scope.telephonenumber = key.username +' - '+ key.telephonenumber;
                    $scope.engineerTelephonenumber.push({ 'text': $scope.telephonenumber, 'username': key.username, 'id': key.id});
                }
                if(key.text) {
                    $scope.email = key.username +' - '+ key.text;
                    $scope.engineerEmail.push({ 'text': $scope.email, 'username': key.username, 'id': key.id});
                }
            });

            /* Engineer email address */
            $scope.select2EngineerContactsEmailOptions = {
                placeholder: "--- Please choose ---",
                'multiple': true,
                id: function (obj) {
                    return obj.id;
                },
                'data': $scope.engineerEmail,
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: function (item) {
                    if (item != '') {
                        return 'No results match "' + item + '"';
                    } else {
                        return "";
                    }
                }
            };

            /* Engineer contact telephone number */
            $scope.select2EngineerContactsSMSOptions = {
                placeholder: "--- Please choose ---",
                'multiple': true,
                id: function (obj) {
                    return obj.id;
                },
                'data': $scope.engineerTelephonenumber,
                formatResult: function (item) {
                    return item.text;
                },
                formatSelection: function (item) {
                    return item.text;
                },
                formatNoMatches: function (item) {
                    if (item != '') {
                        return 'No results match "' + item + '"';
                    } else {
                        return "";
                    }
                }
            };

            $scope.tinymceOptions = {
                menubar: false,
                toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
                plugins: "paste,textcolor",
                paste_as_text: true,
                browser_spellcheck : true,
                paste_block_drop: false
            };
            $scope.tinymceSmsOptions = {
                menubar: false,
                toolbar: false,
                statusbar: false,
                plugins: "paste",
                browser_spellcheck : true,
                paste_as_text: true,
                paste_block_drop: false,
                relative_urls : false,
                remove_script_host : false,
                convert_urls : true
            };
            $scope.tinymceLetterOptions = {
                menubar: false,
                toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
                plugins: "paste,textcolor",
                browser_spellcheck : true,
                paste_as_text: true,
                paste_block_drop: false,
                fontsize_formats: "6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt"
            };

            ReminderDateCtrl.call(this, $scope, data, '', '', 'add', $state);
            $scope.data_loaded = true;

            if($scope.screenType == "job" && $scope.panel_title == 'edit') {
                $scope.jobSkills = data.jobSkills;
                $scope.skillsChanged();
            }

            $scope.resetPanel();
        })
    }

    // Get the diary event todos details
    $scope.getDiaryEventTodosRecords = function getDiaryEventTodosRecords(diaryEventId) {

        // Display the loading text
        $scope.data_loaded = false;

        if($scope.milestoneId) {
            var pathUrl = 'get_diary_event_todos_milestone',
                parameters = { "jobId": $scope.customizeId, "diaryEventId":diaryEventId, "screenType": $scope.screenType, "milestoneId": $scope.milestoneId  };
        } else {
            var pathUrl = 'get_diary_event_todos',
                parameters = { "jobId": $scope.customizeId, "diaryEventId": diaryEventId,  "screenType": $scope.screenType };
        }

        $http.get(Routing.generate(pathUrl, parameters)).success(function (data) {

            $scope.milestones = data.diaryEventTodos.milestones;
            $scope.milestoneCount = data.diaryEventTodos.milestoneCount;
            $scope.assignedTodosCount = data.diaryEventTodos.assignedTodosCount;

            // Update the unassigned todos tab or closed todos tab
            if(($scope.$parent.$parent.hasOwnProperty('job')) && ($scope.$parent.$parent.job.active_todos_filter == 'unassigned')) {
                $rootScope.$broadcast('spreadsheet:todoupdate');
            } else {
                $scope.$emit('todosWithDiaryEvent:selected', 1);
            }
            $scope.resetTodoFilters();
            $scope.countFilteredTodos();
            $scope.data_loaded = true;
        })
    }


    //Check the conditions to disable the save button in edit install todo
    $scope.$watch('active_edit_todo.quantity', function (newVal, oldVal){
        if(((newVal === '') || (newVal == null) || isNaN(newVal)) && $scope.active_edit_todo) {
            if(($scope.active_edit_todo.typeId == 6) && ($scope.active_edit_todo.Status == 'Done')) {
                $scope.message= false;
                $scope.quantityDisable =true;
            }
        }else if(( parseFloat(newVal) <= 0) || (parseFloat($scope.quantity) < newVal))
        {
            $scope.message= true;
            $scope.quantityDisable =true;
        }else{
            $scope.message= false;
            $scope.quantityDisable =false;
        }
    });

    $scope.$watch('active_edit_todo.feedback', function (newVal, oldVal){
        if((( newVal == '') || (newVal == null)))
        {
            $scope.feedbackDisable= true;
        }else{
            $scope.feedbackDisable= false;
        }
    });
    $scope.$watch('active_edit_todo.Status', function (newVal, oldVal){
        if(newVal == 'Done' && ($scope.active_edit_todo.quantity == '' ||$scope.active_edit_todo.quantity == null || isNaN($scope.active_edit_todo.quantity)))
        {
            if((typeof($scope.active_edit_todo) != 'undefined') && ($scope.active_edit_todo.Status !=='Done')){
                $scope.active_edit_todo.quantity = NaN;
                $scope.active_edit_todo.feedback = null;
            }
            if($scope.active_edit_todo.typeId == 6){
                $scope.quantityDisable= true;
            }else{
                $scope.quantityDisable= false;
            }

        }else{

            $scope.quantityDisable= false;
            if(($scope.active_edit_todo && $scope.active_edit_todo.Status !=='Open') && $scope.feedback){
                $scope.feedbackDisable= false;
            }else{
                $scope.feedbackDisable= true;
                if((typeof($scope.active_edit_todo) != 'undefined') && ($scope.active_edit_todo.Status !=='Done')){
                    $scope.active_edit_todo.quantity = NaN;
                    $scope.active_edit_todo.feedback = null;
                }
            }
        }
    });

    // Get the unassigned todos details
    $scope.fetchUnassignedTodos = function fetchUnassignedTodos() {

        $scope.slideInView(3);
        // Display the loading text
        $scope.data_loaded = false;

        if($scope.milestoneId) {
            var pathUrl = 'get_unassigned_todos_milestone',
                parameters = { "jobId": $scope.customizeId, "screenType": $scope.screenType, "milestoneId": $scope.milestoneId  };
        } else {
            var pathUrl = 'get_unassigned_todos',
                parameters = { "jobId": $scope.customizeId, "screenType": $scope.screenType  };
        }

        $http.get(Routing.generate(pathUrl, parameters )).success(function (data) {

            $scope.milestones = data.todos.milestones;
            $scope.milestoneCount = data.todos.milestoneCount;
            $scope.unassignedTodosCount = data.todos.unassignedTodosCount;
            $scope.data_loaded = true;
        })
    }

    // Get the assigned todos to remove
    $scope.fetchRemoveAssignedTodos = function fetchRemoveAssignedTodos() {

        $scope.slideInView(4);
        $scope.getDiaryEventTodosRecords(diary_event_id);
    }

    // Edit the diary event details
    $scope.saveDiaryEvent = function saveDiaryEvent() {
        var closeAfterUpdate = arguments[0];
        $scope.updating = true;
        var startDate = moment($scope.startDateTime);
        var endDate = moment($scope.endDateTime);
        var allDay = ($scope.isAllDay) ? $scope.allDayValue : false;

        var engineerId = $scope.diary_event.engineer_id;
        var event_start = startDate.format('YYYY-MM-DD HH:mm:ss');
        var event_end = endDate.format('YYYY-MM-DD HH:mm:ss');
        var shiftModule = ($rootScope.moduleDetails!='undefined') ? (($rootScope.moduleDetails.Shifts) !='undefined' ? $rootScope.moduleDetails.Shifts : 0 ) : 0;
        var continue_overtime = checkEngineerShift.checkShift(event_start, event_end, engineerId, shiftModule, allDay);
        continue_overtime.then(function (re) {
            if (re == 'overtime'|| re == 'nobreaktime' || re == 'overtime_nobreak') {
                if(re == 'nobreaktime'){
                    confirmation_message = 'This event falls into engineer break time. Please confirm';
                }else if(re == 'overtime_nobreak'){
                    confirmation_message = 'This event falls into engineer break time and outside of the working hours and into overtime. Please confirm';
                }
                confirmationBoxHelper.getConfirmation(confirmation_message, $scope)
                    .then(function () {
                        $scope.confirmUpdateEvent(this, startDate, endDate, allDay, closeAfterUpdate);
                    },function(){
                        $scope.updating = false;
                    });
            }else if(re == 'overlappingshift'){
                warningModal.show('The.shift.with.engineer.have.any.pattern.selected.time', 'Diary.event', 'diary_overlapping_message');
                $scope.updating = false;
            }else{
                $scope.confirmUpdateEvent(this,startDate,endDate,allDay,closeAfterUpdate);
            }

        })
        var confirm_overtime = false;
        var confirmation_message = 'This event falls outside of the working hours and into overtime. Please confirm';
    }
    $scope.confirmUpdateEvent = function confirmUpdateEvent(self,startDate,endDate,allDay,closeAfterUpdate){
        var updateData = {"description" : this.diary_event.description, "engineerNotes" :  this.diary_event.notes,
            "engineerId" : this.diary_event.engineer_id, "is_special_event": this.diary_event.special_event, "actualTime": this.diary_event.actualTime,
            "accessMethod": this.diary_event.diaryAccessMethod, "accessNote": this.diary_event.diaryAccessNote, "parts": $scope.active_edit_part,
            "event_start": startDate.format('YYYY-MM-DD HH:mm:ss'),"event_end": endDate.format('YYYY-MM-DD HH:mm:ss'),"all_day": allDay,
            "location": this.add_normal_event_inputs.address.name, 'latitude': this.add_normal_event_inputs.address.components.location.lat,
            "longitude": this.add_normal_event_inputs.address.components.location.long
        };

        updateData['lock_event'] = this.diary_event.lock_event;
        updateData['previous_lock_status'] = $scope.eventisLocked


        $http.put(prefix + '/edit_diary_event/' + diary_event_id, updateData).success(function (data) {
            $scope.updating = false;
          if(data == 'defaultLocationError'){
            warningModal.show("You cannot pick up this parts because engineer need to set up default stock location", "Engineer default location error", 'default_location_error');
          }
          else {
              if(startDate > endDate)
              {
                  $scope.$broadcast('datepicker:handle_diary_validation', '');
                  $scope.error = true;
              }else if (data != 'Event_overlapping') {
              $scope.updateDiary = true;
                var idFromData = parseInt(data);
                if(idFromData > 0) {
                    diary_event_id = idFromData;
                }
                $scope.panel_title = '';
              $scope.fetchDiaryEventRecords(diary_event_id);
              $scope.slideInView(1);
              if(data != 'Diary event already deleted'){
                  toastBox.show($translate('Diary.event.updated'), 2000, 300);
              }
              if (closeAfterUpdate == 'close') {
                $scope.closePanel();
              }
            }
            else {
                  $scope.event_overlapping = true;
                  $scope.error = true;
                }
            }
        });
    }

    $scope.onEngineerChange = function onEngineerChange() {
        $scope.event_overlapping = false;
        $scope.error = false;
        $scope.skillsChanged();
    }

    $scope.$watchCollection('[startDateTime, endDateTime]', function (newVal, oldVal) {
        $scope.event_overlapping = false;
        $scope.error = false;
        $scope.skillsChanged();
    });

    // Edit the job todos status details
    $scope.saveTodo = function saveTodo() {
        var updateData = {"status": this.active_edit_todo.Status, "engineerFeedback" : this.active_edit_todo.feedback, "quantity" : this.active_edit_todo.quantity};

        $http.put(prefix + '/edit_job_todo/' + $scope.jobTodoId, updateData).success(function (data) {

            $scope.$emit('todosWithDiaryEvent:selected', 1);
            $scope.activateTab('todos');
            toastBox.show(data, 2000, 300);
        });
    }

    // Handle edit diary event
    $scope.editDiaryEvent = function editDiaryEvent() {
        if($scope.diary_event.complete == true) {
            $scope.slideInView(16, $scope.diary_event.diaryEditWarningMessage);
        } else {
            $scope.editView();
        }
    }

    $scope.redirectToOpportunities = function redirectToOpportunities(diaryEvent){
      $scope.closePanel();
      var customerId = diaryEvent.redirectInfo.propertyId;
      var jobEstimateID = diaryEvent.redirectInfo.jobEstimateID;
      $location.path(`customers/customer/${customerId}/opportunity/${jobEstimateID}/view`);
    }

    // View the edit screen
    $scope.editView = function editView() {
        $scope.panel_title = 'edit';
        $scope.fetchDiaryEventRecords(diary_event_id);
        //$scope.slideInView(2);
    }

    var diary_event_id = parseInt($scope.$parent.collection);
    $scope.diary_event_id = diary_event_id;
    $scope.jobsheetUrl = prefix + '/diary/view_job_sheet/print/' + diary_event_id;

    if (in_estimates) {
        $scope.context_scope = $scope.$parent.$parent.estimate;
        $scope.in_estimates = true;
        $scope.id_of_estimate = $scope.$state.params.estimateId;
    }
    else if (in_jobs) {
        $scope.id_of_job = $scope.$state.params.jobId;
    }

    $scope.updateDiary = false;
    $scope.fetchDiaryEventRecords(diary_event_id);

    $scope.customerConfirmationActive = false;
    $scope.engineerConfirmationActive = false;

    $scope.activeConfirmations = {
        engineer: false,
        customer: false,
        work_address: false
    }

    $scope.activeReminders = {
        engineer: false,
        customer: false,
        work_address: false
    }

    $scope.active_tab = 'details';
    $scope.error = false;

    /*==========================================================================================
     Open panel on initialization
     ==========================================================================================*/
    $scope.openPanel = function openPanel() {
        $timeout(function() {
            $scope.initialise();
        }, 0);
    }

    $scope.getUserList = function getUserList () {
        var sortOrder = 'name:asc';
        $http.get(prefix + '/get_clientUsers?sort=' + sortOrder )
            .success(function (data) {
                $scope.userList = data.userRecords;
            });
    }

    $scope.checkIsUserActive = function checkIsUserActive (id = null, updateUserList = false, editFeedbackDetails = {}) {
        if(id) {
            $http.get(prefix + '/getIsUserActive?userId=' + id )
                .success(function (data) {
                    $scope.selectedUser = data;

                    if(updateUserList && data.isDeleted == 1) {
                        $scope.userList.push(data);
                    }
                });
        }

        editFeedbackDetails['show'] = true;
    }

    /*==========================================================================================
     Handle switching tabs
     ==========================================================================================*/
    $scope.activateTab = function activateTab(tab) {

        if($scope.active_slide == 3 || $scope.active_slide == 4 || $scope.active_slide == 5) {
            tab = 'todos';
        } else if($scope.active_slide == 9) {
            tab = 'feedback';
        }

        if(tab === 'details') {

            $scope.panel_title = 'view';
            $scope.fetchDiaryEventRecords(diary_event_id);

        } else if(tab === 'todos') {
            $scope.active_slide =1;
            $scope.getDiaryEventTodosRecords(diary_event_id);
        } else if(tab === 'feedback') {
            $scope.active_slide =1;
            $scope.getDiaryEventFeedBack(diary_event_id);
        }

        $scope.active_tab = tab;
    };

    /*==========================================================================================
     Watch the stores for whether a todo is checked ~ the save button should be disabled
     ==========================================================================================*/
    $scope.handleValidation = function handleValidation() {
        if ($scope.unassigned_todos_store.length > 0 || $scope.todos_store.length > 0) {
            $scope.error = true;
        } else {
            $scope.error = false;
        }
    };

    /*==========================================================================================
     When flipping back to panel 1 view, remove the edit state from the todo
     ==========================================================================================*/
    $scope.removeEditStateFromTodos = function removeEditStateFromTodos() {
        for (var i = 0, l = $scope.diary_event.todos.length; i < l; i++) {
            $scope.diary_event.todos[i].editing = false;
        }
    }

    /*==========================================================================================
     When clicking to edit a todo make a copy of the todo for the panel to use
     ==========================================================================================*/
    $scope.handleEditTodo = function handleEditTodo(todoId) {

        $scope.data_loaded = false;
        $scope.slideInView(5);
        $scope.jobTodoId = todoId;

        $http.get(Routing.generate('get_jobtodo', { todoId: todoId })).success(function (data) {

            $scope.active_edit_todo = data.activeTodo;
            $scope.quantityDisable =false;
            $scope.feedbackDisable =false;

            $scope.quantityDisabled = false;
            if(data.activeTodo.quantity) {
                $scope.quantityDisabled = true;
            }
            $scope.statusArray = data.statusArray;
            $scope.data_loaded = true;
            $scope.active_edit_todo.quantity= parseFloat($scope.active_edit_todo.quantity);
            $scope.quantityValue = isNaN($scope.active_edit_todo.quantity);
            $scope.quantity = data.activeTodo.totalQuantity;
            $scope.feedback = data.activeTodo.feedback;

        })
    }

    /*==========================================================================================
     When clicking to edit a part make a copy of the part for the panel to use
     ==========================================================================================*/
    $scope.handleEditPart = function handleEditPart(part) {
        $scope.active_edit_part = part;
        $scope.$broadcast('newPartEdit', part);
    }

    /*==========================================================================================
     When clicking to edit a key, make a copy of it for the panel to use
     ==========================================================================================*/
    $scope.handleEditKey = function handleEditKey(key, diaryEventId) {

        $http.get(Routing.generate('get_collect_keys', { diaryEventId: diaryEventId })).success(function (data) {
            $scope.status = data.landlordKeyDetails.status;
            $scope.diaryEventId = data.landlordKeyDetails.diaryeventid;
            $scope.statusUpdateOn = data.landlordKeyDetails.statusupdateon;
            $scope.active_edit_key = key;
            $scope.$broadcast('newKeyEdit', key);
        })
    }

    // Get the diary event feedback
    $scope.getDiaryEventFeedBack = function getDiaryEventFeedBack(diaryEventId) {

        // Display the loading text
        $scope.data_loaded = false;

        $http.get(Routing.generate('get_diary_event_feedback', { jobId: $scope.customizeId, diaryEventId: diaryEventId, screenType: $scope.screenType })).success(function (data) {
            $scope.diary_event = data.diaryEventDateTime;
            $scope.diary_event_feed_back = data.diaryEventFeedBack;
            $scope.diaryEventConfigureArriveResults = data.diaryArriveResults;
            $scope.diaryEventConfigureLeaveResults = data.diaryLeaveResults;
            $scope.diary_event_status = data.diaryEventStatus;
            $scope.diary_event_all_status = data.diaryEventAllStatus;
            $scope.show_question = data.showQuestion;
            $scope.engineer_feedback = data.engineerFeedback;
            $scope.engineerSignature = data.engineerSignature;
            $scope.initFeedback($scope.diary_event_feed_back);
            $scope.initSignature($scope.engineerSignature);
            $scope.userList = [];
            $scope.getUserList();
            $scope.diaryEventId = data.diaryEventId;
            $scope.data_loaded = true;
            $scope.timeTrackingRecords = $scope.setTimeTrackingOriginalValues(data.timeTrackingRecords);

            $scope.renderMap();
        })
    }

    $scope.setTimeTrackingOriginalValues = function(timeTrackingRecords) {
        var result = [];
        _.each(timeTrackingRecords, function(value, key){
            value.original_time = moment(value.dateTime.date).format('HH.mm');
            result.push(value);
        });
        return result;
    }

    $scope.selectOption = function selectOption(index) {
        $scope.feedBackQuestion.options[index].checked = !$scope.feedBackQuestion.options[index].checked;
        $scope.handleSelectedOptions();
    }

    $scope.handleSelectedOptions = function handleSelectedOptions() {
        $scope.feedBackSaveButton = true;
        for(var i = 0, l = $scope.feedBackQuestion.options.length; i < l; i++){
            var option = $scope.feedBackQuestion.options[i];
            if ((JSON.parse(option.checked) === true) && ($scope.feedBackQuestion.questionrequired === true)) {
                $scope.feedBackSaveButton = false;
            } else if ($scope.feedBackQuestion.questionrequired === false) {
                $scope.feedBackSaveButton = false;
            }
        }
    }

    $scope.show_date_picker = false;
    /*==========================================================================================
     When clicking to edit a question, expose it on the scope
     ==========================================================================================*/
    $scope.handleEditQuestion = function handleEditQuestion(question, diaryEventId) {
        $scope.active_edit_question = question;
        $scope.diaryEventId = diaryEventId;
        $scope.feedBackSaveButton = true;
        $http.get(prefix + '/get_question_answer?jobId=' + $scope.customizeId + '&diaryEventId=' + diaryEventId + '&questionId=' + question.id + '&questionType=' + question.questiontype + '&settingsType=' + question.settingstype).success(function (data) {
            $scope.$broadcast('newQuestionEdit', question);
            if (data.editAnswer.questiontype != 3) {
                data.editAnswer.answer = (data.editAnswer.answer == null || data.editAnswer.answer == '') ? '' : data.editAnswer.answer.replace(/\\n/g, " \n");
            }
            $scope.feedBackQuestion = data.editAnswer;
            if($scope.feedBackQuestion.questiontype == 5 && data.editAnswer.answer == null && data.editAnswer.questionrequired == false) {
                $scope.feedBackQuestion.answer = 0;
            } else if($scope.feedBackQuestion.questiontype == 5 && data.editAnswer.answer == null && data.editAnswer.questionrequired == true) {
                $scope.feedBackQuestion.answer = data.editAnswer.options[0].value;
            } else {
                $scope.feedBackQuestion.answer = $scope.feedBackQuestion.answer;
            }
            if($scope.feedBackQuestion.questiontype == 3) {
                $scope.selectedDate = moment($scope.feedBackQuestion.answer.date);
                $scope.$broadcast('event:change_default_selected_date', {date: $scope.feedBackQuestion.answer});
            }
            $scope.newEditFeedback = false;
            $scope.feedBackSaveButton = false;
            $scope.slideInView(9);
        });
    }

    $scope.editArriveLeaveFeedback = function(questionId, diaryId, feedbackType, feedbackId, feedbackPkId) {

        $http.get(prefix + '/get_job_diary_single_feedback?diaryId=' + diaryId + '&questionId=' + questionId + '&feedbackType=' + feedbackType + '&feedbackId=' + feedbackId + '&feedbackPkId=' + feedbackPkId).success(function (data) {
            $scope.editFeedbackError = data.error;
            if(data.error) {
                $scope.editFeedbackErrorMessage = data.editFeedbackDetails;
                $scope.slideInView(9);
                return;
            }

            $scope.editFeedbackDetails = data.editFeedbackDetails;
            if(data.editFeedbackDetails.fieldAnswerType == 'Date') {
                $scope.selectedDate = moment($scope.editFeedbackDetails.answerDetails.date);
                $scope.$broadcast('event:change_default_selected_date', {date: $scope.editFeedbackDetails.answerDetails});

            } else if(data.editFeedbackDetails.fieldAnswerType == 'Checkbox') {
                angular.forEach($scope.editFeedbackDetails['fieldAnswerOptions'], function (value, key) {
                    $scope.checkboxValues = $scope.editFeedbackDetails['answerDetails'] ? $scope.editFeedbackDetails['answerDetails'].split(',') : [];
                    if($scope.checkboxValues.indexOf(value.optionValue) < 0) {
                        value.checked = false;
                    } else {
                        value.checked = true;
                    }
                });
            }  else if(data.editFeedbackDetails.fieldAnswerType == 'Photo') {
                $scope.editFeedbackDetails['oldPhotos'] = [];
            } else if($scope.editFeedbackDetails.fieldAnswerType == 'Input with Selection Choices') {
                if($scope.editFeedbackDetails['answerDetails']) {
                    $scope.editFeedbackDetails['answerDetails'] = [{'id': $scope.editFeedbackDetails['answerDetails'], 'text': $scope.editFeedbackDetails['answerDetails']}];
                }
                $scope.customOption = [];
                angular.forEach($scope.editFeedbackDetails['fieldAnswerOptions'], function (value, key) {
                    $scope.customOption.push({id:value.optionValue, text:value.optionValue});
                });
                $scope.customSelection.data = $scope.customOption;
            } else if($scope.editFeedbackDetails.fieldAnswerType == 'Time') {
                if($scope.editFeedbackDetails['answerDetails']) {
                    $scope.editFeedbackDetails['answerDetails'] = moment($scope.editFeedbackDetails['answerDetails']).format('YYYY-MM-DD HH:mm');
                } else {
                    $scope.editFeedbackDetails['answerDetails'] = moment().format('YYYY-MM-DD HH:mm');
                }

                $scope.changeDiaryEventDateTime('', moment($scope.editFeedbackDetails['answerDetails']).format('YYYY-MM-DD HH:mm'));
            } else if(data.editFeedbackDetails.fieldAnswerType == 'User') {
                data.editFeedbackDetails['show'] = false;
                $scope.checkIsUserActive(data.editFeedbackDetails.answerDetails, true, data.editFeedbackDetails);
            }

            $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
            $scope.validFileTypes = ["jpeg","jpg", "png", "gif"];
            $scope.onArriveLeaveValidate();
            $scope.newEditFeedback = true;
            $scope.slideInView(9);

            $scope.base64Image = '';
            $scope.signatureText = '';
        });
    }


    $scope.customSelection = {
        tags: true,
        data: $scope.customOption,
        createSearchChoice: function(term, data) {
            var exists = false;
            data.forEach((idText) => {
                if(idText.text == term)
                    exists = true;
            });
            return term == ' ' || exists ?  null :
                { id: term, text: term };
        },
        formatSelection: function(item) {
            return item.text;
        },
        formatResult: function(item) {
            return item.text;
        }
    }

    $scope.onSelectInputWithSelection = function(event) {
        var data = $scope.customOption;
        if(typeof event != 'undefined') {
            if(event.length && event.length != 1){
                var index = data.findIndex((idText) =>{
                    return idText['id'] == event[1];
                });
                var selector = `#${$scope.editFeedbackDetails.questionID}`;
                if(index != -1) {
                    var idText = data[index];
                    $(selector).select2('data', idText);
                    $scope.editFeedbackDetails.answerDetails = idText['text'];
                }else{
                    //$(selector).select2('data', { id: event[1], text: event[1] });
                    $(selector).select2('data', event[1]);
                    $scope.editFeedbackDetails.answerDetails =event[1];
                }
            }else if(event.length == 1){
                var index = data.findIndex((idText) =>{
                    return idText['id'] == event[0];
                });
                if(index != -1){
                    var idText = data[index];
                    $scope.editFeedbackDetails.answerDetails = idText['text'];
                } else if(index == -1) {
                    $scope.editFeedbackDetails.answerDetails = event[0];
                }
            }
        }
        $scope.onArriveLeaveValidate();
    }

    $scope.changeDiaryEventDateTime = function(name, value) {
        if(typeof $scope.editFeedbackDetails != 'undefined') {
            $scope.editFeedbackDetails['answerDetails'] = value;
            $scope.onArriveLeaveValidate();
        }
    };

    $scope.onUploadSuccess = function (resp, key, value) { /*console.log('resp', resp);*/
        $scope.displayError = false;
        if (!resp.error) {
            $scope.editFeedbackDetails['oldPhotos'][key] = value;
            $scope.editFeedbackDetails['answerDetails'][key] = resp.message.file;
            $scope.displayError = false;
        } else {
            $scope.errorMessage = resp.message;
            $scope.displayError = true;
        }
        $scope.onArriveLeaveValidate();
    }

    $scope.onArriveLeaveValidate = function() {
        if($scope.editFeedbackDetails.required == true && ($scope.editFeedbackDetails.answerDetails == null || $scope.editFeedbackDetails.answerDetails == '')) {
            $scope.feedBackSaveButton = true;
            $scope.errorValidation = true;
        } else {
            $scope.feedBackSaveButton = false;
            $scope.errorValidation = false;
        }
    }

    $scope.getSignature = function(base64Image){
        $scope.editFeedbackDetails['answerDetails'] = base64Image;
        $scope.onArriveLeaveValidate();
    }

    $scope.multiCheckboxOption = function(index) {
        $scope.editFeedbackDetails.fieldAnswerOptions[index].checked = !$scope.editFeedbackDetails.fieldAnswerOptions[index].checked;
        $scope.handleCheckboxOptions();
    }

    $scope.handleCheckboxOptions = function() {
        $scope.feedBackSaveButton = true;
        $scope.editFeedbackDetails.answerDetails='';
        for(var i = 0, l = $scope.editFeedbackDetails.fieldAnswerOptions.length; i < l; i++){
            var option = $scope.editFeedbackDetails.fieldAnswerOptions[i];
            if((JSON.parse(option.checked) === true)) {
                if($scope.editFeedbackDetails.answerDetails) {
                    $scope.editFeedbackDetails.answerDetails = $scope.editFeedbackDetails.answerDetails +','+option.optionValue;
                } else {
                    $scope.editFeedbackDetails.answerDetails = option.optionValue;
                }
            }
            if ((JSON.parse(option.checked) === true) && ($scope.editFeedbackDetails.required === true)) {
                $scope.feedBackSaveButton = false;
            } else if ($scope.editFeedbackDetails.required === false) {
                $scope.feedBackSaveButton = false;
            }
        }
    }

    $scope.dataPickerWatch = $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.$watchCollection('feedBackQuestion', function (newVal, oldVal) {
        if (newVal != undefined) {
            if (newVal.questionrequired == true && newVal.answer != null) {
                $scope.feedBackSaveButton = false;
            } else if (newVal.questionrequired == true && newVal.answer == null) {
                $scope.feedBackSaveButton = true;
            } else if(newVal.questionrequired == false) {
                $scope.feedBackSaveButton = false;
            }
        }
    });

    $scope.saveQuestion = function saveQuestion(diaryEventId) {

        $scope.active_edit_question.answered = true;
        $scope.feedBackAnswer = this.feedBackQuestion;
        if($scope.feedBackAnswer.questiontype == 3) {
            $scope.feedBackAnswer.answer = moment($scope.selectedDate).format('YYYY-MM-DD');
        }
        $scope.data_loaded = false;
        $http.post(prefix + '/save_diary_event_feedback_answer/' + $scope.customizeId + '/' + diary_event_id + '/' + $scope.screenType, "data=" + encodeURIComponent(JSON.stringify($scope.feedBackAnswer))).success(function (data) {
            $scope.diaryEventId = data.diaryEventId;
            $scope.getDiaryEventFeedBack(diary_event_id);
            $scope.data_loaded = true;
            $scope.slideInView(1);
        })
    }

    $scope.saveArriveLeaveQuestion = function saveArriveLeaveQuestion() {

        $scope.editFeedbackDetails = this.editFeedbackDetails;
        if($scope.editFeedbackDetails.fieldAnswerType == 'Date') {
            $scope.editFeedbackDetails.answerDetails = moment($scope.selectedDate).format('YYYY-MM-DD');
        } else if($scope.editFeedbackDetails.fieldAnswerType == 'Input with Selection Choices') {
            $scope.editFeedbackDetails.answerDetails = $scope.editFeedbackDetails.answerDetails.text;
        }
        $scope.data_loaded = false;
        $http.post(prefix + '/save_arrive_leave_feedback/' + diary_event_id, "data=" + encodeURIComponent(JSON.stringify($scope.editFeedbackDetails))).success(function (data) {
            $scope.diaryEventId = data.diaryEventId;
            $scope.getDiaryEventFeedBack(diary_event_id);
            $scope.data_loaded = true;
            $scope.slideInView(1);
        })
    }

    $scope.getMostRecentEngineerFeedback = function getMostRecentEngineerFeedback() {
        for (i = 0; i < $scope.job.milestones.length; i += 1) {
            var milestone = $scope.job.milestones[i];
            if (milestone.diary_events.length > 0) {
                for (x = 0; x < milestone.diary_events.length; x += 1) {
                    var diary_event = milestone.diary_events[x];

                    if (diary_event.engineer_feedback) {
                        if (diary_event.engineer_feedback.length > 0) {
                            return diary_event.engineer_feedback;
                        }
                    }
                }
            }
        }
        return "All work completed and property left tidy";
    }

    $scope.getConfirmationPdf = function(resendConfirmation, dynamicType, e) {
        if (!e.target.classList.contains('disabled')) {
            var resendData = resendConfirmation.replace(/&nbsp;/gi,' ');
            $http.get(prefix + '/resend_pdf_preview_confirmation?letter[resend_confirmation]=' + encodeURIComponent(resendData) + '&letter[id]=' + $scope.id + '&letter[customizedId]=' + $scope.customizeId + '&letter[screenType]=' + $scope.screenType + '&letter[dynamicType]=' + dynamicType, {responseType:'arraybuffer'}).success(function (data) {
                var file = new Blob([(data)], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                $scope.data = $sce.trustAsResourceUrl(fileURL);
                $scope.slideInView(13);
            });
        }
    }
    $scope.openTab = function(resendConfirmation) {
        $scope.url = prefix+'/resend_preview_confirmation?resend_confirmation=' + resendConfirmation;
        slideInView(1);
    }

    /*==========================================================================================
     Handle sending confirmations, reminders and job sheets
     ==========================================================================================*/
    $scope.jobConfirmationTemplates = [];
    $scope.jobConfirmationTemplate = '';
    $scope.dataFromResponse = {'email': {'subject': '', 'message': ''}, 'sms': {'subject': '', 'message': ''}, 'letter': {'subject': '', 'message': ''}};
    $scope.setDefaultValue = function(confirmation) {
        $scope.dataFromResponse.email.subject = confirmation.subject;
        $scope.dataFromResponse.email.message = confirmation.emailMessage;
        $scope.dataFromResponse.sms.message = confirmation.textMessage;
        $scope.dataFromResponse.letter.message = confirmation.letterMessage;
    }
    $scope.setConfirmationTemplate = function(templateId) {
        var selectedTemplate = $scope.dataFromResponse;
        $scope.jobConfirmationTemplate = templateId;
        if(templateId) {
            var jobConfirmationTemplate = _.where($scope.jobConfirmationTemplates, { id: parseInt(templateId)})[0]
            selectedTemplate = jobConfirmationTemplate.confirmationTemplates;
            selectedTemplate.email.message += $scope.defaultFooter;
        }
        $scope.active_confirmation.subject = selectedTemplate.email.subject;
        $scope.active_confirmation.emailMessage = selectedTemplate.email.message;
        $scope.active_confirmation.textMessage = selectedTemplate.sms.message;
        $scope.active_confirmation.letterMessage = selectedTemplate.letter.message;
    }
    $scope.selectedConfirmationType = '';
    $scope.defaultFooter = '';
    $scope.showConfirmationView = function showConfirmationView(confirmation, type) {
        $scope.selectedConfirmationType = type;
        var resend_diary_event_details = {};
        resend_diary_event_details['diary_event_id'] = this.diary_event.id;
        resend_diary_event_details['engineer_id'] = this.diary_event.engineer_id;
        resend_diary_event_details['diary_event_type'] = this.diary_event.diary_event_type;
        resend_diary_event_details['engineer_name'] = this.diary_event.engineer_name;
        resend_diary_event_details['customer_contact_email'] = this.diary_event.customer_contact_email;
        resend_diary_event_details['customer_contact_mobile'] = this.diary_event.customer_contact_mobile;
        resend_diary_event_details['diary_event_created_date'] = this.diary_event.createdDate.date;
        resend_diary_event_details['engineer_email'] = this.diary_event.engineer_email;
        if (type == 'customer') {
            $scope.resendConfirmation = this.diary_event.empty_confirmations[0];
        } else if (type == 'workaddress' || type == 'customer_reminder') {
            $scope.resendConfirmation = this.diary_event.empty_confirmations[1];
        } else if (type == 'work_address_reminder') {
            $scope.resendConfirmation = this.diary_event.empty_confirmations[2];
        } else if (type == 'engineer') {
            $scope.resendConfirmation = this.diary_event.engineer_empty_job_sheet[0];
        }
        resend_diary_event_details['resendConfirmation'] = $scope.resendConfirmation;
        resend_diary_event_details['customerType'] = confirmation.customerType;
        $http.post(prefix + '/edit_resend_confirmation','customizeId=' + $scope.customizeId + '&type=' + type + '&confirmationEditId=' + confirmation.communicationId + '&diaryEvent=' + encodeURIComponent(angular.toJson(resend_diary_event_details))).success(function (data) {
            $scope.active_confirmation = data.customerConfirmation;
            if(!$scope.jobConfirmationTemplates.length) {
                $scope.jobConfirmationTemplates = data.templates;
            } else {
                if($scope.jobConfirmationTemplate > 0) {
                    $scope.setConfirmationTemplate($scope.jobConfirmationTemplate);
                }
            }
            $scope.defaultFooter = data.defaultFooter;
            $scope.setDefaultValue(data.customerConfirmation);
            $scope.dynamicType = data.customerConfirmation.customizedType;
            if($scope.dynamicType == 'work_address_reminder' || $scope.active_confirmation.method == 'Letter'){
                $scope.active_confirmation.method = 'Email';
            }
            $scope.printpost_params = data.print_post_params;
            $scope.moduleAccess = data.moduleAccess;
            $scope.print_post_allow = $scope.sms_allow = false;
            /* Checking the Print and Post module permission */
            if($scope.moduleAccess.PrintAndPost == 1) {
                $scope.print_post_allow = true;
            }
            /* Checking the SMS module permission */
            if($scope.moduleAccess.SMS == 1) {
                $scope.sms_allow = true;
            }
            $scope.data_loaded = true;
            $scope.slideInView(8);
        });
        $scope.showLetterEntryAndPreview = true;
        if (type == 'customer') {
            if($scope.customerEmail.length == 0) {
                $scope.disableEmailMethod = true;
            } else {
                $scope.disableEmailMethod = false;
            }
            if($scope.customerTelephonenumber.length == 0) {
                $scope.disableSmsMethod = true;
            } else {
                $scope.disableSmsMethod = false;
            }
        }
        if (type == 'workaddress') {
            if($scope.workaddressEmail.length == 0) {
                $scope.disableEmailMethod = true;
            } else {
                $scope.disableEmailMethod = false;
            }
            if($scope.workaddressTelephonenumber.length == 0) {
                $scope.disableSmsMethod = true;
            } else {
                $scope.disableSmsMethod = false;
            }
        }
        if (type == 'engineer') {
            $scope.showLetterEntryAndPreview = false;
            if($scope.engineerEmail.length == 0) {
                $scope.disableEmailMethod = true;
            } else {
                $scope.disableEmailMethod = false;
            }
            if($scope.engineerTelephonenumber.length == 0) {
                $scope.disableSmsMethod = true;
            } else {
                $scope.disableSmsMethod = false;
            }
        }
    }

    /* confirmations print and post */
    $scope.printPost = function() {
        $scope.slideInView(12);
        $scope.error = true;
        $http.post(prefix + '/process_printpost', $scope.printpost_params + '&confirmation=' + encodeURIComponent(this.active_confirmation.letterMessage) ).then(function(resp){
            $scope.slideInView(1);
            $scope.fetchDiaryEventRecords($scope.diary_event_id);
        });
    }

    $scope.$watchCollection('active_confirmation', function (newVal, oldVal) {
        if(newVal != undefined) {
            if((newVal.method == "Email") && (newVal.emailMessage == '' || newVal.subject == '' || newVal.subject == undefined || newVal.emailMessage == undefined || newVal.recipientEmail.length == 0)) {
                $scope.confirmationButton = true;
            } else if ((newVal.method == 'SMS') && (newVal.textMessage == '' || newVal.textMessage == undefined || newVal.recipientSms.length == 0)) {
                $scope.confirmationButton = true;
            } else {
                $scope.confirmationButton = false;
            }
        }
    });

    $scope.sendConfirmation = function sendConfirmation(confirmation, dynamicType) {
        $http.post(prefix + '/resend/confirmation', 'resend[customizeId]=' + $scope.customizeId + '&resend[customizedType]=' + dynamicType + '&resend[diaryEventId]=' + $scope.diary_event_id + '&resend[confirmation]=' + encodeURIComponent(angular.toJson(confirmation)) + '&resend[screenType]=' + $scope.screenType).success(function (data) {
            if(data.method == 'Letter') {
                var communicationId = data.communicationId;
                var dynamicType = data.dynamicType;
                var urlToOpen = prefix + '/diary/notification/' + dynamicType +'/' + data.communicationId +'/'+ $scope.screenType +'/'+ $scope.customizeId;
                if(communicationId > 0) {
                    $window.open(urlToOpen, '_blank');
                }
            } else if(data.method == 'Print') {
                var diaryId = data.diaryEventId;
                var urlToOpen = prefix + '/diary/view_job_sheet/print/' + data.diaryEventId;
                if(diaryId > 0) {
                    $window.open(urlToOpen, '_blank');
                }
            }
            $scope.slideInView(1);
            $scope.fetchDiaryEventRecords(data.diaryEventId);
        })
    }

    /*==========================================================================================
     Handle showing or hiding questions, signatures, todos or parts if there are only one
     ==========================================================================================*/
    $scope.initFeedback = function initFeedback(feedback) {
        if(this.diary_event_all_status.indexOf(5) != -1 || this.diary_event_all_status.indexOf(8) != -1 || this.diary_event_all_status.indexOf(10) != -1){

            var feedback_keys = Object.keys($scope.diary_event_feed_back.feedback);
            for (var i = 0, l = feedback_keys.length; i < l; i++) {
                var key = feedback_keys[i];
                feedback = $scope.diary_event_feed_back.feedback[key];

                if(feedback.questions && feedback.questions.length) {
                    for (var x = 0, c = feedback.questions.length; x < c; x++) {
                        var question = feedback.questions[x];
                        question.details_showing = false;
                    }
                }
                if(feedback.signatures && feedback.signatures.length) {
                    for (var x = 0, c = feedback.signatures.length; x < c; x++) {
                        var signature = feedback.signatures[x];
                        signature.details_showing = false;
                    }
                }
                if (feedback.questions && feedback.questions.length) {
                    if (feedback.questions.length >= 1) {
                        feedback.questions[0].details_showing = false;
                    } else {
                        feedback.questions[0].details_showing = true;
                    }
                }
                if(feedback.signatures && feedback.signatures.length) {
                    if (feedback.signatures.length === 1) {
                        feedback.signatures[0].details_showing = true;
                    } else {
                        feedback.signatures[0].details_showing = false;
                    }
                }
            }
        }
    }

    $scope.initSignature = function initSignature(engineerSignature) {
        if(engineerSignature) {
            var engineerSignature = engineerSignature;
            engineerSignature.details_showing = true;
        }
    }

    /*==========================================================================================
     When the panel opens, check whether to open in edit view
     ==========================================================================================*/
    $scope.sidePanelOpenWatch = $rootScope.$on('sidepanel:open', function(e) {
        if ($scope.$parent.panel_title === 'edit') {
            $scope.without_transition = true;
            $scope.active_slide = 2
            $timeout(function() {
                $scope.without_transition = false;
            }, 10);
        } else {
            $scope.without_transition = false;
        }
    });

    $scope.sidePanelCloseWatch = $rootScope.$on('sidepanel:closed', function(e) {
        $scope.resetPanel();
        $scope.removeDatePickerCtrl();
    });

    /*==========================================================================================
     Slide logic
     ==========================================================================================*/
    $scope.hideAllSlides = function hideAllSlides() {
        $scope.active_slide = null;
    }

    $scope.slideInView = function slideInView(slide, message, title) {
        $scope.active_slide = slide;
        $scope.cannot_edit_title = "Edit event";

        if (slide === 16) {
            $scope.cannot_edit_message = message;
            if (title) {
                $scope.cannot_edit_title = title;
            }
        }
    }
    $scope.keyButton = false;
    $scope.changeLandlordKey = function changeLandlordKey(){
        $scope.landlordSelectedKey = this.lanldordEditKey;
        $scope.keyButton = true;
    }

    $scope.slideEditKeys = function slideEditKeys(slide, landlordSelectedKey, id) {

        $http.post(prefix + '/diary/'+ id +'/post_collect_keys', 'key[status]=' + landlordSelectedKey).success(function (data) {
            $scope.slideInView(slide);
            $scope.keyButton = false;
            toastBox.show(data.message, 2000, 300);
            $scope.fetchDiaryEventRecords(data.id);
        })
    }

    $scope.$on('$destroy', function(){
        $scope.removeListeners();
    });

    $scope.closePanel = function closePanel() {
        if($scope.screenType.toLowerCase() == "job") {
            $scope.$emit('job:reload', $scope.$parent.repeat_index);
        } else if($scope.screenType.toLowerCase() == "estimate") {
            $scope.$emit('estimateTimeline:reload');
        }
        $scope.resetPanel();
        $scope.removeDatePickerCtrl();
        $rootScope.$broadcast('sidepanel:close');
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.viewDiaryEventCloseWatch = $rootScope.$on('sidepanel:view_diary_event:closed', function() {
        if($scope.screenType.toLowerCase() == "job") {
            $scope.$emit('job:reload', $scope.$parent.repeat_index);
        } else if($scope.screenType.toLowerCase() == "estimate") {
            $scope.$emit('estimateTimeline:reload');
        }
        $scope.removeDatePickerCtrl();
    });

    $scope.removeDatePickerCtrl = function() {
        var datePickerScope = angular.element('#diary_side_panel_date_picker').scope();
        if(datePickerScope) {
            datePickerScope.$destroy();
        }
    }

    $scope.resetTodoFilters = function resetTodoFilters() {
        $scope.todos_filter = {};
        $scope.todos_filter.show_pending = true;
        $scope.todos_filter.show_not_done = true;
        $scope.todos_filter.show_done = true;
        $scope.todos_filter.show_cancelled = true;
        $scope['Pending'] = true;
        $scope['Not done'] = true;
        $scope['Done'] = true;
        $scope['Cancelled'] = true;
    }

    $scope.resetConfirmationFilters = function resetConfirmationFilters() {
        $scope.confirmation_filter = {};
        $scope.confirmation_filter.show_unsent = true;
        $scope.confirmation_filter.show_sent = true;
        $scope.true = true;
        $scope.false = true;
    }

    $scope.resetPartsFilters = function resetPartsFilters() {
        $scope.parts_filter = {};
        $scope.parts_filter.show_picked_up = true;
        $scope.parts_filter.show_not_picked_up = true;
        $scope.parts_filter.show_installed = true;
        $scope['Picked up'] = true;
        $scope['Not picked up'] = true;
        $scope['Installed'] = true;
    }

    $scope.resetKeysFilters = function resetKeysFilters() {
        $scope.keys_filter = {};
        $scope.keys_filter.show_collected = true;
        $scope.keys_filter.show_not_collected = true;
        $scope.keys_filter.show_dropped_off = true;
        $scope['Collected'] = true;
        $scope['Not collected'] = true;
        $scope['Dropped off'] = true;
    }

    $scope.removeListeners = function(){
        $scope.sidePanelCloseWatch();
        $scope.sidePanelOpenWatch();
        $scope.viewDiaryEventCloseWatch();
        $scope.dataPickerWatch();
        $scope.viewFeedBackWatch();
    }
    $scope.resetPanel = function resetPanel() {
        $scope.hideAllSlides();

        if ($scope.panel_title === 'edit') {
            $scope.active_slide = 2;
        } else {
            $scope.active_slide = 1;
        }

        if($scope.panel_title === 'feedback') {
            $scope.active_tab = 'feedback';
        } else {
            $scope.active_tab = 'details';
        }


        $scope.countFilteredTodos();
        $scope.countFilteredConfirmations();
        $scope.countFilteredParts();
        $scope.countFilteredKeys();

        $scope.diary_event_indexes = $scope.getDiaryEventIndexes();

        $timeout(function() {
            $scope.resetTodoFilters();
            $scope.resetConfirmationFilters();
            $scope.resetPartsFilters();
            $scope.resetKeysFilters();
            $scope.countFilteredTodos();
            $scope.countFilteredConfirmations();
            $scope.countFilteredParts();
            $scope.countFilteredKeys();
            $scope.countUnassignedTodos();
        }, 300);

        $scope.resetTodosStore();
    }

    /*==========================================================================================
     Filter by Status
     ==========================================================================================*/
    $scope.filterByStatus = function filterByStatus(filter_str) {
        $scope[filter_str] = !$scope[filter_str];
        $scope.countFilteredTodos();
        $scope.countFilteredConfirmations();
        $scope.countFilteredParts();
        $scope.countFilteredKeys();
    };

    $scope.matchesCriteria = function matchesCriteria(criteria) {
        if(criteria == 'Not done cloned' ) {
            criteria = 'Not done';
        }
        return $scope[criteria];
    };

    $scope.countFilteredTodos = function countFilteredTodos() {
        $scope.todos_count = 0;
        if($scope.milestones && $scope.milestones.length) {
            $scope.milestones.map(function(milestone) {
                milestone.diaryEventTodos.map(function(todo) {
                    if ($scope[todo.Status]) {
                        $scope.todos_count ++;
                    }
                })
            });
        }
    }

    $scope.countFilteredConfirmations = function countFilteredConfirmations() {
        $scope.confirmations_count = 0;
        $scope.results_set = $filter('filter')($scope.diary_event.confirmations, function(confirmation) {
            if ($scope[confirmation.sent]) {
                $scope.confirmations_count ++;
            }
        });
    }

    $scope.countFilteredParts = function countFilteredParts() {
        $scope.parts_count = 0;
        $scope.results_set = $filter('filter')($scope.diary_event.parts, function(part) {
            if ($scope[part.status]) {
                $scope.parts_count ++;
            }
        });
    }

    $scope.countFilteredKeys = function countFilteredKeys() {
        $scope.keys_count = 0;
        $scope.results_set = $filter('filter')($scope.diary_event.keys, function(key) {
            if ($scope[key.status]) {
                $scope.keys_count ++;
            }
        });
    }

    /*==========================================================================================
     Cancel a todo
     ==========================================================================================*/
    $scope.cancelTodo = function cancelTodo(todo) {
        var confirmation_message = 'You are about to cancel a todo. Are you sure you wish to do this?';

        confirmationBoxHelper.getConfirmation(confirmation_message, this)
            .then(function() {

                $http.delete(prefix + "/cancel_jobtodo/"+ todo).success(function(data) {

                    $scope.activateTab('todos');
                    toastBox.show(data, 4000, 300);

                });

            }, function() {
                return false
            });
    }

    $scope.cloneTodo = function cloneTodo(todo) {
        var confirmation_message = 'You are about to cancel and clone a todo. Are you sure you wish to do this?';

        confirmationBoxHelper.getConfirmation(confirmation_message, this)
            .then(function() {

                $http.delete(prefix + "/cancel_clone_jobtodo/"+ todo).success(function(data) {

                    $scope.activateTab('todos');
                    toastBox.show(data, 4000, 300);
                });

            }, function() {
                return false
            });
    }

    /*==========================================================================================
     Cancel event
     ==========================================================================================*/
    $scope.cancelEvent = function cancelEvent(should_add_geolocation_status) {
        var confirmation_message = 'You are about to cancel a diary event. Are you sure you wish to do this?';

        confirmationBoxHelper.getConfirmation(confirmation_message, this)
            .then(function() {


                $http.post(prefix + "/cancel_diary_event/"+ $scope.diary_event_id, "data=" + $scope.diary_event.reasonCanceled).success(function(data, status) {
                    if (status == 200) {

                        $scope.data_loaded= false;
                        $scope.updateDiary = 'cancelled';
                        $scope.activateTab('details');

                        toastBox.show('Diary event cancelled', 3000, 300);

                    }
                });

            }, function() {
                return false
            });
    }

    /*==========================================================================================
     Cancel event
     ==========================================================================================*/
    $scope.deleteEvent = function deleteEvent() {
        var confirmation_message = $translate('wish.to.delete.diary.confirmation');

        confirmationBoxHelper.getConfirmation(confirmation_message, this)
            .then(function() {

                $http.delete(prefix + "/delete_diary_event/"+ $scope.diary_event_id).success(function(data) {
                    if($scope.isInDiaryScreen) {
                        $scope.$emit('event:refresh_diary_events', {action:'delete', diaryEvent: $scope.diary_event});
                    } else {

                        if($scope.screenType.toLowerCase() == "job") {
                            $scope.$emit('jobTimeline:reload', $scope.$parent.repeat_index);
                        } else if($scope.screenType.toLowerCase() == "estimate" || $scope.screenType.toLowerCase() == "estimateproject") {
                            $scope.$emit('estimateTimeline:reload');
                        } else {
                            $scope.$emit('projectJobTimelineView:reload');
                        }
                    }

                    $rootScope.$broadcast('closeAllSidepanels');
                    if(JSON.parse(data) != $translate('Diary.event.deleted')){
                        warningModal.show($translate("Diary.already.deleted"), $translate("delete.diary.event"), 'delete_diary');
                    }else{
                        toastBox.show(JSON.parse(data), 4000, 300);
                        if($scope.screenType.toLowerCase() == "job" && $state.params.jobId){
                            $state.transitionTo("loggedin.customer_list.view.job.details", { id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId },{reload:true});
                        }
                    }
                });

            }, function() {
                return false
            });
    }

    $scope.getDiaryEventIndexes = function getDiaryEventIndexes() {
        for (var i = 0, l = $scope.context_scope.milestones.length; i < l; i++) {
            var milestone = $scope.context_scope.milestones[i];
            for (var c = 0, x = milestone.diary_events.length; c < x; c++) {
                var diary_event_to_check = milestone.diary_events[c];
                if (diary_event_to_check.id === $scope.diary_event.id) {
                    return {
                        'milestone_index': i,
                        'diary_event_index': c
                    }
                }
            }
        }
    }

    /*==========================================================================================
     Validate feedback time
     ==========================================================================================*/
    $scope.validateAllStatus = function() {
        $scope.feedBackTime = false;
        for (var i = 0, l = $scope.timeTrackingRecords.length; i < l; i++) {
            var index = i;
            var status = $scope.timeTrackingRecords[i];
            // It can't be less than the previous one or greater than the next one
            var new_time_value = status.new_time;

            if (status.new_time.length == 1) {
                new_time_value = status.new_time + '.' + '00';
            } else if (status.new_time.length == 2) {
                new_time_value = status.new_time + '.' + '00';
            }

            if (!new_time_value) {
                status.error = true;
                $scope.feedBackTime = true;
                status.error_message = 'Please.enter.valid.time';

                return status;
            }

            if (new_time_value) {
                var input_pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3])(\.[0-5][0-9])?$/,
                    is_valid = input_pattern.test(new_time_value);
            }

            if (is_valid) {
                status.error = false;
                $scope.feedBackTime = false;
            } else {
                status.error = true;
                $scope.feedBackTime = true;
                status.error_message = 'Please.enter.valid.time';

                return status;
            }

            var time = new_time_value.split('.'),
                time_value = parseInt(time[0] * 60) + parseInt(time[1]);

            var new_time_as_number = time_value,
                previous_time_as_number = 0,
                next_time_as_number = 0;

            if (index !== 0) {
                var previous_time = $scope.timeTrackingRecords[index - 1].new_time.split('.'),
                    previous_time_as_number = (previous_time[0] ? parseInt(previous_time[0] * 60) : 0) + (previous_time[1] ? parseInt(previous_time[1]) : 0);
            }
            if (index !== $scope.timeTrackingRecords.length - 1) {
                var next_time = $scope.timeTrackingRecords[index + 1].new_time.split('.'),
                    next_time_as_number = (next_time[0] ? parseInt(next_time[0] * 60) : 0) + (next_time[1] ? parseInt(next_time[1]) : 0);
            }

            if (index === 0 && next_time_as_number !== 0) {
                if (new_time_as_number > next_time_as_number) {
                    status.error = true;
                    $scope.feedBackTime = true;
                    status.error_message = 'You.cant.save.time.greater.than.next.one';
                    break;
                } else {
                    status.error = false;
                    $scope.feedBackTime = false;
                }
            } else if (index === $scope.timeTrackingRecords.length - 1) {
                if (new_time_as_number < previous_time_as_number) {
                    status.error = true;
                    $scope.feedBackTime = true;
                    status.error_message = 'You.cant.save.time.smaller.than.previous.one';
                    break;
                } else {
                    status.error = false;
                    $scope.feedBackTime = false;
                }
            } else {
                if (new_time_as_number > next_time_as_number) {
                    status.error = true;
                    $scope.feedBackTime = true;
                    status.error_message = 'You.cant.save.time.greater.than.next.one';
                    break;
                } else if (new_time_as_number < previous_time_as_number) {
                    status.error = true;
                    $scope.feedBackTime = true;
                    status.error_message = 'You.cant.save.time.smaller.than.previous.one';
                    break;
                } else {
                    status.error = false;
                    $scope.feedBackTime = false;
                }
            }
        }
    }

    $scope.saveFeedback = function saveFeedback() {
        var dataToSend = [];
        for (var i = 0, l = $scope.timeTrackingRecords.length; i < l; i++) {
            var feedback = $scope.timeTrackingRecords[i];
            var new_time = feedback.new_time,
                new_time_is_am = parseInt(new_time.substring(0, new_time.length - 3)) < 12;


            if (feedback.new_time.length == 2) {
                new_time = feedback.new_time + '.' + '00';
            } else if (feedback.new_time.length == 1) {
                new_time = '0' + feedback.new_time + '.' + '00';
            }

            if (feedback.original_time != feedback.new_time) {
                var dateTimeValue = moment(feedback.dateTime.date).format('YYYY-MM-DD') + ' ' + new_time;
                dataToSend.push({id:feedback.id, value: dateTimeValue});
            }

            if (new_time_is_am) {
                feedback.time = new_time + 'am';
            } else {
                feedback.time = new_time + 'pm';
            }

            if (feedback.new_status) {
                feedback.status = feedback.new_status;
                $scope.diary_event.status = feedback.status;
            }

            feedback.real_time = feedback.new_time;

            if (i === $scope.timeTrackingRecords.length - 1) {
                if (feedback.new_status === 'Cancelled') {
                    $scope.cancelEvent(false);
                }
            }
        }

        if(dataToSend.length) {
            $http.post(prefix + "/save_diary_event_feedback", "data=" + encodeURIComponent(JSON.stringify(dataToSend))).success(function(data, status) {
                if (status == 200) {
                    $scope.timeTrackingRecords = $scope.setTimeTrackingOriginalValues(data.timeTrackingRecords);
                    toastBox.show('Feedback saved', 2000, 300);
                }
            });
        } else {
            toastBox.show('Feedback saved', 2000, 300);
        }
    }

    /*==========================================================================================
     Update the job scope when the status changes
     ==========================================================================================*/
    $scope.updateDiaryEventInJob = function updateDiaryEventInJob(status) {
        var indexes = $scope.getDiaryEventIndexes();
        $scope.context_scope.milestones[indexes.milestone_index].diary_events[indexes.diary_event_index].status = status;
    }

    /*==========================================================================================
     Save todos from the add / remove panel
     ==========================================================================================*/
    $scope.handleTodo = function handleTodo(todo) {
        if (todo.Status !== 'Cancelled' && todo.Status !== 'Not done' && todo.Status !== 'Not done completed' && todo.Status !== 'Done') {
            todo.checked = !todo.checked;
            if (todo.checked === true) {
                $scope.handlePushingTodoFromTodosStore(todo);
            } else {
                $scope.handleRemovingTodoFromTodosStore(todo);
            }
        }
    }

    $scope.handleDetailShowing = function handleDetailShowing(todo) {
        if(todo.detail_showing === true) {
            todo.detail_showing = false;
        } else {
            todo.detail_showing = true;
        }
    }

    $scope.ShowingConfirmation = function ShowingConfirmation(todo) {
        if(todo.audit_showing === true) {
            todo.audit_showing = false;
        } else {
            todo.audit_showing = true;
        }
    }

    $scope.handlePushingTodoFromTodosStore = function handlePushingTodoFromTodosStore(todo) {
        $scope.todos_store.push(todo);
    }

    $scope.handleRemovingTodoFromTodosStore = function handleRemovingTodoFromTodosStore(todo) {
        var index_of_todo = $scope.getIndexOfTodo(todo);

        $scope.todos_store.splice(index_of_todo, 1);
    }

    $scope.getIndexOfTodo = function getIndexOfTodo(todo) {
        var todo_indexes = $scope.todos_store.map(function(todo) {
                return todo.id;
            }),
            todo_index = todo_indexes.indexOf(todo.id);

        return todo_index;
    }

    $scope.removeSelectedTodosFromAvailableTodos = function removeSelectedTodosFromAvailableTodos() {
        var selected_todos = $scope.todos_store;

        selected_todos.map(function(selected_todo, index) {
            for (var i = 0, x = $scope.context_scope.milestones.length; i < x; i ++) {
                var milestone = $scope.context_scope.milestones[i],
                    todos = milestone.unassigned_todos;

                if (todos.length) {
                    for (var c = todos.length - 1, v = 0; c >= v; c --) {
                        var todo = todos[c];

                        if (todo) {
                            if (todo.id === selected_todo.id) {
                                var todo_index = c,
                                    milestone_index = i;

                                todos.splice(c, 1);
                            }
                        }
                    }
                }
            }
        });
    }

    $scope.removeSelectedTodosFromAssignedTodos = function removeSelectedTodosFromAssignedTodos() {
        var selected_todos = $scope.todos_store;

        for (var i = selected_todos.length - 1, l = 0; i >= l; i--) {
            var selected_todo = selected_todos[i],
                assigned_todos = $scope.diary_event.todos;

            for (var c = assigned_todos.length - 1, v = 0; c >= v; c --) {
                var assigned_todo = assigned_todos[c];

                if (assigned_todo) {
                    if (assigned_todo.id === selected_todo.id) {
                        assigned_todos.splice(c, 1);
                    }
                }
            }
        }
    }

    $scope.saveAndAddTodos = function saveAndAddTodos() {
        var todo_to_push= [];

        for (var i = 0, l = $scope.todos_store.length; i < l; i++) {
            todo_to_push.push({'id': $scope.todos_store[i]['id']});
        }

        $http.post(prefix + "/assign_todos_to_diaryEvent", "eventId=" + $scope.diary_event_id +"&todosData=" + angular.toJson(todo_to_push)).success(function(data, status) {
            if (status == 200) {

                if($scope.diary_event.diary_event_type == "Project") {
                    $scope.removeRightPanelTodos($scope.todos_store);
                } else if(($scope.$parent.$parent.hasOwnProperty('job')) && ($scope.$parent.$parent.job.active_todos_filter == 'unassigned')) {
                    $rootScope.$broadcast('spreadsheet:todoupdate');
                } else {
                    $scope.$emit('todosWithDiaryEvent:selected', 1);
                }

                var toast_message = 'Todo added';
                if ($scope.todos_store.length > 1) {
                    toast_message = $scope.todos_store.length + ' todos added';
                }

                toastBox.show(toast_message, 2000, 300);
                $scope.resetTodosStore();
                $scope.data_loaded= false;
                $scope.activateTab('todos');

            }
        });
    }

    // Removed the added todos from diary event in right side view(only in project job screen)
    $scope.removeRightPanelTodos = function removeRightPanelTodos(todos_store) {

        var parentTodos = $scope.$parent.$parent.job.milestones;

        for(var i = 0; i < todos_store.length; i++) {
            var selectedId = todos_store[i].id;

            for(var j = 0; j < parentTodos.length; j++) {

                for(var k =0; k < parentTodos[j].unassigned_todos.length; k++) {

                    if(parentTodos[j].unassigned_todos[k].id == selectedId) {
                        parentTodos[j].unassigned_todos.splice(k,1);
                    }
                }
            }
        }
        $scope.reCountUnassignedTodos(parentTodos);
    }

    // Re-count the available todos
    $scope.reCountUnassignedTodos = function reCountUnassignedTodos(parentTodos) {
        var availableTodosCount = 0;
        for (var i = 0; i < parentTodos.length; i += 1) {
            availableTodosCount = availableTodosCount + parentTodos[i].unassigned_todos.length;
        }
        $scope.$parent.$parent.number_of_available_todos = availableTodosCount;
    }

    $scope.saveAndRemoveTodos = function saveAndRemoveTodos() {

        var todo_to_push= [];

        for (var i = 0, l = $scope.todos_store.length; i < l; i++) {
            todo_to_push.push({'id': $scope.todos_store[i]['id']});
        }

        $http.post(prefix + "/removeJobTodoFromDiary", "jobTodo[id]=" + angular.toJson(todo_to_push)).success(function(data, status) {
            if (status == 200) {

                if($scope.diary_event.diary_event_type == "Project") {
                    $scope.addRightPanelTodos($scope.todos_store);
                } else if(($scope.$parent.$parent.hasOwnProperty('job')) && ($scope.$parent.$parent.job.active_todos_filter == 'unassigned')) {
                    $rootScope.$broadcast('spreadsheet:todoupdate');
                } else {
                    $scope.$emit('todosWithDiaryEvent:selected', 1);
                }

                var toast_message = 'Todo removed';
                if ($scope.todos_store.length > 1) {
                    toast_message = $scope.todos_store.length + ' todos removed';
                }

                toastBox.show(toast_message, 2000, 300);
                $scope.resetTodosStore();
                $scope.data_loaded= false;
                $scope.activateTab('todos');

            }
        });
    }

    // Display the removed todos from diary event in right side view(only in project job screen)
    $scope.addRightPanelTodos =function addRightPanelTodos(todos_store) {

        var parentTodos = $scope.$parent.$parent.job.milestones;

        for(var i = 0; i < todos_store.length; i++) {

            for(var j = 0; j < parentTodos.length; j++) {

                if(parentTodos[j].id == todos_store[i].milestoneId) {

                    var data = {"id": todos_store[i].id, "type": todos_store[i].Type, "typeId": todos_store[i].typeId, "description": todos_store[i].Description,
                        "selected": false, "quantity": todos_store[i].Quantity, "quantity_installed": todos_store[i].quantityInstalled, "status" : ""}

                    parentTodos[j].unassigned_todos.push(data);
                }
            }
        }
        $scope.reCountUnassignedTodos(parentTodos);
    }

    $scope.resetTodosStore = function resetTodosStore() {
        $scope.todos_store = [];
    };

    $scope.countUnassignedTodos = function countUnassignedTodos() {
        $scope.number_of_unassigned_todos = 0;

        $scope.context_scope.milestones.map(function(milestone, index) {
            milestone.unassigned_todos.map(function() {
                $scope.number_of_unassigned_todos ++;
            })
        });
    };

    $scope.showPartsWarning = function showPartsWarning() {
        warningModal.show("You cannot pick up this parts because first you need to mark as arrive in stocks", "Parts pick up", 'parts_pick_up');
    }

    $scope.showPickupWarning = function showPickupWarning() {
        warningModal.show("You cannot change status for this assigned todo because first you need to pick up parts", "Assigned todos", 'assigned_todos');
    }

    $scope.renderMap = function() {

        if(!$scope.timeTrackingRecords.length) {
            return false;
        }

        $timeout(function() {
            L.Map = L.Map.extend({
                openPopup: function(popup) {
                    this._popup = popup;

                    return this.addLayer(popup).fire('popupopen', {
                        popup: this._popup
                    });
                }
            });

            var markers = [],
                tooltips = {
                    type: "FeatureCollection",
                    features: []
                };

            for (var i = 0, x = $scope.timeTrackingRecords.length; i < x; i++) {
                var status = $scope.timeTrackingRecords[i],
                    latlang = [parseFloat(status.latitude), parseFloat(status.longitude)];
                var latlang_exists = status.geolocation_exists;

                if (latlang_exists) {
                    markers.push(latlang);
                    tooltips.features.push({
                        "geometry": {
                            "type": "Point",
                            "coordinates": [parseFloat(status.longitude), parseFloat(status.latitude)]
                        },
                        "type": "Feature",
                        "properties": {
                            "popup_text": (status.diaryStatusText),
                            "color": "rgb(0,0,0)"
                        },
                        "id": status.id
                    })
                }
            }

            if (markers.length) {
                $scope.geolocation_exists = true;
            } else {
                $scope.geolocation_exists = false;
            }

      if ($scope.geolocation_exists === true) {

          //# Getting console error when reassigning to scope after edit the arrive/leave questions
          if(typeof $scope.engineer_location_map == 'undefined') {
              $scope.engineer_location_map = new L.Map('map').setView(markers[0], 10); // Bound to scope so it can be interacted with
          }


                var style = L.tileLayer(gpsServerConfig.mapTilesServer, {
                    maxZoom: 17
                }).addTo($scope.engineer_location_map);

                $scope.engineer_location_map.fitBounds(L.latLngBounds(markers));

                function onEachFeature(feature, layer) {
                    (function(layer, properties) {
                        L.popup({
                            offset: L.point(0, 0),
                            className: 'tooltip',
                            closeButton: false,
                            closeOnClick: false
                        }).setLatLng(layer._latlng).setContent($translate(properties.popup_text)).openOn($scope.engineer_location_map);
                    })(layer, feature.properties);
                }

                L.geoJson(tooltips, {
                    style: function (feature) {
                        return feature.properties && feature.properties.style;
                    },
                    onEachFeature: onEachFeature,
                    pointToLayer: function (feature, latlng) {
                        return L.circleMarker(latlng, {
                            radius: 0,
                            fillColor: feature.properties.color,
                            color: feature.properties.color,
                            weight: 1,
                            opacity: 1,
                            fillOpacity: 1
                        });
                    }
                }).addTo($scope.engineer_location_map);
            }
        });
    };

    $scope.zoomMapToPoint = function(location) {
        if (location.geolocation_exists === true) {
            var latlang = [parseFloat(location.latitude), parseFloat(location.longitude)];

            $scope.engineer_location_map.setView(latlang, 17, {animate: true});
            $scope.location_zoomed_in_on = location.id;
        }
    }

    $scope.clearActiveLocation = function() {
        $scope.location_zoomed_in_on = null;
    }

    $scope.viewFeedBackWatch = $scope.$on('viewFeedback', function(event) {
        $scope.panel_title = 'feedback';
        $scope.activateTab('feedback');
    });
}


function editFeedbackQuestionCtrl($scope) {
    $scope.editQuestion = $scope.editQuestionForm;
}
// This is common function for Diary event functionality in both job & project job screen
function JobDiaryEventCommonCtrl($scope, $rootScope, $state, $compile, warningModal) {
    /*==========================================================================================================================
     Handle showing engineer feedback
     ==========================================================================================================================*/
    $scope.handleFeedbackPopup = function handleFeedbackPopup($event) {
        $scope.show_event_pop_up = true;
        $scope.pop_up_data = angular.fromJson($event.currentTarget.dataset.eventData);
        csPopUpPosition.init($event);
    }

    $scope.hideEventPopup = function hideEventPopup() {
        $scope.show_event_pop_up = false;
    }

    $scope.handleViewDiaryEvent = function handleViewDiaryEvent(diary_event_id) {
        var side_panel_tpl = $compile('<span id="view_diary_event" ' +
            'sidepanel template="view_diary_event" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection="' + diary_event_id + '"' +
            'additional_data="{{unassigned_todos}}"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
    }

    $scope.handleFeedbackDiaryEvent = function handleFeedbackDiaryEvent(diary_event_id) {
        var side_panel_tpl = $compile('<span id="view_diary_event" ' +
            'sidepanel template="view_diary_event" ' +
            'title="feedback" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection="' + diary_event_id + '"' +
            'additional_data="{{unassigned_todos}}"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
    }

    $rootScope.$on('jobs:openDiaryEvent', function(e, milestone_index, event_index) {
        $scope.handleViewDiaryEvent(milestone_index);
    })

    $scope.handleEditDiaryEvent = function handleEditDiaryEvent(diary_event_id, diary_event_completed) {

        if(diary_event_completed) {

            warningModal.show("You cannot edit this diary event because of its status", "Edit diary event");

        } else {

            var side_panel_tpl = $compile('<span id="view_diary_event" ' +
                'sidepanel template="view_diary_event" ' +
                'title="edit" ' +
                'remove_on_close="true" ' +
                'index="' + this.$index + '"' +
                'collection="' + diary_event_id + '"' +
                'additional_data="{{unassigned_todos}}"></span>')($scope);

            angular.element('body').append(side_panel_tpl);

        }

    }

    $scope.handleCompleteJob = function handleCompleteJob(milestone_index, event_index) {
        var side_panel_tpl = $compile('<span id="complete_job" ' +
            'sidepanel template="complete_job" ' +
            'title="Compelete job" ' +
            'remove_on_close="true"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
    }

    $scope.storeDiaryEventForCancel = function storeDiaryEventForCancel(milestone_index, event_index) {
        $scope.active_cancel_diary_event = $scope.job.milestones[milestone_index].diary_events[event_index];
    }

    $scope.handleCancelDiaryEvent = function handleCancelDiaryEvent() {
        $scope.active_cancel_diary_event.status = 'Cancelled';
    }

    /*==========================================================================================
     Handle filtering diary events
     ==========================================================================================*/
    $rootScope.$on('jobs:handleFilteringEvents', function(e, diary_engineers) {

        $scope.handleFilteringEvents(diary_engineers);
    });

    $scope.handleFilteringEvents = function handleFilteringEvents(diary_engineers) {
        $scope.hideAllEvents();
        for (var i = 0; i < $scope.job.diary_engineers.length; i += 1) {
            if (diary_engineers[i].filterActive === true) {
                $scope.job.diary_engineers[i].filterActive=true;
                $scope.filterEventsInDiary($scope.job.diary_engineers[i].name);
            } else {
                $scope.job.diary_engineers[i].filterActive=false;
            }
        }
    }

    $scope.hideAllEvents = function hideAllEvents() {
        // $scope.hideAllCalendarViewEvents();
        $scope.hideAllListViewEvents();
    }

    $scope.filterEventsInDiary = function filterEventsInDiary(engineer) {
        // $scope.filterCalendarViewEvents(engineer);
        $scope.filterListViewEvents(engineer);
        $scope.filterListViewSections();
        $scope.handleNoResultsInListView();
    }

    $scope.hideAllCalendarViewEvents = function hideAllCalendarViewEvents() {
        for (var i = 0; i < $scope.weekdays.length; i += 1) {
            var week = $scope.weekdays[i];
            for (var x = 0; x < week.length; x += 1) {
                var day = week[x];
                if (day.events) {
                    for (var s = 0; s < day.events.length; s += 1) {
                        var diary_event = day.events[s];
                        $scope.weekdays[i][x].events[s].showing = false;
                    }
                }
            }
        }
    }

    $scope.hideAllListViewEvents = function hideAllListViewEvents() {
        for (var x = 0; x < $scope.job.diaryEvents.length; x += 1) {
            var diary_event = $scope.job.diaryEvents[x];
            $scope.job.diaryEvents[x].showing = false;
        }
        $scope.timeline_results = false;
    }

    $scope.filterCalendarViewEvents = function filterCalendarViewEvents(engineer) {
        for (var i = 0; i < $scope.weekdays.length; i += 1) {
            var week = $scope.weekdays[i];
            for (var x = 0; x < week.length; x += 1) {
                var day = week[x];
                if (day.events) {
                    for (var s = 0; s < day.events.length; s += 1) {
                        var diary_event = day.events[s];
                        if (diary_event.engineer === engineer) {
                            $scope.weekdays[i][x].events[s].showing = true
                        }
                    }
                }
            }
        }
    }

    $scope.filterListViewEvents = function filterListViewEvents(engineer) {

        for (var x = 0; x < $scope.job.diaryEvents.length; x += 1) {
            var diary_event = $scope.job.diaryEvents[x];
            if (diary_event.engineer_name === engineer) {
                $scope.job.diaryEvents[x].showing = true;
            }
        }
    }

    $scope.filterListViewSections = function filterListViewSections() {
        for (var i = 0; i < $scope.job.timeline_sections.length; i += 1) {
            var section = $scope.job.timeline_sections[i];
            $scope.job.timeline_sections[i].showing = false;
            for (var c = 0; c < $scope.job.diaryEvents.length; c += 1) {
                var diary_event = $scope.job.diaryEvents[c];
                if (diary_event.timeline_id === section.id) {
                    if (diary_event.showing === true) {
                        $scope.job.timeline_sections[i].showing = true;
                    }
                }
            }
        }
    }

    $scope.handleNoResultsInListView = function handleNoResultsInListView() {
        $scope.timeline_results = false;
        for (var i = 0; i < $scope.job.timeline_sections.length; i += 1) {
            if ($scope.job.timeline_sections[i].showing === true) {
                $scope.timeline_results = true;
            }
        }
    }
}

function JobTodosSidebar($scope, $rootScope, $state, $document, $filter, $timeout, dragHelper) {
    var job_scope = $scope.job;

    /*==========================================================================================================================
     Initiate the selected state on the todos
     ==========================================================================================================================*/
    $scope.initTodos = function initTodos() {
        for (var i = 0; i < job_scope.milestones.length; i += 1) {
            for (var x = 0; x < job_scope.milestones[i].unassigned_todos.length; x += 1) {
                job_scope.milestones[i].unassigned_todos[x].selected = false;
            }
        }
    }

    /*==========================================================================================================================
     Handle selected todos in the schedule panel
     ==========================================================================================================================*/
    $scope.handleSelectingTodo = function handleSelectingTodo(todo) {
        todo.selected = !todo.selected;

        var selected_todos = [];
        for (var i = 0; i < job_scope.milestones.length; i += 1) {
            for (var x = 0; x < job_scope.milestones[i].unassigned_todos.length; x += 1) {
                if (job_scope.milestones[i].unassigned_todos[x].selected === true) {
                    selected_todos.push(job_scope.milestones[i].unassigned_todos[x]);
                }
            }
        }

        $rootScope.selected_unassigned_todos = selected_todos;

        if ($rootScope.selected_unassigned_todos.length > 0) {
            $scope.setDragMessage();
        }
        $scope.updateSelectAllUi();
    }

    $scope.updateSelectAllUi = function() {

        for (var i = 0; i < job_scope.milestones.length; i += 1) {
            if ($rootScope.selected_unassigned_todos.length === job_scope.milestones[i].unassigned_todos.length) {
                $scope.all_todos_selected = true;
                $scope.select_all_text = "Un select all todos"
            } else {
                $scope.all_todos_selected = false;
                $scope.select_all_text = "Select all todos";
            }
        }
    }
    $scope.select_all_text = "Select all todos";

    $scope.handleSelectAllClick = function() {
        if ($scope.all_todos_selected === true) {
            for (var i = 0; i < job_scope.milestones.length; i += 1) {
                for (var x = 0; x < job_scope.milestones[i].unassigned_todos.length; x += 1) {
                    job_scope.milestones[i].unassigned_todos[x].selected = false;
                }
            }
            $rootScope.selected_unassigned_todos = [];
        } else {
            var selected_todos = [];
            for (var i = 0; i < job_scope.milestones.length; i += 1) {
                for (var x = 0; x < job_scope.milestones[i].unassigned_todos.length; x += 1) {
                    job_scope.milestones[i].unassigned_todos[x].selected = true;
                    selected_todos.push(job_scope.milestones[i].unassigned_todos[x]);
                }
            }
            $rootScope.selected_unassigned_todos = selected_todos;
        }
        $scope.updateSelectAllUi();
        $scope.setDragMessage();
    }

    $rootScope.$on('jobtodos:addTodos', function(e) {
        $scope.uncheckAndRemoveSelectedTodos();
    });

    $scope.uncheckAndRemoveSelectedTodos = function uncheckAndRemoveSelectedTodos() {
        for (var i = job_scope.milestones.length -1, l = 0; i >= l; i--) {
            for (var x = job_scope.milestones[i].unassigned_todos.length -1, c = 0; x >= c; x--) {
                if(job_scope.milestones[i].unassigned_todos[x].selected === true){
                    job_scope.milestones[i].unassigned_todos.splice(x,1);
                }
            }
        }

        $rootScope.selected_unassigned_todos = [];
        $scope.countUnassignedTodos();
    }

    $scope.countUnassignedTodos = function countUnassignedTodos() {
        $scope.number_of_available_todos = 0;
        for (var i = 0; i < job_scope.milestones.length; i += 1) {
            $scope.number_of_available_todos = $scope.number_of_available_todos + job_scope.milestones[i].unassigned_todos.length;
        }
    }

    $scope.setDragMessage = function setDragMessage() {
        dragHelper.setNames('Todo', 'Todos');
        dragHelper.setContextData($rootScope.selected_unassigned_todos);
    }

    $scope.handleSearch = function handleSearch() {
        var all_todos = _.flatten($scope.job.milestones.map(function(milestone) {
            return milestone.unassigned_todos;
        }));

        var filtered_todos = $filter('filter')($scope.job.milestones, $scope.search_unassigned_todos);
        var	no_results = filtered_todos.length === 0;

        if (no_results) {
            $scope.no_results = true;
        } else {
            $scope.no_results = false
        }
    }

    $scope.countUnassignedTodos();
}

function JobTodosAssignedRemov($scope, $rootScope, tableCollection)
{
    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosen_todos = rows_store;
        $scope.listChosenTodos = [];
        if($scope.chosen_todos.length === 0) {
          return true;
        }
        for(var i = 0, l = $scope.chosen_todos.length; i < l; i++) {
            var row = _.has($scope.chosen_todos[i][0], 'row')? $scope.chosen_todos[i][0]['row'] : false;
            if(row) {
            var chosen_todo = $scope.chosen_todos[i];
            chosen_todo.todo = tableCollection.getSidepanelData(row, 'job_assigned_todos');
            $scope.listChosenTodos.push({'selectedTodo': tableCollection.getSidepanelData(row, 'job_assigned_todos')});
            }
        }
    });

    $scope. removeTodosDiaryEvent = function removeTodosDiaryEvent() {
        $scope.shouldBeOpen = true;
        $scope.deleteText = "Remove todos from diary event";
        $scope.deleteTextConfirm = "CONFIRM"
        $scope.deleteUrl = "/removeJobTodoFromDiary";
        $scope.title = "Remove todos from diary event";
        $scope.validationMessage = "CONFIRM to Remove this todo";
        $scope.deleteParams = "jobTodo[id]=";

        var chosenTodos=[], todosId =[];
        angular.forEach($scope.chosen_todos, function (key, value) {
            chosenTodos.push({'Type': key.todo.Type, 'Description': key.todo.Description});
            todosId.push({'id': key.todo.id});
        });

        $scope.chosenTodos = chosenTodos;
        $scope.selectedId = angular.toJson(todosId);
    }
    $scope.fetchRecords = function fetchRecords() {
        $rootScope.rows_store = [];
        $scope.shouldBeOpen = false;
        $scope.$emit('todosWithDiaryEvent:selected', $scope.currentPage);
    }

    $scope.$on('modal:close', function(evt,data) {
        $scope.shouldBeOpen = false;
    });
}

function JobDetailsCtrl($scope, $state, prefix, $http, getIdData, $rootScope, tableCollection, defaultPagingValue, changeStatusDrop, jobData, $compile, warningModal, $sce, skills_data, $location) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message = getIdData.deleteOrNull;
        $state.transitionTo('loggedin.customer_list.view.property', {'id':  $state.params.id, 'type': $state.params.type},{reload: true});
    }

  PermissionCheck.call(this, $scope);
  $scope.jobId = $state.params.jobId || getIdData.jobId;
  $scope.officeTaskCount = getIdData.officeTaskCount;
  $scope.skills_data = skills_data;
  $scope.selectedId = $state.params.id || getIdData.id;
  $scope.customerTabName = $state.params.type || getIdData.customerTabName;
  $scope.canOfficeTaskShow =  getIdData.canOfficeTaskShow;
  $scope.slaData = getIdData.slaJobs;
  $scope.dataformatIsObject = function(data){
      if(_.isObject(data)) {
          return true;
      }
      else {
          return false;
      }
  };
  $scope.$emit('tabCustomer:selected', getIdData);
  $scope.$emit('displayScreen', false);
  $scope.$emit('displayInvoiceScreen', false);
  $scope.$emit('displayAssetScreen', false);
  jobData.setPartLocations(getIdData.partLocation);
  $scope.selectedTab = "JobDetails";
  $scope.jobSubNavStatic = false;
  $scope.hideSubNavCustomerName = true;
    $scope.skills = _.pluck(getIdData.skills, 'text').join(',');
    $scope.shouldBeOpenNew = false;
    $scope.$on('modal:close', function(evt,data) {
        $scope.shouldBeOpenNew = false;
    });
    $rootScope.$broadcast('additionalWork:countupdated', getIdData.no_pending_additional_works);
    $scope.lastEventDetails = getIdData.lastEventDetails;
    $scope.nextEventDetails = getIdData.nextEventDetails;
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    if(getIdData.jobPartDetailsCount[0].orderCount != ''){
        $scope.jobPartsOrderCount = getIdData.jobPartDetailsCount[0].orderCount;
    } else{
        $scope.jobPartsOrderCount = 0;
    }
    if(getIdData.jobPartDetailsCount[0].requestedCount != ''){
        $scope.jobPartsRequestedCount = getIdData.jobPartDetailsCount[0].requestedCount;
    } else{
        $scope.jobPartsRequestedCount = 0;
    }
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type});

  $scope.job = getIdData.jobDetails;
  $scope.job["roughModalPipe"] = $scope.job.pipeline;
  $scope.jobPipelineList = getIdData.jobDetails.jobPipelineList;
  $scope.jobAssignedUsers = getIdData.jobAssignedUsers;
  $scope.moduleType = $rootScope.moduleType;
  $scope.jobStagePermission = getIdData.jobStagePermission;
  var scope = {name: 'invoicesCount', value: getIdData.invoicesCount};
    $rootScope.$emit('event:changeJobScope', scope);

    //$scope.job.active_milestone_id = $scope.job.milestones[0].id;
    $scope.empty_todos = [];
    $scope.timeline_results = true;

    $scope.job_is_complete = false;
    $scope.job_is_on_reserve_list = false;
    $scope.job_is_on_hold = false;
    $scope.job_is_recall = false;
    $scope.job_is_additional_work = false;
    $scope.job_has_recalls = $scope.job.recalls && $scope.job.recalls.length > 0;
    $scope.job_has_invoices = $scope.job.invoices && $scope.job.invoices.length > 0;
    $scope.job_from_estimate = $scope.job.estimateid !== null;
    $scope.final_invoice_raised = false;

    $scope.job_has_purchase_orders = false;
    $scope.job_has_certificates = false;
    $scope.job_has_invoices = false;
    $scope.job_has_used_parts = false;
    $scope.job_has_additional_works = false;
    $scope.job_has_recalls = false;

    $scope.unassigned_todos = [];
    $scope.weekdays = [];
    $scope.job.diary_engineers = [];
    $scope.estimate_options = [];
    $scope.all_assigned_todos = [];

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId+'/details/todos';
    $scope.addUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/details/todos/new';
    $scope.updateUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/details/todos/update';
    $scope.spreadSheetDeleteUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/details/todos/delete';

    $scope.getDiaryEventUrl = "jobTodosWithDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_assigned_todos";
    $scope.updateDiaryEventUrl = "jobTodosWithDiaryEventEdit/"+$scope.jobId;

    $scope.getDiaryCompletedTodoAllUrl = "jobTodosWithDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_completed_todos_all";
    $scope.getDiaryCompletedTodoNotDoneUrl = "jobTodosWithDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_completed_todos_not_done";
    $scope.getDiaryCompletedTodoDoneUrl = "jobTodosWithDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_completed_todos_done";

    $scope.filterStatus = getIdData.filterStatus;
    if($rootScope.clientJobType != 3){
        $scope.filterStatus = _.omit($scope.filterStatus, 'Returned_To_Stock');
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }
    $scope.diaryEvents = [];
    $scope.diaryEventsCount = getIdData.diaryEvents.jobDiaryEventsCount;
    $scope.feedback =  getIdData.feedback;

    function mappingFeedback() {
      return _.chain($scope.feedback)
        .flatten()
        .filter(function(item){
          return item.answer != 0;
        })
        .groupBy(function(item){
          return item.diaryId;
        })
        .value();
    }
    $scope.mappedFeedBacked = mappingFeedback();

    JobPartsListCtrl.call(this, $scope, $http, getIdData, $rootScope, tableCollection, changeStatusDrop);

    $scope.deleteParam = [
        {
            "available": {
                "url": "/mark_part_available",
                "validationMessage": "CONFIRM to Part as Available",
                "title": "Mark part as available",
                "content": "To confirm you wish to mark this part as available, please type 'CONFIRM' in the box below and click mark part as available.",
                "deleteText": "Mark part as available",
                "deleteArray": "jobPartAvailable[id]="
            },
            "installed": {
                "url": "/mark_part_installed",
                "validationMessage": "CONFIRM to Part as installed",
                "title": "Mark part as installed",
                "content": "To confirm you wish to mark this part as installed, please type 'CONFIRM' in the box below and click mark part as installed.",
                "deleteText": "Mark part as installed",
                "deleteArray": "jobPartInstalled[id]="
            }
        }
    ];

    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
    });

    $scope.fetchJobParts = function (pageNum) {
        $scope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.partCategoryBatch, currentPage:pageNum});
    }

    $scope.triggerConfirm = function triggerConfirm(cat){
        $scope.partIds = [];
        angular.forEach($scope.chosenParts, function (key, value) {
            $scope.partIds.push({'id': key.part.id});
        });
        $scope.currentPage = $scope.$parent.pageNum;
        $scope.selectedId = angular.toJson($scope.partIds);
        $scope.shouldBeOpenNew = true;
        $scope.deleteText = $scope.deleteParam[0][cat].deleteText;
        $scope.deleteTextConfirm = 'CONFIRM';
        $scope.deleteUrl = $scope.deleteParam[0][cat].url;
        $scope.title = $scope.deleteParam[0][cat].title;
        $scope.validationMessage = $scope.deleteParam[0][cat].validationMessage;
        $scope.content = $scope.deleteParam[0][cat].content;
        $scope.deleteArrayText = $scope.deleteParam[0][cat].deleteArray;
        $scope.listData = $scope.chosenParts;
    }

    tableCollection.setData('job_assigned_todos', getIdData.jobTodos.job_assigned_todos);
    tableCollection.setData('job_completed_todos_all', getIdData.jobTodos.job_completed_todos_all);
    tableCollection.setData('job_completed_todos_not_done', getIdData.jobTodos.job_completed_todos_not_done);
    tableCollection.setData('job_completed_todos_done', getIdData.jobTodos.job_completed_todos_done);

    JobTodosAssignedRemov.call(this, $scope, $rootScope, tableCollection);
    JobDiaryEventCommonCtrl.call(this, $scope, $rootScope, $state, $compile, warningModal);


    $scope.fetchDiaryEvents = function (pageNum) {
        $scope.mappedFeedBacked = mappingFeedback();
        $http.get(Routing.generate('list_job_diary_events', { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.diaryEvents = _.map(data.jobDiaryEvents, function(event){
              event.feedbacks = $scope.mappedFeedBacked[event.id] ? $scope.mappedFeedBacked[event.id] : [];
              return event;
            });
            $scope.diaryEventsCount = data.jobDiaryEventsCount;
            $scope.current_diary_page = pageNum;
        })
    }

    $scope.$on("informationbar:estimate_details", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.estimate_details.survey", { id: $scope.selectedId, type: $scope.customerTabName, estimateId: $scope.job.estimateid });
    });

    $scope.$on("informationbar:job_recall", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.details", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.job.recallof });
    });

    $scope.$on("informationbar:job_reminder", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.new_job_reminder", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
    });

    $scope.$on("informationbar:free_of_charge_job", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.free_of_charge", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
    });

    $scope.$on("informationbar:raise_final_invoice", function (event, message) {
        $location.path('/customers/'+ $scope.customerTabName +'/' + $scope.selectedId + '/jobs/'+ $scope.jobId +'/invoices/new/final');
    });

    $scope.$on("informationbar:final_invoice", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.invoices.view.view_invoice", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId, invoiceId: $scope.job.finalInvoiceId });
    });

  $scope.$on("informationbar:final_invoice_multiple", function (event, message) {
      if(message.propertyid == null){
          $location.path('/invoice_address/' + message.invoiceaddressid + '/multiple_invoice/'+ message.invoiceId +'/view');
      }else{
          $location.path('/customers/customer/' + message.propertyid + '/multiple_invoice/'+ message.invoiceId +'/view');
      }
  });
    // Call for to reload timeline view
    $scope.$on("jobTimeline:reload", function (event, data) {
        if($scope.job.type == 'Job') {
            $scope.fetchDiaryEvents($scope.currentPage);
        }
        else {
            $scope.diaryEvents.splice(data, 1);
        }
    });

    $scope.$on("job:reload", function (event) {
        var diary_page_url = Routing.generate('job_details', { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId }) + '?page='+$scope.current_diary_page;
        $http.get(diary_page_url).success(function (data) {
            // Get the view options
            //$scope.diaryEvents = data.diaryEvents.jobDiaryEvents;
            $scope.feedback = data.feedback;
            $scope.mappedFeedBacked = mappingFeedback();
            $scope.diaryEvents = _.map(data.diaryEvents.jobDiaryEvents, function(event){
              event.feedbacks = $scope.mappedFeedBacked[event.id] ? $scope.mappedFeedBacked[event.id] : [];
              return event;
            });
            $scope.diaryEventsCount = data.diaryEvents.jobDiaryEventsCount;
            $scope.job = data.jobDetails;
        })
    });
    $scope.checkEditAccess = function (jobId, isAngular7) {
        $http.get(prefix + '/check_edit_job_access?jobId=' + jobId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                if(isAngular7) {
                    $location.path('/customers/'+ $scope.customerTabName +'/' + $scope.selectedId + '/jobs/views/'+ $scope.jobId +'/edits');
                } else {
                    $state.transitionTo("loggedin.customer_list.view.job.edit_job", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
                }
            }
        });
    }

    $scope.fetchAssetDetails = function () {
        $scope.assetDetails = '';
        $http.get(Routing.generate('get_job_asset_details') + '?propertyId=' + $scope.selectedId + '&jobId=' + $scope.jobId + '&screenType=history').success(function (data, status) {
            if(status == 200){
                $scope.assetDetails = data;
            }
        });
    }

    $scope.fetchAssetDetails();

    // chat icon
    $scope.chatChannelExists = false;
    $rootScope.enableJobEstimateChatFeature = false;
    $scope.isMember = 0;
    $scope.checkChatEnable = function checkChatEnable() {
      var channelName = "job"+$scope.jobId;
        $http.get(prefix + '/checkChatEnable?channelName='+channelName).success(function (data) {

            if(data.isEnable) {
                $scope.showChatIcon = true;

                var channelName = "job"+ $scope.jobId;
                $scope.chatTeamName = data.chatDetails.chatTeamId;
                $scope.chatUserId = data.chatDetails.chatUserId;
                $scope.chatToken = data.chatDetails.chatAuthToken;
                $scope.channelAdmin = data.chatDetails.channelAdminUser;
                $scope.channelPwd = data.chatDetails.channelAdminPassword;
                if( data.chatDetails.channelId ) {
                  $scope.channelId = data.chatDetails.channelId;
                  $scope.chatChannelExists = true;
                } else {
                  $scope.chatChannelExists = false;
                }
                if( data.chatDetails.isMember ) {
                  $scope.isMember = 1;
                } else {
                  $scope.isMember = 0;
                }
                
                if( data.chatDetails.features ) {
                    if( data.chatDetails.features[1]) {
                      $rootScope.enableJobEstimateChatFeature = true;
                    }
                }
                $scope.chatChannelUrl = data.chatUrl+ "/login?commusoft="+data.chatLoginKey+'&redirect_to=/'+data.chatDetails.chatTeamName+"/"+'channels/'+channelName;

            } else {
                $scope.showChatIcon = false;
            }
        });
    }

    $scope.checkChatEnable();
    $scope.$watch('enableJobEstimateChatFeature', function (newValue) {
        $scope.$emit('enableJobEstimateChatFeature', newValue);
    });

    $scope.handleChatClick = function(diaryId,chatChannelUrl,chatTeamName,chatUserId,chatToken,channelAdmin,channelPwd,channelId) {
      $scope.loading = true;

      if( !$scope.chatChannelExists || !$scope.isMember ) {
        $http({
          method  : 'POST',
          url     : prefix + '/createChatChannel',
          data    :  $.param({
              'channelType': 'job',
              'id': $scope.jobId,
              'diaryId': diaryId,
              'chatChannelUrl': chatChannelUrl,
              'chatTeamName': chatTeamName,
              'chatUserId': chatUserId,
              'chatToken': chatToken,
              'channelAdmin':channelAdmin,
              'channelPwd': channelPwd,
              'channelId': channelId,
          }),
          headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).
        success(function (data, status) {
            window.open(chatChannelUrl);
        }).
        error(function (data, status) {
            $scope.data = data;
        });
      } else {
        window.open(chatChannelUrl);
      }

    }

    $scope.select2Options = {
        placeholder: "-- Please choose --",
        // 'multiple': false,
        width:"200px",
        dropdownCssClass: 'pipeline-selector',
    }

    setTimeout(function() {
        // Get references to the elements
        const valueSelector = document.querySelector('#pipeValueSelector'); // Dropdown for selecting pipe
        const saveButton = document.querySelector('#pipeSaveButton'); // Save button
        const cancelButton = document.querySelector('#pipeCancelButton'); // Cancel button
        const textElement = document.querySelector('#pipeTextElement'); // Text element (pen icon for edit)
        const container = document.querySelector(".pipeline-container-align"); // outside the container click
        const pipelineSelected = document.querySelector(".pipelineSelected"); // to update value

        const select2Container = document.querySelector(".select2-container"); //drop down
        const select2ChosenSpan = document.querySelector(".select2-chosen");
        const stageTextArea = document.querySelector(".stage-data-view");

        if (select2ChosenSpan && !$('#pipeValueSelector').val()) {
            select2ChosenSpan.textContent = pipelineSelected.firstChild.textContent;
        }

        if($scope.job.pipeline ){
            $scope.jobPipelineList.forEach((pipe,index) => {

                if (index === 0) {
                    const emptyOption = document.createElement("option");
                    emptyOption.value = "";
                    emptyOption.id = "";
                    emptyOption.style.display = "none";
                    valueSelector.appendChild(emptyOption);
                }

                const option = document.createElement("option");
                option.value = pipe.id;
                option.textContent = pipe.name;
                valueSelector.appendChild(option);
            });
        }

        // Ensure the elements exist before attaching event listeners
        if (valueSelector && saveButton && cancelButton && textElement) {

            function hideDropdownAndButtons() {
                valueSelector.style.display = "none";
                saveButton.style.display = "none";
                cancelButton.style.display = "none";
                select2Container.style.display = "none";
                pipelineSelected.style.display = 'inline-block'
                select2ChosenSpan.textContent = pipelineSelected.firstChild.textContent;
                valueSelector.value = '';
            }
            // Event listener for the Edit button (pen icon)
            textElement.addEventListener("click", function() {
                // Show the dropdown and buttons when the edit icon is clicked
                valueSelector.style.display = "inline-block";
                saveButton.style.display = "inline-block";
                cancelButton.style.display = "inline-block";
                select2Container.style.display = "inline-block";
                pipelineSelected.style.display = 'none'
                select2ChosenSpan.textContent = pipelineSelected.firstChild.textContent;
            });

            // Event listener for the Cancel button
            cancelButton.addEventListener("click", function() {
                event.stopPropagation();
                hideDropdownAndButtons();
            });

            let previousPipeId = $scope.job.pipelineId;
            // Event listener for the Save button
            saveButton.addEventListener("click", function() {
                // Get the selected value from the dropdown
                const selectedPipeId = valueSelector.value;

                if (pipeValueSelector.selectedIndex !== 0) {
                    const selectedPipeText = pipeValueSelector.options[pipeValueSelector.selectedIndex].text;
                    pipelineSelected.firstChild.textContent = selectedPipeText;
                    $scope.job.pipeline = selectedPipeText;
                }
                if((selectedPipeId != previousPipeId && selectedPipeId != '')){
                    $http.post(prefix + '/job_pipeline_update', "jobId=" + $scope.jobId + '&pipelineId=' + selectedPipeId).
                    success(function (data, status) {
                        if(data){
                            stageTextArea.innerText = data.pipelineStageNew;
                            stageTextArea.style.backgroundColor = data.stageColorNew;
                            previousPipeId = selectedPipeId;
                        }
                    });
                }
                event.stopPropagation();
                hideDropdownAndButtons();
            });
            // Event listener to detect clicks outside the text and dropdown
            document.addEventListener("click", function(event) {
                // Check if the click was outside the container
                if (!container.contains(event.target) && (event.target.id != 'pipeTextElement') && (event.target['tagName'] != 'BODY')) {
                    event.stopPropagation();
                    hideDropdownAndButtons();
                }
                if((event.target).lastChild){
                    if($scope.job.pipeline.trim() == pipelineSelected.firstChild.textContent.trim()){
                        $('.select2-results .select2-result').removeClass('select2-highlighted');
                        const selectedText = select2ChosenSpan.textContent.trim();
                        $('.select2-results .select2-result').each(function() {
                            const listItemText = $(this).find('.select2-result-label').text().trim();
                            if (listItemText === selectedText) {
                                $(this).addClass('select2-highlighted');
                            }
                        });
                    }
                }
            });

        } else {
            console.error('Required elements not found!');
        }
    }, 3000);

}

function JobVisitsCtrl($scope, $state, prefix, $http, getIdData, $rootScope, tableCollection, defaultPagingValue, $document, $filter, $timeout, dragHelper, $compile, $stateParams, jobData, emailService, confirmationBoxHelper, toastBox, warningModal){
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', true);

    $scope.selectedTab = 'Visits';
    $scope.jobSubNavStatic = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', getIdData.jobId);

    $scope.job = getIdData.jobDetails;
    $scope.moduleType = $rootScope.moduleType;
}

function JobVisitsViewCtrl($scope, $state, prefix, $http, getIdData, $rootScope, $document, $filter, $timeout, dragHelper, $compile, $stateParams, emailService, confirmationBoxHelper, toastBox, warningModal){
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.milestoneId = getIdData.milestoneId;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', true);

    $scope.selectedTab = 'Visits';
    $scope.jobSubNavStatic = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', getIdData.jobId);

    $scope.job = getIdData.jobDetails;
    $scope.moduleType = $rootScope.moduleType;

    $scope.timeline_results = true;

    $scope.job.diary_engineers = getIdData.diaryEngineers;
    $scope.job.timeline_sections = getIdData.timelineSections;
    $scope.allDiaryStatus = getIdData.allDiaryStatus;

    $scope.handleAddingDraggedTodos = function handleAddingDraggedTodos(eventId, todosData) {

        // Assign the selected todos to the particular diary event
        todosData = angular.toJson($rootScope.selected_unassigned_todos);

        $http.post(prefix + '/assign_todos_to_diaryEvent', "eventId=" + eventId + '&todosData=' + todosData).
        success(function (data, status) {
            if (status == 200) {
                $rootScope.$broadcast('jobtodos:addTodos');
            }
        });
    }

    // Call for to reload timeline view
    $scope.$on("projectJobTimelineView:reload", function (event) {
        $http.get(Routing.generate('projectJobVisitsView', { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId, milestoneId: $scope.milestoneId })).success(function (data) {
            // Get the view options
            $scope.jobDiaryView = $scope.job.diary_view;
            $scope.job = data.jobDetails;
            $scope.job.diary_view = $scope.jobDiaryView;
            $scope.job.diary_engineers = data.diaryEngineers;
            $scope.job.timeline_sections = data.timelineSections;
            $scope.allDiaryStatus = data.allDiaryStatus;
        })
    });

    // Call this function for view the sidepanel
    JobTodosSidebar.call(this, $scope, $rootScope, $state, $document, $filter, $timeout, dragHelper);

    JobDiaryEventCommonCtrl.call(this, $scope, $rootScope, $state, $compile, warningModal);
}

function JobTodosCtrl($scope, $state, prefix, $http, getIdData, $rootScope) {
    PermissionCheck.call(this, $scope);
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', true);

    $scope.selectedTab = "Todos";
    $scope.jobSubNavStatic = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', getIdData.jobId);

    $scope.job = getIdData.jobDetails;
    $scope.moduleType = $rootScope.moduleType;

    /*==========================================================================================
   Left nav data
   - when totals are set / changed show the value in the left nav
   ==========================================================================================*/
    $rootScope.$on('spreadsheet:jobtodos:row_count_updated', function(e, number_of_valid_rows, spreadsheet_title) {
        var milestone_to_update = _.findWhere($scope.job.milestones, {name : spreadsheet_title});

        if (milestone_to_update) {
            milestone_to_update.available_todos = number_of_valid_rows;
        }
    });
}

function JobTodosViewCtrl($scope, $state, prefix, $http, $timeout, getIdData, $rootScope, tableCollection, defaultPagingValue) {
    PermissionCheck.call(this, $scope);
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', true);

    $scope.selectedTab = "Todos";
    $scope.jobSubNavStatic = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', getIdData.jobId);

    $scope.job = getIdData.jobDetails;
    $scope.moduleType = $rootScope.moduleType;

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/projectjobs/'+$scope.jobId+'/todos/milestone';
    $scope.addUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/projectjobs/' + $scope.jobId + '/todos/new/milestone';
    $scope.updateUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/projectjobs/' + $scope.jobId + '/todos/update/milestone';
    $scope.spreadSheetDeleteUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/projectjobs/' + $scope.jobId + '/todos/delete/milestone';

    $scope.getDiaryEventUrl = "jobTodosWithMilestoneDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_assigned_todos";
    $scope.updateDiaryEventUrl = "jobTodosWithDiaryEventEdit/"+$scope.jobId;

    // URL for get the closed todos
    $scope.getDiaryCompletedTodoAllUrl = "jobTodosWithMilestoneDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_completed_todos_all";
    $scope.getDiaryCompletedTodoNotDoneUrl = "jobTodosWithMilestoneDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_completed_todos_not_done";
    $scope.getDiaryCompletedTodoDoneUrl = "jobTodosWithMilestoneDiaryEvent/"+$scope.selectedId+"/"+$scope.jobId+"/job_completed_todos_done";

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    tableCollection.setData('job_assigned_todos', getIdData.jobTodos.job_assigned_todos);
    tableCollection.setData('job_completed_todos_all', getIdData.jobTodos.job_completed_todos_all);
    tableCollection.setData('job_completed_todos_not_done', getIdData.jobTodos.job_completed_todos_not_done);
    tableCollection.setData('job_completed_todos_done', getIdData.jobTodos.job_completed_todos_done);

    JobTodosAssignedRemov.call(this, $scope, $rootScope, tableCollection);
}

function AssignTodosToDiaryEventSidepanel($scope, $rootScope, $state, $timeout, $stateParams) {
    $rootScope.$on('spreadsheet:rows_store_updated', function(rootscope_event, rows_store){
        $scope.selected_todos = rows_store;
    });
    $scope.jobId = $stateParams.jobId;
    $scope.job = "";
    $scope.milestones = $scope.job.milestones;
    $scope.selectedMilestone = 'all'

    $scope.selectDiaryEvent = function selectDiaryEvent(diary_event, milestone) {
        if (diary_event.selected === false) {
            // Checkbox has been checked
            diary_event.selected = true;
            $scope.selectedMilestone = milestone.name;
        } else {
            diary_event.selected = false;
            var milestoneHasSelectedEvents = $scope.checkIfMilestoneHasSelectedEvents(milestone);
            if (milestoneHasSelectedEvents === true) {
                $scope.selectedMilestone = milestone.name
            } else {
                $scope.selectedMilestone = 'all';
            }
        }
    }

    $scope.checkIfMilestoneHasSelectedEvents = function
        checkIfMilestoneHasSelectedEvents(milestone) {
        for(var i = 0, l = milestone.diary_events.length; i < l; i++) {
            var diary_event = milestone.diary_events[i];
            if (diary_event.selected === true) {
                return true
            }
        }
    }

    $scope.showModalView = function showModalView() {
        var overlay = document.querySelector('#page-overlay');
        overlay.classList.add('darker');
        $scope.with_modal_transition = true;
        $timeout(function(){
            $scope.show_modal_view = true;
        },100)
    }
    $scope.hideModalView = function hideModalView() {
        var overlay = document.querySelector('#page-overlay');
        overlay.classList.remove('darker');
        $scope.show_modal_view = false;
        $timeout(function(){
            $scope.with_modal_transition = false;
        },100)
    }
}

function CreateNewJobCtrl($scope, $state, $http, $timeout, formPanelCollection,creditcards ,getIdData, $rootScope, warningModal, toastBox,cardExpiryMonths, cardExpiryYears, prefix, canLoad, $q, skills_data) {
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = getIdData.customerTabName;
    formPanelCollection.setFilterData(getIdData.contactCreate);
    $scope.contacts = getIdData.contacts;
    $scope.jobDescriptions = getIdData.jobDescriptions;
    $scope.customerTabName = getIdData.customerTabName;
    $scope.jobDurationTime = getIdData.jobDurationTime;
    $scope.customerContracts = getIdData.customerContracts;
    $scope.customerSubNavStatic = true;
    $scope.skills_data = skills_data;
    $scope.addressline1 = getIdData.addressline1;
    $scope.addressline2 = getIdData.addressline2;
    $scope.addressline3 = getIdData.addressline3;
    $scope.county = getIdData.county;
    $scope.name = getIdData.customerName;
    $scope.postcode = getIdData.postcode;
    $scope.town = getIdData.town;
    $scope.expectedCompletionDate ='';
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.assetId =  $state.params.assetId ? $state.params.assetId : '';
    $scope.instanceId = $state.params.instanceId;
    if($scope.instanceId) {
        $scope.job_description = getIdData.description;
        $scope.selected_service_type_options = [];
        $scope.isservicejob = true;
        $scope.selected_service_type_options.push({
            'selected_type': -$scope.instanceId
        });
        $scope.priority = 'Medium_Importance';
    }
    $scope.handleSpecialEventValues = function () {
        return;
    }
    $scope.endDateError = false;
    $scope.dateError = false;
    $scope.setJobDuration = false;
    $scope.durationWarning = false;
  if($scope.jobDurationTime != undefined) {
      $scope.setJobDuration = true;
      var duration = parseInt($scope.jobDurationTime);
      $scope.startDateTime = moment().add(15 - (moment().minute() % 15), "minutes").subtract(moment().second(),"seconds").toISOString();
      $scope.demodate = $scope.startDateTime;
      $scope.endDateTime = moment($scope.demodate).add(duration, "minutes").toISOString();
      DiaryDateTimeInputs.call(this, $scope, $timeout);
  }
    $scope.minDate = moment();
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        if($scope.expectedcompletedondatetime!='' && message.name=='expectedCompletionDate'){
            var selectedDate = moment(message.date);
            var temp =moment($scope.expectedcompletedondatetime);
            $scope.expectedcompletedondatetime = selectedDate.clone().hours(temp.hours()).minutes(temp.minutes());
        }else if(message.name=='expectedCompletionDate'){
            var temp = moment().add(15 - (moment().minute() % 15), "minutes").subtract(moment().second(),"seconds");
            var selectedDate = moment(message.date);
            $scope.expectedcompletedondatetime = selectedDate.clone().hours(temp.hours()).minutes(temp.minutes());
        }
    });
    $scope.$on('setDatepicker', function(event, message) {
        if(message.name =='expectedcompletedondatetime'){
            $scope.expectedcompletedondatetime=message.value;
        }else if(message.name =='expectedCompletionDate'){
            $scope.expectedcompletedondatetime=message;
        }
    });


    $scope.service_types = getIdData.contactCreate.serviceTypes;
    $scope.job_is_booked = false;
    $scope.bookDiaryEvent = false;
    $scope.recall = false;

  /*---------------Customer contracts-------------------------*/
    if($scope.customerContracts) {
        var jobDescriptionsId = 0, assets = [], jobAllowance;
        $scope.contractSuggestList = [];
        $scope.showCustomerContracts = false;
        $scope.customerContract = '';
        $scope.showJobsCountWarning = false;

        $scope.checkContractJobAllowances = function (value) {
            $scope.customerContract = value;
            if (value != "") {
                let contract = _.find($scope.customerContracts, function (ar) {
                    return ar['id'] == $scope.customerContract;
                });
                // set invoice category
                $scope.invoicecategoryid = (contract['invoiceCategory'] != null)? contract['invoiceCategory']: "";
                // set user group
                $scope.usergroupsid = (contract['usersGroup'] != null)? contract['usersGroup']: "";
                $scope.opened = !!(contract['invoiceCategory'] || contract['usersGroup']);
                jobAllowance = _.find(contract['jobAllowances'], function (ar) {
                    return ar['jobDescriptionId'] == jobDescriptionsId;
                });

                if (jobAllowance) {
                    if (jobAllowance['jobMaxCount'] <= jobAllowance['contractJobsCount']) {
                        $scope.showJobsCountWarning = true;
                    }
                    else {
                        $scope.showJobsCountWarning = false;
                    }
                }
            }
            else {
                $scope.showJobsCountWarning = false;
                $scope.usergroupsid = "";
                $scope.opened = false;
                $scope.invoicecategoryid = "";
            }
        }

        // filters the customer contracts
        var filterCustomerContracts = function () {
            let contractAvailable = false;
            $scope.customerContracts.forEach(function(customerContract,ind){
                let array1 = customerContract['assets'].sort();
                jobAllowance = _.find(customerContract['jobAllowances'], function (ar) {
                    return ar['jobDescriptionId'] == jobDescriptionsId;
                });
                if(_.isEqual(array1, assets) && jobAllowance){
                    $scope.customerContracts[ind]['disabledSelection'] = null;
                    $scope.customerContract = customerContract['id'];
                    contractAvailable = true;
                }
                else{
                    $scope.customerContracts[ind]['disabledSelection'] = true;
                }
            });

            if (contractAvailable) {
                $scope.checkContractJobAllowances($scope.customerContract);
                $scope.showCustomerContracts = true;
            }
            else {
                $scope.customerContract = '';
                $scope.checkContractJobAllowances($scope.customerContract);
                $scope.showCustomerContracts = false;
                $scope.showJobsCountWarning = false;
            }
        };

        // triggers on changes in asset selection
        $scope.$on('jobAsset:selected', function (evt, data) {
            assets = data.selectedAssets.sort();
            filterCustomerContracts();
        });
    }
    $scope.$on('servicetype:selected', function (evt, selected_service_types) {
        $scope.servicereminderinstances =  selected_service_types.map(function(selected_service_type) {
            return selected_service_type.selected_type;
        });
    });

  $scope.formSubmit = function (formElem) {

        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            if(data.warning == true) {
                toastBox.show('Additional contact cannot be same as contact', 4000);
                $scope.saving = false;
            }else if(data.paymentFailed == true){
                $scope.saving = false;
                $scope.error = data.error;
                return false;
            }else if(data.saveCard.status == 'failure') {
                $scope.error = (data.saveCard.response.message)?data.saveCard.response.message:data.saveCard.response;
                $scope.saving = false;
                return false;
            }
            else {
                $scope.newJobForm.$setPristine();
                $rootScope.flashMessage = "Job.Saved";
                $scope.customerId = data.customerId;
                $scope.jobId = data.jobId;
                if ($scope.bookDiaryEvent) {
                    $state.transitionTo("loggedin.diary_from_job_estimate", {
                        'typeId': data.jobId,
                        'type': 'job'
                    });
                } else {
                    $state.transitionTo("loggedin.customer_list.view.job.details", {
                        'id': $scope.customerId,
                        'type': $scope.customerTabName,
                        'jobId': $scope.jobId
                    });
                }
            }
        })
    }
    $scope.contactid = '';
    if(!$scope.instanceId && $scope.contacts.length != 0) {
        $scope.contactid = $scope.contacts[0].contactid;
    }
    else if($scope.instanceId) {
        $scope.contactid = getIdData.contactId;
    }
    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.contactid = data.contactId;
        $scope.contacts.push({contactid:data.contactId, surname: data.contactFullName});
    });

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();
    $scope.cardfun=function (cv,CMod,sCard,s_card) {
        HandleCardData.call(this, $scope, creditcards, cv, CMod, sCard, s_card);
    }
    $scope.selectedSkills = [];

    SkillsManager.call(this, $scope, $scope.skills_data);

    $scope.skillsChanged = function() {
        var skills = '';
        if($scope.selectedSkills && $scope.selectedSkills.length) {
            angular.forEach($scope.selectedSkills, function (val, key) {
                var concat = (skills != '' ? "," : "");
                skills += concat + val.id;
            });
        }
        // Todo: Need to fix this, as this is coming from twig, I'm using JQuery to set value
        $('#selectedSkill').val(skills);
    }
    $scope.validAmount = false;
    $scope.updateInvoiceAmount = function(val){
        $scope.invoiceAmount = val;
        if(val == undefined)
            $scope.validAmount = false;
        else
            $scope.validAmount = true;
    }
    $scope.selectedSkills = [];
    var canceler = null;
    $scope.getSkillsForJobDescription = function(jobDescriptionId) {
        jobDescriptionsId = jobDescriptionId;
        if($scope.customerContracts) {
            filterCustomerContracts();
            $scope.checkContractJobAllowances($scope.customerContract);
        }
        $scope.invoiceAmount = 0;
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $http.get(prefix + '/get_assigned_skills?type=job_description&primaryId=' + jobDescriptionId, {timeout: canceler.promise}).success(function (data) {
            $scope.selectedSkills = data.skills;
            $scope.skillsChanged();
        });
        var matchedEntry = _.find($scope.jobDescriptions, function(description){ return description['id'] == jobDescriptionId ; });
        $timeout(function(){
            $scope.$apply(function(){
                $scope.invoiceAmount = (matchedEntry['price'] != null) ? parseFloat(matchedEntry['price']) : 0;
                $('#invoice-amount').val($scope.invoiceAmount);
                $scope.validAmount = true;
            });
        });
    }
}

function JobRemindersListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope) {
    ViewReminderListCtrl.call(this, $scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope)
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
}

function JobNewReminderCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    CreateNewReminder.call(this, $scope, $state, $http, prefix, getIdData, $rootScope)
}

function EditJobReminderCtrl($scope, $state, getIdData, $http, prefix, $rootScope) {
    ViewEditReminder.call(this, $scope, $state, getIdData, $http, prefix, $rootScope)
}

function GetJobFilesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, tableCollection, $rootScope){
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.jobSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};
    tableCollection.setData('attached_files', getIdData.attached_files);

    var message = {name: 'filesCount', value: getIdData.attached_files.count};
    $rootScope.$emit('event:changeJobScope', message);

    $scope.$emit('displayScreen', false);
    $scope.$emit('jobId:selected', $scope.jobId);

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {

        $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_job_files', { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            var message = {name: 'filesCount', value: data.attached_files.count};
            $rootScope.$emit('event:changeJobScope',message);
            tableCollection.setData('attached_files', data.attached_files);
            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }

    // TODO::combine single method for preview/download/delete are using the same logic
    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });

    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });
    /*
  $scope.$on("file:preview", function(evt, data) {
      var data = tableCollection.getSidepanelData(data.index, 'attached_files');
    var file = {
      'name': data['File name'],
      'location': data['location'],
      'mimeType': data['Type'],
      'createdondatetime': data['createdondatetime'],
      'type': data['Type'],
      'id': data['id']
    };
    $scope.previewFile(file);
  });

  $scope.previewFile = function (fileObj) {
    $scope.enablePreviewFiles = "JobsAttachedFiles";
    $scope.imageFile = false;
    $scope.loadFromObject = false;
    $scope.file = fileObj;
    $scope.selectedRow = fileObj.id;

    $scope.image360 = false;
    $scope.videoFile = false;
    $scope.viewableFile = false;
    $scope.image360 = $scope.detect360Images(fileObj.location);
    $scope.fileTypeDetectionFinished = false;

    if (fileObj.type.toLowerCase() == 'jpg' || fileObj.type.toLowerCase() == 'jpeg') {
	    $scope.image360.then(function(isImage360) {
		    if (isImage360 == true) {
		      $scope.image360 = true;
		      $scope.viewableFile = true;

		      pannellum.viewer('panorama', {
			  'type': 'equirectangular',
			  'panorama': fileObj.location,
			  'autoLoad': true,
			  'autoRotate': -5,
			  'autoRotateStopDelay': 4000
		      });
		    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
		      $scope.imageFile = true;
		      $scope.viewableFile = true;
		    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
		      $scope.loadFromObject = true;
		    }

		    $scope.fileTypeDetectionFinished = true;

		    $scope.$apply();
	    });
    }
    else {
	    if (fileObj.type.toLowerCase() == 'mp4' || fileObj.type.toLowerCase() == 'webm' || fileObj.type.toLowerCase() == 'ogm') {
	      $scope.videoFile = true;
	      $scope.viewableFile = true;
	      $scope.fileTypeDetectionFinisheed = true;
	    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
	      $scope.imageFile = true;
	      $scope.viewableFile = true;
	    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
	      $scope.loadFromObject = true;
	    }

	    $scope.fileTypeDetectionFinished = true;
    }
    $scope.fileSelected = true;
  }

  $scope.detect360Images = function(fileUrl) {
    var element = {};
    element['src'] = fileUrl;

    var async = $q.defer();

    EXIF.enableXmp();
    EXIF.getData(element, function() {
	var make = EXIF.getTag(this, "Make");
	var model = EXIF.getTag(this, "Model");
        var panoramaFound = false;
        if (make) {
	  if (make.toLowerCase().indexOf('ricoh') > -1) {
              panoramaFound = true;
              async.resolve(true);
	  }
        }
        if (model && !panoramaFound) {
	  if (model.toLowerCase().indexOf('ricoh') > -1) {
              panoramaFound = true;
              async.resolve(true);
	  }
        }
	async.resolve(false);
    });

    return async.promise;
  }

  $scope.closePreview = function () {
    $scope.enablePreviewFiles = "";
    $scope.selectedRow = "";
    $scope.file = "";
    $scope.fileSelected = false;
    $scope.recordArgs = '';
    $rootScope.selection = '';
    $rootScope.$broadcast('closeall');
  }
*/
}

function NewJobFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService){
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    $scope.jobId = $state.params.jobId;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerTabName = $state.params.type;
    $scope.error = false;
    $scope.testDrag = true;
    $scope.jobSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_job_file?mode=jobs&jobId=' + $scope.jobId;
    $scope.uploadAction = $scope.action;

    $scope.uploadCallback = function() {
        $scope.viewJobFiles($scope.selectedId, $scope.jobId, $scope.customerTabName);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File.Uploaded";
            $scope.viewJobFiles($scope.selectedId, $scope.jobId, $scope.customerTabName);
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}

function AddNewPartCtrl($scope, $state, $http, prefix, getIdData, $rootScope, formPanelCollection, $timeout, $modal,$compile, $translate, $filter) {
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customCurrency = $rootScope.CustomCurrency;
    $scope.$emit('displayScreen', false);
    $scope.job = getIdData.jobDetails;
    $scope.moduleType = $rootScope.moduleType;
    $scope.jobSubNavStatic = true;
    $scope.part={};
    $scope.part.partid = '';
    $scope.selectedPart = '';
    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_parts',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                let partsInOrder = [];
                let partIds = [];
                let partCatalogs = {};
                // collect unique parts and supplierpartnumber without breaking the sort order
                angular.forEach(data, function(part) {
                    let partId = part['id'];
                    if(!partIds.includes(partId)) {
                        partsInOrder.push(part);
                        partIds.push(partId);
                        partCatalogs[partId] = [];
                    }
                    if(part['supplierpartnumber'] != null && part['supplierpartnumber']  != '') {
                        partCatalogs[partId].push({'supplierpartnumber' : part['supplierpartnumber'], 'suppliername' : part['suppliername']});
                    }
                });

                let returnParts = [];
                angular.forEach(partsInOrder, function(part) {
                    let partId = part['id'];
                    if(partCatalogs.hasOwnProperty(partId)){
                        part['supplierpartnumber'] = partCatalogs[partId];
                    }
                    returnParts.push(part);
                });

                return { results: returnParts };
            }
        },
        formatResult: function(item, container, query, escapeMarkup) {
            let splitSearchTerm = Array.from(new Set(query.term.trim().split(" "))); //Get unique words
            splitSearchTerm = splitSearchTerm.filter(function (elm) {
                return elm !== "";
            });
            let htmlResult = '';
            let openHtmlUnderline = 'xx_openHtmlUnderline_xx';
            let closeHtmlUnderline = 'xx_closeHtmlUnderline_xx';
            let regex = new RegExp($rootScope.escapeRegExpSpecial(splitSearchTerm.join("|")), 'gi');
            let itemDescription = item.text.replace(regex, function (i) { return openHtmlUnderline + i + closeHtmlUnderline; });
            itemDescription = $rootScope.escapeHtmlSpecial(itemDescription);
            htmlResult = itemDescription.replaceAll(openHtmlUnderline,'<u>').replaceAll(closeHtmlUnderline,'</u>')

            if(item.manufacturepartnumber){
                let mpnText = item.manufacturepartnumber.replace(regex, function (i) { return openHtmlUnderline + i + closeHtmlUnderline; });
                mpnText = $rootScope.escapeHtmlSpecial(mpnText);
                htmlResult += ', '+ $translate('MPN') + ' - ' + mpnText.replaceAll(openHtmlUnderline,'<u>').replaceAll(closeHtmlUnderline,'</u>');
            }

            if(item.supplierpartnumber.length > 0) {
                htmlResult += '<br>';
                angular.forEach(item.supplierpartnumber, function (spn) {
                    if(spn['supplierpartnumber'] != null && spn['supplierpartnumber'] != ''){
                        let supplierPartNo = spn['supplierpartnumber'].replace(regex, function(i) { return openHtmlUnderline + i + closeHtmlUnderline });
                        supplierPartNo = $rootScope.escapeHtmlSpecial(supplierPartNo);
                        supplierPartNo = supplierPartNo.replaceAll(openHtmlUnderline,'<u>').replaceAll(closeHtmlUnderline,'</u>')
                        htmlResult += '<div class="select2 supplier-product-code cs-text-muted"><span>' + $filter('translate')('product.code', { supplierName : $rootScope.escapeHtmlSpecial(spn['suppliername']) }) + '</span>: ' + supplierPartNo + '</div>';
                    }
                });
            }
            return $compile('<div class="select2 parts-list-dropdown">' + htmlResult + '</div>')($scope);
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }
    $scope.markUpDisable = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', getIdData.jobId);

    formPanelCollection.setFilterData(getIdData.newPart);

    $scope.isPopupContentLoaded = false;
    $scope.supplierPriceItems = [];
    $scope.getSupplierPriceList = function(e) {
        var contentTpl = $('script#cs-popover-tpl').html();
        $scope.popupContent = 'Loading...';
        if($scope.oldSelectedPartId !== $scope.selectedPart.id) {
            $scope.supplierPriceLoading = true;
            $scope.oldSelectedPartId = $scope.selectedPart.id;
            $http.get(prefix + '/parts_and_prices/'+ $scope.selectedPart.id +'/supplier_prices').success(function (response) {
                $scope.supplierPriceLoading = false;
                $scope.supplierPriceItems = response.data;
                var sortedPrices = _.sortBy($scope.supplierPriceItems, function (i) { return parseInt(i.price); });
                $scope.supplierPriceItems = sortedPrices;
                if(sortedPrices.length > 0) {
                    $scope.maxSupplierPrices = sortedPrices[sortedPrices.length - 1].price;
                    $scope.minSupplierPrices = sortedPrices[0].price;
                }
            });
        }
        setTimeout( function() {
            var el = $(e.target).next('.popover').css('width', '700px');
            var content = $compile(contentTpl)( $scope );
            el.find('.popover-content').html(content[0]);

            $('body').on('click', function (e) {
                $('[popover-popup]').each(function () {
                    if ($(this).has(e.target).length === 0 && !$(e.target).is('[popover]')) {
                        $('.supplier-price-popover').trigger('click')
                    }
                });
            });
        }, 2000)
    }
    $scope.applySupplierPrice = function(i){
        $scope.unitprice = i['price'];
    }


    $scope.openAddPartModal = function() {
      var modalInstance = $modal.open({
        windowClass: 'df-action df-action-add_section-modal df-action-modal sidepanel settings-partsandprices',
        controller: 'JobAddPartCtrl',
        templateUrl: '/template/side_panels/job_add_part.html',
        keyboard: false,
        resolve: {
          dataSet: function() {
            return {
              partItems: getIdData.newPart.new_part
            };
          }
        }
      });
      modalInstance.result.then(function(data) {
        $scope.selectedPart = {id:data.id, text: data.description};
        $scope.parts = 1;
        $timeout(function () {
          $scope.part.partid = data.id;
          $scope.unitprice = data.price;
          $scope.markup = data.markUp;
          $scope.taxItemId = data.taxItemId;
        }, 100);
      })
    };

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newPartForm.$setPristine();
            $rootScope.flashMessage = "Part.Saved";
            if($scope.job.type === 'Job'){
                $state.transitionTo("loggedin.customer_list.view.job.parts.view_parts", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId });
            }
            if($scope.job.type === 'Project'){
                $state.transitionTo("loggedin.customer_list.view.job.parts.view", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId, 'milestoneId' : $scope.milestoneId });
            }
            $scope.saving = false;
        })
    }
    $scope.changePart = function changePart(partId) {
        $scope.part.partid = partId;
        $http.post(prefix + '/get_part_details/'+ $scope.jobId, "partId=" + partId).
            success(function (data, status) {
                if (status == 200) {
                    $scope.unitprice = data.unitCost;
                    $scope.markup = data.markUp;
                    $scope.taxItemId = data.taxItemId;
                    $scope.markUpDisable = data.markUpDisable;
                }
            });

    }
    $scope.parts = getIdData.parts;

    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.selectedPart = {id:data.id, text: data.description};
        $scope.parts = 1;
        $timeout(function () {
            $scope.part.partid = data.id;
            $scope.unitprice = data.price;
            $scope.markup = data.markUp;
            $scope.taxItemId = data.taxItemId;
        }, 100);
    });

    $scope.$watch('quantity', function (newVal, oldVal) {
        if (parseFloat(newVal) === 0) {
            $scope.newPartForm['part[quantity]'].$setValidity("quantityError", false);
        }
        else {
            $scope.newPartForm['part[quantity]'].$setValidity("quantityError", true);
        }
    });

    $scope.$watch('unitprice', function (newVal, oldVal) {
        if (parseFloat(newVal) < 0) {
            $scope.newPartForm['part[unitcost]'].$setValidity("unitCostError", false);
        }
        else {
            $scope.newPartForm['part[unitcost]'].$setValidity("unitCostError", true);
        }
    });
}

function JobPartsFilter($scope, $rootScope){
    var filterData = {};
    filterData['partsSearch'] = $rootScope.partsSearch;
    filterData['partsStatus'] = $rootScope.partsStatus;
    $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson(filterData));
}

function JobCertificateCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, tableCollection, formPanelCollection, emailService, canLoad, $q) {
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = 'Certificates';
    $scope.shouldBeOpen = false;
    $scope.jobSubNavStatic = false;
    $scope.certificateSubject = getIdData.certificateSubject;
    $scope.certificateMessage = getIdData.certificateMessage;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic': $scope.jobSubNavStatic };
    tableCollection.setData('job_certificates', getIdData.job_certificates);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    // Show or hide add new form button
    $scope.showAddNewFormButton = getIdData.showAddNewFormButton;
    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.searchText = '';
    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchText=newVal;
            $scope.$broadcast('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchText': $scope.searchText}), category : 'job_certificates'}));
        }
    });

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.emailJobCertificate = function(data){
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId +'&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + $scope.jobId;
        clone_obj.subject = $scope.certificateSubject;
        clone_obj.message = $scope.certificateMessage;
        clone_obj.relatedObjectType = (data.certificateType == 69) ? data.pat_type : 47;    // for Certificate lookup id
        clone_obj.relatedObjectId = data.id;

        // Remove all comma from the string
        let name = data.Type.replace(/,/g, '');

        if(data.certificateType == 69)       // for pat certificate lookup table type
        {
            clone_obj.attached_files = [
                {
                    id: "PATCERTIFICATE|" + name + "|" + data.id + "|" + data.pat_type,
                    file_name: "Job "+ name + ". " + data.Number,
                    file_size: 0,
                    type: 'generated'
                }
            ];
        }else
        {
            clone_obj.attached_files = [
                {
                    id: "CERTIFICATE|" + name + "|" + data.id + "|" + data.Number,
                    file_name: "Job "+ name + ". " + data.Number,
                    file_size: 0,
                    type: 'generated'
                }
            ];
        }
        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.showSidePanel = false;
    $scope.jobInfo = {
        jobId: $scope.jobId,
        addressline1: getIdData.addressline1,
        addressline2: getIdData.addressline2,
        county: getIdData.county,
        postcode: getIdData.postcode,
        town: getIdData.town,
        customerName: getIdData.customerName,
        customerId: getIdData.id,
        type: 'ADD',
        certificateId: '',
    };
    $scope.openCertificateAddEditSidePanel = function(){
        $scope.showSidePanel = true;
    };

    $scope.onSidePanelClose = function (reload) {
        $scope.showSidePanel = false;
        $scope.jobInfo.type = 'ADD';
        $scope.jobInfo.certificateId = '';
    };

    $scope.$on('dynamic_certificate:edit', function(event, id) {
        $scope.jobInfo.type = 'EDIT';
        $scope.jobInfo.certificateId = id;
        $scope.showSidePanel = true;
    });

    $scope.reloadSmartTable = function (reload) {
        $scope.$broadcast('smartTable:partsReload', 1, '');
    }
}

function CustomerJobCommunicationsCtrl($scope,$state, $http, prefix, getIdData, $rootScope, dateRange, emailService) {
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "Notes";
    $scope.hideSubNavCustomers = true;
    $scope.hideSubNavCustomerName = true;
    $scope.jobSubNavStatic = false;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.searchText = '';
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.contacts = getIdData.contacts;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected',  $state.params.jobId);
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.action = "";

    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }

      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId + '&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + $scope.jobId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 31;        // For Job
        clone_obj.relatedObjectId = $scope.jobId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        if( message.action ) {
          $scope.action = message.action;
        }
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action};

        $scope.$broadcast('filterValue', filters);
    });

    var baseRoute = prefix + '/customers/' + $scope.selectedId + '/jobs/' + $state.params.jobId;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}

function CustomerJobNotesCtrl($scope,$state, $http, prefix, getIdData, $rootScope, defaultPagingValue, canLoad, $q, $sce, emailService) {

    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.customerId = getIdData.navBarDetails.id;
    $scope.selectedTab = 'Notes';
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.hideSubNavCustomers = true;
    $scope.hideSubNavCustomerName = true;
    $scope.jobSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.shouldBeOpen = false;
    $scope.$emit('jobId:selected',  $state.params.jobId);
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;


    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notes = getIdData.notesDetails.notesDetails;
        $scope.notesCount = getIdData.notesDetails.notesCount;
        $scope.notesCountMain = getIdData.notesDetails.notesCountMain;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("Jobnotesandcommunications", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });
    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Job Notes List';
            $scope.excelRouteName = 'downloadnotes';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.selectedId;
            $scope.pageName = 'Job Notes List';
            $scope.pdfRouteName = 'printnotes';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.currentPagePhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.currentPagePhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.customerId +'&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + $scope.jobId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 31;        // For Job
        clone_obj.relatedObjectId = $scope.jobId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.searchCommunications(1);
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesCount = data.notesCount;
            $scope.notesCountMain = data.notesCountMain;
            var message = {name: 'notesCount', value: $scope.notesCount};
            $scope.$emit('event:changeJobScope', message);
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNumPhoneCalls) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/listPhoneCalls?pagePhoneCalls=' + pageNumPhoneCalls + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceler = null;
    $scope.fetchSmsMessages = function (pageNumSmsMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceler) {
            smsCanceler.resolve();
        }
        smsCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/listSms?pageSmsMessages=' + pageNumSmsMessages + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: smsCanceler.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNumEmailMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/jobs/' + $scope.jobId +  '/listEmail?pageEmailMessages=' + pageNumEmailMessages + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if (searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    if ($state.current.data.limitChatMessages) {
      $scope.limitChatMessages = parseInt($state.current.data.limitChatMessages);
    } else {
      $scope.limitChatMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumChatMessages) {
      $scope.pageNumChatMessages = parseInt($state.current.data.pageNumChatMessages);
    } else {
      $scope.pageNumChatMessages = 1;
    }
    var chatCanceller = null;

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    var searchCanceller = null;
    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);
        if (searchCanceller) {
            searchCanceller.resolve();
        }
        searchCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/notes/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: searchCanceller.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notes = data.notesDetails.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
            $scope.selectedId = data.navBarDetails.id;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);
            var message = {name: 'notesCount', value: $scope.notesCount};
            $scope.$emit('event:changeJobScope', message);

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }
    $scope.downloadCallRecording = function(fileLink){
        let link = document.createElement('a');
        link.href = fileLink;

        if (link.download !== undefined) {
            link.download = 'call-recording.wav';
        }
        if (document.createEvent) {
            let e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
        }
    };
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function CustomerJobNotesTimeViewCtrl($scope, $state, $http, prefix, getIdData, $timeout, $rootScope, canLoad, $q, $sce, emailService) {

    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "Notes";
    $scope.hideSubNavCustomers = true;
    $scope.hideSubNavCustomerName = true;
    $scope.jobSubNavStatic = false;
    $scope.$emit('jobId:selected',  $state.params.jobId);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':false };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("Jobnotesandcommunications", function (event, action, data) {
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId + '&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=31&id=' + $scope.jobId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 31;        // For Job
        clone_obj.relatedObjectId = $scope.jobId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $rootScope.$on('enableJobEstimateChatFeature', function (event, value) {
        $scope.enableJobEstimateChatFeature = value;
    });

    var url = '/customers/customer_list/' + $state.params.id + '/jobs/' + $state.params.jobId + '/notes/view/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
}

function CustomerJobsNewNotesCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "Notes";
    $scope.modeChange = $state.params.mode;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic': true };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected',  $state.params.jobId);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.saving = false;
            $rootScope.flashMessage = data.success;
            $state.transitionTo("loggedin.customer_list.view.job.notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId });
        })
    }
}

function JobEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.customerOffices = getIdData.navBarDetails;
    $scope.customerId = $scope.customerOffices.id;
    $scope.jobSubNavStatic = true;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.selectedTab = "Notes";
    $scope.$emit('jobId:selected',  $state.params.jobId);
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
            + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
        success(function (data, status) {
            if (status == 200) {
                $scope.editNoteForm.$setPristine();
                $rootScope.flashMessage = data;
                if ($scope.modeChange == 'timeline') {
                    $scope.jobNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.jobId);
                } else if ($scope.modeChange == 'table') {
                    $scope.jobNotesTableList($scope.selectedId, $scope.customerTabName, $scope.jobId);
                }
                $scope.updating = false;
            }
        });
    }
}

function JobNewSmsMessageCtrl($scope, $http, $state, datasets,  prefix, smsTemplates, $rootScope, formService, getIdData, sidePanel, $sce) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    $scope.notes = getIdData.customerOffices;
    smsTemplates.setTemplatesValue(getIdData.templateDetails);
    $scope.smsTemplates = getIdData.templateDetails;
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "Notes";
    $scope.jobSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected',  $state.params.jobId);

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });
    SMSMessageControllers.call(this, $scope);
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.saving = false;
            $rootScope.flashMessage = data.success;
            $state.transitionTo("loggedin.customer_list.view.job.notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId });
        })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function JobNewPhoneCallCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "Notes";
    $scope.jobSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected',  $state.params.jobId);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newPhoneCallForm.$setPristine();
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.jobNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.jobId);
            } else if ($scope.modeChange == 'table') {
                $scope.jobNotesTableList($scope.selectedId, $scope.customerTabName, $scope.jobId);
            }
            $scope.saving = false;
        })
    }
}

function oldBatchFulfillParts($scope, $rootScope, $state, $stateParams, $timeout, jobData, $http, prefix) {
    $scope.jobId = $state.params.jobId;
    $scope.customerId = $state.params.customerId;

    $rootScope.$on('smart_table:pinned_rows_part_category', function(event, category) {
        $scope.category = category;
    });
    /*=========================================================================================================
     Set data on the scope
     =========================================================================================================*/
    $scope.show_modal_view = false;
    $scope.with_modal_transition = false;
    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
        if ($scope.chosenParts.length > 0) {
            var part_ids = [];
            for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
                part_ids.push($scope.chosenParts[i].part.partId);
            }
            $http.get(prefix + '/getStockLocationAvailable' + '?partId='+ part_ids).success(function (data) {
                jobData.setPartLocations(data);
                for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
                    var chosen_part = $scope.chosenParts[i];
                    var part_locations = '';
                    if (chosen_part.part !== undefined && chosen_part.part.id !== undefined) {

                        var part_id = chosen_part.part.partId,
                            part_locations = jobData.getStockLocationsByPartId(part_id) ? jobData.getStockLocationsByPartId(part_id).locations : '';


                        chosen_part['locations'] = part_locations;
                        chosen_part.part_allocation_total = $scope.generatePartAllocationTotal(part_locations);

                        var order_from_supplier_amount_exists = chosen_part.order_from_supplier !== 'undefined' &&
                            chosen_part.order_from_supplier !== undefined;
                        if (order_from_supplier_amount_exists) {
                            chosen_part.part_allocation_total += parseFloat(chosen_part.order_from_supplier.amount);
                        } else {
                            chosen_part.order_from_supplier = {amount : 0};
                        }

                        if (chosen_part.part_allocation_total == chosen_part.part.Quantity) {
                            chosen_part.valid = true;
                        } else {
                            chosen_part.valid = false;
                        }
                    } else {
                        break
                    }
                }
                if ($scope.chosenParts[0].part !== undefined &&
                    $scope.chosenParts[0].part.id !== undefined) {

                    $scope.updateChosenPartsAllocationTotals();
                    $scope.updateSavingButtonText();
                    $scope.checkIfAnyPartsAreInvalid();
                }
            });
        }
    });

    $scope.generatePartAllocationTotal = function generatePartAllocationTotal(locations) {
        var allocation_total = 0;

        for(var i = 0, l = locations.length; i < l; i++) {
            var allocation = parseFloat(locations[i].allocation);
            allocation_total += allocation;
        }

        return allocation_total;
    }

    /*=========================================================================================================
     Validate the input if its a number - remove all
     letters
     =========================================================================================================*/
    $scope.validateOptions = function validateOptions(amount, part_id, location_id) {
        var allocation = this.location.allocation,
            allocation = allocation.replace(/[^0-9.]/g,'');

        $scope.chosenParts[part_id].locations[location_id].allocation = allocation;

        $scope.isQuantityInBounds(allocation, part_id);
    }

    /*=========================================================================================================
     Validate order from supplier input
     =========================================================================================================*/
    $scope.validateOrderQuantity = function validateOrderQuantity(amount, part_id) {

        var amount = amount.replace(/[^0-9.]/g,''),
            input_is_number = !isNaN(parseFloat(amount));

        $scope.chosenParts[part_id].order_from_supplier.amount = amount;

        if(input_is_number) {
            $scope.isQuantityInBounds(amount, part_id);
        }
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.isQuantityInBounds = function isQuantityInBounds(num, part_id) {
        var grand_total = 0,
            num = parseFloat(num),
            total_allocated = 0,
            new_quantity_in_bounds,
            new_quantity_valid = true;

        var chosen_parts = $scope.chosenParts;

        $scope.handlePartValidation(part_id, num);
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.handlePartValidation = function handlePartValidation(part_id, num) {
        var chosen_parts = $scope.chosenParts;

        $timeout(function(){
            var allocation = 0,
                quantity = 0,
                part_allocation_total = 0,
                current_part_quantity_needed = 0,
                part_locations,
                order_from_supplier_amount = parseFloat(chosen_parts[part_id].order_from_supplier.amount);

            $scope.disable_saving = true;

            // check if each allocation is valid against its stock location
            part_locations = chosen_parts[part_id].locations;
            for(var x = 0, c = part_locations.length; x < c; x++) {

                allocation = parseFloat(part_locations[x].allocation);
                quantity = parseFloat(part_locations[x].quantity);
                part_allocation_total += allocation;

                if (allocation <= quantity) {
                    $scope.chosenParts[part_id].locations[x].valid = true;
                }else{
                    $scope.chosenParts[part_id].locations[x].valid = false;
                }
                if (part_locations[x].location === 'General') {
                    $scope.chosenParts[part_id].locations[x].valid = true;
                }
            }
            // check if the sum of these allocations exceeds the total required for the part
            part_allocation_total += order_from_supplier_amount;

            var part_required_total = $scope.chosenParts[part_id].part.Quantity;
            if (part_allocation_total == part_required_total) {
                $scope.chosenParts[part_id].valid = true;
            } else {
                $scope.chosenParts[part_id].valid = false;
            }

            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        },15);
    }

    /*=========================================================================================================
     Check if all inputs are valid
     =========================================================================================================*/
    $scope.updateChosenPartsAllocationTotals = function updateChosenPartsAllocationTotals(){
        var chosen_parts = $scope.chosenParts;

        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var  part_locations = chosen_parts[i].locations;
            var current_part_quantity_needed = parseFloat(chosen_parts[i].Quantity);
            var current_part_allocation_total = 0;
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);

            for(var x = 0, c = part_locations.length; x < c; x++) {
                var  allocation = parseFloat(part_locations[x].allocation);
                current_part_allocation_total += (allocation)?allocation:0;
            }

            current_part_allocation_total += order_from_supplier_amount;
            $scope.chosenParts[i].part_allocation_total = current_part_allocation_total;
        }
    }

    $scope.updateSavingButtonText = function updateSavingButtonText() {
        var chosen_parts = $scope.chosenParts;

        $scope.saving_text = 'Save';
        $scope.show_purchase_order_button = false;
        $scope.hide_fulfill_all_parts = false;
        // update saving button text
        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);

            for(var j = 0, ll = chosen_parts[i].locations.length; j < ll; j++) {
                var location_allocation = parseFloat(chosen_parts[i].locations[j].allocation);
                if (location_allocation > 0) {
                    $scope.hide_fulfill_all_parts = true;
                }
            }

            if (order_from_supplier_amount > 0) {
                if($rootScope.clientJobType == 1){
                    $scope.saving_text = 'Save';
                    $scope.show_purchase_order_button = false;
                    $scope.hide_fulfill_all_parts = true;
                }else{
                    $scope.saving_text = 'Save and create purchase order';
                    $scope.show_purchase_order_button = true;
                }
            }
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.disable_saving = false;
        var chosen_parts = $scope.chosenParts;

        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var entire_part_valid = chosen_parts[i].valid === true;
            if (entire_part_valid) {
                var current_part_allocation_total = 0;
                var part_locations = chosen_parts[i].locations;

                for(var x = 0, c = part_locations.length; x < c; x++) {
                    var allocation = parseFloat(part_locations[x].allocation);
                    current_part_allocation_total += allocation;

                    var part_location_invalid = part_locations[x].valid === false;

                    if (part_location_invalid) {
                        $scope.disable_saving = true;
                        $scope.chosenParts[i].valid = false;
                        return
                    }
                }
                // check if the part allocation total matches the required total
                current_part_allocation_total += parseFloat(chosen_parts[i].order_from_supplier.amount);

                var current_part_quantity_needed = parseFloat(chosen_parts[i].part.Quantity);

                if (current_part_allocation_total !== current_part_quantity_needed) {
                    $scope.disable_saving = true;
                    $scope.chosenParts[i].valid = false;
                    return
                }

            }else{
                $scope.disable_saving = true;
                $scope.chosenParts[i].valid = false;
                return
            }

        }
        if (current_part_allocation_total !== current_part_quantity_needed && $scope.disable_saving === false) {
            $scope.disable_saving = true;
            $scope.chosenParts[i].valid = false;
        } else{
            $scope.disable_saving = false;
        }
    }

    /*=========================================================================================================
     When clicking order all from supplier, set all the
     other inputs to 0 and order from supplier input
     to the quantity required
     =========================================================================================================*/
    $scope.orderAllFromSupplier = function orderAllFromSupplier(part_id) {
        var locations = $scope.chosenParts[part_id].locations,
            chosen_parts = $scope.chosenParts;

        for(var i = 0, l = locations.length; i < l; i++) {
            locations[i].allocation = 0;
            locations[i].valid = true;
        }

        $scope.chosenParts[part_id].valid = true;
        $scope.chosenParts[part_id].order_from_supplier.amount = parseFloat($scope.chosenParts[part_id].part.Quantity);

        $scope.handlePartValidation(part_id);
        $scope.updateChosenPartsAllocationTotals();
        $scope.updateSavingButtonText();
        $scope.checkIfAnyPartsAreInvalid();
    }

    /*=========================================================================================================
     If the user blurs away from the inout and the input
     is blank
     =========================================================================================================*/
    $scope.handleBlur = function handleBlur(event, location_id, part_id) {
        if (event.target.value.length === 0) {
            $scope.chosenParts[part_id].locations[location_id].allocation = 0;

            $scope.handlePartValidation(part_id);
            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }
    /*=========================================================================================================
     If the user blurs away from the order from supplier
     input and the input is blank
     =========================================================================================================*/
    $scope.handleSupplierOrderBlur = function handleSupplierOrderBlur(event, part_id) {
        if (event.target.value.length === 0) {
            $scope.chosenParts[part_id].order_from_supplier.amount = 0;

            $scope.handlePartValidation(part_id);
            $scope.updateChosenPartsAllocationTotals();
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }

    /*=========================================================================================================
     When a purchase order needs to be generated
     =========================================================================================================*/
    $scope.populateItemsForPurchaseOrder = function populateItemsForPurchaseOrder() {
        var chosen_parts = $scope.chosenParts;
        $scope.itemsForPurchaseOrder = [];
        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);
            if (order_from_supplier_amount > 0) {
                $scope.itemsForPurchaseOrder.push(chosen_parts[i]);
            }
        }
    }
    $scope.goToPurchaseOrder = function goToPurchaseOrder(partsData, fulfil) {

        $state.params.prepopulatePurchaseOrder = true;
        $scope.totalPartData = [];
        if(fulfil === 'OrderFromSupplier' && $rootScope.clientJobType != 1) {
            $scope.show_purchase_order_button = true;
        }
        angular.forEach(partsData, function (key, value){
            var orderToSupplier = (fulfil === 'OrderFromSupplier')?key.part.Quantity:key.order_from_supplier.amount;
            _.find(key.locations, function (location, lkey) {
                if (location && (location.location === fulfil)) {
                    key.locations[lkey].allocation = key.part.Quantity;
                }
            });
            $scope.totalPartData.push({'orderToSupplier':orderToSupplier, 'totalQuantity':key.part.Quantity, 'availableParts': key.locations, 'jobPartId': key.part.id, 'partId':key.part.partId, 'salesPrice': key.part['Unit sales price'], 'costPrice': key.part['Unit cost price']})
        });
        jobData.setSelectedPart($scope.totalPartData);
        if($scope.show_purchase_order_button){
            $scope.populateItemsForPurchaseOrder();
            $scope.closePanel();
            if($state.params.milestoneId){
                $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order_milestone", { id: $state.params.id, jobId: $state.params.jobId, type: $state.params.type, milestoneId : $state.params.milestoneId  });
            }else{
                $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order", { id: $state.params.id, jobId: $state.params.jobId, type: $state.params.type });
            }

        }else{
            $http.post(prefix + '/save_part_fulfill', "selectedParts=" + encodeURIComponent(angular.toJson($scope.totalPartData)) + "&clientJobType=" + $rootScope.clientJobType + "&jobToDos=" + $rootScope.clientJobTodos + "&jobId=" + $state.params.jobId + "&milestoneId=" + $state.params.milestoneId ).
            success(function (data, status) {
                if (status == 200) {
                    $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category});
                    $rootScope.$broadcast('spreadsheet:todoupdate');
                    $scope.closePanel();
                }
            });
        }
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('smart_table:unpin_all_pinned_rows');
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.cancelSidePanel = function cancelSidePanel() {
        $scope.$emit('sidepanel:close');
    }

    // Check whether Purchase order screen have permission or not.
    var featureName = 'Purchaseorder', featureAccess = 'writeaccess', featureId = features[featureName];
    var purchaseOrderPermissions = $rootScope.hasPermission(featureId, featureAccess);

    if(purchaseOrderPermissions || $rootScope.clientJobType == 1 ){
        $scope.purchaseOrderAccess = true;
    }else{
        $scope.purchaseOrderAccess = false;
    }
}

function FulFillParts($scope, $rootScope, $state, $stateParams, $timeout, jobData, $http, prefix, warningModal) {
    $scope.jobId = $state.params.jobId;
    $scope.customerId = $state.params.customerId;
    $scope.fulfillError = false;

    // Check whether Purchase order screen have permission or not.
    var featureName = 'Purchaseorder', featureAccess = 'writeaccess', featureId = features[featureName];
    var purchaseOrderPermissions = $rootScope.hasPermission(featureId, featureAccess);

    if(purchaseOrderPermissions || $rootScope.clientJobType == 1 ){
        $scope.purchaseOrderAccess = true;
    }else{
        $scope.purchaseOrderAccess = false;
    }

    /*=========================================================================================================
     Set data on the scope based on existing row data
     =========================================================================================================*/
    $scope.setupStockLocations = function setupStockLocations(part_id) {
        var stockDetails = jobData.getStockLocationsByPartId(part_id);
        $scope.stockLocations = stockDetails['locations'];
        $scope.saving_text = 'Save';
        $scope.part_allocation_total = 0;
        $scope.disable_saving = true;
        var stock_locations = $scope.stockLocations;

        $timeout(function(){
            var	current_allocation_total = 0,
                part_required_total = parseFloat($scope.sidepanelData['Quantity']);
            $scope.requestedQuantity = parseFloat($scope.sidepanelData['Quantity']);
            $scope.part_allocation_total = 0;

            for(var i = 0, l = stock_locations.length; i < l; i++) {
                $scope.stockLocations[i].quantity = parseFloat(stock_locations[i].quantity);
                $scope.stockLocations[i].allocation = 0;
                var location_allocation = parseFloat(stock_locations[i].allocation);
                if (location_allocation > 0) {
                    $scope.part_allocation_total += location_allocation;
                }
            }

            current_allocation_total += parseFloat($scope.part_allocation_total);

            $scope.part_allocation_total = current_allocation_total

            if (current_allocation_total === part_required_total) {
                $scope.valid = true;
            }
        },400);
    }

    /*=========================================================================================================
     Validate the input if its a number & remove all non-numerical characters
     =========================================================================================================*/
    $scope.validateOptions = function validateOptions() {
        var allocation = this.location.allocation,
            allocation = allocation.replace(/[^0-9.]/g,'');

        this.location.allocation = allocation;

        if(!isNaN(parseFloat(allocation))) {
            $scope.isQuantityInBounds(allocation);
        }
    }

    $scope.validateFulfill = function validateFulfill(allocatedQuantity,requestedQuantity) {
        $scope.sidepanelData.Quantity = allocatedQuantity.replace(/[^0-9.]/g,'');
        if(allocatedQuantity <= 0 || allocatedQuantity > requestedQuantity){
            $scope.sidepanelData.Quantity = ($scope.sidepanelData.Quantity <= 0) ? '' : $scope.sidepanelData.Quantity;
            $scope.fulfillError = true;
        }else {
            $scope.fulfillError = false;
        }
        /*if($scope.sidepanelData.order_from_supplier.amount != 0 && $scope.sidepanelData.order_from_supplier.amount > allocatedQuantity){
            $scope.valid = false;
        }*/
        if($scope.part_allocation_total == $scope.sidepanelData.Quantity){
            $scope.valid = true;
        }else{
            $scope.valid = false;
        }
    }


    /*=========================================================================================================
     Validate order from supplier input
     =========================================================================================================*/
    $scope.validateOrderQuantity = function validateOrderQuantity(amount) {
        var input_is_number = !isNaN(parseInt(amount)),
            amount = amount.replace(/[^0-9.]/g,'');

        $scope.sidepanelData.order_from_supplier.amount = amount;

        if(input_is_number) {
            $scope.isQuantityInBounds(amount);
        }
    }

    /*=========================================================================================================
     When clicking order all from supplier, set all the other inputs to 0 and order from supplier input
     to the quantity required
     =========================================================================================================*/
    $scope.orderAllFromSupplier = function orderAllFromSupplier() {
        var stock_locations = $scope.stockLocations;

        for(var i = 0, l = stock_locations.length; i < l; i++) {
            stock_locations[i].allocation = 0;
            stock_locations[i].valid = true;
        }

        $scope.sidepanelData.valid = true;
        $scope.sidepanelData.order_from_supplier.amount = $scope.sidepanelData.Quantity;

        $scope.handlePartValidation(0);
        $scope.updateSavingButtonText();
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.isQuantityInBounds = function isQuantityInBounds(num) {
        var num = parseFloat(num);
        $scope.handlePartValidation(num);
        $scope.updateSavingButtonText();
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.handlePartValidation = function handlePartValidation(num) {
        var stock_locations = $scope.stockLocations;
        $timeout(function(){
            var allocation = 0,
                quantity = 0,
                part_allocation_total = 0,
                current_part_quantity_needed = 0,
                order_from_supplier_amount = parseFloat($scope.sidepanelData.order_from_supplier.amount);

            $scope.disable_saving = true;

            for(var x = 0, c = stock_locations.length; x < c; x++) {

                allocation = parseFloat(stock_locations[x].allocation);
                quantity = parseFloat(stock_locations[x].quantity);
                part_allocation_total += (allocation)?allocation:0;

                if (allocation <= quantity) {
                    $scope.stockLocations[x].valid = true;
                }else{
                    $scope.stockLocations[x].valid = false;
                }

                if (stock_locations[x].location === 'General') {
                    $scope.stockLocations[x].valid = true;
                }
            }

            // check if the sum of these allocations exceeds the total required for the part
            var part_required_total = parseFloat($scope.sidepanelData['Quantity']);
            part_allocation_total += order_from_supplier_amount;

            $scope.part_allocation_total = part_allocation_total;

            if (part_allocation_total <= part_required_total) {
                $scope.valid = true;
            } else {
                $scope.valid = false;
            }
            if (part_allocation_total > part_required_total) {
                $scope.showError = true;
            } else {
                $scope.showError = false;
            }

            $scope.checkIfAnyPartsAreInvalid();

        },15);
    }

    /*=========================================================================================================
     Check if all inputs are valid
     =========================================================================================================*/
    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.valid = true;

        var stock_locations = $scope.stockLocations,
            current_part_allocation_total = 0,
            order_from_supplier_amount = parseFloat($scope.sidepanelData.order_from_supplier.amount);

        for(var i = 0, l = stock_locations.length; i < l; i++) {
            var location_valid = stock_locations[i].valid === true;

            if (location_valid) {
                var allocation = parseFloat(stock_locations[i].allocation);
                current_part_allocation_total += allocation;
            }else{
                $scope.valid = false;
                return
            }
        }
        // check if the part allocation total matches the required total
        var part_required_total = parseFloat($scope.sidepanelData['Quantity']);
        current_part_allocation_total += order_from_supplier_amount;

        if (current_part_allocation_total !== part_required_total) {
            $scope.valid = false;
            return
        }
    }

    /*=========================================================================================================
     When blurring away from an input, check if the value is blank and if it is, set it to 0
     =========================================================================================================*/
    $scope.handleBlur = function handleBlur(event, location_id) {
        if (event.target.value.length === 0) {
            $scope.stockLocations[location_id].allocation = 0;

            $scope.isQuantityInBounds(0);
        }
    }

    /*=========================================================================================================
     If the user blurs away from the order from supplier
     input and the input is blank
     =========================================================================================================*/
    $scope.handleSupplierOrderBlur = function handleSupplierOrderBlur(event, part_id) {
        if (event.target.value.length === 0) {
            $scope.sidepanelData.order_from_supplier.amount = 0
            $scope.handlePartValidation(part_id);
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }

    $scope.goToPurchaseOrder = function goToPurchaseOrder(loc, partData) {
        $state.params.prepopulatePurchaseOrder = true;
         if(partData.order_from_supplier.amount > 0){
            jobData.setSelectedPart([{'orderToSupplier':partData.order_from_supplier.amount, 'totalQuantity':partData.Quantity, 'availableParts': loc, 'jobPartId': partData.id, 'partId': partData.partId, 'salesPrice': partData['Unit sales price'], 'costPrice': partData['Unit cost price']}]);
         }
        if($scope.show_purchase_order_button){
            //$scope.populateItemsForPurchaseOrder();
            $scope.closePanel();
            if($state.params.milestoneId){
                $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order_milestone", { id: $state.params.id, jobId: $state.params.jobId, type: $state.params.type, milestoneId : $state.params.milestoneId  });
            }else{
                $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order", { id: $state.params.id, jobId: $state.params.jobId, type: $state.params.type });
            }
        }else{
            $http.post(prefix + '/save_part_fulfill', "selectedParts=" + encodeURIComponent(angular.toJson([{'orderToSupplier':partData.order_from_supplier.amount, 'availableParts': loc, 'jobPartId': partData.id, 'partId': partData.partId, 'salesPrice': partData['Unit sales price'], 'costPrice': partData['Unit cost price']}])) + "&clientJobType=" + $rootScope.clientJobType + "&jobToDos=" + $rootScope.clientJobTodos + "&jobId=" + $state.params.jobId + "&milestoneId=" + $state.params.milestoneId).
                success(function (data, status) {
                    if (status == 200) {
                        $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': $scope.category});
                        $rootScope.$broadcast('spreadsheet:todoupdate');
                        $scope.closePanel();
                    }
            }).error(function(data,statusCode){
                if(statusCode == 400){
                    var message = title = id = '';

                    if(data.errorMsg == "stock_transaction_exceeds_actual_quantity" || data.errorMsg == "stock_transaction_exceeds_reserved_quantity"  || data.errorMsg == "allocation_exceeds_actual_quantity" ) {
                        id = data.errorMsg;
                        title = 'Error';
                        message = data.errorMsg;
                    }
                    warningModal.show(message, title, id);
                }
            });
        }


    }

    $scope.updateSavingButtonText = function updateSavingButtonText() {
        var chosen_parts = $scope.chosenParts;

        $scope.saving_text = 'Save';
        $scope.show_purchase_order_button = false;

        // update saving button text
        var order_from_supplier_amount = parseFloat($scope.sidepanelData.order_from_supplier.amount);
        if (order_from_supplier_amount > 0) {
            if($rootScope.clientJobType == 1){
                $scope.saving_text = 'Save';
                $scope.show_purchase_order_button = false;
            }else{
                $scope.saving_text = 'Save and create purchase order';
                $scope.show_purchase_order_button = true;
            }
        }
    }

    /*=========================================================================================================
     Handle generating a purchase order if the user has chosen to order parts from a supplier
     =========================================================================================================*/
    $scope.closePanel = function closePanel(po) {
        var chosen_locations = $scope.getChosenLocations();

        $rootScope.$broadcast('fulfillparts:partsfulfilled', chosen_locations);
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.getChosenLocations = function getChosenLocations() {
        var stock_locations = $scope.stockLocations,
            chosen_locations = [];

        for(var i = 0, l = stock_locations.length; i < l; i++) {
            if (stock_locations[i].allocation > 0) {
                chosen_locations.push(stock_locations[i]);
            }
        }
        return chosen_locations;
    }
}

function JobCostingsCtrl($scope, $state, getIdData) {

    $scope.selectedTab = "Costs";
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.purchaseOrders = getIdData.purchaseOrders;
    $scope.purchaseOrderCount = getIdData.purchaseOrderCount;
    $scope.jobSubNavStatic = false;

    $scope.job = getIdData.jobDetails;
    $scope.job['active_milestone_id'] = parseInt($state.params.milestoneId);
    $scope.milestoneId = getIdData.jobDetails.active_milestone_id;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);
}

function JobCostingsViewCtrl($scope, $rootScope, $state, getIdData, $http, prefix, tableCollection, $sce, defaultPagingValue, jobData, warningModal, creditNoteData, $translate, confirmationBoxHelper,dragNDropService,datasets, formService, $modal) {

    $scope.selectedTab = "Costs";
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.supplierObjects = [];
    // $scope.supplierObjects = getIdData.supplierObject;
    $scope.jobSubNavStatic = false;
    $scope.showPurchaseOrder = true;
    $scope.job = getIdData.jobDetails;
    $scope.milestoneId = getIdData.jobDetails.active_milestone_id;
    //$scope.travels = getIdData.travels;
    $scope.travelDescription = getIdData.travelDescription;
    $scope.laboursCount = getIdData.laboursCount;
    $scope.partsCount = getIdData.partsCount;
    $rootScope.$broadcast('parts:countupdated', getIdData.partsCount);
    $scope.jobCostSummary = getIdData.jobCostSummary;
    var milestoneId = ($state.params.milestoneId) ? $state.params.milestoneId : 0;
    tableCollection.setData('job_cost_supplier_returns',  getIdData.returns.job_cost_supplier_returns);
    $scope.returnsTotal = getIdData.returns.job_cost_supplier_returns.returnsTotal;
    $scope.returnsCount = getIdData.returns.job_cost_supplier_returns.count;
    $scope.labourPermission = false;
    var featureName = 'Travelcosts', featureAccess = 'readaccess', featureId = features[featureName];
    $scope.labourPermission = $rootScope.hasPermission(featureId, featureAccess);
     $scope.supplierInvoicePage = 1;
     $scope.supplierInvoiceLimit = defaultPagingValue;

     $scope.labours = $scope.travels = $scope.purchaseOrders = $scope.supplierInvoices = $scope.estimated_costs = [];
     $scope.laboursLoading= $scope.travelsLoading = $scope.purchaseOrdersLoading =
     $scope.supplierInvoicesLoading = $scope.creditNotesLoading = $scope.stockAndGeneralItemsLoading = true;

    $scope.fetchSupplierInvoices = function (pageNum) {
        if(pageNum){
            $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/' + 'jobs/'+$scope.jobId + '/costs/job_list_supplier_invoice?page=' + pageNum + '&limit=' + $scope.supplierInvoiceLimit + '&milestoneId=' + milestoneId).success(function (data) {
                $scope.supplierInvoicesLoading=false;
                $scope.supplierInvoices = data.supplierInvoices;
                $scope.supplierInvoiceCount = data.supplierInvoiceCount;
                $scope.totalInvoiceAmount = data.totalInvoiceAmount;
            });
        }
    }

    $scope.creditNotePage = 1;
    $scope.creditNoteLimit = defaultPagingValue;
    $scope.fetchCreditNotes = function (pageNum){
        if(pageNum){
            $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/' + 'jobs/'+$scope.jobId + '/costs/list_creditnotes?page=' + pageNum + '&limit=' + $scope.supplierInvoiceLimit + '&milestoneId=' + milestoneId).success(function (data) {
                $scope.creditNotesLoading=false;
                $scope.creditNotes = data.creditNotes;
                $scope.creditNoteCount = data.creditNoteCount;
                $scope.totalCreditAmount = data.totalCreditAmount;
            });
        }
    }

    $scope.stockAndGeneralItemPage = 1;
    $scope.stockAndGeneralItemLimit = defaultPagingValue;

    $scope.fetchStockAndGeneralItems = function (pageNum) {
        if(pageNum){
            $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/' + 'jobs/'+$scope.jobId + '/costs/job_cost_list_stock_general_items?page=' + pageNum + '&limit=' + $scope.supplierInvoiceLimit + '&milestoneId=' + milestoneId).success(function (data) {
                $scope.stockAndGeneralItemsLoading=false;
                $scope.stockAndGeneralItems = data.stockAndGeneralItems;
                $scope.stockAndGeneralItemCount = data.stockAndGeneralItemCount;
                $scope.stockAndGeneralItemTotalAmount = data.stockAndGeneralItemTotalAmount;
            });
        }
    }

    $scope.purchaseOrderPage = 1;
    $scope.purchaseOrderLimit = defaultPagingValue;

    $scope.fetchPurchaseOrders = function (pageNum) {
        if(pageNum){
            $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/' + 'jobs/'+$scope.jobId + '/costs/job_cost_list_purchase_order?page=' + pageNum + '&limit=' + $scope.supplierInvoiceLimit + '&milestoneId=' + milestoneId).success(function (data) {
                var purchaseOrders = data.purchaseOrders;
                angular.forEach(purchaseOrders, function (key, value) {
                    if(purchaseOrders[value].ponumber) {
                      purchaseOrders[value].poid = purchaseOrders[value].ponumber;
                    } else {
                      purchaseOrders[value].poid = purchaseOrders[value].id;
                    }
                });
                $scope.purchaseOrdersLoading=false;
                $scope.purchaseOrders = purchaseOrders;
                $scope.purchaseOrderCount = data.purchaseOrderCount;
                $scope.purchaseOrderTotalAmount = data.purchaseOrderTotalAmount;
            });
        }
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    tableCollection.setData('job_estimate_costs', getIdData.job_estimate_costs);

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);
    $rootScope.$on('update:jobs:costs:spreadsheetGrandTotals', function() {
        $scope.total_labour_time = 10;
        $scope.total = 10;
        $scope.total_unbilled = 10;

        var grand_totals_str = localStorage.getItem('jobs:costs:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = grand_totals.length > 1;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.total_labour_time += grand_totals[i][key]["Total Labour Time"];
            $scope.total += grand_totals[i][key]["Total"];
            $scope.total_unbilled += grand_totals[i][key]["Total Unbilled"];
        }

        $scope.total_labour_time = parseFloat($scope.total_labour_time.toFixed(2));
        $scope.total = parseFloat($scope.total.toFixed(2));
        $scope.total_unbilled = parseFloat($scope.total_unbilled.toFixed(2));
    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = 5;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.fetchtravels = function (pageNum) {
        if(pageNum){
            $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/' + 'jobs/'+$scope.jobId+ '/' + $scope.milestoneId +'/travels/view?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
                $scope.travelsLoading=false;
                $scope.travels = data.travels;
                $scope.travelsCount = data.travelCount;

            });
        }
    }

    $scope.fetchReturns = function (page){
        if(page){
            $http.get(prefix + '/customers/'+ $scope.customerTabName + '/' + $scope.selectedId +'/jobs/' + $scope.jobId +'/list_supplier_returns?page=' + page+ '&limit=' + $scope.limit).success(function (data,status){
                if (status == 200) {
                    $scope.returnsTotal = data.job_cost_supplier_returns.returnsTotal;
                    $rootScope.$broadcast('smart_tablefilter:newsearch', {'category' : 'job_cost_supplier_returns'});
                    $scope.returnSupplier = '';
                }
            })
        }
    }

    if ($state.current.data.labourPageNumber) {
        $scope.labourPageNumber = parseInt($state.current.data.labourPageNumber);
    } else {
        $scope.labourPageNumber = 1;
    }

    $scope.fetchLabours = function (pageNum) {
        if(pageNum){
            $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/' + 'jobs/'+$scope.jobId+ '/' + $scope.milestoneId +'/listLabours?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
                // $scope.job.milestones[0].costs.labour = data.labours;
                $scope.laboursLoading=false;
                $scope.labours = data.labours;
                $scope.laboursCount = data.laboursCount;

            });
        }
    }

    /* Estimated Cost - Check box click to show the purchase order button */
    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $rootScope.$on('smart_table:pinned_rows_part_category', function(event, category) {
            $scope.category = category;
        });
        if($scope.category == 'job_estimate_costs'){
            $scope.selectedSuppliers = rows_store;
            if(rows_store.length == 0) {
                $scope.suppliers = [];
            } else {
                $scope.suppliers = [];
                var supplierIdToCheck = 0;
                angular.forEach($scope.selectedSuppliers, function (val, key) {
                    supplierIdToCheck = val.part.supplierId;
                    $scope.suppliers.push({'supplierId':val.part.supplierId, 'JobCostId': val.part.jobCostId});
                    jobData.setSelectedPart($scope.suppliers);
                });
                if(supplierIdToCheck) {
                    var supplierRecords = _.where($scope.suppliers, {supplierId: supplierIdToCheck});
                    var setSupplierValue = $scope.suppliers.length == supplierRecords.length;
                    if(setSupplierValue){
                        //$scope.supplierId = supplierIdToCheck; Need to test this clearly, removing temporarily - santha
                    }
                }
            }
        }else if($scope.category == 'job_cost_supplier_returns'){
            $scope.selectedReturns = rows_store;
            if(rows_store.length == 0) {
                $scope.returns = [];
            } else {
                $scope.returns = [];
                var supplierIdToCheck = 0;
                angular.forEach($scope.selectedReturns, function (val, key) {
                    supplierIdToCheck = val.part.supplierId;
                    $scope.returns.push({'supplierId':val.part.supplierId});
                });
                if(supplierIdToCheck) {
                    var supplierRecords = _.where($scope.returns, {supplierId: supplierIdToCheck});
                    var setSupplierValue = $scope.returns.length == supplierRecords.length;
                    if(setSupplierValue){
                        //$scope.supplierId = supplierIdToCheck; Need to test this clearly, removing temporarily - santha
                    }
                }
            }
        }
    });

    /* Estimated cost - To cancel the estimated job cost with side panel */
    $scope.$on('smart_tablefilter:costcancel', function(event, data) {
        $http.post(prefix + '/estimated_cost_cancel_reason', "jobCost[reason]=" + angular.fromJson(data).reason + "&jobCost[jobCostId]=" + angular.fromJson(data).jobCostId + "&jobCost[milestoneId]=" + $scope.milestoneId).
        success(function (data, status) {
            if (status == 200) {
                $scope.jobCostSummary = data.job_estimate_costs.jobCostSummary;
                $rootScope.$broadcast('smart_tablefilter:newsearch',{'category': 'job_estimate_costs'});
            }
        });
    });

    /* Estimated cost - Filter with supplier */
    $scope.handleCostWithSupplier = function(supplierId) {
      if (supplierId) {
        $scope.supplierId = supplierId;
        $scope.$broadcast('smart_tablefilter:newsearch', ({
          filter: angular.toJson({'supplierId': $scope.supplierId.id}),
          category: 'job_estimate_costs'
        }));
      }
    }

    $scope.handleRaiseCreditNote = function (supplier) {
      if(supplier) {
        $scope.returnSupplier = supplier;
        $scope.$broadcast('smart_tablefilter:newsearch', ({filter : angular.toJson({'supplierId': $scope.returnSupplier.id}), category : 'job_cost_supplier_returns'}));
      }
    };

    $rootScope.$on('smart_table:active_row_id', function (event, message) {
        $rootScope.$on('smart_table:pinned_rows_part_category', function(event, category) {
            $scope.category = category;
        });
        if($scope.category == 'job_cost_supplier_returns'){
            $scope.returnId = message;
        }
    });

    $scope.setSupplierReturns = function(returnId){

        $scope.supplierReturns = [];
        var poStatus = [];
        var supplierReturns = getIdData.returns.job_cost_supplier_returns.allReturnsRecords;

        angular.forEach(supplierReturns, function(value, key){
            if(inArray(value.referenceID, returnId)){
                $scope.supplierReturns.push(value);
                if(value.poStatus != 'Completed'){
                    poStatus.push(value.referenceID);
                }
            }
        });
        if(poStatus.length > 0){
            warningModal.clean('return_error');
            warningModal.show($translate('Please raise supplier invoice before raising credit note for reference ' + poStatus.join()), 'Error', 'return_error');
        }else{
            creditNoteData.setSelectedReturns($scope.supplierReturns);
            $state.transitionTo("loggedin.list_supplier.view.new_credit_note_returns",({ id: $scope.returnSupplier.id, returnId: returnId }));
        }
    }

    $scope.setCreditNoteNotRequired = function(returnId){
        $scope.returnId = returnId;
        $scope.shouldBeOpen = true;
    }

    $scope.$on('ReturnsDetails', function (event, message) {
        $scope.returnsTotal = message.returnsTotal;
        $scope.returnSupplier = message.returnSupplier;
    });

    $scope.showUploadInBackground = false;
    $scope.uploadPercentage = '0%';
    $scope.filesToBeUploaded = [];
    $scope.uploadInProgress = false;
    $scope.uploadProgressStyle = { 'width': '0%' };
    $scope.progressBar = 0;
    $scope.uploadInterval = false;
    $scope.supplierId = "";

    dragNDropService.customErrors('docparser', {'pattern': $translate('Please.upload.a.PDF.file')});
    $scope.uploadFileTypeError = $translate('Please.upload.a.PDF.file');

    $scope.continueDocParser = function() {
        $scope.showUploadInBackground = false;

        $rootScope.$broadcast('dragndrop:closeuploadprompt');
    };

    $scope.uploadAction = '/postDocParserDocuments';

    $scope.uploadCallback = function() {
        $scope.showUploadInBackground = true;
    }

    $scope.supplierId = '';
    $scope.supplierError = false;

    $scope.jobUploadObject = {};

    $scope.jobSupplierChanged = function(supplierId) {
        if (supplierId !== undefined && supplierId !== null) {
            if ($scope.jobUploadObject['customUploadObject']) {
                if ($scope.jobUploadObject['customUploadObject']['supplier']) {
                    $scope.jobUploadObject['customUploadObject']['supplier']['supplierid'] = supplierId;
                }
            }
        }
    };

    $scope.checkIfUploadPossible = function(files, singleFile, newFiles, duplicateFiles, invalidFiles, evt) {
        if (Array.isArray(newFiles)) {
            if ($state.current.name == "loggedin.customer_list.view.job.viewcost") {
                $scope.customUploadFileParams = {'supplier': {supplierid: $scope.supplierId,jobid: $scope.jobId}, 'file': []};
                $scope.customUploadFileParams['file'] = files;

                $scope.showSupplier = false;

                if( $scope.customUploadFileParams.supplier && !$scope.customUploadFileParams.supplier.supplierid ) {
                    $scope.showSupplier = true;
                } else {
                    $scope.showSupplier = false;
                }

                $scope.jobUploadObject = {
                    'files': files,
                    'customUploadObject': $scope.customUploadFileParams,
                    'singleFile': singleFile,
                    'newFiles': newFiles,
                    'duplicateFiles': duplicateFiles,
                    'invalidFiles': invalidFiles,
                    'originalEvent': evt
                };

                $rootScope.$broadcast('ngFileUploadFake', $scope.jobUploadObject);
            }
            else {
                if (newFiles) {
                    $scope.customUploadFileParams = {'supplier': {supplierid: $scope.supplierId,jobid: $scope.jobId}, 'file': []};
                    $scope.customUploadFileParams['file'] = files;

                    $scope.jobUploadObject = {
                        'files': files,
                        'customUploadObject': $scope.customUploadFileParams,
                        'singleFile': singleFile,
                        'newFiles': newFiles,
                        'duplicateFiles': duplicateFiles,
                        'invalidFiles': invalidFiles,
                        'originalEvent': evt
                    };

                    $rootScope.$broadcast('ngFileUpload', $scope.jobUploadObject);

                    return true;
                }
            }
        }
    };

    $scope.isDocparserEnabled = function(permission) {
        var featureId = features['Setupdocparserintegration'];
        return $rootScope.hasPermission(featureId, permission);
    }


    $scope.jobHandleUploadFiles = function(supplierId) {
        if( supplierId != "" || supplierId != 0) {
            $scope.supplierError = false;
            $scope.showSupplier = false;

            $rootScope.$broadcast('ngFileUpload', $scope.jobUploadObject);

            if(angular.isDefined($scope.supplierId)){
                delete $scope.supplierId;

            }
            $scope.supplierId = "";
            return true;

        } else {
            $scope.supplierError = true;
        }
    }

    $scope.supplierId = '';
    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_suppliers',
            data: function (term, page) {
                return { 'q': term };
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }

}

function JobAdditionalWorksCtrl($scope, $rootScope, $state, getIdData, $http, prefix, $filter, warningModal, $translate)
{
    $scope.selectedTab = "AdditionalWorks";
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.job = getIdData.jobDetails;
    $scope.job_is_completed = (getIdData.jobStatus == 'completed') ? true : false;
    $scope.jobCompletedReasons = getIdData.jobCompletedReasons;
    $scope.moduleAccess = getIdData.moduleAccess;

    /*==========================================================================================================================
   Filtering additional works
   ==========================================================================================================================*/
    $scope.worksFilter = {};
    $scope.worksFilter.show_drafts = true;
    $scope.worksFilter.show_rejected = true;
    $scope.worksFilter.show_waiting_approval = true;
    $scope.worksFilter.show_accepted = true;
    $scope['Draft'] = true;
    $scope['Rejected'] = true;
    $scope['Waiting Approval'] = true;
    $scope['Accepted'] = true;

    $scope.filterByStatus = function(filter_str) {
        $scope[filter_str] = !$scope[filter_str];
        $scope.countFilteredWorks();
    };

    $scope.matchesCriteria = function(criteria) {
        return $scope[criteria];
    };

    $scope.countFilteredWorks = function() {
        $scope.works_count = 0;
        $scope.results_set = $filter('filter')($scope.job.additional_works, function(work) {
            if ($scope[work.status]) {
                $scope.works_count ++;
            }
        });
    }
    $scope.countFilteredWorks();

    $scope.newAdditionalWork = function() {
        $scope.button_clicked = true;
        var params = { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId':$scope.jobId};

        $http.post(Routing.generate('newAdditionalWork'), "id="+ $scope.selectedId + "&type=" + $scope.customerTabName +"&jobId="+ $scope.jobId).
        success(function (data, status) {
            if (status == 200) {
                $scope.viewAdditionalWork($scope.selectedId, $scope.customerTabName,  $scope.jobId, data.additionalWorkId);
            }
        });
    }
    $scope.triggerAdditionalWorkDelete = function triggerAdditionalWorkDelete(additionalWorkId) {
        $scope.additionalWorkId = additionalWorkId;
        $scope.additionalWorkDeleteShouldBeOpen = true;
    }

    $scope.fetchDeleteAdditionalWork = function () {
        $scope.flashMessage = 'Additional.work.deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $scope.jobAdditionalWork($scope.selectedId, $scope.jobId, $scope.customerTabName);
    }

    $scope.viewAdditionalWorkStatus = function viewAdditionalWorkStatus(status) {
        var message = $translate('Cannot.delete.additional.work.because.historical');
        warningModal.show(message, 'Delete.additional.work', 'warning-delete-additional-work');
    }

    $scope.handleJobCompleted = function() {
        var message = $translate('You.cannot.add.new.additional.work'),
            completeReason = 'completed',
            warningId = 'completed';
        if($scope.jobCompletedReasons) {
            warningId = $scope.jobCompletedReasons.toLowerCase();
            if($scope.jobCompletedReasons.toLowerCase() == 'freeofcharge') {
                completeReason = $translate("free.of.charged");
            } else {
                completeReason = $scope.jobCompletedReasons.toLowerCase();
            }
            message = message + completeReason;
        } else {
            message = message + completeReason;
        }

        warningModal.show(message, "Additional.Works", "additional_work_"+warningId);
    }
}

function JobAdditionalWorkSubNavCtrl($scope, $rootScope, $state, getIdData, $http, prefix)
{
    $scope.selectedTab = "AdditionalWorks";
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.moduleAccess = getIdData.moduleAccess;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);

    CommonEstimateSubNavigation.call(this, $scope, $rootScope, getIdData);

    $scope.information = getIdData.information;
    $scope.communication_information = getIdData.communicationInformation;
    $scope.jobId = getIdData.jobId;

    $scope.show_communication_information = false;
    if(getIdData.leftNavCount) {
        if((getIdData.leftNavCount.invoiceScheduleTotal != 0) &&(getIdData.leftNavCount.invoiceScheduleTotal  == getIdData.leftNavCount.priceTotal)) {
            $scope.show_communication_information = true;
        }
    }

    $scope.$on("informationbar:accepted_estimate", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.details", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
    });

    $rootScope.$on('communicaion_info:update', function(event, data) {
        $scope.show_communication_information = data;
    });
    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 110) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.communication_information = data;
            });
        }
    });
}

function JobAdditionalWorkNewCtrl($scope, $rootScope, $state, getIdData, $http, prefix, warningModal, $translate, fullScreenEditorCollection)
{
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message= getIdData.deleteOrNull;
        warningModal.show('This additional work has been deleted', 'Additional work', 'Deleted');
        $state.transitionTo('loggedin.customer_list.view.property', {'id': $state.params.id,'type': $state.params.type },{reload: true});
    }
  PermissionCheck.call(this, $scope);
  $scope.selectedTab = "AdditionalWorks";
    if($scope.returnState == 'Project no.') {
        $scope.additional_work_type = 'project_job';
    } else {
        $scope.additional_work_type = 'job';
    }
    $scope.jobId = $state.params.jobId;
    $scope.selectedId = $state.params.id;
    $scope.customerTabName = $state.params.type;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.$emit('tabCustomer:selected', getIdData);
    fullScreenEditorCollection.setPanelData(getIdData.notesCollection);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.estimateDescription = getIdData.estimateDetails.estimateDescription;
    $scope.status = getIdData.status;
    $scope.estimateBody = getIdData.estimateDetails.estimateBody;
    $scope.active_option = getIdData.estimateDetails.activeOption;
    if ($scope.status == 'completed') {
        $scope.active_option = 2;
    }

    $scope.historicalEstimate = getIdData.estimateDetails.estimateStatus &&
        (getIdData.estimateDetails.estimateStatus == 'accepted' || getIdData.estimateDetails.estimateStatus == 'rejected');

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);

    $scope.tinymceOptions = {
        plugins: "paste,link,textcolor",
        menubar: false,
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        auto_focus: "estimate_body",
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        resize: false
    };

    $scope.init = function (formReturnName) {
        $scope.estimateOptions = {
            emailMessage: formReturnName + '[estimateBody]'
        }
    }

    $scope.actualEditor = function(){
        var tinyInstance = tinymce.get('estimate_body').getContent();
        $scope.$broadcast('estimateContent', tinyInstance)
    }

    $scope.$on("estimateFullScreenView", function (event, message) {
        var tinyInstance = tinymce.get('estimate_body');
        if(tinyInstance){
            tinyInstance.setContent(message);
        }
    });

    $scope.submitEstimateBody = function(active_option, description, estimateBody){
        $scope.saving = true;
        var postUrl = $state.current.url.replace('^', '');
        var autoSaveValues = "type=" + $scope.customerTabName + "&id=" + $scope.selectedId + "&jobId=" + $scope.jobId +"&additionalWorkId=" + $scope.additionalWorkId
            + "&activeOption="+ active_option + "&estimateDescription=" + encodeURIComponent(description) + "&estimateBody" + "=" + encodeURIComponent(estimateBody);

        $http.post(prefix + postUrl, autoSaveValues + "&" + "autoSave" + "=" + 'autosave').
        success(function(data, status) {
            if (status == 200) {
                $scope.saving = false;
                $scope.flashMessage = 'Additional.work.details.saved';
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
            }
        });
    }
    $scope.donotAllowJob = function() {
        warningModal.show($translate('You.cannot.link.this.additional.work.job.Because.aleady.completed'), $translate('Link.job'));
    }

    $scope.doNotAllowFullViewEditor = function() {
        warningModal.show($translate('Additional.work.description.cannot.be.empty'), $translate('Additional.work.description'));
    }
}

// Additional work todos tab
function JobAdditionalWorkTodosCtrl($scope, $state, prefix, $http, getIdData, $rootScope, spreadSheetData)
{
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "AdditionalWorks";

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);
    $rootScope.$emit('estimateDetail:value', getIdData.leftNavCount);

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/todoslists';
    $scope.addUrl = prefix + '/customers/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/todos/new';
    $scope.updateUrl = prefix + '/customers/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/todos/update';
    $scope.deleteUrl = prefix + '/customers/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/todos/delete';
    $scope.categoryId = '';

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabCustomer:successMessage', data);

    });

    if (getIdData.moduleAccess == false) {
        $scope.noPermission = true;
    }

}

// Additional work budget cost tab
function JobAdditionalWorkBudgetcostCtrl($scope, $state, prefix, $http, getIdData, $rootScope, spreadSheetData)
{
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "AdditionalWorks";

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);

    $rootScope.$emit('estimateDetail:value', getIdData.leftNavCount);
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+$scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/costlist';
    $scope.addUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/cost/new';
    $scope.updateUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/cost/update';
    $scope.deleteUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/cost/delete';
    $scope.categoryId = '';

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabCustomer:successMessage', data);
    });

    $scope.grand_totals = [];
    $rootScope.$on('update:costs:spreadsheetGrandTotals', function() {
        $scope.total_cost = 0;
        $scope.total_price = 0;

        var grand_totals_str = localStorage.getItem('costs:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = grand_totals.length > 1;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.total_cost += grand_totals[i][key]["Total Cost"];
            $scope.total_price += grand_totals[i][key]["Total Price"];
        }

        $scope.total_cost = parseFloat($scope.total_cost.toFixed(2));
        $scope.total_price = parseFloat($scope.total_price.toFixed(2));

        $rootScope.$emit('leftnav:update_cost', $scope.total_cost);
    });

}

// Additional work price tab
function JobAdditionalWorkPriceCtrl($scope, $state, prefix, $http, getIdData, $rootScope, toastBox,confirmationBoxHelper)
{
  PermissionCheck.call(this, $scope);
  $scope.selectedId = $state.params.id || getIdData.id;
  $scope.jobId = $state.params.jobId || getIdData.jobId;
  $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
  $scope.customerTabName = $state.params.type || getIdData.customerTabName;
  $scope.total_price = getIdData.leftNavCount.priceTotal;
  $scope.estimateId = getIdData.estimateId;
  $scope.selectedTab = "AdditionalWorks";

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

  $scope.estimateStatus = getIdData.estimateStatus;
  $scope.priceView = $scope.priceViewOption = getIdData.priceViewOption;
  $scope.invoiceItemCategory = getIdData.categoryRec;
  $scope.invoiceItem = getIdData.invoiceItemCategory;
  $scope.invoiceItemCategoryLists = getIdData.invoiceItemCategoryLists;
  $scope.estimateOptionId = ''; // There is no option for additional work
  $scope.moduleAccess = getIdData.moduleAccess;
  $scope.InvoiceItemCategoryButton = false;

  if ($scope.moduleAccess == false) {
      $scope.advanced_estimate = false;
  } else {
      $scope.advanced_estimate = true;
  }

  $scope.customisable_spreadsheet_columns = getIdData.customisableSpreadsheetColumns;
  $scope.show_vat_in_grand_totals = getIdData.showVatGrantTotal.selected;
  $scope.isHideVat = getIdData.isHideVat;
  $scope.taxItems = getIdData.taxItems;
  $scope.deletedTaxItems = getIdData.deletedTaxItems;
  $scope.taxLabel = $rootScope.taxLabel;
  $scope.taxBreakdownType = $rootScope.taxBreakdownType;
  $scope.overAllVatRate = getIdData.overAllVatRate;
  $scope.lineItemCount  = getIdData.lineItemCount;

  $scope.categoryId = '';


  internalizationTaxSettings.call(this,$scope);
  getEstimatePriceSpreadsheet.call(this,$scope,toastBox,prefix,$http,confirmationBoxHelper);
  CommonPriceViewsFunction.call(this, $rootScope, $scope, prefix, $http);
}


// Additional work invoice schedule tab
function JobAdditionalWorkInvoiceScheduleCtrl($scope, $state, prefix, $http, getIdData, $rootScope, spreadSheetData)
{
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "AdditionalWorks";

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);

    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    CommonInvoiceScheudleViewFunction.call(this, $rootScope, $scope, prefix, $http);

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId +  '/additional_works/'+ $scope.additionalWorkId + '/invoiceSchedulelists';
    $scope.updateUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/' + $scope.jobId + '/additional_works/'+ $scope.additionalWorkId + '/invoiceSchedule/update';

}

// Additional work send to customer tab
function JobAdditionalWorkSendToCustomerCtrl($scope, $state, prefix, $http, $timeout, getIdData, $rootScope, formService, emailService, $compile)
{
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedTab = "AdditionalWorks";
    $scope.printAndPostModuleAccess = getIdData.printAndPostModuleAccess;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.jobDetails = { 'jobId': $scope.jobId, 'additionalWorkId':$scope.additionalWorkId }
    $scope.$emit('jobId:selected', $scope.jobDetails);
    var isEstimateAttachToEmail = getIdData.isEstimateAttachToEmail;

    $scope.sendToCustomerEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        var propertyId = getIdData.customerType == 'workaddress' ? getIdData.parentPropertyId : $scope.selectedId;
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + propertyId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=51&jobId=' + $scope.jobId;
        clone_obj.subject = $scope.estimateDefaultSubject;
        clone_obj.message = $scope.estimateDefaultMessage;
        clone_obj.relatedObjectType = 110;
        clone_obj.relatedObjectId = $scope.estimateId;
        if(isEstimateAttachToEmail)
        {
            clone_obj.attached_files = [
                {
                    id: "AW|" + $scope.selectedId + "|" + $scope.additionalWorkId + "|" + $scope.jobId + "|" + 110,
                    file_name: 'Additional Work No. ' + $scope.additionalWorkId,
                    file_size: 0,
                    type: 'generated'
                }
            ];
        }
        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    CommonSendToCustomerViewFunction.call(this, $rootScope, $scope, prefix, $http, getIdData, $state, $timeout, formService, emailService);
    $scope.showPDF = function showEstimatePDF(){
        var side_panel_tpl = $compile('<span id="view_pdf" ' +
            'sidepanel template="print_and_post" ' +
            'print_and_post_message="Your estimate is on its way to the customer"'+
            'supports_print_and_post="'+$scope.printAndPostModuleAccess+'"'+
            'title="Print and Post Additional Work"'+
            'pdf_url="'+prefix+'/customers/'+$state.params.id+'/jobs/'+$state.params.jobId+'/additional_works/'+$scope.additionalWorkId+'/loadSendToCustomer"'+
            'printpost_params="tableType=110&jobId='+$scope.jobId+'&tablePKID='+$scope.additionalWorkId+'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_pdf').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_pdf')) {
            angular.element('#view_pdf').remove();
        };
    });

}

function BatchMarkPartAsAvailable($scope, $rootScope, $state, $stateParams, $timeout) {
    /*====================================================
     Set data on the scope
     ====================================================*/
    $rootScope.$on('smart_table:pinned_rows_updated', function(event, rows_store) {
        $scope.chosenParts = rows_store;
        $scope.checkIfAnyPartsAreInvalid();
    });

    $scope.initAvailableQuantity = function initAvailableQuantity(part) {
        part.error = false;
        part.available_quantity = parseFloat(part.part.Quantity);
        // Set default on input
        part.quantity = part.available_quantity;
    }

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateQuantity = function validateQuantity(part) {
        var input_is_number = !isNaN(parseFloat(part.quantity)),
            quantity_valid;

        if(input_is_number) {
            $scope.isQuantityInBounds(part);
        }
        $scope.checkIfAnyPartsAreInvalid();
    }

    $scope.isQuantityInBounds = function isQuantityInBounds(part) {
        if ((parseFloat(part.quantity) > parseFloat(part.available_quantity)) || parseFloat(part.quantity) < 0) {
            part.error = true;

            if (parseFloat(part.quantity) < 0) {
                part.error_message = 'Quantity cannot be negative';
            } else {
                part.error_message = 'There aren\'t enough parts to ' + $scope.selectedStatus;
            }
        } else {
            part.error = false;
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.error = false;
        for(var i = 0, l = $scope.chosenParts.length; i < l; i++) {
            var chosen_part = $scope.chosenParts[i];
            if (chosen_part.error === true) {
                $scope.error = true;
            }
        }
    }
}

function AddNewReserveListCtrl($scope, $state, $http, getIdData, $rootScope) {

    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;

    if($state.params.estimateId != undefined || getIdData.estimateId != undefined){
        EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
        $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
        $scope.estimateSubNavStatic = true;
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic': $scope.estimateSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
        $scope.$emit('tabCustomer:selected', getIdData.customerRec);
        $scope.$emit('estimateId:selected', $scope.estimateId);
        $scope.$emit('displayScreen', false);

        $scope.estimateDetails = getIdData.estimateDetails;

        $scope.estimatedescriptionValue = $scope.estimateDetails.description;
        $scope.estimateengineerNotesValue = $scope.estimateDetails.engineernotes;
    }

    if($scope.jobId = $state.params.jobId != undefined || getIdData.jobId != undefined){
        $scope.jobId = $state.params.jobId || getIdData.jobId;
        $scope.customerTabName = $state.params.type || getIdData.customerTabName;
        $scope.jobSubNavStatic = true;
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic': $scope.jobSubNavStatic };
        $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
        $scope.$emit('tabCustomer:selected', getIdData.customerRec);
        $scope.$emit('jobId:selected',  $state.params.jobId);

        $scope.jobDetails = getIdData.jobDetails;

        $scope.jobdescriptionValue = $scope.jobDetails.description;
        $scope.jobengineerNotesValue = $scope.jobDetails.engineernotes;
    }

    ReminderDateCtrl.call(this, $scope, getIdData, '', '', 'add', $state);
    $scope.minDate = new Date();

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$watch('userDetails', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }

        }
    });

    $scope.getEstimateEngineerNote = function()
    {
        $scope.engineernote = $scope.estimateengineerNotesValue.replace(/<br\s*[\/]?>/gi, "\n");
    }
    $scope.getEstimateDescription = function()
    {
        $scope.description = $scope.estimatedescriptionValue.replace(/<br\s*[\/]?>/gi, "\n");
    }

    $scope.getJobEngineerNote = function()
    {
        $scope.engineernote = $scope.jobengineerNotesValue.replace(/<br\s*[\/]?>/gi, "\n");
    }
    $scope.getJobDescription = function()
    {
        $scope.description = $scope.jobdescriptionValue.replace(/<br\s*[\/]?>/gi, "\n");
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.addReserveListForm.$setPristine();
            $scope.saving = false;
            $scope.type = data.type;
            $scope.status = data.status;
            $scope.flashMessage = "Reserve.list.saved";
            $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
            var message = {name: 'jobStatus', value: $scope.status};
            $scope.$emit('event:changeJobScope', message);
            if($scope.type == "Estimate"){
                $scope.estimateViewDetails($scope.selectedId, $scope.estimateId, $scope.customerTabName, $scope.dynamicState);
            } else if($scope.type == "Job"){
                $scope.viewJobDetails($scope.selectedId, $scope.customerTabName, $scope.jobId);
            }

        })
    }
    $scope.backTo = function ()
    {
        if($scope.estimateId != undefined){
            $state.transitionTo('loggedin.customer_list.view.' + $scope.dynamicState + '.survey', {'id': $scope.selectedId, 'estimateId': $scope.estimateId, 'type': $scope.customerMode});
        } else if($scope.jobId != undefined){
            $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId });
        }
    }
}

function JobEditCtrl($scope, $state, $http, $timeout, formPanelCollection, getIdData, $rootScope, warningModal, toastBox, prefix, skills_data, cardExpiryMonths, cardExpiryYears, creditcards) {
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.job = getIdData.jobDetails;
    $scope.contactid = getIdData.jobDetails.contactid;
    $scope.customerContracts = getIdData.customerContracts;
    $scope.existingContract = getIdData.existingContract;
    $scope.contacts = getIdData.contacts;
    $scope.userGroups = getIdData.userGroups;
    $scope.selectedSkills = getIdData.skills;
    $scope.skills_data = skills_data;
    $scope.rolename= getIdData.rolename;
    $scope.jobDurationSettings = getIdData.setupJobDuration;
    if($scope.job.usergroupsid == null) {
        $scope.job.usergroupsid = '';
    }
    if($scope.job.invoicecategoryid == null) {
        $scope.job.invoicecategoryid = '';
    }
    $scope.handleSpecialEventValues = function () {
        return;
    }
    $scope.dateError = false;
    $scope.endDateError = false;
    $scope.setJobDuration = false;
    $scope.durationWarning = false;
    $scope.minDate = moment();
    $scope.expectedCompletionDate ='';
    if(getIdData.jobDetails.expectedcompletedondatetime){
        $scope.expectedcompletedondatetime = moment(getIdData.jobDetails.expectedcompletedondatetime);
        $scope.expectedCompletionDate = getIdData.jobDetails.expectedcompletedondatetime;
    }else {
        $scope.expectedcompletedondatetime = '';
    }
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        if($scope.expectedcompletedondatetime!='' && (message.name=='expectedCompletionDate' || message.name=='expectedcompletedondatetime' )){
            var selectedDate = moment(message.date);
            var temp =moment($scope.expectedcompletedondatetime);
            $scope.expectedcompletedondatetime = selectedDate.clone().hours(temp.hours()).minutes(temp.minutes());
        }else if(message.name=='expectedCompletionDate' || message.name =='expectedcompletedondatetime'){
            var temp = moment().add(15 - (moment().minute() % 15), "minutes").subtract(moment().second(),"seconds");
            var selectedDate = moment(message.date);
            $scope.expectedcompletedondatetime = selectedDate.clone().hours(temp.hours()).minutes(temp.minutes());
        }
    });
    $scope.$on('setDatepicker', function(event, message) {
        if(message.name =='expectedcompletedondatetime'){
            $scope.expectedcompletedondatetime=message.value;
        }else if(message.name =='expectedCompletionDate'){
            $scope.expectedcompletedondatetime=message;
        }

    });
    if($scope.jobDurationSettings != null) {
        $scope.setJobDuration = true;
        if ($scope.job.startdatetime != null) {
            $scope.startDateTime = moment($scope.job.startdatetime);
            $scope.endDateTime =  moment($scope.job.enddatetime);
            $scope.diaryDate = $scope.startDateTime;
            $scope.selectedDate = $scope.startDateTime;
            DiaryDateTimeInputs.call(this, $scope, $timeout);
        }
        else{
            var duration = parseInt($scope.jobDurationSettings.jobDuration);
            $scope.startDateTime = moment().add(15 - (moment().minute() % 15), "minutes");
            $scope.demodate = $scope.startDateTime;
            $scope.endDateTime = moment($scope.demodate).add(duration, "minutes");
            $scope.diaryDate = $scope.startDateTime;
            $scope.selectedDate = $scope.startDateTime;
            DiaryDateTimeInputs.call(this, $scope, $timeout);
        }
    }

    $scope.disableCSDateTime = ($scope.job.status == 'completed' || getIdData.engineerDetails != null || getIdData.jobDetails.completedreason != '') ? true : false ;
    SkillsManager.call(this, $scope, $scope.skills_data);
    $scope.jobSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type});
    formPanelCollection.setFilterData(getIdData.contactCreate);
    if($scope.job.engineernotes){
        $scope.job.engineernotes = $scope.job.engineernotes.replace(/<br\s*[\/]?>/gi, "\n");
    }
    if($scope.job.status == 'completed'){
        $scope.job.completed = true;
    }else{
        $scope.job.completed = false;
    }
    if($scope.contactid == null && $scope.contacts.length != 0){
        $scope.contactid = $scope.contacts[0].contactid;
    }
    $scope.job.ponumber = ($scope.job.ponumber == null)?'':$scope.job.ponumber;
    $scope.job.quoted_amount = ($scope.job.quoted_amount == null)?'':$scope.job.quoted_amount;
    $scope.job.priority = ($scope.job.priority == '')?null:$scope.job.priority;
    $scope.completedon = ($scope.job.completedon)?moment($scope.job.completedon.date):moment();
    $scope.access_methods = getIdData.access_methods;
    $scope.invoiceCategories = getIdData.invoiceCategories;
    $scope.service_types = getIdData.contactCreate.serviceTypes;
    $scope.service_type_options = $scope.job.servicereminderinstances;
    $scope.servicereminderinstances = [];
    $scope.job_is_booked = false;
    $scope.bookDiaryEvent = false;
    $scope.existingServiceReminders =  angular.copy(getIdData.jobDetails.servicereminderinstances);
    $scope.selected_service_type_options = [];
    if($scope.service_type_options) {
        $scope.service_type_options.map(function (type) {
            return $scope.selected_service_type_options.push({
                'selected_type': type
            });
        });
    }
    else {
        $scope.selected_service_type_options = [
            {
                'selected_type' : null
            }
        ];
    }

    /*---------------Customer contracts-------------------------*/
    if($scope.customerContracts) {
        $scope.customerContract = $scope.job.contractId;
        var existsContract = $scope.existingContract;
        let contractExpired = false;
        if (existsContract) {
            let contractEndDate;
            contractEndDate = moment($scope.existingContract['contractEndDate']);
            contractExpired = moment().isAfter(contractEndDate);
        }

        $scope.disableContract = (getIdData.invoicesCount != 0 || contractExpired || $scope.job.isPpmJob) ? true : null;

        var jobDescriptionsId = $scope.job.jobDescriptionId, assets = [], jobAllowance;
        $scope.contractSuggestList = [];
        $scope.showJobsCountWarning = false;

        $scope.checkContractJobAllowances = function (value) {
            $scope.customerContract = value;
            if (value != "") {
                let contract = _.find($scope.customerContracts, function (ar) {
                    return ar['id'] == $scope.customerContract;
                });
                // set invoice category
                if($scope.job.invoicecategoryid == '') {
                    $scope.job.invoicecategoryid = (contract['invoiceCategory'] != null)? contract['invoiceCategory']: null;
                }

                // set user group
                if($scope.job.usergroupsid == '') {
                    $scope.job.usergroupsid = (contract['usersGroup'] != null)? contract['usersGroup']: "";
                }

                $scope.opened = !!(contract['invoiceCategory'] || contract['usersGroup']);

                jobAllowance = _.find(contract['jobAllowances'], function (ar) {
                    return ar['jobDescriptionId'] == jobDescriptionsId;
                });

                if (jobAllowance) {

                    if (jobAllowance['jobMaxCount'] <= jobAllowance['contractJobsCount']) {
                        $scope.showJobsCountWarning = true;
                    }
                    else {
                        $scope.showJobsCountWarning = false;
                    }

                    if ($scope.job.contractId == $scope.customerContract) {
                        if ($scope.job.exceededJob) {
                            $scope.showJobsCountWarning = true;
                        }
                        else {
                            $scope.showJobsCountWarning = false;
                        }
                    }

                }
            }
            else {
                $scope.showJobsCountWarning = false;
                $scope.job.usergroupsid = "";
                $scope.opened = false;
                $scope.job.invoicecategoryid = null;
            }
        }

        // filters the customer contracts
        let filterCustomerContracts = function () {

            let indices = [];
            $scope.customerContracts.forEach(function (customerContract, ind) {
                let array1 = customerContract['assets'].sort();
                jobAllowance = _.find(customerContract['jobAllowances'], function (ar) {
                    return ar['jobDescriptionId'] == jobDescriptionsId;
                });
                if (_.isEqual(array1, assets) && jobAllowance) {
                    $scope.customerContracts[ind]['disabledSelection'] = null;
                    indices.push(ind);
                }
                else {
                    $scope.customerContracts[ind]['disabledSelection'] = true;
                }
            });

            if (indices.length) {

                if ($scope.customerContract === null || ($scope.customerContract === "" && !($scope.job.contractId))) {
                    $scope.customerContract = "";
                }
                else{
                    $scope.customerContract = $scope.job.contractId ? $scope.job.contractId : $scope.customerContracts[indices[0]]["id"];
                    $scope.checkContractJobAllowances($scope.customerContract);
                }

                $scope.showCustomerContracts = true;
            }
            else {
                $scope.showCustomerContracts = false;
                $scope.showJobsCountWarning = false;
            }
        };

        // triggers on changes in asset selection
        $scope.$on('jobAsset:selected', function (evt, data) {
            assets = data.selectedAssets.sort();
            if ((existsContract && contractExpired) || $scope.job.isPpmJob) {
                let contracts = [];
                contracts.push($scope.existingContract);
                $scope.customerContracts = contracts;
                $scope.showCustomerContracts = true;
            }
            else{
                filterCustomerContracts();
            }
        });
    }

    else if($scope.existingContract){
        $scope.customerContract = $scope.job.contractId;
        $scope.customerContracts = [$scope.existingContract];
        $scope.disableContract = true;
        $scope.showCustomerContracts = true;
    }

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$on('servicetype:selected', function (evt, selected_service_types) {
        $scope.servicereminderinstances =  selected_service_types.map(function(selected_service_type) {
            return selected_service_type.selected_type;
        });
    });

    $scope.editJob = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }

        var paymentDetails = '';
        angular.forEach($scope.newJobForm, function (value, key) {
            if (value.hasOwnProperty('$modelValue')){
                if (key.indexOf('payment') != -1 || key.indexOf('storedCard') != -1) {
                    paymentDetails += '&' + key + '=' + encodeURIComponent(value.$modelValue);
                }
            }
        });

        var skills = '';
        if(this.selectedSkills && this.selectedSkills.length) {
            angular.forEach(this.selectedSkills, function (val, key) {
                var concat = (skills != '' ? "," : "");
                skills += concat + val.id;
            });
        }
        $scope.updating = true;
        $scope.current = this;
        var isservicejob = (this.job.isservicejob == true)?1:0;
        var params = { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId':$scope.jobId};
        if($scope.servicereminderinstances != null) {
            $scope.servicereminderinstances = ($scope.servicereminderinstances.length == 0)?this.job.servicereminderinstances:$scope.servicereminderinstances;
        }
        $scope.startDateTime = ($scope.startDateTime != undefined) ? moment($scope.startDateTime).subtract(moment($scope.startDateTime).second(),"seconds").toISOString() : null;
        $scope.endDateTime = ($scope.endDateTime != undefined) ? moment($scope.endDateTime).subtract(moment($scope.endDateTime).second(),"seconds").toISOString() : null;
        $scope.customerContract = $scope.customerContract ? $scope.customerContract: "";
        $scope.existingServiceReminders = ($scope.existingServiceReminders != null)?$scope.existingServiceReminders:'';
        $scope.completedon = (this.completedon)?moment(this.completedon, 'L').format('YYYY-MM-DD'):null;
        $scope.expectedcompletedondatetime = (this.expectedcompletedondatetime)?this.expectedcompletedondatetime.format("YYYY-MM-DD") +' '+this.expectedcompletedondatetime.format("HH:mm"):null;
        $http.post(Routing.generate('update_job', params), "customerType=" + $scope.customerType
            + "&job[id]=" + this.job.id
            + "&job[contactid]=" + this.contactid
            + "&job[description]=" + encodeURIComponent(this.job.description)
            + "&job[startdatetime]=" + $scope.startDateTime
            + "&job[enddatetime]=" + $scope.endDateTime
            + "&job[engineernotes]=" + encodeURIComponent(this.job.engineernotes)
        + "&job[isservicejob]=" + isservicejob
        + "&job[servicereminderinstances]=" + angular.toJson($scope.servicereminderinstances)
        + "&job[quotedamount]=" + encodeURIComponent(this.job.quoted_amount)
        + "&job[ponumber]=" + this.job.ponumber
        + "&job[priority]=" + this.job.priority
        + "&job[invoicecategoryid]=" + this.job.invoicecategoryid
        + "&job[completed]=" + this.job.completed
        + "&job[completedon]=" + $scope.completedon
        + "&job[additionalcontactid]=" + this.job.additionalcontactid
        + "&job[usergroupsid]=" + this.job.usergroupsid
        + "&job[workaddressaccessmethod]=" + this.job.workaddressaccessmethod
        + "&job[accessnotes]=" + encodeURIComponent(this.job.accessnotes)
        + "&job[assets]=" + this.inputs.jobassets
        + "&job[customerContract]=" + $scope.customerContract
        + "&job[expectedcompletedondatetime]=" + $scope.expectedcompletedondatetime
        + "&skills=" + skills
        + "&exceededContractJob=" + $scope.showJobsCountWarning
        + "&existingServiceReminders=" + $scope.existingServiceReminders + paymentDetails).
            success(function (data, status) {
                if(data.warning == true) {
                    toastBox.show('Additional contact cannot be same as contact', 4000);
                    $scope.updating = false;
                }else if(data.completedReason == true){
                    warningModal.show(data.message, data.title, data.id);
                    $scope.updating = false;
                    $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId },{reload:true});
                } else {
                    if (status == 200) {
                        $scope.viewJobDetails($scope.selectedId, $scope.customerTabName,  $scope.jobId);
                        $scope.updating = false;
                    }
                }
        });
    }

    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.contactid = data.contactId;
        $scope.contacts.unshift({contactid:data.contactId, surname: data.contactFullName});
    });

    /*---------------------------Credit card details ----------------------------*/

    $scope.addressline1 = getIdData.addressline1;
    $scope.addressline2 = getIdData.addressline2;
    $scope.addressline3 = getIdData.addressline3;
    $scope.county = getIdData.county;
    $scope.name = getIdData.customerName;
    $scope.postcode = getIdData.postcode;
    $scope.town = getIdData.town;
    $scope.deferredPaymentDone = typeof getIdData.deferredPayment == 'object' && getIdData.deferredPayment.hasOwnProperty('amount');

    $scope.cardId = getIdData.creditCardType && getIdData.creditCardType.hasOwnProperty('value') ? getIdData.creditCardType.value: false;
    $scope.cardDetails = getIdData.jobCardDetails;
    $scope.jobexpiryCard = getIdData.jobCardDetails.jobexpiryCard;
    $scope.isFromEstimate = getIdData.jobDetails.estimateId > 0;

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();

    HandleCardData.call(this, $scope, creditcards, $scope.cardDetails.cardVendorSupportsStoredCards, $scope.cardDetails.creditCardModule,
        $scope.cardDetails.settingCreditCard, $scope.cardDetails.storedCards);
}

function HandleCardData($scope, creditcards, cv, CMod, sCard, s_card) {
    $scope.cardVendorSupportsStoredCards = cv;
    $scope.creditCardModule = CMod;
    $scope.settingCreditCard = sCard;
    $scope.storedCards = JSON.parse(s_card.replaceAll('\\', ''));
    $scope.storedCardsCount = $scope.storedCards.length;
    $scope.showCardDropdown = false;
    $scope.showCardForm = false;
    $scope.selectedMethodIsCard = false;
    $scope.validation = true;
    $scope.invoiceAmount = 0;
    if ($scope.creditCardModule && $scope.settingCreditCard) {
        $scope.selectedMethodIsCard = true;
        $scope.showCardDetails = true;
    } else {
        $scope.selectedMethodIsCard = false;
        $scope.storedCard = '';
        $scope.cardName = $scope.cardNumber = $scope.cardExpiryMonth = $scope.cardExpiryYear = $scope.cardCvc = $scope.saveCard = $scope.cardAddressLine1 = $scope.cardAddressLine2 = $scope.cardAddressLine3 = $scope.cardTown = $scope.cardCounty = $scope.cardPostcode = '';
        $scope.card_type = '';
        $scope.error = false;
    }

    $scope.showCardDropdown = false;
    $scope.cardDropdownRequired = false;
    $scope.showCardForm = false;
    $scope.showFormDefault = false;
    if ($scope.selectedMethodIsCard && $scope.cardVendorSupportsStoredCards) {
        $scope.showCardDropdown = true;
        $scope.cardDropdownRequired = true;
        $scope.showCardForm = false;
    }
    if ($scope.selectedMethodIsCard && (!$scope.cardVendorSupportsStoredCards || $scope.storedCards.length == 0 || $scope.showStoredCards)) {
        $scope.showCardForm = true;
        $scope.showFormDefault = true;
        $scope.cardDropdownRequired = false;
    }
    if ($scope.jobexpiryCard) {
        $scope.showCardDropdown = false;
        $scope.showCardForm = true;
    }
    if ($scope.cardId == 4 || $scope.cardId == 3 || $scope.cardId == 5) {
        $scope.isRequired = true;
    }
    else
        $scope.isRequired = false;
    if (($scope.cardId == 4 || $scope.cardId == 5) && (!($scope.showCardDropdown) || $scope.showFormDefault)) {
        $scope.isDeferred = true;
        $scope.isRequired = false;
    }
    else
        $scope.isDeferred = false;
    if ($scope.cardId == 4 || $scope.cardId == 5)
        $scope.isDeferredPayment = true;
    else
        $scope.isDeferredPayment = false;

    handleJobCardCharges.call(this, $scope, creditcards, 'newJobForm');
}

function PlaceJobOnHoldCtrl($scope, $state, $http, getIdData, $rootScope)
{
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.jobSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic': $scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData.customerRec);
    $scope.$emit('jobId:selected',  $state.params.jobId);
    $scope.minDate = new Date();

    $scope.jobDetails = getIdData.jobDetails;
    $scope.jobStatus = $scope.jobDetails.status;

    if($scope.jobStatus == 'reserved'){
        $scope.job_is_on_reserve_list = true;
    } else {
        $scope.job_is_on_reserve_list = false;
    }

    ReminderDateCtrl.call(this, $scope, getIdData, '', '', 'reminderAdd', $state);

    $scope.$watch('userDetails', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }

        }
    });

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.placeJobOnHoldForm.$setPristine();
                $scope.status = data.status;
                $scope.flashMessage = "Job.on.hold.saved";
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                var message = {name: 'jobStatus', value: $scope.status};
                $scope.$emit('event:changeJobScope', message);
                $scope.viewJobDetails($scope.selectedId, $scope.customerTabName, $scope.jobId);

        })
    }
    $scope.backTo = function ()
    {
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId });

    }
}

function NotesCheckBoxCtrl($scope){
    $scope.$watch('selectedImportant', function (newVal, oldVal) {
        if (!$scope.selectedImportant) {
            $scope.selectedStopwork = false;
        }
    });
}

function RecallJobsCtrl($scope, $state, $http, getIdData, $rootScope, formPanelCollection, $timeout)
{
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.jobDurationTime = getIdData.jobDurationTime;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.job = getIdData.jobDetails;
    $scope.jobSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', { 'jobId' : getIdData.jobId, 'jobType' : getIdData.jobDetails.type});
    formPanelCollection.setFilterData(getIdData.contactCreate);

    $scope.access_methods = getIdData.access_methods;
    $scope.contacts = getIdData.contacts;
    $scope.invoiceCategories = getIdData.invoiceCategories;
    $scope.bookDiaryEvent = false;
    $scope.recall = true;
    $scope.jobType = getIdData.jobDetails.type;
    $scope.handleSpecialEventValues = function () {
        return;
    }
    $scope.endDateError = false;
    $scope.dateError = false;
    $scope.setJobDuration = false;
    $scope.durationWarning = false;
    if($scope.jobDurationTime != undefined) {
        $scope.setJobDuration = true;
        var duration = parseInt($scope.jobDurationTime);
        $scope.startDateTime = moment().add(15 - (moment().minute() % 15), "minutes").subtract(moment().second(),"seconds").toISOString();
        $scope.demodate = $scope.startDateTime;
        $scope.endDateTime = moment($scope.demodate).add(duration, "minutes").toISOString();
        DiaryDateTimeInputs.call(this, $scope, $timeout);
    }



    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newJobForm.$setPristine();
            $scope.saving = false;
            $scope.status = data.status;
            $rootScope.flashMessage = "Recall.job.saved";
            var message = {name: 'jobStatus', value: $scope.status};
            $scope.$emit('event:changeJobScope', message);
            if($scope.bookDiaryEvent) {
                $state.transitionTo("loggedin.diary_from_job_estimate", { 'typeId': data.jobId, 'type': 'job' });
            } else {
                $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': data.jobId });
            }

        })
    }

    $scope.contactid = '';
    if($scope.contacts.length != 0){
        $scope.contactid = $scope.contacts[0].contactid;
    }

    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.contactid = data.contactId;
        $scope.contacts.push({contactid:data.contactId, surname: data.contactFullName});
    });
}

function ChangeRecallBlame($scope, $rootScope, $http, prefix, $stateParams,  $element)
{
    $scope.jobId = $stateParams.jobId;
    $scope.selected_engineer = $scope.$parent.panel_data;
    $scope.blame = JSON.parse($scope.$parent.additional_data);
    $scope.collection = angular.fromJson($scope.$parent.collection);
    $scope.engineers = $scope.collection.engineers;
    $scope.recall_job_blame = $scope.collection.recall_job_blame;
    $scope.engineer_id = $scope.collection.engineer_id;

    $scope.handleSave = function handleSave() {
        $scope.engineer_id = ($scope.blame === true)?$scope.engineer_id:null;
        $http.post(prefix + '/change_recall_blame', "blameId=" + $scope.recall_job_blame + "&engineerId=" + $scope.engineer_id + "&blame=" + $scope.blame + "&jobId=" + $scope.jobId).
        success(function (data, status) {
            if (status == 200) {
                $scope.recall_job_blame = data;
                if ($scope.blame === false) {
                    $scope.$emit('jobs:blame_removed');
                    $scope.$emit('sidepanel:close');
                } else {
                    var change_engineer = $scope.engineers.map(function(engineer) {
                        if(engineer.id == $scope.engineer_id) {
                            return engineer.text;
                        }
                    });
                    change_engineer = change_engineer.filter(function(engineer){ return engineer != undefined });
                    $scope.$emit('jobs:blame_changed', change_engineer[0]);
                    $scope.$emit('sidepanel:close');
                }
            }
        });
    }
   // when blame is false the select2 is hidden. Select2 is initialize but remains hidden so we need to reinitialize it.
    $scope.$watch('blame', function (newVal, oldVal) {
        if (newVal) {
           $element.find('[ng-model=engineer_id]').select2(); // Initialize Select2
        }
    });

    $scope.cancelSidePanel = function cancelSidePanel(){
        $scope.$emit('sidepanel:close');
    }
}

function viewJobReserveList($scope, $rootScope, $state, $filter, $document, $timeout, $stateParams, $http, prefix)
{
    if($stateParams.jobId){
        $scope.jobId = $stateParams.jobId;
        $scope.type = 'job';
    } else if($stateParams.estimateId){
        $scope.estimateId = $stateParams.estimateId;
        $scope.type = 'estimate';
    }
    $scope.collection = angular.fromJson($scope.$parent.collection);
    $scope.engineers = $scope.collection.engineers;
    $scope.selected_engineers = $scope.collection.selected_engineers;
    $scope.reserve_list_id = $scope.collection.reserve_list_id;
    $scope.isDisabled = true;

    $scope.existing_selected_engineers =  angular.copy($scope.collection.selected_engineers);

    $scope.select2Options = {
        placeholder: "-- Please choose --",
        'multiple': true,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.engineers,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        }
    };

    $scope.chosen_engineers = $scope.selected_engineers;
    /*==========================================================================================
     Slide logic
     ==========================================================================================*/
    $scope.hideAllSlides = function hideAllSlides() {
        $scope.active_slide = null;
    }

    $scope.slideInView = function slideInView(slide) {
        $scope.active_slide = slide;
    }

    $scope.active_slide = 1;

    $scope.removeEngineer = function removeEngineer(engineer_index) {
        $scope.chosen_engineers.splice(engineer_index, 1);
        if($scope.chosen_engineers.length > 0) {
            $scope.isDisabled = false;
        } else {
            $scope.isDisabled = true;
        }
    }

    $scope.handleSearch = function handleSearch() {
        var filtered_preferences = $filter('filter')($scope.chosen_engineers, $scope.searchFilter),
            no_results = filtered_preferences.length === 0;

        if (no_results) {
            $scope.no_results = true;
        } else {
            $scope.no_results = false
        }
    }

    if($scope.type  == 'job') {
        var saveUrl = prefix + '/job_change_reserve_list_engineers';
    } else {
        var saveUrl = prefix + '/estimate_change_reserve_list_engineers';
    }

    $scope.saveEngineers = function saveEngineers(index) {
        $http.post(saveUrl, "reserve_list_id=" + $scope.reserve_list_id + "&chosen_engineers=" + angular.toJson($scope.chosen_engineers) + "&existing_selected_engineers=" + angular.toJson($scope.existing_selected_engineers)).
        success(function (data, status) {
            if (status == 200) {
                $scope.existing_selected_engineers = data;
                $scope.$emit('jobs:reserve_list_changed', $scope.chosen_engineers.length);
                if(index === undefined) {
                    $scope.$emit('sidepanel:close');
                }
            }
        });
    }

    $scope.$watch('chosen_engineers', function (newVal, oldVal) {
        if (newVal) {
            if ($scope.chosen_engineers.length > 0){
                $scope.isDisabled = false;
            } else {
                $scope.isDisabled = true;
            }

        }  else {
            $scope.isDisabled = true;
        }
    });

    $scope.addEngineer = function addEngineer() {
        $scope.selected_engineers = $scope.chosen_engineers;
        $scope.saveEngineers(1);
    }

    $scope.cancelEngineers = function cancelEngineers() {
        $scope.chosen_engineers = $scope.selected_engineers;
    }

    $scope.cancelSidePanel = function cancelSidePanel(){
        $scope.cancelEngineers();
        $scope.$emit('sidepanel:close');
    }
}

function AddNewTravelCtrl($scope, $state, $http, prefix, $rootScope, getIdData, jobData)
{
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId;
    $scope.selectedDate = '';
    $scope.customerMode = $state.params.type;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.customerTabName = getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).success(function (data, status) {
            $scope.newTravelForm.$setPristine();
            $rootScope.flashMessage = "Travel saved";
            if($scope.milestoneId){
                $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", { 'id': $state.params.id, 'type': $scope.customerTabName, 'jobId' : $scope.jobId, milestoneId : $scope.milestoneId });
            }else{
                $state.transitionTo("loggedin.customer_list.view.job.viewcost", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId });
            }
            $scope.saving = false;
        })
    }
}

function EditTravelsCtrl($scope, $state, $http, prefix, getIdData, $rootScope)
{
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.travels = getIdData.travels;
    $scope.travelDetails =getIdData.travelDetails;
    $scope.show_date_picker = false;
    if ($scope.travelDetails.date != null) {
        $scope.selectedDate = moment($scope.travelDetails.date.date);
    } else {
        $scope.selectedDate = '';
    }
    $scope.description = getIdData.travelDetails.description;
    $scope.price = getIdData.travelDetails.price;
    $scope.vehicle = getIdData.travelDetails.vehicle;
    $scope.customerMode = $state.params.type;
    $scope.engineerlist = getIdData.engineerlist;
    $scope.engineer = getIdData.travelDetails.engineer;
    $scope.vehiclelist = getIdData.vehiclelist;
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.jobSubNavStatic = true;
    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.$emit('displayScreen', false);
    $scope.customerTabName = getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });
    $scope.editTravel = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.saving = true;
        $scope.current = this;
        $http.post(prefix + '/update_travel', "travel[selectedId]=" + this.selectedId
            + "&travel[id]=" + encodeURIComponent(this.travelDetails.travelId)
            + "&travel[selectedDate]=" + moment(this.selectedDate).format('YYYY-MM-DD')
            + "&travel[description]=" + encodeURIComponent(this.description)
            + "&travel[engineer]=" + encodeURIComponent(this.engineer)
            + "&travel[vehicle]=" + encodeURIComponent(this.vehicle)
            + "&travel[price]=" + encodeURIComponent(this.price)
            + "&travel[jobid]=" + encodeURIComponent($scope.jobId)
            + "&travel[milestoneid]=" + encodeURIComponent($scope.milestoneId)).
        success(function (data, status) {
            if (status == 200) {
                $scope.selectedId = data.customerid;
                $rootScope.flashMessage = "Travel Updated";

                if($scope.milestoneId){
                    $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId' : $scope.jobId, milestoneId : $scope.milestoneId });
                }else{
                    $state.transitionTo("loggedin.customer_list.view.job.viewcost", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId });
                }
                $scope.saving = false;
            }
        })
    }
}
function AddNewJobLabourCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData)
{
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId;
    $scope.customerMode = $state.params.type;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.customerTabName = getIdData.customerTabName;
    $scope.users = getIdData.modules;
    $scope.userValue = true;
    $scope.traveltimehours = $scope.traveltimemins = $scope.onsitetimehours  = $scope.onsitetimemins = $scope.total = $scope.labourrate = $scope.travelrate = 0;
    $scope.showBreakDown = false;
    $scope.userRates = getIdData.userRates;
    $scope.rates = {
        labourrate:0,
        travelrate:0
    };

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);

    $scope.$watch('userDetails', function (newVal, oldVal) {
        if (newVal) {
            $scope.userId = newVal;
            $scope.userValue = false;
            $scope.costRate = $scope.userRates[$scope.userId][0];
            $scope.rates.labourrate = $scope.costRate;
            $scope.rates.travelrate = $scope.costRate;
        }
    });

    $scope.handleBreakDownChange = function handleBreakDownChange(model) {
        $scope.showBreakDown = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTotalChange = function handleTotalChange(model) {
        $scope.total = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTravelRateChange = function handleTravelRateChange(model) {
        $scope.rates.travelrate = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTravelTimeHourChange = function handleTravelTimeHourChange(model) {
        $scope.traveltimehours = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTravelTimeMinChange = function handleTravelTimeMinChange(model) {
        $scope.traveltimemins = model;
        $scope.validateBreakdownData();
    }

    $scope.handleLabourRateChange = function handleLabourRateChange(model) {
        $scope.rates.labourrate = model;
        $scope.validateBreakdownData();
    }

    $scope.handleOnSiteTimeHourChange = function handleOnSiteTimeHourChange(model) {
        $scope.onsitetimehours = model;
        $scope.validateBreakdownData();
    }

    $scope.handleOnSiteTimeMinChange = function handleOnSiteTimeMinChange(model) {
        $scope.onsitetimemins = model;
        $scope.validateBreakdownData();
    }

    $scope.validateBreakdownData = function validateBreakdownData() {

        if($scope.showBreakDown == 0) {
            if($scope.total < 0) {
                $scope.errorModule = true;
            } else {
                $scope.errorModule = false;
            }
        } else {
            if((($scope.onsitetimehours != 0 || $scope.onsitetimemins != 0) && $scope.rates.labourrate >= 0) || (($scope.traveltimehours !=0 || $scope.traveltimemins != 0)  && $scope.rates.travelrate >= 0)) {
                $scope.errorModule = false;
            } else {
                $scope.errorModule = true;
            }
        }
    }

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newLabourForm.$setPristine();
            $scope.saving = false;
            $rootScope.flashMessage = "Job.cost.labour.saved";
            if($scope.milestoneId){
                $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId , milestoneId : $scope.milestoneId });
            }else{
                $state.transitionTo("loggedin.customer_list.view.job.viewcost", { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId });
            }
        })
    }
}

function EditJobLabourCtrl($scope, $state, $http, prefix, getIdData, $rootScope, $timeout) {
    $scope.selectedId = $state.params.id;
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.selectedTab = "Costs";
    $scope.jobId = $state.params.jobId;
    $scope.milestoneIdValue = $state.params.milestoneId;
    $scope.jobSubNavStatic = true;
    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.$emit('displayScreen', false);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.rates = {};
    $scope.customCurrency = $rootScope.CustomCurrency;
    $scope.diaryEventId = getIdData.labourDetails.diaryEventId;
    $scope.advancedInvoicing = getIdData.advancedInvoicing;
    if($scope.milestoneIdValue){
        $scope.milestoneId = $scope.milestoneIdValue;
    }else{
        $scope.milestoneId = null;
    }

    $scope.labourId = $state.params.labourId;
    $scope.users = getIdData.users;
    $scope.userDetails = getIdData.selectedUsers.id;

    $scope.showBreakDown = parseInt(getIdData.labourDetails.isbreakdown);
    $scope.traveltimehours = getIdData.labourDetails.traveltimehours ? parseInt(getIdData.labourDetails.traveltimehours):0;
    $scope.traveltimemins = getIdData.labourDetails.traveltimemins ? parseInt(getIdData.labourDetails.traveltimemins):0;
    $scope.onsitetimehours = getIdData.labourDetails.onsitetimehours ? parseInt(getIdData.labourDetails.onsitetimehours):0;
    $scope.onsitetimemins = getIdData.labourDetails.onsitetimemins ? parseInt(getIdData.labourDetails.onsitetimemins):0;
    $scope.rates.travelrate = getIdData.labourDetails.travelrate ? parseFloat(getIdData.labourDetails.travelrate):0;
    $scope.rates.labourrate = getIdData.labourDetails.labourrate ? parseFloat(getIdData.labourDetails.labourrate):0;
    $scope.accpettimehours = 0;
    $scope.accepttimemins = 0;
    $scope.total = getIdData.labourDetails.total;

    $scope.labourSelectedDate = $scope.selectedDate = getIdData.labourDetails.date.date;

    $scope.settingsTravelRate = getIdData.settingsTravelRate;

    if($scope.travelrate == '' || $scope.travelrate == null) {
        $scope.travelRateValue = $scope.settingsTravelRate;
    } else {
        $scope.travelRateValue = $scope.travelrate;
    }

    $scope.handleBreakDownChange = function handleBreakDownChange(model) {
        $scope.showBreakDown = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTotalChange = function handleTotalChange(model) {
        $scope.total = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTravelRateChange = function handleTravelRateChange(model) {
        $scope.rates.travelrate = model;
        $scope.validateBreakdownData();
    }

    $scope.showDatePickerPopup = {
        timeTrackingTravelDateTime:false,
        timeTrackingArriveDateTime:false,
        timeTrackingLeaveDateTime: false,
        timeTrackingNoAccessDateTime: false,
        timeTrackingAbortDateTime: false
    };

    $scope.showDatePicker = function (keyname) {
        $timeout(function () {
            $scope.$apply(function () {
                $scope.showDatePickerPopup[keyname] = true
            })
        });
    };

    $scope.handleTravelTimeHourChange = function handleTravelTimeHourChange(model) {
        $scope.traveltimehours = model;
        $scope.validateBreakdownData();
    }

    $scope.handleTravelTimeMinChange = function handleTravelTimeMinChange(model) {
        $scope.traveltimemins = model;
        $scope.validateBreakdownData();
    }

    $scope.handleLabourRateChange = function handleLabourRateChange(model) {
        $scope.rates.labourrate = model;
        $scope.validateBreakdownData();
    }

    $scope.handleOnSiteTimeHourChange = function handleOnSiteTimeHourChange(model) {
        $scope.onsitetimehours = model;
        $scope.validateBreakdownData();
    }

    $scope.handleOnSiteTimeMinChange = function handleOnSiteTimeMinChange(model) {
        $scope.onsitetimemins = model;
        $scope.validateBreakdownData();
    }

    $scope.validateBreakdownData = function validateBreakdownData() {
        if($scope.showBreakDown == 0) {
            if($scope.total < 0) {
                $scope.errorModule = true;
            } else {
                $scope.errorModule = false;
            }
        } else {
            if($scope.onsitetimehours < 0 || $scope.onsitetimemins < 0 || $scope.rates.labourrate < 0 ||
                $scope.traveltimehours < 0 || $scope.traveltimemins < 0 || $scope.rates.travelrate < 0 ||
                $scope.accepttimemins < 0 || $scope.accpettimehours < 0) {
                $scope.errorModule = true;
            } else {
                $scope.errorModule = false;
            }
        }
    }

    $scope.$watch('userDetails', function (newVal, oldVal) {
        if (newVal) {
            $scope.userId = newVal;

            if($scope.userId == 0 && $scope.labourrate == 0) {
                $scope.labourrate = 0;
            }

            if($scope.userId != 0){
                $http.get(prefix + '/getLabourTravelRate?userId=' + $scope.userId + '&labourId=' + $scope.labourId).success(function (data) {
                    $scope.rates.labourrate = data.costRate;
                    $scope.rates.travelrate = data.saleRate;
                })
            }
        }
    });

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.labourDetails.date});
    });

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope.labourSelectedDate = message.date;
    });

    $scope.calcTotalTimeAndCost = function (state = true) {
        if($scope.advancedInvoicing && $scope.diaryEventId) {
            if($scope.timeTracking.hasOwnProperty('accept') && $scope.timeTracking.hasOwnProperty('travel')){
                var travel = moment($scope.timeTracking['travel']['dateTime']).startOf('minute');
                var accept = moment($scope.timeTracking['accept']['dateTime']).startOf('minute');

                var acceptDiff = travel.diff(accept,'minutes');
                $scope.accpettimehours = acceptDiff < 0 ? Math.ceil(acceptDiff/60) :Math.floor(acceptDiff/60);
                $scope.accepttimemins = acceptDiff % 60;
            }

            if($scope.timeTracking['travel'] !== ''){
                var travel = moment($scope.timeTracking['travel']['dateTime']).startOf('minute');
                let travelDiff = 0;
                if($scope.timeTracking['arrive'] !== ''){
                    var arrive = moment($scope.timeTracking['arrive']['dateTime']).startOf('minute');
                    travelDiff = arrive.diff(travel,'minutes');

                }else if($scope.timeTracking['noAccess'] !== ''){
                    var noAccess = moment($scope.timeTracking['noAccess']['dateTime']).startOf('minute');
                    travelDiff = noAccess.diff(travel,'minutes');

                }
                $scope.traveltimehours = travelDiff < 0 ? Math.ceil(travelDiff/60) :Math.floor(travelDiff/60);
                $scope.traveltimemins = travelDiff % 60;
            }

            if($scope.timeTracking['arrive'] !== ''){
                var arrive = moment($scope.timeTracking['arrive']['dateTime']).startOf('minute');
                let onsiteDiff = 0;
                if($scope.timeTracking['leave'] !== ''){
                    var leave = moment($scope.timeTracking['leave']['dateTime']).startOf('minute');
                    onsiteDiff = leave.diff(arrive,'minutes');

                }else if($scope.timeTracking['abort'] !== ''){
                    var abort = moment($scope.timeTracking['abort']['dateTime']).startOf('minute');
                    onsiteDiff = abort.diff(arrive,'minutes');
                }
                $scope.onsitetimehours = onsiteDiff < 0 ? Math.ceil(onsiteDiff/60) :Math.floor(onsiteDiff/60);
                $scope.onsitetimemins = onsiteDiff % 60;
            }
            if(state){
                $scope.errorModule = false;
                if($scope.onsitetimemins < 0 || $scope.onsitetimehours < 0
                    || $scope.traveltimemins < 0 || $scope.traveltimehours < 0
                    || $scope.accepttimemins < 0 || $scope.accpettimehours < 0) {
                    $scope.errorModule = true;
                } else {
                    $scope.validateBreakdownData();
                }
            }
        }
    }
    
    $scope.setTimeTrackingOriginalValues = function(timeTrackingRecords) {
        _.each(timeTrackingRecords, function(value, key){
            if(value) {
                value['dateTime'] = new Date(value.dateTime.date);
            }
        });
        return timeTrackingRecords;
    }
    
    $scope.timeTracking  = $scope.setTimeTrackingOriginalValues(getIdData.labourDetails.timeTracking);
    $scope.calcTotalTimeAndCost(false);
    $scope.onTimeChange = function (name, value) {
        $scope.timeTracking[name]['dateTime'] = new Date(value);
        $scope.calcTotalTimeAndCost();
    };

    $scope.editLabour = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.labourDate = (moment($scope.labourSelectedDate, 'L').format('YYYY-MM-DD') != "Invalid date" ) ? moment($scope.labourSelectedDate, 'L').format('YYYY-MM-DD') : moment(this.labourSelectedDate).format('YYYY-MM-DD');
        if(_.size($scope.timeTracking) > 0) {
            $scope.diaryTimeTracking = {};
            _.each($scope.timeTracking, function(value, key) {
                $scope.diaryTimeTracking[key] = {'id': value['id'], 'dateTime': moment(value['dateTime']).format('YYYY-MM-DD HH:mm:ss')};
            });
        }

        $http.post(prefix + '/edit_labour', "labourtype[labourId]=" + $scope.labourId
            + "&labourtype[labourdate]=" + $scope.labourDate
            + "&labourtype[engineerid]=" + encodeURIComponent(this.userDetails)
            + "&labourtype[isbreakdown]=" + this.showBreakDown
            + "&labourtype[total]=" + encodeURIComponent(this.total)
            + "&labourtype[traveltimehours]=" + encodeURIComponent(this.traveltimehours)
            + "&labourtype[traveltimemins]=" + encodeURIComponent(this.traveltimemins)
            + "&labourtype[onsitetimehours]=" + encodeURIComponent(this.onsitetimehours)
            + "&labourtype[onsitetimemins]=" + encodeURIComponent(this.onsitetimemins)
            + "&labourtype[travelrate]=" + encodeURIComponent(this.rates.travelrate)
            + "&labourtype[labourrate]=" + encodeURIComponent(this.rates.labourrate)
            + "&labourtype[diaryTimeTracking]=" + encodeURIComponent(JSON.stringify($scope.diaryTimeTracking))
            + "&labourtype[milestoneid]=" + encodeURIComponent($scope.milestoneId)).
        success(function (data, status) {
            if (status == 200) {
                if (data.error) {
                    $scope.errorDateMessage = true;
                } else {
                    if($scope.milestoneId){
                        $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId , milestoneId : $scope.milestoneId });
                    }else{
                        $state.transitionTo("loggedin.customer_list.view.job.viewcost", { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId });
                    }
                    $rootScope.flashMessage = data;
                }
                $scope.updating = false;
            }
        })
    }
}

function FreeOfChargeAndAbortJobCtrl($scope, $state, $http, getIdData, $rootScope, prefix)
{
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.jobSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic': $scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData.customerRec);
    $scope.$emit('jobId:selected',  $state.params.jobId);
    $scope.minDate = new Date();

    $scope.job = getIdData.jobDetails;
    $scope.jobStatus = $scope.job.status;

    $scope.$on('freeofchargedata', function(evt,data) {
        $scope.serviceObjects = data;
        $scope.Url = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/jobs/'+ $scope.jobId + '/free_of_charge_job';
        $http.post($scope.Url, "freeofchargeandabortjob[reason]=" + $scope.reason
            + "&freeofchargeandabortjob[moredetails]=" + encodeURIComponent($scope.moredetails) + "&freeofchargeandabortjob[serviceObjects]=" + $scope.serviceObjects).
        success(function (data, status) {
            if (status == 200) {
                if (data.error) {
                    $scope.errorDateMessage = data.error;
                } else {
                    $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId },{reload:true});
                }
            }
        });
    });

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            if(data['error'] == true){
                $scope.paymentError = true;
                $scope.saving = false;
            }
            else {
                $scope.newFreeOfChargeAndAbortJobForm.$setPristine();
                $scope.saving = false;
                $scope.paymentError = false;
                $scope.status = data.status;
                $scope.flashMessage = "saved";
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                var message = {name: 'jobStatus', value: $scope.status};
                $scope.$emit('event:changeJobScope', message);
                $state.transitionTo("loggedin.customer_list.view.job.details", {
                    'id': $scope.selectedId,
                    'type': $scope.customerTabName,
                    'jobId': $scope.jobId
                }, {reload: true});
            }
        })
    }

    $scope.freeofchargemodal = function ($event) {
        $event.preventDefault();
        $scope.shouldBeOpen = true;
    }

    $scope.backTo = function ()
    {
        $state.transitionTo("loggedin.customer_list.view.job.details", { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'jobId': $scope.jobId });
    }
}

// Additional work accept or reject tab
function JobAdditionalWorkAcceptRejectCtrl($scope, $state, prefix, $http, getIdData, $rootScope, spreadSheetData)
{
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.additionalWorkId = $state.params.additionalWorkId || getIdData.additionalWorkId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.advanceEstimate = getIdData.advanceEstimate;
    $scope.leftNavCount = getIdData.leftNavCount;
    $scope.jobStatus = getIdData.jobStatus;
    $scope.completedReason = getIdData.completedReason;
    $scope.bookDiary= {event: false};
    if($scope.jobStatus === 'completed') {
        $scope.dontAllow = true;
    } else {
        $scope.dontAllow = false;
    }
    $scope.canWork = getIdData.canWork;
    if ($scope.leftNavCount.priceTotal == $scope.leftNavCount.invoiceScheduleTotal) {
        $scope.invalid_invoice_schedule = false;
    } else {
        $scope.invalid_invoice_schedule = true;
        $scope.estimate_total = $scope.leftNavCount.priceTotal;
        $scope.invoice_total = $scope.leftNavCount.invoiceScheduleTotal;
        $scope.invoice_difference = $scope.estimate_total - $scope.invoice_total;
    }
    $scope.selectedTab = "AdditionalWorks";

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);

    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.acceptRejectSubmitButton = false;
    $scope.estimate_options = getIdData.estimateObject;
    $scope.estimateRejectReasons = getIdData.estimateRejectReasons;
    $scope.acceptButton = true;

    $scope.estimateAccept = function (estimateOptionRec) {
        $scope.estimateOptionRecs = [];
        angular.forEach(estimateOptionRec, function (key, value) {
            $scope.estimateOptionRecs.push({'estimateId': key.estimate_id, 'estimateOptionId': key.estimate_option_id});
            $scope.shouldBeOpenAccept = true;
        });
    }
    $scope.estimateReject = function (estimateOptionRec) {
        $scope.estimateOptionRecs = [];
        angular.forEach(estimateOptionRec, function (key, value) {
            $scope.estimateOptionRecs.push({'estimateId': key.estimate_id, 'estimateOptionId': key.estimate_option_id});
            $scope.shouldBeOpenReject = true;
        });
    }

    $scope.estimateOptionsSuccess = function (selectedId, jobId, bookDiaryEvent) {
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + selectedId + '/jobs/'+ $scope.jobId +'/additional_works/'+ $scope.additionalWorkId +'/view/accept_or_reject').success(function (data, status) {
            if (status == 200) {
                $scope.estimate_options = data.estimateObject;
                $scope.estimateRejectReasons = data.estimateRejectReasons;
                $scope.acceptRejectSubmitButton = false;
                $scope.estimateId = $scope.estimate_options[0].estimate_id;
                if(data.additionalWorkType == 1){
                    var type = 'additional_work', typeId = $scope.estimateId;
                }else if(data.additionalWorkType == 2){
                    var type = 'job', typeId = jobId;
                }

                for(var i = 0, l = $scope.estimate_options.length; i < l; i++){
                    var option = $scope.estimate_options[i];
                    $rootScope.$emit('acceptRejectTabCheck', 1);
                    if (option.confirmText === "Accepted") {
                        $scope.acceptButton = false;
                        $scope.historicalEstimate = true;

                        // Check whether Job screen have permission or not.
                        var featureName = 'Jobs', featureAccess = 'readaccess', featureId = features[featureName];
                        var jobPermissions = $rootScope.hasPermission(featureId, featureAccess);

                        if(jobPermissions && bookDiaryEvent) {
                            $state.transitionTo("loggedin.diary_from_job_estimate", { 'typeId': typeId, 'type': type});
                        } else if(jobPermissions && !bookDiaryEvent){
                            $state.transitionTo('loggedin.customer_list.view.job.details', {'id': selectedId, 'type': data.customerTabName, 'jobId': jobId});
                        } else {
                            $state.transitionTo('loggedin.customer_list.view.property', {'id': selectedId, 'type': data.customerTabName});
                        }
                    } else if(option.confirmText == "Rejected") {
                        $scope.historicalEstimate = true;
                        $state.transitionTo("loggedin.customer_list.view.job.additional_works", {'id': selectedId, 'type': data.customerTabName, 'jobId': $scope.jobId}, {reload:true});
                    }
                }
            }
        });
    }

}

function viewPart ($scope, $rootScope, jobData, $state, $stateParams)
{
    $scope.partViewData = jobData.getJobPartData();

    $scope.purchaseOrders = $scope.partViewData['purchaseOrderDetails'][0];
    $scope.stockDetails = $scope.partViewData['stockDetails'];
    $scope.diaryDetails = $scope.partViewData['diaryDetails'];
    $scope.returnDetails = $scope.partViewData['returnDetails'][0];

    $scope.$state = $state;

    $scope.show_purchase_order = false;
    $scope.show_supplier_invoice = false;
    $scope.show_stock_details = false;
    $scope.show_event_details = false;
    $scope.show_return_details = false;

    $scope.taxLabel = $rootScope.taxLabel;

    $scope.status = $scope.sidepanelData.Status;

    if ($scope.status === 'Requested') {

    } else if ($scope.status === 'On Order') {
        $scope.show_purchase_order = true;
    } else if ($scope.status === 'Available' || $scope.status === 'Returned To Stock') {
        $scope.show_stock_details = true;
    } else if ($scope.status === 'Scheduled') {
        $scope.show_event_details = true;
    } else if ($scope.status === 'In Progress') {
        $scope.show_event_details = true;
    } else if ($scope.status === 'Installed') {
        $scope.show_event_details = true;
    } else if ($scope.status === 'Cancelled') {
        $scope.show_event_details = true;
    } else if ($scope.status === 'Returned') {
        $scope.show_return_details = true;
    }

}

function SMSMessageControllers($scope) {
    $scope.$watch('message', function (newVal, oldVal) {
        if (newVal == undefined && oldVal == "") {
            $scope.messageError = false;
        }
        else {
            var tinyInstance = tinymce.get('communication_smsmessage_message');
            if (tinyInstance.getContent() == "") {
                $scope.messageError = true;
            }
            else {
                $scope.messageError = false;
            }
        }
    });
}

function CustomerAccountCreditCtrl($scope, $http, $state, prefix, getIdData, defaultPagingValue, canLoad, $q, $rootScope, tableCollection) {
    tableCollection.setData('customer_account_credit', getIdData.customer_account_credit);
    $scope.selectedId = $state.params.id;
    $scope.$emit('tabCustomer:selected', getIdData.subNavDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("CustomerPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Customer List';
            var excelRouteName = 'downloadAccountCreditExcel';
            var download = prefix + '/' + excelRouteName + '?page=' + pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Customer List';
            var pdfRouteName = 'printAccountCreditPdf';
            var print = prefix + '/' + pdfRouteName + '?page=' + pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    $scope.pdfRoute = prefix + '/printAccountCreditPdf?'+ 'id ='+$scope.selectedId;
    $scope.excelRoute = prefix + '/downloadAccountCreditExcel?'+ 'id ='+$scope.selectedId;

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
}
function CreateNewCustomerAccountCreditCtrl($scope, $state, $http, prefix, getIdData, $rootScope, creditcards) {
    $scope.selectedId = $state.params.id;
    $scope.customerSubNavStatic = true;
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.cardCharges = getIdData.cardCharges;
    $scope.enableCardIntegration = getIdData.enableCardIntegration;
    $scope.paymentIntegration = getIdData.paymentIntegration;

    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.showCardDetails = false;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    var addUrl = prefix + '/customers/' + $state.params.type + '/' + $state.params.id + '/customer_account_credit/new_payment';

    $scope.formSubmit = function (formElem) {
        $http.post(addUrl, formElem.serialize()).
        success(function (data, status) {
            $scope.newPaymentForm.$setPristine();
            $scope.saving = false;
            $scope.selectedId = data.id;
            $rootScope.flashMessage = data.message;
            $state.transitionTo('loggedin.customer_list.view.account_credit', {'id': $scope.selectedId, 'type': $scope.customerTabName });
        })
    }
    $scope.resetCardDetails = function (model) {
        if($scope.paymentIntegration) {
            var cardTypeValue = $scope.payment.method;
            $scope.nominal_AC_id = $scope.paymentIntegration[cardTypeValue];
            var selectedAccount = $scope.nominalAccounts.find(account => account.id == $scope.nominal_AC_id);
            if (selectedAccount) {
                $scope.nominalAccount = selectedAccount.id;
            }
            else{
                $scope.nominalAccount = '';
            }
        }
    }
}
function InvoiceAddressAccountCreditCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope, tableCollection)
{
    $scope.selectedId = $state.params.id;

    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.$emit('tabInvoice:selected', getIdData.subNavDetails);

    tableCollection.setData('invoice_address_account_credit', getIdData.invoice_address_account_credit);

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.pdfRoute = prefix + '/printAccountCreditPdf?'+ 'id ='+$scope.selectedId;
    $scope.excelRoute = prefix + '/downloadAccountCreditExcel?'+ 'id ='+$scope.selectedId;
}
function CreateNewInvoiceAddressAccountCreditCtrl($scope, $state, $http, prefix, getIdData, $rootScope, creditcards)
{
    $scope.selectedId = $state.params.id;
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.cardCharges = getIdData.cardCharges;
    $scope.enableCardIntegration = getIdData.enableCardIntegration;

    $scope.$emit('tabInvoice:selected', getIdData);
    $scope.showCardDetails = false;

    var addUrl = prefix + '/invoice_address/' + $state.params.id + '/invoice_address_account_credit/add_payment';

    $scope.formSubmit = function (formElem) {
        $http.post(addUrl, formElem.serialize()).
        success(function (data, status) {
            $scope.newPaymentForm.$setPristine();
            $scope.saving = false;
            $scope.selectedId = data.id;
            $rootScope.flashMessage = data.message;
            $state.transitionTo('loggedin.invoice_address.view.account_credit', {'id': $scope.selectedId });
        })
    }
}

function EditCustomerAccountCreditCtrl($scope, $state, $http, prefix, getIdData, $rootScope, $translate, creditcards) {

    $scope.selectedId = $state.params.id;
    $scope.customerSubNavStatic = true;
    $scope.screen = 'customer';

    $scope.$emit('tabCustomer:selected', getIdData.subNavDetails);

    $scope.accountCreditId = $state.params.accountCreditId;
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.nominalCode = getIdData.creditAccountDetails.nominalAccount;
    $scope.selectedDate = getIdData.creditAccountDetails.paymentdate.date;
    $scope.paymentMethod = getIdData.creditAccountDetails.pamentMethodId;
    $scope.amount = getIdData.creditAccountDetails.amount;
    $scope.editAmount = getIdData.editAmount;
    $scope.reference = (getIdData.creditAccountDetails.reference != null) ? getIdData.creditAccountDetails.reference : '';

    var creditAccountDetails = $scope.creditAccountDetails = getIdData.creditAccountDetails;
    if(creditAccountDetails.pamentMethodId == 2) {
        $scope.payment = {};
        $scope.addressLine1 = (creditAccountDetails.addressline1) ? creditAccountDetails.addressline1 : '';
        $scope.addressLine2 = (creditAccountDetails.addressline2) ? creditAccountDetails.addressline2 : '';
        $scope.addressLine3 = (creditAccountDetails.addressline3) ? creditAccountDetails.addressline3 : '';
        $scope.town = (creditAccountDetails.town) ? creditAccountDetails.town : '';
        $scope.county = (creditAccountDetails.county) ? creditAccountDetails.county : '';
        $scope.postcode = (creditAccountDetails.postcode) ? creditAccountDetails.postcode : '';
        $scope.nameOnCard = (creditAccountDetails.nameOnCard) ? creditAccountDetails.nameOnCard : '';
        $scope.telephone = (creditAccountDetails.telephone) ? creditAccountDetails.telephone : '';
        $scope.email = (creditAccountDetails.email) ? creditAccountDetails.email : '';
        $scope.payment.number = parseInt(creditAccountDetails.cardNumber);
        $scope.payment.cvc = creditAccountDetails.cvcCode;
        $scope.expiryDate = (creditAccountDetails.expiryDate) ? moment(creditAccountDetails.expiryDate.date).format('YYYY-MM-DD') : '';
        $scope.expiryDateToView = (creditAccountDetails.expiryDate) ? moment(creditAccountDetails.expiryDate.date).format('YYYY-MM-DD') : '';
    }
    $scope.showCardDetails = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    // for add card details is not used in account credit page if used change payment method represent to show card value
    // if($scope.paymentMethod == 2 || $scope.paymentMethod == 5) {
    //     $scope.showCardDetails = true;
    // }

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: $scope.creditAccountDetails.paymentdate});
        $scope.selectedDate = $scope.creditAccountDetails.paymentdate.date;
        $scope.paymentmethod = creditAccountDetails.pamentMethodId;
        if(creditAccountDetails.pamentMethodId == 2) {
            $scope.payment.number = parseInt(creditAccountDetails.cardNumber);
            $scope.payment.cvc = creditAccountDetails.cvcCode;
            $scope.handleCardValidation();
        }
    });
    $scope.$on('smartexpiryinput:date_changed', function(e, date) {
        $scope.expiry_error = false;

        $scope.payment.expiration_month = date.split(' / ')[0],
            $scope.payment.expiration_year = date.split(' / ')[1];

        $scope.handleCardValidation();
    });

    $scope.$watch('selectedDate', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '') {
            $scope.selectedDate = newVal;
        }
    });

    $scope.$watch('amount', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '') {
            $scope.newAmount = newVal;
            if($scope.newAmount < $scope.editAmount){
                $scope.showError = true;
                $scope.validation_message = $translate('The.amount.must.be.greater.than.the.allocated.amount');
            } else {
                $scope.showError = false;
            }
        } else {
            $scope.showError = false;
        }
    });

    $rootScope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.updateAccountCredit = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        var editDate = moment(this.selectedDate).format("YYYY-MM-DD");
        var saveUrl = prefix + '/save_account_credit/' + $state.params.id + '/account_credit/' + $scope.accountCreditId + '/save_customer_account_credit';

        $http.post(saveUrl, "accountCredit[id]=" + this.selectedId
            + "&accountCredit[accountCreditId]=" + encodeURIComponent(this.accountCreditId)
            + "&accountCredit[selectedDate]=" + editDate
            + "&accountCredit[reference]=" + encodeURIComponent(this.reference)
            + "&accountCredit[nominalCode]=" + encodeURIComponent(this.nominalCode)
            + "&accountCredit[amount]=" + encodeURIComponent(this.amount)
            + "&accountCredit[paymentMethod]=" + encodeURIComponent(this.paymentMethod)).
        success(function (data, status) {
            if (status == 200) {
                $scope.id = data;
                $rootScope.flashMessage = "Account Credit Updated";
                $state.transitionTo("loggedin.customer_list.view.account_credit", { 'id': $state.params.id, 'type': $state.params.type });
                $scope.updating = false;
            }
        });
    }

}
function EditInvoiceAddressAccountCreditCtrl($scope, $state, $http, prefix, getIdData, $rootScope, $translate, creditcards) {

    $scope.selectedId = $state.params.id;

    $scope.accountCreditId = $state.params.accountCreditId;
    $scope.nominalAccounts = getIdData.nominalAccounts;
    $scope.paymentMethods = getIdData.paymentMethods;
    $scope.nominalCode = getIdData.creditAccountDetails.nominalAccount;
    $scope.selectedDate = getIdData.creditAccountDetails.paymentdate.date;
    $scope.paymentMethod = getIdData.creditAccountDetails.pamentMethodId;
    $scope.amount = getIdData.creditAccountDetails.amount;
    $scope.editAmount = getIdData.editAmount;
    $scope.reference = (getIdData.creditAccountDetails.reference != null) ? getIdData.creditAccountDetails.reference : '';

    var creditAccountDetails = $scope.creditAccountDetails = getIdData.creditAccountDetails;

    if(creditAccountDetails.pamentMethodId == 2) {
        $scope.payment = {};
        $scope.addressLine1 = (creditAccountDetails.addressline1) ? creditAccountDetails.addressline1 : '';
        $scope.addressLine2 = (creditAccountDetails.addressline2) ? creditAccountDetails.addressline2 : '';
        $scope.addressLine3 = (creditAccountDetails.addressline3) ? creditAccountDetails.addressline3 : '';
        $scope.town = (creditAccountDetails.town) ? creditAccountDetails.town : '';
        $scope.county = (creditAccountDetails.county) ? creditAccountDetails.county : '';
        $scope.postcode = (creditAccountDetails.postcode) ? creditAccountDetails.postcode : '';
        $scope.nameOnCard = (creditAccountDetails.nameOnCard) ? creditAccountDetails.nameOnCard : '';
        $scope.telephone = (creditAccountDetails.telephone) ? creditAccountDetails.telephone : '';
        $scope.email = (creditAccountDetails.email) ? creditAccountDetails.email : '';
        $scope.payment.number = parseInt(creditAccountDetails.cardNumber);
        $scope.payment.cvc = creditAccountDetails.cvcCode;
        $scope.expiryDate = (creditAccountDetails.expiryDate) ? moment(creditAccountDetails.expiryDate.date).format('YYYY-MM-DD') : '';
        $scope.expiryDateToView = (creditAccountDetails.expiryDate) ? moment(creditAccountDetails.expiryDate.date).format('YYYY-MM-DD') : '';
    }

    $scope.showCardDetails = false;
    $scope.invoiceSubNavStatic = true;
    $scope.screen = 'invoice_address';
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // if($scope.paymentMethod == 2 || $scope.paymentMethod == 5) {
    //     $scope.showCardDetails = true;
    // }

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: $scope.creditAccountDetails.paymentdate});
        $scope.selectedDate = $scope.creditAccountDetails.paymentdate.date;
        $scope.payment.method = creditAccountDetails.pamentMethodId;
        if(creditAccountDetails.pamentMethodId == 2) {
            $scope.payment.number = parseInt(creditAccountDetails.cardNumber);
            $scope.payment.cvc = creditAccountDetails.cvcCode;
            $scope.handleCardValidation();
        }
    });
    $scope.$on('smartexpiryinput:date_changed', function(e, date) {
        $scope.expiry_error = false;

        $scope.payment.expiration_month = date.split(' / ')[0],
            $scope.payment.expiration_year = date.split(' / ')[1];

        $scope.handleCardValidation();
    });

    $scope.$emit('tabInvoice:selected', getIdData.subNavDetails);

    $scope.$watch('selectedDate', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '') {
            $scope.selectedDate = newVal;
        }
    });

    $scope.$watch('amount', function (newVal, oldVal) {
        if (newVal != undefined && newVal != '') {
            $scope.newAmount = newVal;
            if($scope.newAmount < $scope.editAmount){
                $scope.showError = true;
                $scope.validation_message = $translate('The.amount.must.be.greater.than.the.allocated.amount');
            } else {
                $scope.showError = false;
            }
        } else {
            $scope.showError = false;
        }
    });

    $rootScope.$on('datepicker:datepicker_selected', function(event, message){
        $scope.selectedDate = message.date;
    });

    $scope.updateAccountCredit = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        var editDate = moment(this.selectedDate).format("YYYY-MM-DD");
        var saveUrl = prefix + '/save_account_credit/' + $state.params.id + '/account_credit/' + $scope.accountCreditId + '/save_invoice_address_account_credit';

        $http.post(saveUrl, "accountCredit[id]=" + this.selectedId
            + "&accountCredit[accountCreditId]=" + encodeURIComponent(this.accountCreditId)
            + "&accountCredit[selectedDate]=" + editDate
            + "&accountCredit[reference]=" + encodeURIComponent(this.reference)
            + "&accountCredit[nominalCode]=" + encodeURIComponent(this.nominalCode)
            + "&accountCredit[amount]=" + encodeURIComponent(this.amount)
            + "&accountCredit[paymentMethod]=" + encodeURIComponent(this.paymentMethod)).
        success(function (data, status) {
            if (status == 200) {
                $scope.id = data;
                $rootScope.flashMessage = "Account Credit Updated";
                $state.transitionTo("loggedin.invoice_address.view.account_credit", { 'id': $state.params.id });
                $scope.updating = false;
            }
        });
    }

}
function ViewPurchaseOrderCtrl($scope, getIdData, prefix, $http, $rootScope, formService, $state, emailService, $compile, $timeout) {
    $scope.supplierId = getIdData.navBarDetails.id;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.purchaseOrderId = getIdData.poData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "Costs";
    $scope.customerId = $scope.customerSubNavDetails.id;

    $scope.vatType = getIdData.poData.vatType;

    $scope.isPoDelete = getIdData.poData.isPoDelete;
    $scope.isPoReject = getIdData.poData.isPoReject;
    $scope.isPoUnReject = getIdData.poData.isPoUnReject;
    $scope.purchaseOrderLogs = getIdData.purchaseOrderLogs;
    $scope.customerSubNavDetails = true;
    $scope.jobSubNavStatic = false;
    $scope.navBarDetails = true;
    $rootScope.supplierId = getIdData.navBarDetails.id;
    $scope.wolseleyDetails = getIdData.wolseleyDetails;
    $rootScope.thirdpartyIntegrationName =  $scope.wolseleyDetails.thirdpartyName;
    $scope.$emit('jobId:selected',  $state.params.jobId);
    handleViewPo.call(this, $scope, getIdData, prefix, $http, $rootScope, formService, $state, emailService);
    $scope.tabRouteCustomer = { 'customerSubNavDetails': $scope.customerSubNavDetails, 'navBarDetails': $scope.navBarDetails, 'jobSubNavStatic': $scope.jobSubNavStatic, 'poData': $scope.poData, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.$emit('updatePoStatus', {'isPoDelete': $scope.isPoDelete, 'isPoReject': $scope.isPoReject, 'isPoUnReject': $scope.isPoUnReject, 'purchase_order_has_items_that_are_arrived': $scope.markArrivedStatus, 'status': $scope.poStatus});

    $scope.$on("informationbar:purchase_order_complete", function (event, message) {
        $state.transitionTo("loggedin.list_supplier.view.invoice.details", { 'id': $scope.supplierId , 'invoiceId' : $scope.poData.invoiceId });
    });
    $scope.showPOpdf = function showPOpdf(){
        var side_panel_tpl = $compile('<span id="view_pdf" ' +
            'sidepanel template="print_and_post" ' +
            'supports_print="true"'+
            'print_and_post_message="Your purchase order is on its way to the supplier"' +
            'title="Print and post purchase order"'+
            'pdf_url="'+ prefix + '/customers/customer/ '+ $scope.customerId + '/jobs/' + $scope.jobId + '/costs/purchase_orders/' +  $scope.purchaseOrderId + '/printPurchaseOrder"'+
            'printpost_params="tableType=6&tablePKID='+ $scope.purchaseOrderId +'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_pdf').scope().$$childTail.initialise();
        }, 600);
    }
    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_pdf')) {
            angular.element('#view_pdf').remove();
        };
    });
    
    //wolseley
   
    $scope.isSupplierPoIntegration = false;
    var featureName = 'SupplierPoIntegration', featureAccess = 'readaccess', featureId = features[featureName];
    $scope.isSupplierPoIntegration = $rootScope.hasPermission(featureId, featureAccess);
    
    $scope.isWolseleySetup = 0;
    if( $scope.isSupplierPoIntegration ) {
        $http.get(prefix + '/getWolseleyAccountData?supplierId='+$scope.supplierId+'&page=1&limit=1').success(function(data) {
                
            $scope.wolseleyData = data.data;
            //(data.count);
            if( data.count > 0 ) {
                if( data.accountStatus == 1 ) {
                    $scope.isWolseleySetup = 1;
                } else {
                    $scope.isWolseleySetup = 0;
                }
            } else {
                $scope.isWolseleySetup = 0;
            }
        });
    }

    $scope.shouldBeOpenWolseley = false;
    $scope.triggerSendPO = function() {
         $scope.shouldBeOpenWolseley = true;
    }
    $scope.sendToWolseley = function () {
        $http.post(prefix + '/sendPOWolseley', "po[id]=" + $state.params.purchaseOrderId + "&po[supplierId]=" +$scope.supplierId+'&po[thirdparty]='+$rootScope.thirdpartyIntegrationName).
        success(function (data, status) {
            if (status == 200) {
                
                $scope.$emit('tabSupplier:successMessage', "Successfully send to Wolseley");
                if( $state.params.jobId ) {
                    $state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order",  { 'id': $state.params.id, 'type': $state.params.type, 'jobId': $state.params.jobId, 'purchaseOrderId': $state.params.purchaseOrderId }, {reload:true})
                } else {
                    $state.transitionTo("loggedin.list_supplier.view.purchase_order.details", { 'id': $scope.supplierId , 'purchaseOrderId' : $state.params.purchaseOrderId  }, {reload:true});
                    
                }
                //$rootScope.sending = false;
                //$rootScope.poStatus = data.status;
            }
        });
    }
    
    $scope.panelData = {};
    $scope.panelData.poDetails = getIdData;

    $scope.sending = true;

    $scope.openPOSidePanel  = function openPOSidePanel() {
        if (angular.element('#wolseley_po')) {
            angular.element('#wolseley_po').remove();
        };
        var side_panel_tpl1 = $compile('<span id="wolseley_po" ' +
            'sidepanel template="send_po_wolseley" ' +
            'title="Send Po"'+ ' maindata="panelData" ></span>')($scope);
        
        angular.element('body').append(side_panel_tpl1);
        $timeout(function() {
            angular.element('#wolseley_po').scope().$$childTail.initialise();
        }, 600);
    }
    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#wolseley_po')) {
            angular.element('#wolseley_po').remove();
        };
    });
}

function handleViewPo($scope, getIdData, prefix, $http, $rootScope, formService, $state, emailService) {
    $scope.navBarDetails = $state.params.navBarDetails || getIdData.navBarDetails;
    $scope.poData = getIdData.poData;
    $scope.purchaseOrders = getIdData.purchaseOrders;

    $scope.taxBreakdownByRates = getIdData.taxBreakdownByComponents.taxRateOfBreakdownByRates;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;

    $scope.markArrivedStatus = getIdData.poData.purchase_order_has_items_that_are_arrived;
    $scope.poStatus = getIdData.poData.status;
    $scope.prefix = prefix;
    $scope.audits = getIdData.audits;
    $scope.reorderAudit = getIdData.reorderAudit;
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: true});
    if($scope.poData.ponumber) {
        $scope.poData.poid = $scope.poData.ponumber;
    } else {
        $scope.poData.poid = $scope.poData.id;
    }

    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 6) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                if($scope.reorderAudit != null){
                    if($scope.audits.length > 1){
                        $scope.audits[0] = data[0]
                    }else{
                        $scope.audits.splice(0,0,data[0]);
                    }
                }else{
                    $scope.audits = data;
                }
            });
        }

    });
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_supplier_emailaddresses?id=' + $scope.supplierId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=6&id=' + $scope.poData.id;
        clone_obj.subject = getIdData.emailData.subject;
        clone_obj.message = getIdData.emailData.message;
        clone_obj.relatedObjectType = 6;        // For PO
        clone_obj.relatedObjectId = $scope.poData.id;
        clone_obj.attached_files = [
            {
                id: "PO|" + $scope.supplierId + "|" + $scope.poData.id,
                file_name: 'Purchase Order No. ' + $scope.poData.id,
                file_size: 0,
                type: 'generated'
            }
        ];
        $rootScope.$broadcast('email:createNew', clone_obj);
    }
}
function JobOfficeTaskCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, $q) {
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = "OfficeTask";

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic': false };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);
}

function CustomerServicePlanCtrl($scope, $http, $state, datasets, prefix, getIdData, canLoad, $q, $rootScope) {

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Service plan';

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    CommonServicePlan.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);

    if($scope.servicePlans.length) {
        $scope.selectedDate = moment($scope.servicePlans[0].expiredate.date);
        $scope.servicePlanDate = moment($scope.servicePlans[0].expiredate.date);
    }
}

function AddServicePlanCtrl($scope, $http, $state, datasets, prefix, getIdData, canLoad, $q, $rootScope)
{
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Service plan';

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    CommonServicePlan.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);

    // For work address we set invoice address id with radio option.
    $scope.setInvoiceAddress = function setInvoiceAddress(id) {
        $scope.invoiceAddressId = id;
        $scope.checkContactStatus();
    }
}

function EditServicePlanCtrl($scope, $http, $state, datasets, prefix, getIdData, canLoad, $q, $rootScope)
{
    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Service plan';

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.servicePlan = getIdData.servicePlan;
    $scope.activePanel = $scope.servicePlan.id;
    $scope.servicePlanName = $scope.servicePlan.description;
    $scope.servicePlanDetails = $scope.servicePlan.details;

    CommonServicePlan.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);
    //$scope.$emit('event:change_default_selected_date', {date: $scope.servicePlan.expiredate});
    $scope.servicePlanDate = moment($scope.servicePlan.expiredate.date);
    $scope.contactDetails = getIdData.contactDetails;
    $scope.checkContactStatus();

}

function InvoiceServicePlanCrl($scope, $http, $state, datasets, prefix, getIdData, canLoad, $q, $rootScope)
{
    $scope.selectedId = getIdData.invoiceValues.id;

    $scope.selectedTab = "Service plan";
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);

    CommonServicePlan.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);

    if($scope.servicePlans.length) {
        $scope.selectedDate = moment($scope.servicePlans[0].expiredate.date);
    }
}

function InvoiceAddServicePlanCrl($scope, $http, $state, datasets, prefix, getIdData, canLoad, $q, $rootScope)
{
    $scope.selectedId = getIdData.invoiceValues.id;

    $scope.selectedTab = "Service plan";
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);

    CommonServicePlan.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);

}

function InvoiceEditServicePlanCrl($scope, $http, $state, datasets, prefix, getIdData, canLoad, $q, $rootScope)
{
    $scope.selectedId = getIdData.invoiceValues.id;

    $scope.selectedTab = "Service plan";
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': $scope.selectedTab, 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    // Pass the invoice details.
    $scope.$emit('tabInvoice:selected', getIdData.invoiceValues);

    $scope.servicePlan = getIdData.servicePlan;
    $scope.activePanel = $scope.servicePlan.id;
    $scope.servicePlanName = $scope.servicePlan.description;
    $scope.helpText = $scope.servicePlan.details;
    $scope.helpText = '';
    CommonServicePlan.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);
    // $scope.$emit('event:change_default_selected_date', {date: $scope.servicePlan.expiredate});
    $scope.servicePlanDate = moment($scope.servicePlan.expiredate.date);

    $scope.contactDetails = getIdData.contactDetails;
    $scope.checkContactStatus();

}

function CommonServicePlan($scope, $state, $http, prefix, $rootScope, getIdData)
{
    $scope.contactChecked = false;
    $scope.servicePlans = getIdData.servicePlans;
    $scope.contactDetails = getIdData.contactDetails;
    $scope.customerType = getIdData.customerType;
    $scope.invoiceAddressId = '';
    $scope.servicePlanDate = moment();

    $scope.changeServicePlan = function changeServicePlan(planId) {
        $scope.activePanel = planId;
        $scope.selectedServicePlan = _.findWhere($scope.servicePlans, function(plan) {
            id: planId
        });
    }

    $rootScope.$on('datepicker_selected', function(event, message){
        $scope[message.modelName] =message.date;
    });

    // Validate the check box and radio button
    $scope.checkContactStatus = function checkContactStatus() {
        $scope.contactChecked = false;

        // Check whether any one property is selected or not
        angular.forEach($scope.contactDetails, function(val,key) {
            if(val.selected) {
                $scope.contactChecked = true;
            }
        });
    }

    // Add a new service plan
    $scope.savePreference = function savePreference() {
        $scope.saving = true;
        var date = moment($scope.servicePlanDate, 'L').format('YYYY-MM-DD'),
            params = 'servicePlan[settingsServicePlanId]='+$scope.activePanel+'&servicePlan[expireDate]='+date+'&servicePlan[customerType]='+$scope.customerType;

        if($scope.customerType == 'invoiceAddress') {
            var pathToSave= prefix + '/invoice_address/' + $scope.selectedId + '/service_plan/add';
        } else {
            var pathToSave= prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/service_plan/add';
        }

        if($scope.customerType == 'workaddress') {
            params = params + '&servicePlan[invoiceAddressId]=' + $scope.invoiceAddressId;
        } else {
            params = params + '&servicePlan[contacts]='+encodeURIComponent(angular.toJson($scope.contactDetails));
        }

        $http.post(pathToSave, params).
        success(function (data, status) {
            if (status == 200) {
                $scope.$emit('tabCustomer:successMessage', "Service plan added");
                $scope.servicePlanCancel();
            }
        });

    }

    $scope.updatePreference = function updatePreference()   {
        $scope.saving = true;
        var date = moment($scope.servicePlanDate, 'L').format('YYYY-MM-DD'),
            params = 'servicePlan[expireDate]='+date+'&servicePlan[customerType]='+$scope.customerType;
        if($scope.customerType == 'invoiceAddress') {
            var  pathToSave= prefix + '/invoice_address/' + $scope.selectedId + '/service_plan/update/'+$scope.customerType + '/' +$scope.activePanel;
        } else {
            var  pathToSave= prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/service_plan/update/'+$scope.activePanel;
        }

        params = params + '&servicePlan[contacts]='+angular.toJson($scope.contactDetails);

    $http.post(pathToSave, params).success(function (data, status) {
      if (status == 200) {
        $scope.$emit('tabCustomer:successMessage', "Service plan updated");
        if($rootScope.previousState) {
            if($rootScope.previousState!="loggedin.empty") {
                $state.transitionTo($rootScope.previousState, $rootScope.previousStateparams);
            }else{
                history.back();
            }
        } else {
          $scope.servicePlanCancel();
        }
      }
    });
  }

  $scope.servicePlanCancel = function servicePlanCancel() {
      if($rootScope.previousState=="loggedin.empty") {
          history.back();
      }else{
          if($scope.customerType == 'invoiceAddress') {
              $state.transitionTo("loggedin.invoice_address.view.service_plan", {id: $state.params.id});
          } else {
              $state.transitionTo("loggedin.customer_list.view.service_plan", {id: $state.params.id, type: $state.params.type});
          }
      }
  }

    $scope.$on("modal:close", function() {
        $scope.deleteServicePlanShouldBeOpen = false;
    })

    $scope.showDeleteBox = function(deleteServicePlanId) {
        $scope.deleteServicePlanId = deleteServicePlanId;
        $scope.deleteServicePlanShouldBeOpen = true;
    }

    $scope.deleteServicePlanDetails = function() {
        $scope.flashMessage = 'Service plan deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        if($scope.customerType == 'invoiceAddress') {
            $state.transitionTo('loggedin.invoice_address.view.service_plan', {id: $state.params.id}, {reload: true});
        } else {
            $state.transitionTo('loggedin.customer_list.view.service_plan', {id: $state.params.id, type: $state.params.type}, {reload: true});
        }
    }
    $scope.checkContactStatus();
}
function EstimateOfficeTaskCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, $q) {
    $scope.selectedId = $scope.customerSelectedId = $state.params.id;
    $scope.selectedTab = "OfficeTask";
    $scope.hideSubNavCustomers = true;
    $scope.reminders = getIdData.reminders;
    $scope.remindersCount = getIdData.remindersCount;
    $scope.futureRemindersCount = getIdData.futureRemindersCount;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;

    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.estimateSubNavStatic = false;
    $scope.$emit('displayScreen', false);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':false };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
}
function CustomerStoredCardsCtrl($scope, getIdData, $state, defaultPagingValue, $http, prefix, canLoad, $q) {
    $scope.selectedId = $state.params.id;
    $scope.storedCards = getIdData.storedCards;
    $scope.storedCardsCount = getIdData.storedCardsCount;
    $scope.cardVendorSupportsStoredCards = getIdData.cardVendorSupportsStoredCards;
    $scope.selectedTab = 'Stored cards';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.triggerDelete = function (id, cardNumber) {
        $scope.selectedCardId = id;
        $scope.cardDescription = cardNumber;
        $scope.shouldBeOpen = true;

    }

    $scope.$watch('currentPage', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
    });

    var url = '/customers/' + $state.params.type +'/'+ $state.params.id + '/listStoredCard';
    var canceler = null;
    $scope.fetchStoredCards = function (pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        $http.get(prefix + url + '?page=' + pageNum + '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            $scope.storedCards = data.storedCards;
            $scope.storedCardsCount = data.storedCardsCount;
        });
    }

    $scope.fetchAllStoredCards = function (pageNum) {
        $scope.flashMessage = 'Card Deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $scope.fetchStoredCards(pageNum);
    }
}
function AddStoredCardCtrl($scope, $state, getIdData, $http, cardExpiryMonths, cardExpiryYears, prefix, creditcards) {

    $scope.selectedId = $state.params.id;
    $scope.selectedTab = 'Stored cards';

    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerSubNavStatic = true;
    $scope.customerTabName = $state.params.type;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();
    $scope.showInvoiceAddressCancel = false;
    $scope.showCustomerCancel = true;

    var addUrl = prefix + '/customers/'+ $state.params.type+'/'+ $state.params.id + '/stored_card/add';

    $scope.formSubmit = function (formElem) {
        $scope.error = false;
        $scope.isDisable = false;
        $http.post(addUrl, formElem.serialize()).
        success(function (data, status) {
            if (data['status'] == 'failure') {
                $scope.error = data['response']['message'];
                $scope.saving = false;
            } else {
                $scope.newStoredCardForm.$setPristine();
                $scope.isDisable = true;
                $state.transitionTo("loggedin.customer_list.view.stored_cards", {id: $state.params.id, type: $state.params.type}, {reload:true});
                $scope.saving = false;
            }
        });
    }
    handleCardCharges.call(this, $scope, creditcards);

    $scope.goBackStoredCards = function() {
        $state.transitionTo("loggedin.customer_list.view.stored_cards", {id: $state.params.id, type: $state.params.type}, {reload:true});
    }
}
function InvoiceAddressStoredCardsCtrl($scope, getIdData, $state, defaultPagingValue, $http, prefix, canLoad, $q, $rootScope)
{
    $scope.selectedId = $state.params.id;
    $scope.storedCards = getIdData.storedCards;
    $scope.storedCardsCount = getIdData.storedCardsCount;
    $scope.cardVendorSupportsStoredCards = getIdData.cardVendorSupportsStoredCards;
    $scope.selectedTab = 'Stored cards';
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.$emit('tabInvoice:selected', getIdData.customerDetails);

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.$watch('currentPage', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
    });

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }
    $scope.triggerDelete = function (id, cardNumber) {
        $scope.selectedCardId = id;
        $scope.cardDescription = cardNumber;
        $scope.shouldBeOpen = true;

    }

    var url = '/invoice_address/' + $state.params.id + '/listStoredCard';
    var canceler = null;
    $scope.fetchStoredCards = function (pageNum) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        $http.get(prefix + url + '?page=' + pageNum + '&limit=' + $scope.limit, {timeout: canceler.promise}).success(function (data) {
            $scope.storedCards = data.storedCards;
            $scope.storedCardsCount = data.storedCardsCount;
        });
    }

    $scope.fetchAllStoredCards = function (pageNum) {
        $scope.flashMessage = 'Card Deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $scope.fetchStoredCards(pageNum);
    }
}
function InvoiceAddressAddStoredCardCtrl($scope, $state, getIdData, $http, cardExpiryMonths, cardExpiryYears, prefix, creditcards)
{
    $scope.selectedId = $state.params.id;
    $scope.invoiceSubNavStatic = true;
    $scope.tabRouteInvoice = { 'selectedTab': '', 'invoiceSubNavStatic':$scope.invoiceSubNavStatic };
    $scope.$emit('tabRouteInvoice:selected', $scope.tabRouteInvoice);

    $scope.$emit('tabInvoice:selected', getIdData.customerDetails);

    $scope.cardExpiryMonths = cardExpiryMonths.getCardExpiryMonths();
    $scope.cardExpiryYears = cardExpiryYears.getCardExpiryYears();
    $scope.showInvoiceAddressCancel = true;
    $scope.showCustomerCancel = false;

    var addUrl = prefix + '/invoice_address/' + $state.params.id + '/stored_card/add';

    $scope.formSubmit = function (formElem) {
        $scope.error = false;
        $scope.isDisable = false;
        $http.post(addUrl, formElem.serialize()).
        success(function (data, status) {
            if (data['status'] == 'failure') {
                $scope.error = data['response']['message'];
                $scope.saving = false;
            } else {
                $scope.newStoredCardForm.$setPristine();
                $scope.isDisable = true;
                $state.transitionTo("loggedin.invoice_address.view.stored_cards", {id: $state.params.id}, {reload:true});
                $scope.saving = false;
            }
        });
    }
    handleCardCharges.call(this, $scope, creditcards);

    $scope.goBackStoredCards = function() {
        $state.transitionTo("loggedin.invoice_address.view.stored_cards", {id: $state.params.id}, {reload:true});
    }
}

function InvoiceAddressAndCustomerDebtsDetails (debtorInvoiceAddressId, $http, prefix, $filter, $scope,$rootScope) {
    $http.get(prefix + '/invoice_address/' + debtorInvoiceAddressId + '/get_debts').success(function (data) {
        var debts_data = {
            total: 0.00,
            current:0.00,
            ninety: 0.00,
            ninety_plus: 0.00,
            thirty: 0.00,
            sixty: 0.00
        };
        angular.forEach(data, function(v, i) {
            debts_data['total'] += parseFloat(data[i]['Total']);
            debts_data['current'] += parseFloat(data[i]['Current']);
            debts_data['ninety'] += parseFloat(data[i]['61-90']);
            debts_data['sixty'] += parseFloat(data[i]['31-60']);
            debts_data['thirty'] += parseFloat(data[i]['0-30']);
            debts_data['ninety_plus'] += parseFloat(data[i]['90']);

        });
        debts_data['text']='debtor';
        debts_data['show'] = (debts_data.total > 0);
        debts_data['total'] = $filter('customCurrency')(debts_data.total, $rootScope.CustomCurrency);
        debts_data['current'] = $filter('customCurrency')(debts_data.current, $rootScope.CustomCurrency);
        debts_data['ninety'] = $filter('customCurrency')(debts_data.ninety, $rootScope.CustomCurrency);
        debts_data['sixty'] = $filter('customCurrency')(debts_data.sixty, $rootScope.CustomCurrency);
        debts_data['thirty'] = $filter('customCurrency')(debts_data.thirty, $rootScope.CustomCurrency);
        debts_data['ninety_plus'] = $filter('customCurrency')(debts_data.ninety_plus, $rootScope.CustomCurrency);

        $scope.debts = angular.copy(debts_data);

        $scope.debts['html'] = '<span class="invoice-tooltip-content"><span>Total money owed: ' + $scope.debts.total + '</span><span>Current: ' + $scope.debts.current + '</span><span>1-30 days old: ' + $scope.debts.thirty + '</span><span>31-60 days old: ' + $scope.debts.sixty + '</span><span>61-90 days old: ' + $scope.debts.ninety + '</span><span>90+ days old: ' + $scope.debts.ninety_plus + '</span><div class="clearfix"></div>';
    });

}

function GetAllLanlordsCtrl($scope, $state, $http, prefix,preselect) {
    $scope.select2OptionsLandLords = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_landlords',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            if(preselect){
                var data = {
                    id: preselect['id'],
                    text: preselect['text']
                };
                callback(data);
            }else{
                callback($(element).data('$ngModelController').$modelValue);
            }
        }
    }
}
/*
function CloseVideoModal($scope, $modalInstance) {
    $scope.close = function () {
        $scope.shouldBeOpen = true;
        $modalInstance.close();
    }
}*/
function DatePickerWithDefaultJobDate($scope, $element, $rootScope, clickEvents, $filter) {
    DatePickerWidget.call(this, $scope, $element, $rootScope, clickEvents, $filter);
}

function ConfigureSlaSidepanel($scope, $rootScope,$state, sidePanel, $http, prefix,toastBox){

    $scope.selectedId = $state.params.id;
    GetAllSLACtrl.call(this, $scope, prefix,$scope.selectedId);

    $http.get(prefix + '/get_sla_details_records/'+$scope.selectedId).success(function (data) {
        $scope.slaSetup = data.sla;
        $scope.selectedSlaId = data.slaId;
        $scope.slaData = data.slaData;
        if($scope.slaData!=''){
            GetAllSLACtrl.call(this, $scope, prefix,$scope.selectedId,$scope.slaData);
        }else{
            GetAllSLACtrl.call(this, $scope, prefix,$scope.selectedId);
        }
    });

    $scope.changeSla = function changeSla(slaData) {
        this.selectedSlaId = slaData.id;
    }
    $scope.$parent.shouldBeOpen=false;

    $scope.closePanel = function closePanel() {
        $rootScope.$emit('closeAllSidepanels');
    }

    $scope.saveSla = function (){
        $scope.error = false;
        this.selectedSlaId = $scope.slaData.id;
        $http.post(prefix + '/get_sla_details_post/' +$scope.selectedId+'/'+this.selectedSlaId).success(function (data) {
           if(data.error =='Please confirm'){
               $scope.$parent.selectedId = $scope.selectedId+','+$scope.slaData.id;
               $scope.$parent.postParams = 'editcustomerSla[slaId]='+ $scope.selectedId+','+$scope.slaData.id;
               $scope.$parent.shouldBeOpen = true;
           }else{
               $rootScope.$emit('closeAllSidepanels');
               $state.transitionTo("loggedin.customer_list.view.property", {id: $state.params.id,type:$state.params.type}, {reload:true});
               toastBox.show('saved', 4000);
           }
        });
    }
}


function GetAllSLACtrl($scope, prefix,selectedId,preselect) {
    $scope.select2OptionsSLA = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_sla_details_records/'+selectedId,
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data['sla'] };
            }
        },
        initSelection : function (element, callback) {
            if(preselect){
                var data = {
                    id: preselect['id'],
                    text: preselect['text']
                };
                callback(data);
            }else{
                callback($(element).data('$ngModelController').$modelValue);
            }
        }
    }
}
function GetPricingItemsCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, tableCollection, $rootScope, $compile, $timeout, canLoad, $q, formPanelCollection){
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.jobId = $state.params.jobId || getIdData.jobId;
    $scope.selectedTab = 'Pricingitems';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.jobSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customerContractId = $state.params.customerContractId || getIdData.customerContractId;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.CustomCurrency = $rootScope.CustomCurrency;


    $scope.$emit('jobId:selected', $scope.jobId);
    $scope.lineItems = getIdData.lineItems;
    $scope.taxRates = getIdData.taxRates;
    $scope.pricingItemsId = [];


    if (getIdData.lineItems) {
        var allocated_total = 0;
        var remaining_total = 0;
        angular.forEach(getIdData.lineItems, function (v, i) {
            if(getIdData.lineItems[i]['already_invoiced']){
                allocated_total += parseFloat(getIdData.lineItems[i]['total_price']);
            }else{
                remaining_total += parseFloat(getIdData.lineItems[i]['total_price']);
                $scope.pricingItemsId.push(getIdData.lineItems[i]['pricingItemID']);
            }
        });
        $scope.allocatedTotal = allocated_total;
        $scope.remainingTotal = remaining_total;
        if ($scope.pricingItemsId.length) {
            $scope.pricingItemsId = $scope.pricingItemsId.filter(x => x !== null);
            $scope.excludePricingItems = $scope.pricingItemsId.join(',');
        }
    }


    $scope.triggerDelete = function (lineItem) {
        $scope.selectedId = lineItem.line_item_id;
        $scope.shouldBeOpen = true;
    }
    $scope.openBreakdownSidepanel = function(lineItem){
        $scope.$emit('breakdown-sidepanel-template',lineItem);
    }
    var canceler = null;
    var url = '/customers/' + $state.params.type +'/'+ $state.params.id + '/job/'+ $state.params.jobId +'/invoice_items/view';
    var excludeUrl = '/get_pricing_items?customerId='+$scope.selectedId + '&contractId=' + $scope.customerContractId;
    $scope.endPoint = "/customers/" + $state.params.type +"/"+$scope.selectedId+"/jobs/"+$scope.jobId+"/invoice_items/save";
    var pricingItemLabel = {
        "pricing_item": {
            "fields": [
                {
                    "label": "Select a pricing item",
                    "type": "smart_filter_dynamic",
                    "url": excludeUrl+'&excludePricingItems='+ $scope.excludePricingItems,
                    "required": false,
                    "model": "pricing_item",
                    "paidFilter" : "ng-change='getPricingItems()'   ",
                },
                {
                    "filePath": "/template/side_panels/pricing_item_sidepanel.html",
                    "type": "dynamicTemplate",
                    "templateCategory": "display_pricing_items",
                }
            ]
        }
    };
    $scope.fetchInvoiceItems = function(){
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();

        $http.get(prefix + url, {timeout: canceler.promise}).success(function (data) {
            $scope.lineItems = data.lineItems;
            if (data.lineItems) {
                var allocated_total = 0;
                var remaining_total = 0;
                $scope.pricingItemsId = [];
                angular.forEach(data.lineItems, function (v, i) {
                    if(data.lineItems[i]['already_invoiced']){
                        allocated_total += parseFloat(data.lineItems[i]['total_price']);
                    }else{
                        remaining_total += parseFloat(data.lineItems[i]['total_price']);
                        $scope.pricingItemsId.push(data.lineItems[i]['pricingItemID']);
                    }
                });
                $scope.allocatedTotal = allocated_total;
                $scope.remainingTotal = remaining_total;
                if ($scope.pricingItemsId.length) {
                    $scope.pricingItemsId = $scope.pricingItemsId.filter(x => x !== null)
                    $scope.excludePricingItems = $scope.pricingItemsId.join(',');
                }
            }
        });
    }


    formPanelCollection.setFilterData(pricingItemLabel);
    var removeListener = $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.fetchInvoiceItems();
    });
    $scope.$on("$destroy", removeListener);
    $scope.$on('sidepanel-template', function (evt, instance) {
        instance.isShowPanel = false;
        instance.form_is_valid = false;
        instance.getPricingItems = function () {
            if(instance.pricing_item)
            {
                instance.isShowPanel = true;
                instance.PI_quantity = 1;
                instance.PI_includedMinutes = 0;
                instance.PI_unitprice = instance.pricing_item.unitprice;
                instance.PI_total = (instance.pricing_item.unitprice * instance.PI_quantity).toFixed(2);
                instance.Tax_rate=instance.pricing_item.taxId;

                $http.get(prefix+'/get_pricing_items?customerId='+$scope.selectedId + '&contractId=' + $scope.customerContractId+'&excludePricingItems='+ $scope.excludePricingItems).success(function (value,status) {
                    if(typeof value.error == 'undefined' || value.error === false) {
                        instance.form_is_valid = true;
                        instance.isShowPanel = true;
                    }else
                    {
                    }
                }).error(function(error) {
                    console.log(error.data);
                });
            }
        };

    });
    $rootScope.$on("pricing_item:url", function (event, data) {
        if(pricingItemLabel){
            pricingItemLabel['pricing_item']['fields']['0']['url'] = excludeUrl+'&excludePricingItems='+ $scope.excludePricingItems;
            formPanelCollection.setFilterData(pricingItemLabel);
        }
    });
}

function JobOpportunitiesListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope) {
    // ViewReminderListCtrl.call(this, $scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope)
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.selectedId = $state.params.id;
    $scope.opportunity = getIdData;
    $scope.selectedTab = "Opportunities";
    $scope.commonName ='job';
    $scope.commonId = $state.params.jobId;
    $scope.opportunities = $scope.opportunity['opportunitiesList'];
    $scope.opportunitiesCount = $scope.opportunity['count'];
    $scope.loadingOpportunity = true;
    $scope.fetchingOpportunity = false;
    $scope.canWork = getIdData.customerNav['canWork'];
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData.customerNav);
    $scope.$emit('jobId:selected',  $state.params.jobId);

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    $scope.fetchReminders = function (pageNum) {
        $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.selectedId + '/'+$scope.commonName+'/' + $scope.commonId +'/opportunities?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.opportunities = data.opportunitiesList;
            $scope.opportunitiesCount = data.count;
            $scope.customerDetails = data.customerNav;
            var message = {name: 'opportunitiesCount', value: $scope.opportunitiesCount};
            $rootScope.$emit('event:changeJobScope',message);
            $scope.$emit('tabCustomer:selected', data.customerNav);
        })
    }
}

