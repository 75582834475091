commusoftCommon.directive('cstagging', ['prefix', '$http','tagList','$state', function(prefix, $http,tagList,$state) {
    return {
        restrict: 'EA',
        scope: {
            controlledBy: '@',
            controls: '@',
            tags: '@'
        },
        templateUrl: 'template/tagging_controls.html',
        controller: function($scope) {
            $scope.formatStr = function formatStr(str) {
                var formatted_str = str.split('_');
                for (var i=0; i<formatted_str.length; i++) {
                    formatted_str[i] = formatted_str[i].charAt(0).toUpperCase() + formatted_str[i].slice(1);
                }
                return formatted_str.join('.');
            }
            $scope.setTag = function setTag(tag){
                tinyMCE.activeEditor.execCommand('mceInsertContent', false, '['+tag+']');
            }
        },
        link: function(scope, element, attrs) {
            scope.taggingAction = false;
            angular.element(scope.controlledBy).click(function() {
                if (angular.element('.page-sidebar').css('right') == "0px") {
                    scope.$apply(function() { scope.taggingAction = false; });
                    angular.element(this).html("Use tagging");
                } else {
                    scope.$apply(function() { scope.taggingAction = true; });
                    angular.element(this).html("Close tagging");
                }
            });
            angular.element('.close-sidebar').click(function() {
                angular.element(scope.controlledBy).trigger('click');
                // scope.$apply(function() { scope.taggingAction = false; });
                // angular.element('.open-sidebar').html("Use tagging");
            });
            angular.element('.tagging-help-btn').click(function() {
                if (angular.element('.close-tagging-help-wrap').has(angular.element(this)).length != 0) {
                    angular.element('.tags-wrapper').slideDown();
                    angular.element('.tagging-help-section').hide();
                    angular.element('.close-tagging-help-wrap').hide();
                    angular.element('.open-tagging-help-wrap').show();
                } else {
                    angular.element('.tagging-help-section').slideDown();
                    angular.element('.tags-wrapper').hide();
                    angular.element('.close-tagging-help-wrap').show();
                    angular.element('.open-tagging-help-wrap').hide();

                }
            });
            scope.tagsName = make_ordered_hash();
            scope.tagsValue = make_ordered_hash();
            scope.tags = scope.tags.split(',');
            angular.forEach(scope.tags, function(value, key){
                scope.tagsName.push(scope.formatStr(value), value);
                scope.tagsValue.push(value, tagList.tagging.val(value));
            });
            scope.hideTag = '';
            if($state.current.name === 'loggedin.payment_receipts_message') {
                scope.hideTag = 'statement_portal_url';
            }
            $http.get(prefix+"/get_restricted_tags"+"?tagList="+angular.toJson(scope.tags)).success(function (data,status) {
                angular.forEach(data, function(tagValue, tagKey){
                    if(tagValue.length == 0){
                        if(tagKey == 'service_reminder'){
                            scope.tagsValue.add(tagKey,'service_reminder_booking_portal_url');
                        }
                    }else{
                        angular.forEach(tagValue , function(unpermittedTags)
                        {
                            scope.tagsValue.remove(tagKey,unpermittedTags);
                            window.tagsValue = scope.tagsValue;
                        })
                    }
                });
            });

            window.tagsValue = scope.tagsValue;
        }
    }
}]);
