commusoftCommon.service('warningModal', function($q, $compile, $rootScope) {
    this.show = function show(message, title, identifier, translateParams) {
        var scope = $rootScope.$new(true);
        translateParams = (translateParams === undefined) ? {} : translateParams;

        if (identifier === undefined) {
            identifier = 'cannot-delete-modal';
        }

        var modal_tpl = '<div cannotdeletemodal message="' +  message +
            '" title="'  + title +
            '" translateparams="' + JSON.stringify(translateParams) +
            '" identifier="' + identifier +
            '" class="cannot-delete-directive ' + identifier +
            '" open_on_compile="true" remove_on_close="true"></div>',
            modal_dir = $compile(modal_tpl)(scope)[0];

        angular.element('body').append(modal_dir);
    }
    this.clean = function clean(identifier) {
        angular.element("div[identifier='" + identifier + "']").remove();
    }
});
